import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container } from "@mui/material";

import { debugLevel } from "config/personalize";
import Button from "components/MaterialKit/MKButton";
import Box from "components/MaterialKit/MKBox";
import IdeaSelect from "../idea/IdeaSelect";
import NavbarTitle from "../navbar/NavbarTitle";
import DialogNoSave from "../dialog/DialogNoSave";
import CreateIdea from "../idea/CreateIdea";
import InfoCard from "../layout/InfoCard";

const DialogIdeaAdd = ({
  title = "",
  activityType, // 0:Info-message, 1:inTell, 2:Framing-statement
  handleDialogClose,
}) => {
  const [tabValue, setTabValue] = useState(activityType === 0 || activityType === 2 ? 1 : 0); // if info or framing, goto myContent view by default
  const [confirmExit, setConfirmExit] = useState(false);
  const [editsMade, setEditsMade] = useState(false);
  const [confirmTabChange, setConfirmTabChange] = useState(false);
  const [fieldsValid, setFieldsValid] = useState(false);
  const [createIdeaBusy, setCreateIdeaBusy] = useState(false);

  const handleOnSelect = (e) => {
    if (e) {
      // User selected idea from list
      handleDialogClose(e);
    }
  };

  const handleBackClick = () => {
    if (tabValue === 1 && editsMade) {
      setConfirmExit(true);
    } else {
      // No changes made; exit directly
      handleDialogClose(null);
    }
  };

  const handleConfirmExit = (e) => {
    if (confirmExit) {
      if (debugLevel > 0) console.log("ConfirmExit", e);
      if (e) {
        handleDialogClose(null);
      }
      setConfirmExit(false);
    } else {
      if (debugLevel > 0) console.log("ConfirmTabChange", e);
      if (e) {
        setTabValue(0);
      }
      setConfirmTabChange(false);
    }
  };

  const [requestSave, setRequestSave] = useState(false);
  const onSubmit = () => {
    setRequestSave(true);
    // This function sets the requestSave state to true
  };

  const handleTabValue = (e) => {
    if (e === 0 && editsMade) {
      // tab switching to 0 & edits made
      setConfirmTabChange(true);
    } else {
      setTabValue(e);
    }
  };

  const handleOnNewIdea = (e) => {
    if (debugLevel > 0) console.log("handleOnNewIdea");
    if (e) {
      // User successfully created a new idea
      handleDialogClose(e);
    }
    setRequestSave(false);
  };

  return (
    <>
      <Dialog open fullScreen>
        <NavbarTitle
          paper
          title={title}
          backClick={handleBackClick}
          showSave={tabValue === 0 ? -1 : editsMade && fieldsValid && !createIdeaBusy ? 1 : 0}
          saveClick={onSubmit}
        />
        <Container sx={{ mt: 5 }}>
          <Box
            sx={{
              mb: 2,
              display: "grid",
              gap: 0,
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <Button
              fullWidth
              onClick={() => handleTabValue(0)}
              sx={{
                borderBottom: tabValue === 0 ? "3px solid #0074F4" : "",
                borderRadius: 0,
              }}
            >
              Select
            </Button>
            <Button
              fullWidth
              onClick={() => handleTabValue(1)}
              sx={{
                borderBottom: tabValue === 1 ? "3px solid #0074F4" : "",
                borderRadius: 0,
              }}
            >
              Create
            </Button>
          </Box>
          <Box sx={{ mt: 4 }}>
            {/* Select existing */}
            {tabValue === 0 && (
              <>
                {activityType === 0 && (
                  <InfoCard
                    title="Select an existing info message"
                    message="Info messages provide guidance and instruction to participants"
                  />
                )}
                {activityType === 1 && (
                  <InfoCard
                    title="Select an existing inTell"
                    message="Participants will interpret this inTell and indicate their views of its level of groundedness and goodness"
                  />
                )}
                {activityType === 2 && (
                  <InfoCard
                    title="Select an existing framing statement"
                    message="A framing statement is written as a declarative statement so that participants can indicate their level of agreement or disagreement"
                  />
                )}

                <Box sx={{ mt: 4 }}>
                  <IdeaSelect
                    setSelectedIdea={handleOnSelect}
                    instructions={
                      activityType === 0
                        ? 20 // any info
                        : activityType === 2
                          ? 19 // any framing, ideaType: 10-19
                          : 9 // any inTell, ideaType: 0-8
                    }
                  />
                </Box>
              </>
            )}

            {/* Create new idea */}
            {tabValue === 1 && (
              <>
                {activityType === 0 && (
                  <InfoCard
                    title="Create a new info message"
                    message="Info messages provide guidance and instruction to participants"
                  />
                )}
                {activityType === 1 && (
                  <InfoCard
                    title="Create a new inTell"
                    message="Participants will interpret this inTell and indicate their views of its level of groundedness and goodness"
                  />
                )}
                {activityType === 2 && (
                  <InfoCard
                    title="Create a new framing statement"
                    message="A framing statement is written as a declarative statement so that participants can indicate their level of agreement or disagreement"
                  />
                )}

                <CreateIdea
                  ideaType={activityType === 0 ? 20 : activityType === 1 ? 0 : 10}
                  newCreatedIdea={handleOnNewIdea}
                  setFieldsValid={setFieldsValid}
                  setEditsMade={setEditsMade}
                  requestSave={editsMade && fieldsValid && requestSave}
                  disableExpertise={activityType === 0}
                  createIdeaBusy={createIdeaBusy}
                  setCreateIdeaBusy={setCreateIdeaBusy}
                />
              </>
            )}
          </Box>
        </Container>
      </Dialog>

      {confirmExit && <DialogNoSave handleDialogClose={handleConfirmExit} />}
      {confirmTabChange && <DialogNoSave handleDialogClose={handleConfirmExit} />}
    </>
  );
};

DialogIdeaAdd.propTypes = {
  activityType: PropTypes.number,
  handleDialogClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default DialogIdeaAdd;
