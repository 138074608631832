import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Grid, Alert } from "@mui/material";

import IntellCard from "../intell/IntellCard";
import ElephantFramingStatement from "../chart/ElephantFramingStatement";
import ElephantRationalGood from "../chart/ElephantRationalGood";
import exists from "../../utils/exists";

const Elephant = ({
  interpretations,
  showChangeLines,
  isQuestion = false,
  updatedCount,
  setUpdatedCount,
  idea, // eslint-disable-line
  auth,
}) => {
  const lgSize = useMediaQuery("only screen and (min-width: 1200px)");

  // CONSTANTS
  const [emphasizeExpertise] = useState(3); // ranges from 1 (no emphasis) to ...: 1, 3, 3^2, 3^3 (maps to expertise levels 1, 2, 3, 4)

  const [dataPoints, setDataPoints] = useState([]);
  //  const [avgX, setAvgX] = useState(0);
  //  const [avgY, setAvgY] = useState(0);
  //  const [count, setCount] = useState(0);
  //  const [stdevX, setStdevX] = useState(0);
  //  const [stdevY, setStdevY] = useState(0);
  //  const [sharpeX, setSharpeX] = useState(0);
  //  const [sharpeY, setSharpeY] = useState(0);
  const [myStarIndex, setMyStarIndex] = useState(-1);

  const [selected, setSelected] = useState([]);

  //  const standardDeviation = (arr) => {
  //    const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
  //    return Math.sqrt(
  //      arr
  //        .reduce((acc, val) => acc.concat((val - mean) ** 2), [])
  //        .reduce((acc, val) => acc + val, 0) /
  //        (arr.length - 1)
  //    );
  //  };

  //  const avg = (arr) => {
  //    return arr.reduce((acc, val) => acc + val, 0) / arr.length;
  //  };

  useEffect(() => {
    // Force a refresh (page was not fully refreshing when moving between posts; carried over some outdated data)
    setSelected([]);

    const data = [];
    //    let uniqueCount = 0;
    let myStar = -1;
    let index = 0;
    //  0.1 = midpoint, 0.6 = max, -0.4 = min, range: max-min = 1.0
    //  cnt: interpretations.length (11)
    //  interval = if( cnt>1, range/(cnt-1), 0)
    //
    //  index:0   0.6  (max - index*interval)
    //  index:1   0.5
    //  ...
    //  index:9  -0.3
    //  index:10 -0.4
    interpretations
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .forEach((e, forLoopIndex) => {
        if (
          auth &&
          auth.user &&
          auth.user._id &&
          e.user &&
          e.user._id &&
          auth.user._id === e.user._id
        ) {
          myStar = index;
        }

        const interval = interpretations.length > 1 ? 1.0 / (interpretations.length - 1) : 0;
        if (exists(e.supportTopic)) {
          e.x = e.supportTopic;
          e.y = interpretations.length <= 1 ? 0.1 : 0.6 - forLoopIndex * interval;
          // e.y = 0.1;

          if (exists(e.supportTopic1)) {
            e.x1 = e.supportTopic1;
            if (!e.createdAt) {
              // console.log( "!e.createdAt", forLoopIndex);
              // Use existing ordering based on <updatedAt>
              e.y1 = interpretations.length <= 1 ? 0.1 : 0.6 - forLoopIndex * interval;
            } else {
              // console.log( "****  e. createdAt ***** ", forLoopIndex, new Date(e.createdAt) );
              // We have a valid <createdAt>. How does fit into the ordering?
              // Initialize it to to at the end of the array (making it the oldest)
              let updatedIndex = interpretations.length - 1;

              for (let j = forLoopIndex; j < interpretations.length - 1; j += 1) {
                // console.log( j+1, new Date(interpretations[j+1].updatedAt) );
                if (new Date(e.createdAt) > new Date(interpretations[j + 1].updatedAt)) {
                  // The <createdAt> is more recent than <interpretations[j+1].updatedAt>, record its updated index
                  // console.log("BREAK");
                  updatedIndex = j;
                  break;
                }
              }
              // console.log( "updatedIndex:", updatedIndex );
              // Updated y position on elephant graph may be shifted  based on updatedIndex
              e.y1 = interpretations.length <= 1 ? 0.1 : 0.6 - updatedIndex * interval;
            }
          }
        } else if (exists(e.rational) && exists(e.theGood)) {
          e.x = e.rational;
          e.y = e.theGood;
          if (exists(e.rational1) && exists(e.theGood1)) {
            e.x1 = e.rational1;
            e.y1 = e.theGood1;
          }
        }

        // Specify how much influence this interpretation has on the plots: 1, 3, 3^2, 3^3
        const loopQty = !e.expertise
          ? 1
          : e.expertise.myExpertiseLevel >= 4
            ? emphasizeExpertise * emphasizeExpertise * emphasizeExpertise
            : e.expertise.myExpertiseLevel >= 3
              ? emphasizeExpertise * emphasizeExpertise
              : e.expertise.myExpertiseLevel >= 2
                ? emphasizeExpertise
                : 1;

        // Create at least one dot for each interpretation
        for (let j = 0; j < loopQty; j += 1) {
          data.push(j === 0 ? e : { x: e.x, y: e.y }); // Strip down object to just x/y (when adding multiple dots)
          index += 1;
        }
        //      }
      });

    setDataPoints(data);
    setMyStarIndex(myStar);
    //    setCount(uniqueCount);
    //    setAvgX(avg(data.map((e) => e.x)));
    //    setAvgY(avg(data.map((e) => e.y)));
    //    setStdevX(standardDeviation(data.map((e) => e.x)));
    //    setStdevY(standardDeviation(data.map((e) => e.y)));
    //    setSharpeX(
    //      standardDeviation(data.map((e) => e.x)) !== 0
    //        ? avg(data.map((e) => e.x)) / standardDeviation(data.map((e) => e.x))
    //        : 0
    //    );
    //    setSharpeY(
    //      standardDeviation(data.map((e) => e.y)) !== 0
    //        ? avg(data.map((e) => e.y)) / standardDeviation(data.map((e) => e.y))
    //        : 0
    //    );
    setUpdatedCount(updatedCount + 1);
  }, [interpretations.length]);

  const filterElephant = (arr) => {
    return arr.filter(
      (e) =>
        (e.myPoint && e.myPoint.trim().length > 0) ||
        (e.associatedAgent && e.associatedAgent.name && e.associatedAgent.name.length > 0) ||
        (e.expertise && e.expertise.myExpertiseLevel > 1)
    );
  };

  // Each interpretation can have multiple children (each representing a inTell); extract all replies
  const extractAllReplies = (arr) => {
    const allReplies = [];
    arr.forEach((interpretationScale) => {
      if (interpretationScale.children && interpretationScale.children.length > 0) {
        interpretationScale.children.forEach((childIdea) => {
          if (!childIdea.ideaType || childIdea.ideaType === 0 || childIdea.ideaType === 1) {
            const newIdeaObj = childIdea;
            allReplies.push(newIdeaObj);
          }
        });
      } else {
        allReplies.push(interpretationScale);
      }
    });
    return allReplies;
  };

  //  <ElephantTitle
  //    avgX={avgX}
  //    avgY={avgY}
  //    count={count}
  //    stdevX={stdevX}
  //    stdevY={stdevY}
  //    sharpeX={sharpeX}
  //    sharpeY={sharpeY}
  //  />

  return (
    <>
      {!isQuestion && lgSize && (
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 2 }}
          alignItems={selected.length === 0 ? "center" : ""}
        >
          <Grid item xs>
            <div style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
              {/* ElephantTitle */}
              <ElephantRationalGood
                data={dataPoints}
                showChangeLines={showChangeLines}
                updatedCount={updatedCount}
                setSelected={setSelected}
                myStarIndex={myStarIndex}
              />
            </div>
          </Grid>
          <Grid item xs alignItems="center">
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              {selected.length === 0 && dataPoints.length > 0 && (
                <Alert severity="info">
                  Select a region on the elephant, deepen your understanding
                </Alert>
              )}
              {selected.length === 0 && dataPoints.length === 0 && (
                <Alert severity="info">Nothing to see, no interpretations yet</Alert>
              )}

              {filterElephant(extractAllReplies(selected)).map((e) => (
                <div key={Math.random()}>
                  <IntellCard intell={e} />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      )}

      {!isQuestion && !lgSize && (
        <>
          <div style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
            {/* ElephantTitle */}
            <ElephantRationalGood
              data={dataPoints}
              showChangeLines={showChangeLines}
              updatedCount={updatedCount}
              setSelected={setSelected}
              myStarIndex={myStarIndex}
            />
          </div>

          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            {selected.length === 0 && dataPoints.length > 0 && (
              <Alert severity="info">
                Select a region on the elephant, deepen your understanding
              </Alert>
            )}
            {selected.length === 0 && dataPoints.length === 0 && (
              <Alert severity="info">Nothing to see, no interpretations yet</Alert>
            )}
            {filterElephant(extractAllReplies(selected)).map((e) => (
              <div key={Math.random()}>
                <IntellCard intell={e} />
              </div>
            ))}
          </div>
        </>
      )}

      {isQuestion && (
        <>
          <div style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}>
            {/* ElephantTitle */}
            <ElephantFramingStatement
              data={dataPoints}
              showChangeLines={showChangeLines}
              updatedCount={updatedCount}
              setSelected={setSelected}
              myStarIndex={myStarIndex}
            />
          </div>

          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            {selected.length === 0 && dataPoints.length > 0 && (
              <Alert severity="info">
                Select a region on the elephant, deepen your understanding
              </Alert>
            )}
            {selected.length === 0 && dataPoints.length === 0 && (
              <Alert severity="info">Nothing to see, no interpretations yet</Alert>
            )}
            {filterElephant(extractAllReplies(selected)).map((e) => (
              <div key={Math.random()}>
                <IntellCard intell={e} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

Elephant.propTypes = {
  interpretations: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  showChangeLines: PropTypes.bool.isRequired,
  updatedCount: PropTypes.number.isRequired,
  setUpdatedCount: PropTypes.func.isRequired,
  isQuestion: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  auth: state.auth,
});

export default connect(mapStateToProps)(Elephant);
