import React from "react";
import PropTypes from "prop-types";

// MUI
import { FormControl, MenuItem, Select, Grid, Divider } from "@mui/material";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const MySelect = ({
  icon = null,
  label = "",
  subLabel = [""],
  select = 0,
  setSelect = null,
  menuList = [],
  align = "right",
  divider = "partial", // full, partial (or none)
  // activeSubLabel = true,
  children = undefined,
}) => {
  return (
    <>
      <Box sx={{ ml: 1 }}>
        <Grid container columnSpacing={{ xs: icon ? 1 : 0, sm: icon ? 2 : 0 }}>
          <Grid item sx={{ opacity: 0.7, mr: 2, ml: 2 }}>
            {icon}
          </Grid>
          <Grid item xs>
            {label.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ fontSize: { xs: "13px", sm: "15px", md: "17px" }, fontWeight: 500 }}
                >
                  {label}
                </Typography>
                {setSelect && (
                  <FormControl sx={{ m: 1, minWidth: { xs: 130, sm: 160 } }}>
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        mt: -0.7,
                      }}
                      value={select}
                      onChange={(e) => setSelect(e.target.value)}
                      inputProps={{ IconComponent: () => null }}
                    >
                      {menuList.map((e) => (
                        <MenuItem key={e.value} value={e.value} disabled={e.disabled}>
                          <Typography
                            variant="button"
                            color="primary"
                            sx={{
                              fontSize: { xs: "14px", sm: "16px", md: "18px" },
                              textAlign: align,
                            }}
                          >
                            {e.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
            {subLabel.length > 0 && (
              <>
                <Box sx={{ pt: label.length > 0 ? 0 : 0.5 }}>
                  {subLabel.map((e, index) => (
                    <div key={Math.random()}>
                      <Typography
                        sx={{ fontSize: { xs: "11px", sm: "12px", md: "13px" }, fontWeight: "100" }}
                      >
                        {subLabel[index]}
                      </Typography>
                    </div>
                  ))}
                </Box>
                {children}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Divider without any conditional margin adjustments */}
      {divider === "partial" && <Divider sx={{ mt: 2.5, ml: { xs: 8.5, sm: 9.5 } }} />}
      {divider === "full" && <Divider sx={{ mt: 2.5 }} />}
    </>
  );
};

MySelect.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]),
  select: PropTypes.number,
  menuList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  setSelect: PropTypes.func,
  label: PropTypes.string,
  subLabel: PropTypes.arrayOf(PropTypes.string),
  align: PropTypes.string,
  divider: PropTypes.string,
  children: PropTypes.node,
};

export default MySelect;
