import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI
import { Help, Warning } from "@mui/icons-material";

import { debugLevel } from "config/personalize";
import Box from "../MaterialKit/MKBox";
import Alert from "../MaterialKit/MKAlert";
import truncate from "../../utils/truncate";
import Spinner from "../layout/Spinner";
import SliderTheGood from "../slider/SliderTheGood";
import SliderRational from "../slider/SliderRational";
import SliderGoodFaith from "../slider/SliderGoodFaith";
import DialogMessage from "../dialog/DialogMessage";
import AssociateWavemaker from "../agent/AssociateWavemaker";
import QuestionText from "../styles/QuestionText";
import ClickText from "../styles/ClickText";
import Submit from "./Submit";
import Expertise from "../expertise/Expertise";
import Transcribe from "../idea/Transcribe";
import exists from "../../utils/exists";
import { setAlert } from "../../actions/alert";
import { addIdea, modifyIdea } from "../../actions/idea";

const InterpretIntell = ({
  intell,
  setIntell, // Update intell updated inTell
  updatedCount,
  setUpdatedCount,
  readOnly = false,
  alwaysInterpretable = false, // overrides any other rules
  addIdea,
  modifyIdea,
  idea,
  fellowship: { fellowship },
  auth,
}) => {
  // If an existing scale exists but does not contain <rational>, this scale was created as a dummy-stub for the inTell author
  // Essentially, we never ask users to provide a rational/good interpretation of their own inTell
  const [dummyMyScale] = useState(false);

  // Whenever intell changes, check whether current user has interpreted it
  const [myInterpretationExists, setMyInterpretationExists] = useState(false);
  const [initComplete] = useState(false);
  const [originalSaved, setOriginalSaved] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [interpretable, setInterpretable] = useState(false);

  useEffect(() => {
    if (
      !intell.scales ||
      intell.scales.length === 0 ||
      (intell.parentScale &&
        !intell.parentScale.expertise &&
        (!intell.parentScale.myExpertiseLevel || intell.parentScale.myExpertiseLevel <= 1)) ||
      (intell.parentScale &&
        intell.parentScale.expertise &&
        (!intell.parentScale.expertise.myExpertiseLevel ||
          intell.parentScale.expertise.myExpertiseLevel <= 1))
    ) {
      // Guaranteed not to be interpreted:
      // - no scales and/or no stub scales OR
      // - insufficient myExpertiseLevel
      setInterpretable(alwaysInterpretable);
    } else {
      // intTell is interpretable
      setInterpretable(true);

      if (
        auth &&
        auth.user &&
        auth.user._id &&
        intell.scales &&
        intell.scales.find((e) => e.user._id === auth.user._id)
      ) {
        setMyInterpretationExists(true);
      } else {
        setMyInterpretationExists(false);
      }
    }
  }, [intell]);

  // CONSTANTS
  // Let's assume associateWavemakerLevel==0.5. Associate waveMaker if:
  // (rational > 0.5 &$ theGood > 0.5) || (rational > 0.5 && theGood < -0.5) || (rational < -0.5 &$ theGood > 0.5) ||(rational < -0.5 $& theGood < -0.5)
  // *Note if associateWavemakerLevel>=1, this essentially means no waveMaker can be associated
  const [associateWavemakerLevel] = useState(0.5);
  const [requestExpertise] = useState(true);
  const [mandatoryMyPoint] = useState(fellowship.intellLink0 >= 0); // TODO -1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-after

  const [useAI] = useState(true);

  // DIALOGS
  const [goodFaithDialog, setGoodFaithDialog] = useState(false);
  const [intentDialog, setIntentDialog] = useState(false);

  // All items controllable by user
  const [rational, setRational] = useState(-2); // Typically ranges -1->1 (-2 represents uninitialized)
  const [goodFaith, setGoodFaith] = useState(-2); // Typically ranges  -1->1 (-2 represents uninitialized)
  const [theGood, setTheGood] = useState(-2); // Typically ranges -1->1 (-2 represents uninitialized)
  const [associatedAgent, setAssociatedAgent] = useState(null);
  const [linkWavemakerCheckbox, setLinkWavemakerCheckbox] = useState(true);
  const [myPoint, setMyPoint] = useState("");
  const [myExpertise, setMyExpertise] = useState({
    myExpertiseLevel: 1, // 1=non-expert, 2/3
    myExpertiseText: "",
    changedText: "",
    changedMind: false,
    steelMan: [""],
  });

  // TODO steelManOrig required
  const [rationalOrig, setRationalOrig] = useState(-2);
  const [goodFaithOrig, setGoodFaithOrig] = useState(-2);
  const [theGoodOrig, setTheGoodOrig] = useState(-2);
  const [associatedAgentOrig, setAssociatedAgentOrig] = useState(null);
  const [linkWavemakerCheckboxOrig, setLinkWavemakerCheckboxOrig] = useState(true);
  const [myPointOrig, setMyPointOrig] = useState("");
  const [myExpertiseLevelOrig, setMyExpertiseLevelOrig] = useState(1);
  const [myExpertiseTextOrig, setMyExpertiseTextOrig] = useState("");
  const [changedTextOrig, setChangedTextOrig] = useState("");
  const [changedMindOrig, setChangedMindOrig] = useState(false);

  const [displayGoodFaith, setDisplayGoodFaith] = useState(false);
  const [displayTheGood, setDisplayTheGood] = useState(false);
  const [displayAssociatedAgent, setDisplayAssociatedAgent] = useState(false);
  const [displayMyPoint, setDisplayMyPoint] = useState(false);
  const [displayExpertise, setDisplayExpertise] = useState(false);
  const [displaySubmit, setDisplaySubmit] = useState(false);

  // Use _theGood variable as theGood may not be yet be updated/available in this refresh cycle
  const linkWavemaker = (_theGood) => {
    if (
      (rational < 0 && associateWavemakerLevel < 1 && goodFaith > -2 && goodFaith < 0) ||
      ((_theGood > associateWavemakerLevel || _theGood < -associateWavemakerLevel) &&
        (rational > associateWavemakerLevel || rational < -associateWavemakerLevel))
    ) {
      return true;
    }
    return false;
  };

  const [editsMade, setEditsMade] = useState(false);
  useEffect(() => {
    if (originalSaved) {
      if (
        rationalOrig === rational &&
        (rational >= 0 || goodFaithOrig === goodFaith) &&
        theGoodOrig === theGood &&
        (!linkWavemaker(theGood) ||
          !linkWavemakerCheckbox ||
          associatedAgentOrig === associatedAgent) &&
        linkWavemakerCheckbox === linkWavemakerCheckboxOrig &&
        (!linkWavemakerCheckbox || (linkWavemakerCheckbox && associatedAgent)) &&
        myPoint === myPointOrig &&
        myExpertiseLevelOrig === myExpertise.myExpertiseLevel &&
        (myExpertise.myExpertiseLevel < 2 || myExpertiseTextOrig === myExpertise.myExpertiseText) &&
        (myExpertise.myExpertiseLevel < 2 || changedTextOrig === myExpertise.changedText) &&
        (myExpertise.myExpertiseLevel < 2 || changedMindOrig === myExpertise.changedMind)
      ) {
        setEditsMade(false);
      } else {
        setEditsMade(true);
      }
    }
  }, [
    rational,
    goodFaith,
    theGood,
    associatedAgent,
    linkWavemakerCheckbox,
    myPoint,
    //  myExpertiseLevel,
    //  myExpertiseText,
    //  changedText,
    //  changedMind,
    //  steelMan,
    myExpertise, // TODO - is this sufficient?
    originalSaved,
  ]);

  useEffect(() => {
    if (initComplete) {
      setRationalOrig(rational);
      setGoodFaithOrig(goodFaith);
      setTheGoodOrig(theGood);
      setAssociatedAgentOrig(associatedAgent);
      setLinkWavemakerCheckboxOrig(linkWavemakerCheckbox);
      setMyPointOrig(myPoint);
      setMyExpertiseLevelOrig(myExpertise.myExpertiseLevel);
      setMyExpertiseTextOrig(myExpertise.myExpertiseText.trim());
      setChangedTextOrig(myExpertise.changedText.trim());
      setChangedMindOrig(myExpertise.changedMind);
      setOriginalSaved(true);
    }
  }, [initComplete]);

  const displayExpertiseAndFieldsBelow = () => {
    if (requestExpertise) {
      // console.log("Expertise is being requested");
      setDisplayExpertise(true);
    } else {
      // console.log("Expertise NOT being requested");
      setDisplayExpertise(false);
      setDisplaySubmit(!readOnly); // Can submit [if not readOnly]
    }
  };

  const displayMyPointAndFieldsBelow = () => {
    if (myInterpretationExists) {
      // Do not display my point (we are editing existing interpretation)
      setDisplayMyPoint(false);
      console.log("No need to display my point , but display expertise");
      displayExpertiseAndFieldsBelow();
    } else {
      // Display my point only if adding new interpretation
      setDisplayMyPoint(true);

      if (myPoint.trim().length > 0 || !mandatoryMyPoint) {
        if (debugLevel > 0) console.log("<myPoint> provided (or not mandatory)");
        console.log("No need to display myPoint, but display expertise");
        displayExpertiseAndFieldsBelow();
      } else {
        if (debugLevel > 0) console.log("Waiting for <myPoint>");
        setDisplayExpertise(false);
        setDisplaySubmit(
          !mandatoryMyPoint || (mandatoryMyPoint && myPoint.trim().length > 0) ? !readOnly : false
        ); // Can submit only if myPoint not mandatory [if not readOnly]; submit button label & additional visibility controlled in the JSX markup below
      }
    }
  };

  // Use _theGood variable as theGood may not be yet be updated/available in this refresh cycle
  const displayTheGoodAndFieldsBelow = (_theGood) => {
    if (rational < 0) {
      // It's irrational... no need to show <theGood>
      setDisplayTheGood(false);
    } else {
      // It's rational... show <theGood>
      setDisplayTheGood(true);
    }
    if (_theGood > -2) {
      // <theGood> defined
      if (linkWavemaker(_theGood)) {
        // link waveMaker
        setDisplayAssociatedAgent(true);
        if (associatedAgent !== null || !linkWavemakerCheckbox) {
          // agent associated (or skipped by user)
          displayMyPointAndFieldsBelow();
        } else {
          // Waiting for <associatedAgent> (or skipped by user)
          setDisplayMyPoint(false);
          setDisplayExpertise(false);
          setDisplaySubmit(false);
        }
      } else {
        // waveMaker link not required
        setDisplayAssociatedAgent(false);
        displayMyPointAndFieldsBelow();
      }
    } else {
      // Waiting for <theGood>
      setDisplayAssociatedAgent(false);
      setDisplayMyPoint(false);
      setDisplayExpertise(false);
      setDisplaySubmit(false);
    }
  };

  // Everytime there is any user interaction, update what can/should be displayed
  useEffect(() => {
    if (rational > -2) {
      // <rational> defined
      if (rational < 0) {
        // Noise
        setDisplayGoodFaith(true);
        if (goodFaith > -2) {
          // <goodFaith> defined
          if (goodFaith < 0) {
            // Purposeful manipulation (set <theGood> appropriately -- negative [in evil quadrant])
            // Set theGood at same level as <goodFaith>
            setTheGood(goodFaith);
            displayTheGoodAndFieldsBelow(goodFaith);
          } else {
            // Not purposeful manipulation (set <theGood> appropriately -- positive [give benefit of the doubt, in foolish quadrant])
            // The higher the level of <goodFaith>, the higher the level of <theGood>
            // NOTE: the maximum level of <theGood> is limited to half of goodFaith (i.e, max=0.5)
            setTheGood(goodFaith / 2);
            displayTheGoodAndFieldsBelow(goodFaith / 2);
          }
        } else {
          // Waiting for <goodFaith>
          setDisplayTheGood(false);
          setDisplayAssociatedAgent(false);
          setDisplayMyPoint(false);
          setDisplayExpertise(false);
          setDisplaySubmit(false);
        }
      } else {
        // Signal
        setDisplayGoodFaith(false);
        displayTheGoodAndFieldsBelow(theGood);
      }
    } else {
      // Waiting for <rational>
      setDisplayGoodFaith(false);
      setDisplayTheGood(false);
      setDisplayAssociatedAgent(false);
      setDisplayMyPoint(false);
      setDisplayExpertise(false);
      setDisplaySubmit(false);
    }
  }, [
    rational,
    goodFaith,
    theGood,
    linkWavemakerCheckbox,
    associatedAgent,
    myPoint,
    //  myExpertiseLevel,
    //  myExpertiseText,
    //  changedText,
    //  changedMind,
    //  steelMan,
    myExpertise, // TODO - is this sufficient?
  ]);

  //  useEffect(() => {
  //    // First time entering & not submitting
  //    if (intell && intell.scales && !submitting) {
  //      for (let i = 0; i < intell.scales.length; i += 1) {
  //        if (
  //          intell.scales[i].user &&
  //          intell.scales[i].user._id &&
  //          JSON.stringify(intell.scales[i].user._id) === JSON.stringify(auth.user._id)
  //        ) {
  //          if (exists(intell.scales[i].rational)) {
  //            // Not Stub inTell
  //            setRational(intell.scales[i].rational);
  //            if (intell.scales[i].goodFaith) {
  //              setGoodFaith(intell.scales[i].goodFaith);
  //            }
  //            setTheGood(intell.scales[i].theGood);
  //            if (intell.scales[i].associatedAgent) {
  //              setAssociatedAgent(intell.scales[i].associatedAgent);
  //            } else {
  //              setLinkWavemakerCheckbox(false);
  //            }
  //          } else {
  // If an existing scale exists but does not contain <rational>, this scale was created as a stub for the inTell author
  // Create dummy values (they will be ignored on scale submission)
  //            setRational(0);
  //            setTheGood(0);
  //            setLinkWavemakerCheckbox(false);
  //            setDummyMyScale(true);
  //          }

  //          if (intell.scales[i].expertise) {
  //            setMyExpertiseLevel(
  //              intell.scales[i].expertise.myExpertiseLevel
  //                ? intell.scales[i].expertise.myExpertiseLevel
  //                : 1
  //            );
  //            if (
  //              intell.scales[i].expertise.myExpertiseText &&
  //              intell.scales[i].expertise.myExpertiseText.trim().length > 0
  //            ) {
  //              setMyExpertiseText(intell.scales[i].expertise.myExpertiseText.trim());
  //            }
  //            if (intell.scales[i].expertise.changedMind) {
  //              setChangedMind(intell.scales[i].expertise.changedMind);
  //            }
  //            if (
  //              intell.scales[i].expertise.changedText &&
  //              intell.scales[i].expertise.changedText.trim().length > 0
  //            ) {
  //              setChangedText(intell.scales[i].expertise.changedText.trim());
  //            }
  //          } else {
  //            setMyExpertiseLevel(
  //              intell.scales[i].myExpertiseLevel ? intell.scales[i].myExpertiseLevel : 1
  //            );
  //            if (
  //              intell.scales[i].myExpertiseText &&
  //              intell.scales[i].myExpertiseText.trim().length > 0
  //            ) {
  //              setMyExpertiseText(intell.scales[i].myExpertiseText.trim());
  //            }
  //            if (intell.scales[i].changedMind) {
  //              setChangedMind(intell.scales[i].changedMind);
  //            }
  //            if (intell.scales[i].changedText && intell.scales[i].changedText.trim().length > 0) {
  //              setChangedText(intell.scales[i].changedText.trim());
  //            }
  //          }
  //        }
  //      }
  //    }
  //    setInitComplete(true);
  // }, [intell]);

  // Everytime there is any user interaction, notify parent by updating a counter
  //  useEffect(() => {
  //    if (!myInterpretationExists && myPoint.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //   }
  //  }, [rational, goodFaith, theGood, linkWavemakerCheckbox, associatedAgent]);

  //  useEffect(() => {
  //    if (myExpertiseText.trim().length === 0 && !changedMind && changedText.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [myExpertiseLevel]);

  //  useEffect(() => {
  //    if (!changedMind && changedText.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [myExpertiseText]);

  //  useEffect(() => {
  //    if (changedText.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [changedMind]);

  //  useEffect(() => {
  //    setUpdatedCount(updatedCount + 1);
  //  }, [changedText]);

  useEffect(() => {
    if (editsMade && myInterpretationExists) setUpdatedCount(updatedCount + 1);
  }, [editsMade]);

  //  useEffect(() => {
  //    if (
  // /     myExpertiseLevel === 1 &&
  //      myExpertiseText.trim().length === 0 &&
  //      !changedMind &&
  //     changedText.trim().length === 0 &&
  //     !myInterpretationExists
  //    ) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [myPoint]);

  const onSubmit = () => {
    const navigate = useNavigate();
    if (!(exists(fellowship) && exists(fellowship._id))) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }

    let bValid = true;
    if (myInterpretationExists && !editsMade) {
      setAlert("You can only submit if changes made", "danger");
      bValid = false;
    }

    if (bValid) {
      setSubmitting(true);

      if (!myInterpretationExists) {
        // Add new scale
        if (debugLevel > 0) console.log("addIdea");
        if (debugLevel > 0) console.log("rational:", rational);
        if (debugLevel > 0) console.log("theGood:", theGood);
        if (debugLevel > 0)
          console.log("goodFaith:", goodFaith > -2 && rational < 0 ? goodFaith : null);
        if (debugLevel > 0)
          console.log(
            linkWavemaker(theGood) && associatedAgent && associatedAgent._id
              ? associatedAgent
              : null
          );
        if (debugLevel > 0) console.log(myPoint);

        addIdea({
          fellowshipId: fellowship._id,
          parentIdeaId: intell._id,
          rational,
          theGood,
          goodFaith: goodFaith > -2 && rational < 0 ? goodFaith : -2,
          associatedAgent:
            linkWavemakerCheckbox &&
            linkWavemaker(theGood) &&
            associatedAgent &&
            associatedAgent._id
              ? associatedAgent
              : null,
          myExpertiseLevel: myExpertise.myExpertiseLevel > -2 ? myExpertise.myExpertiseLevel : null,
          myExpertiseText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength)
              : "",
          changedMind: myExpertise.changedMind,
          changedText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength)
              : "",
          steelMan:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength)
              : [""],
          myPoint,
          ideaType: 0,
        });
      } else if (dummyMyScale) {
        // Modify existing (stub)
        // modifyIdea() can only modify existing <expertise> props, and cannot replace one expertise object with another
        modifyIdea(intell._id, {
          myExpertiseLevel: myExpertise.myExpertiseLevel > -2 ? myExpertise.myExpertiseLevel : null,
          myExpertiseText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength)
              : "",
          changedMind: myExpertise.changedMind,
          changedText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength)
              : "",
          steelMan:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength)
              : [""],
        });
      } else {
        // Modify existing (normal)
        // modifyIdea() can only modify existing <expertise> props, and cannot replace one expertise object with another
        modifyIdea(intell._id, {
          rational,
          theGood,
          goodFaith: goodFaith > -2 && rational < 0 ? goodFaith : -2,
          associatedAgent:
            linkWavemakerCheckbox &&
            linkWavemaker(theGood) &&
            associatedAgent &&
            associatedAgent._id
              ? associatedAgent
              : null,
          myExpertiseLevel: myExpertise.myExpertiseLevel > -2 ? myExpertise.myExpertiseLevel : null,
          myExpertiseText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength)
              : "",
          changedMind: myExpertise.changedMind,
          changedText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength)
              : "",
          steelMan:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelMan)
              : [""],
        });
      }
    }
  };

  // Exit conditions:
  // (1) If no initial user interpretation exists, simply finding one is sufficient
  // (2) If an initial user interpretation exists, has it changed?
  useEffect(() => {
    //  if (debugLevel > 0) console.log("useEffect idea.idea has changed", idea.idea);
    if (idea.idea && idea.idea.scales && submitting) {
      for (let i = 0; i < idea.idea.scales.length; i += 1) {
        if (idea.idea.scales[i].user && idea.idea.scales[i].user._id) {
          if (myInterpretationExists && idea.idea.scales[i].user._id === auth.user._id) {
            if (
              exists(idea.idea.scales[i].rational) &&
              idea.idea.scales[i].rational >= -1 && // Valid
              rationalOrig !== idea.idea.scales[i].rational
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <rational> changed from ",
                  rationalOrig,
                  " to ",
                  idea.idea.scales[i].rational
                );
              setIntell(idea.idea);
              break;
            } else if (
              exists(idea.idea.scales[i].theGood) &&
              idea.idea.scales[i].theGood >= -1 && // Valid
              theGoodOrig !== idea.idea.scales[i].theGood
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <theGood> changed from ",
                  theGoodOrig,
                  " to ",
                  idea.idea.scales[i].theGood
                );
              setIntell(idea.idea);
              break;
            } else if (
              exists(idea.idea.scales[i].goodFaith) &&
              idea.idea.scales[i].goodFaith >= -1 && // Valid
              goodFaithOrig !== idea.idea.scales[i].goodFaith
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <goodFaith> changed from ",
                  goodFaithOrig,
                  " to ",
                  idea.idea.scales[i].goodFaith
                );
              setIntell(idea.idea);
              break;
            } else if (
              // Valid idea.idea.scales[i].associatedAgent, but invalid associatedAgentOrig OR not matching
              (idea.idea.scales[i].associatedAgent &&
                idea.idea.scales[i].associatedAgent._id &&
                ((associatedAgentOrig &&
                  associatedAgentOrig._id !== idea.idea.scales[i].associatedAgent._id) ||
                  !associatedAgentOrig ||
                  !associatedAgentOrig._id)) ||
              // Valid associatedAgentOring, but invalid idea.idea.scales[i].associatedAgent
              (associatedAgentOrig &&
                associatedAgentOrig._id &&
                (!idea.idea.scales[i].associatedAgent || !idea.idea.scales[i].associatedAgent._id))
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <associatedAgent> changed from ",
                  associatedAgentOrig,
                  " to ",
                  idea.idea.scales[i].associatedAgent
                );
              setIntell(idea.idea);
              break;
            } else if (
              idea.idea.scales[i].myPoint &&
              idea.idea.scales[i].myPoint.length > 0 && // Valid
              myPointOrig !== idea.idea.scales[i].myPoint
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <myPoint> changed from ",
                  myPointOrig,
                  " to ",
                  idea.idea.scales[i].myPoint
                );
              setIntell(idea.idea);
              break;
            } else if (idea.idea.scales[i].expertise) {
              if (
                exists(idea.idea.scales[i].expertise.myExpertiseLevel) &&
                idea.idea.scales[i].expertise.myExpertiseLevel >= 0 && // Valid
                myExpertiseLevelOrig !== idea.idea.scales[i].expertise.myExpertiseLevel
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <myExpertiseLevel> changed from ",
                    myExpertiseLevelOrig,
                    " to ",
                    idea.idea.scales[i].expertise.myExpertiseLevel
                  );
                setIntell(idea.idea);
                break;
              } else if (
                idea.idea.scales[i].expertise.myExpertiseText &&
                idea.idea.scales[i].expertise.myExpertiseText.length > 0 && // Valid
                myExpertiseTextOrig !== idea.idea.scales[i].expertise.myExpertiseText
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <myExpertiseText> changed from ",
                    myExpertiseTextOrig,
                    " to ",
                    idea.idea.scales[i].expertise.myExpertiseText
                  );
                setIntell(idea.idea);
                break;
              } else if (
                idea.idea.scales[i].expertise.changedText &&
                idea.idea.scales[i].expertise.changedText.length > 0 && // Valid
                changedTextOrig !== idea.idea.scales[i].expertise.changedText
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <changedText> changed from ",
                    changedTextOrig,
                    " to ",
                    idea.idea.scales[i].expertise.changedText
                  );
                setIntell(idea.idea);
                break;
              } else if (
                exists(idea.idea.scales[i].expertise.changedMind) &&
                changedMindOrig !== idea.idea.scales[i].expertise.changedMind
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <changedMind> changed from ",
                    changedMindOrig,
                    " to ",
                    idea.idea.scales[i].expertise.changedMind
                  );
                setIntell(idea.idea);
                break;
              }
            }
          } else if (!myInterpretationExists) {
            // At least one scale exists
            if (debugLevel > 0)
              console.log(
                "Idea changed: submission confirmation received from backend - 1st time scale created"
              );
            setIntell(idea.idea);
            break;
          }
        }
      }
    }
  }, [idea.idea]);

  return submitting ? (
    <Spinner />
  ) : (
    <div>
      {interpretable && (
        <div style={{ marginBottom: "30px" }}>
          {/* inTell rational? */}
          {!dummyMyScale && (
            <>
              <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                <QuestionText large={rational <= -2 ? 1 : 0}>This inTell is...</QuestionText>
                {rational <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
              </div>
              <SliderRational userInitLevel={rational} updated={readOnly ? null : setRational} />
            </>
          )}

          {!dummyMyScale && displayGoodFaith && (
            <>
              <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                <QuestionText large={goodFaith <= -2 ? 1 : 0}>
                  I believe the author of this inTell is...
                  <Help
                    onClick={() => setGoodFaithDialog(true)}
                    color="primary"
                    style={{ marginLeft: "3px", marginBottom: "3px" }}
                  />
                </QuestionText>
                {goodFaith <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
              </div>
              <SliderGoodFaith userInitLevel={goodFaith} updated={readOnly ? null : setGoodFaith} />

              {goodFaith > -2 && goodFaith < 0 && (
                <div style={{ marginTop: "40px" }}>
                  <Alert color="error">
                    <Warning style={{ marginRight: "10px" }} />
                    Exposing deception (this is not simply foolishness)
                  </Alert>
                </div>
              )}
            </>
          )}

          {!dummyMyScale && displayTheGood && (
            <>
              <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
                <QuestionText large={theGood <= -2 ? 1 : 0}>
                  This inTell draws us towards...
                </QuestionText>
                {theGood <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
              </div>
              <div>
                <SliderTheGood
                  userInitLevel={theGood}
                  updated={
                    (rational < 0 && goodFaith > -2 && goodFaith < 0) || readOnly
                      ? null
                      : setTheGood
                  }
                />
              </div>
            </>
          )}

          {!dummyMyScale && displayAssociatedAgent && (
            <Box mt={2} mb={6} sx={{ display: "flex", justifyContent: "center" }}>
              <AssociateWavemaker
                title={
                  goodFaith > -2 && goodFaith < 0 && rational < 0
                    ? "This deliberate deception most serves the interests of..."
                    : theGood > 0
                      ? "I associate the light in this inTell most with…"
                      : "I associate the darkness in this inTell most with…"
                }
                idea={intell}
                associatedAgent={associatedAgent}
                linkWavemakerCheckbox={linkWavemakerCheckbox}
                setLinkWavemakerCheckbox={setLinkWavemakerCheckbox}
                handleAssociatedAgent={setAssociatedAgent}
                useAI={useAI}
                readOnly={readOnly}
              />
            </Box>
          )}

          {(dummyMyScale || displayMyPoint) && !myInterpretationExists && (
            <Transcribe
              disabled={readOnly}
              textLength={fellowship.intellLinkTextLength}
              requiredField={mandatoryMyPoint}
              message={myPoint}
              label={
                goodFaith > -2 && goodFaith < 0 && rational < 0
                  ? "So what's the real agenda?"
                  : "Any additional thoughts to share about this inTell?"
              }
              setMessage={setMyPoint}
            />
          )}

          {displayExpertise && (
            <Expertise
              myExpertise={myExpertise}
              setMyExpertise={readOnly ? null : setMyExpertise}
              // allowSelectPrior={!myInterpretationExists}
              setExpertiseFieldsValid={!readOnly && setDisplaySubmit}
            />
          )}

          {displaySubmit && (
            <>
              {myInterpretationExists && editsMade && (
                <div style={{ marginTop: "30px" }}>
                  <Submit buttonLabel="Submit changes" onSubmit={onSubmit} />
                </div>
              )}
              {!myInterpretationExists && (
                <div style={{ marginTop: "30px" }}>
                  <Submit onSubmit={onSubmit} />
                </div>
              )}
            </>
          )}

          {goodFaithDialog && (
            <DialogMessage goodFaith handleDialogClose={() => setGoodFaithDialog(false)} />
          )}
          {intentDialog && (
            <DialogMessage intent handleDialogClose={() => setIntentDialog(false)} />
          )}
        </div>
      )}
    </div>
  );
};

InterpretIntell.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updatedCount: PropTypes.number.isRequired,
  setUpdatedCount: PropTypes.func.isRequired,
  setIntell: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  alwaysInterpretable: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  auth: state.auth,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { addIdea, modifyIdea })(InterpretIntell);
