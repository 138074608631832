import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { AddLink } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import NavbarTitle from "../navbar/NavbarTitle";
import MyRadioMulti from "../layout/MyRadioMulti";
import DefaultWaveMakers from "./DefaultWaveMakers";
import InfoCard from "../layout/InfoCard";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DialogWaveMakers = ({
  handleBack,
  linkWaveMaker,
  setLinkWaveMaker,
  defaultWaveMakers,
  handleNewDefaultWaveMaker,
  handleRemoveDefaultWaveMaker,
}) => {
  const options = [
    { label: "never", value: -1 },
    { label: "limit waveMaker selection to default list (below)", value: 0 },
    {
      label: "unlimited: use default waveMaker list (+ any suggestions put forth by participants)",
      value: 1,
    },
  ];

  return (
    <Dialog open fullScreen>
      <NavbarTitle
        paper
        title="How will waveMakers be connected?"
        backClick={() => handleBack(false)}
      />

      <Container sx={{ mt: 4, mb: 3 }}>
        <ContentPanel elevation={0}>
          <InfoCard
            title="The deeper the divide, the more critical it is to analyze power dynamics"
            message="Who are the waveMakers (person, group, organization) connected to a story?"
          />

          {/* linkWaveMaker: -1:never, 0=limit-to-defaultWaveMakers, 1:unlimited) */}
          <MyRadioMulti
            icon={<AddLink />}
            options={options}
            label="Allow waveMaker(s) to be linked to inTell..."
            select={linkWaveMaker}
            setSelect={setLinkWaveMaker}
            divider="none"
          />

          {linkWaveMaker >= 0 && (
            <div style={{ marginTop: "30px" }}>
              <DefaultWaveMakers
                defaultWaveMakers={defaultWaveMakers}
                newWaveMaker={handleNewDefaultWaveMaker}
                removeWaveMaker={handleRemoveDefaultWaveMaker}
              />
            </div>
          )}
        </ContentPanel>
      </Container>
    </Dialog>
  );
};

DialogWaveMakers.propTypes = {
  handleBack: PropTypes.func.isRequired,
  linkWaveMaker: PropTypes.number.isRequired,
  setLinkWaveMaker: PropTypes.func.isRequired,
  defaultWaveMakers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleNewDefaultWaveMaker: PropTypes.func.isRequired,
  handleRemoveDefaultWaveMaker: PropTypes.func.isRequired,
};

export default DialogWaveMakers;
