import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Spinner from "../layout/Spinner";
import exists from "../../utils/exists";
import InvalidIntellCard from "./InvalidIntellCard";
import ValidIntellCard from "./ValidIntellCard";

const IntellCard = ({
  intell = null,
  activity = null,
  cardOutline = "normal", // "none", "normal", "bold"
  view = 2, // 0=text/title only (no header, no navigate), 1=minimal (header, no pics, no navigate), 2:normal
  onDelete = undefined,
}) => {
  //  console.log("intell", intell);
  const navigate = useNavigate();

  const [navigationClicked, setNavigationClicked] = useState(false);
  const [verified, setVerified] = useState(null);
  const [validIntell, setValidIntell] = useState(true);

  const handleNavigateLink = () => {
    if (view >= 2 && activity && activity._id) {
      if (exists(activity)) {
        console.log(`handleNavigateLink /activity/${activity._id}`);
        navigate(`/activity/${activity._id}`);
        setNavigationClicked(true);
      } else if (view >= 2 && verified && verified._id) {
        console.log(`handleNavigateLink /post/${verified._id}`);
        navigate(`/post/${verified._id}`);
        setNavigationClicked(true);
      } else {
        console.log("handleNavigateLink - no action taken");
      }
    }
  };

  // First time (or idea change)
  useEffect(() => {
    // There are essentially two types of <intell> data/structure we can expect:
    // (1) <intell> data has already been pre-scrubbed (in PostView* or QuestionView*):
    //     - Legacy data has been converted to latest data format
    //
    //     - For intell with ideaType === 10/11
    //       - user has been fully populated
    //       - From parent scale, the following props have been copied over:
    //         - supportTopic/supportTopic1
    //         - expertise
    //       - <badgeType> prop has been added
    //         - 4: interpretation of/response to framing statement intell (ideaType === 10/11);
    //       - myPoint: assumes exists
    //       - createdAt: copied over
    //
    //     - For intell with ideaType !== 10/11
    //       - user has been fully populated
    //       - From parent scale, the following props have been copied over:
    //         - rational/rational1
    //         - theGood/theGood1
    //         - goodFaith/goodFaith1
    //         - createdAt/updatedAt
    //         - associatedAgent
    //         - expertise
    //       - created: manipulative (if goodFaith < 0)
    //       - <badgeType> prop has been added
    //         - 1: manipulative
    //         - 2: response to my own intell; we do not interpret our own intell
    //         - 3: interpretation of/response to intell
    //       - myPoint: assumes exists
    //       - createdAt: copied over
    //
    // (2) <intell> data has not yet been pre-scrubbed
    //
    // * Pre-scrubbing is done in PostView/QuestionView as full data structures are readily available at this point
    //   (as all props are not available/umpopulated and would be difficult to access later if not pre-scrubbed)
    if (!intell) {
      setValidIntell(false);
    } else if (exists(intell.badgeType)) {
      setValidIntell(true);
      setVerified(intell);
      //    console.log("Already pre-scrubbed...", intell);
    } else if (intell.parentScale) {
      setValidIntell(true);
      //    console.log("Let's pre-scrub - this intell is a response...", intell);

      // inTell has not yet been pre-scrubbed
      const verifiedObj = intell; // user, data, myPoint

      if (
        intell.parentScale.associatedAgent &&
        intell.parentScale.associatedAgent.name &&
        intell.parentScale.associatedAgent.name.length > 0
      ) {
        verifiedObj.associatedAgent = intell.parentScale.associatedAgent;
      }

      verifiedObj.manipulative = false;
      if (exists(intell.parentScale.goodFaith) && intell.parentScale.goodFaith > -2) {
        if (intell.parentScale.goodFaith < 0) {
          verifiedObj.manipulative = true;
        }
      }

      if (intell.parentScale.expertise) {
        verifiedObj.expertise = { ...intell.parentScale.expertise };
      }
      verifiedObj.ideaType = intell.ideaType ? intell.ideaType : 0;
      verifiedObj.theGood = intell.parentScale.theGood;
      verifiedObj.theGood1 = intell.parentScale.theGood1;
      verifiedObj.rational = intell.parentScale.rational;
      verifiedObj.rational1 = intell.parentScale.rational1;
      verifiedObj.goodFaith = intell.parentScale.goodFaith;
      verifiedObj.goodFaith1 = intell.parentScale.goodFaith1;
      verifiedObj.supportTopic = intell.parentScale.supportTopic;
      verifiedObj.supportTopic1 = intell.parentScale.supportTopic1;
      verifiedObj.updatedAt = intell.parentScale.updatedAt;
      verifiedObj.createdAt = intell.parentScale.createdAt;

      // Badge
      if (!(verifiedObj.ideaType === 10 || verifiedObj.ideaType === 11)) {
        if (verifiedObj.manipulative) {
          // Badge should display manipulation
          verifiedObj.badgeType = 1;
        } else if (
          intell.parentIdea &&
          (!intell.parentIdea.ideaType ||
            !(intell.parentIdea.ideaType === 10 || intell.parentIdea.ideaType === 11)) &&
          intell.parentIdea.user &&
          intell.parentIdea.user === intell.parentScale.user
        ) {
          // Intell reply -- parent inTell is mine
          verifiedObj.badgeType = 2;
        } else if (
          intell.parentIdea &&
          (!intell.parentIdea.ideaType ||
            !(intell.parentIdea.ideaType === 10 || intell.parentIdea.ideaType === 11)) &&
          exists(verifiedObj.rational) &&
          exists(verifiedObj.theGood)
        ) {
          // Intell reply -- parent intell not mine, and has a valid interpretation
          verifiedObj.badgeType = 3;
        } else if (
          intell.parentIdea &&
          (intell.parentIdea.ideaType === 10 || intell.parentIdea.ideaType === 11) &&
          exists(verifiedObj.supportTopic)
        ) {
          // Framing statement with valid interpretation
          verifiedObj.badgeType = 4;
        }
      }
      setVerified(verifiedObj);
    } else if (intell.scales && intell.scales.length > 0) {
      //  Note: No parentIntell (and therefore no badge)
      setValidIntell(true);
      //    console.log("Let's pre-scrub - this intell is not response...", intell);

      // inTell has not yet been pre-scrubbed
      const verifiedObj = intell; // user, data, myPoint

      if (intell.scales[0].expertise && intell.scales[0].expertise.myExpertiseLevel) {
        // This intell is not a response (stand-alone inTell with expertise in scales[0])
        verifiedObj.expertise = { ...intell.scales[0].expertise };
        //      console.log("Found expertise", intell.scales[0].expertise);
      }
      verifiedObj.ideaType = intell.ideaType ? intell.ideaType : 0;
      setVerified(verifiedObj);
    } else {
      setVerified(intell);
    }
  }, [intell]);

  return (!verified || navigationClicked) && validIntell ? (
    <Spinner />
  ) : (
    <div>
      {!validIntell ? (
        <InvalidIntellCard cardOutline={cardOutline} />
      ) : (
        <ValidIntellCard
          verified={verified}
          activity={activity}
          view={view}
          cardOutline={cardOutline}
          setNavigationClicked={setNavigationClicked}
          handleNavigateLink={handleNavigateLink}
          onDelete={onDelete}
        />
      )}
    </div>
  );
};

IntellCard.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]),
  cardOutline: PropTypes.string,
  view: PropTypes.number,
  onDelete: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
};

export default IntellCard;
