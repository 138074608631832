import axios from "axios";
import { setAlert } from "./alert";
import {
  FELLOWSHIP_ERROR,
  MODIFY_FELLOWSHIP,
  ADD_FELLOWSHIP,
  GET_FELLOWSHIPS,
  GET_FELLOWSHIP,
  LOADING_FELLOWSHIP,
  DELETE_FELLOWSHIP,
} from "./types";
import normalizeObjectsToIds from "../utils/normalizeObjectsToIds";

// Loading fellowships
export const loadingFellowship = () => (dispatch) => {
  dispatch({ type: LOADING_FELLOWSHIP });
};

// Get fellowships (IMPORTANT: called at start in App.js)
export const getFellowships = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/fellowships`);
    dispatch({
      type: GET_FELLOWSHIPS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FELLOWSHIP_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(
      setAlert(`Failed To Retrieve Fellowships From Server: ${err.response.data}`, "danger")
    );
  }
};

// TODO: Get only fellowships attributed to userId (where current user is an admin or a participant)

/**
 * Get fellowship data with optional cache bypass
 * @param {string} id - Fellowship ID
 * @param {boolean} bypassCache - Whether to bypass browser and server cache
 * @returns {Promise} - The fellowship data
 */
export const getFellowship =
  (id, bypassCache = false) =>
  async (dispatch) => {
    try {
      // Configure cache headers
      const config = bypassCache
        ? {
            headers: {
              "Cache-Control": "no-cache, no-store",
              Pragma: "no-cache",
              Expires: "0",
            },
          }
        : {};

      // Add cache-busting parameter if requested
      const url = bypassCache
        ? `/api/fellowships/${id}?bypassCache=true&_=${new Date().getTime()}`
        : `/api/fellowships/${id}`;

      const res = await axios.get(url, config);

      dispatch({
        type: GET_FELLOWSHIP,
        payload: res.data,
      });

      return res.data;
    } catch (err) {
      dispatch({
        type: FELLOWSHIP_ERROR,
        payload: {
          msg: err.response?.data || "Unknown error",
          status: err.response?.status || 500,
        },
      });

      if (err.response?.data) {
        dispatch(setAlert(`Failed to retrieve fellowship: ${err.response.data}`, "danger"));
      }

      return Promise.reject(err);
    }
  };

// Add fellowship
export const addFellowship = (formData) => async (dispatch) => {
  try {
    // Normalize the data before sending to the API
    const normalizedData = normalizeObjectsToIds(formData);

    const res = await axios.post("/api/fellowships", normalizedData);

    dispatch({
      type: ADD_FELLOWSHIP,
      payload: res.data,
    });

    dispatch(setAlert("Fellowship Created", "success"));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create Fellowship On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: FELLOWSHIP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Modify existing fellowship of fellowshipID
export const modifyFellowship = (id, formData) => async (dispatch) => {
  try {
    // Normalize the data before sending to the API
    const normalizedData = normalizeObjectsToIds(formData);

    // Add cache busting parameter to prevent potential caching issues
    const res = await axios.post(
      `/api/fellowships/${id}?_=${new Date().getTime()}`,
      normalizedData
    );

    dispatch({
      type: MODIFY_FELLOWSHIP,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    console.log("Modify error", err);
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Modify Fellowship On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: FELLOWSHIP_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });

    return Promise.reject(err);
  }
};

// Delete fellowship
export const deleteFellowship = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/fellowships/${id}`);
    dispatch({
      type: DELETE_FELLOWSHIP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FELLOWSHIP_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Delete Fellowship From Server: ${err.response.data}`, "danger"));
  }
};
