import React from "react";
import PropTypes from "prop-types";
import isImage from "is-image";

// MUI
import { Chip, Box } from "@mui/material";

import renderMedia from "../media/renderMedia";

const ViewAttachments = ({ attachments, setAttachments = undefined }) => {
  const handleDeleteAttachment = (index) => {
    if (setAttachments) {
      setAttachments((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const getOriginalFileName = (uploadedFileName) => {
    const parts = uploadedFileName.split("-");
    const originalFileName = parts.slice(1).join("-"); // Reconstruct name excluding prefix
    return originalFileName.replace("-streamable.mp4", "");
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      {attachments.map((e, index) => (
        <Box
          key={e}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isImage(e.split("?")[0]) ? (
            <>
              <Box sx={{ width: "60px" }}>{renderMedia(e)}</Box>

              {setAttachments && (
                <Chip
                  sx={{ mt: isImage(e.split("?")[0]) ? -1.5 : 0 }}
                  variant="filled"
                  color="secondary"
                  size="small"
                  onDelete={() => handleDeleteAttachment(index)}
                  label={getOriginalFileName(e.split("?")[0])}
                />
              )}
            </>
          ) : (
            <Chip
              sx={{ mt: isImage(e.split("?")[0]) ? -1.5 : 0 }}
              variant="filled"
              color="secondary"
              size="small"
              {...(setAttachments && {
                onDelete: () => handleDeleteAttachment(index),
              })}
              label={getOriginalFileName(e.split("?")[0])}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

ViewAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAttachments: PropTypes.func,
};

export default ViewAttachments;
