// MUI
import { Public, AccountCircle } from "@mui/icons-material";

const RoutesUser = (profileID) => {
  // Check if profileID is valid
  const validProfileID = profileID && profileID.length > 0 ? profileID : "me";

  return [
    {
      name: "My profile",
      icon: <AccountCircle />,
      route: `/profile/${validProfileID}`,
    },
    {
      name: "Logout",
      icon: <Public />,
      route: "/logout",
    },
  ];
};

export default RoutesUser;
