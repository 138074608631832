import axios from "axios";
import { setAlert } from "./alert";
import {
  SEARCH_AGENTS,
  AGENT_ERROR,
  ADD_AGENT,
  GET_AGENT,
  GET_AGENTS,
  LOADING_AGENT,
} from "./types";

// Loading
export const loadingAgent = () => (dispatch) => {
  dispatch({ type: LOADING_AGENT });
};

// Get agents attributed to userId
export const getAgents = (userId, limitSize) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/agents/user/${userId}`, {
      params: {
        limitSize,
      },
    });
    dispatch({
      type: GET_AGENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AGENT_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Agents From Server: ${err.response.data}`, "danger"));
  }
};

// Search agents by searchString
//   special: undefined (no special instructions)
//   special: "100" (search urls only - needs to be 100% match)
//   special: "200" (do not return agents where the name is an email address); this was a cluge to deal with a legacy db -- can be removed
export const searchAgents =
  (searchString, limitSize, special = undefined) =>
  async (dispatch) => {
    try {
      const res = await axios.get("/api/agents", {
        params: {
          searchString,
          limitSize,
          special,
        },
      });
      dispatch({
        type: SEARCH_AGENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AGENT_ERROR,
        payload: { msg: err.response.data, status: err.response.status },
      });
      dispatch(setAlert(`Failed To Retrieve Agents From Server: ${err.response.data}`, "danger"));
    }
  };

// Add agent
export const addAgent = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/agents", formData, config);

    dispatch({
      type: ADD_AGENT,
      payload: res.data,
    });
    //  dispatch(setAlert('Agent Created', 'success', 1000));
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`Failed To Create Agent On Server: ${error.msg}`, "danger"))
      );
    }
    dispatch({
      type: AGENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get agent
export const getAgent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/agents/${id}`);

    dispatch({
      type: GET_AGENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AGENT_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Agent From Server: ${err.response.data}`, "danger"));
  }
};
