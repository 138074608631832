import React from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/system";
import { Checkbox } from "@mui/material";

import Box from "../MaterialKit/MKBox";
import LabelText from "../styles/LabelText";
import AgentSelect from "./AgentSelect";

const VerticalListBox = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 360,
    [theme.breakpoints.up("sm")]: {
      width: 500,
    },
  })
);

const AssociateWavemaker = ({
  idea,
  title,
  linkWavemakerCheckbox,
  setLinkWavemakerCheckbox,
  associatedAgent,
  handleAssociatedAgent,
  readOnly = false,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <div style={{ textAlign: "left", marginBottom: "-10px" }}>
        <LabelText>
          <Checkbox
            onClick={() => setLinkWavemakerCheckbox(!linkWavemakerCheckbox)}
            checked={linkWavemakerCheckbox}
            disabled={readOnly}
          />
          {title}
        </LabelText>
      </div>

      {linkWavemakerCheckbox && (
        <VerticalListBox>
          <div style={{ textAlign: "left" }}>
            <AgentSelect
              idea={idea}
              selectedWavemaker={associatedAgent}
              setSelectedWavemaker={handleAssociatedAgent}
            />
          </div>
        </VerticalListBox>
      )}
    </Box>
  );
};

AssociateWavemaker.propTypes = {
  idea: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  linkWavemakerCheckbox: PropTypes.bool.isRequired,
  setLinkWavemakerCheckbox: PropTypes.func.isRequired,
  associatedAgent: PropTypes.shape({}),
  handleAssociatedAgent: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

export default AssociateWavemaker;
