import React from "react";
import PropTypes from "prop-types";
import { useTheme, alpha } from "@mui/material/styles";
import { Paper } from "@mui/material";

import Input from "../MaterialKit/MKInput";
import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const EditName = ({ name, handleName, nameValid, inputLabelEmpty = "" }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        background: alpha(theme.palette.background.paper, 0.5),
        borderRadius: "12px",
        border:
          name.length > 0 && !nameValid
            ? `1px solid ${alpha(theme.palette.error.main, 0.5)}`
            : `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
        transition: "all 0.3s ease",
        padding: 2,
        mb: 2,
      }}
    >
      <Box onChange={(e) => handleName(e.target.value)}>
        <Input
          error={name.length > 0 && !nameValid}
          type="inputText"
          label={name.length > 0 && !nameValid ? "Error" : inputLabelEmpty}
          fullWidth
          value={name}
          helperText=""
        />
        <Typography
          variant="caption"
          color={name.length > 0 && !nameValid ? "error" : "secondary"}
          sx={{
            fontSize: "11px",
            mt: 0.5,
            display: "block",
            fontStyle: name.length > 0 && !nameValid ? "normal" : "italic",
          }}
        >
          {name.length > 0 && !nameValid
            ? "A group with this name already exists"
            : "Select a unique and descriptive name"}
        </Typography>
      </Box>
    </Paper>
  );
};

EditName.propTypes = {
  name: PropTypes.string.isRequired,
  handleName: PropTypes.func.isRequired,
  nameValid: PropTypes.bool.isRequired,
  inputLabelEmpty: PropTypes.string,
};

export default EditName;
