import React from "react";
import PropTypes from "prop-types";

// MUI
import { Paper } from "@mui/material";
import {
  AltRoute,
  CenterFocusStrong,
  ZoomIn,
  Tsunami,
  Engineering,
  Policy,
} from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import MyGoto from "../layout/MyGoto";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const FellowshipOptions = ({
  fellowshipType,
  setDialogType,
  setDialogFraming,
  setDialogIntell,
  setDialogWaveMakers,
  setDialogDig,
  setDialogPrivacy,
  setDialogDelete,
}) => {
  const getTypeLabel = () => {
    switch (fellowshipType) {
      case 1:
        return "LEARN";
      case 2:
        return "GUIDE";
      case 3:
        return "DISCUSS";
      case 4:
        return "ACT";
      default:
        return "UNKNOWN";
    }
  };

  return (
    <Box sx={{ my: 3 }}>
      <GlassPanel elevation={0}>
        <MyGoto
          icon={<AltRoute />}
          label={`Fellowship type: ${getTypeLabel()}`}
          subLabel={["How should this fellowship operate?", "Learn, guide, discuss, or act?"]}
          handleClick={setDialogType}
        />

        <MyGoto
          icon={<CenterFocusStrong />}
          label="Framing statements"
          subLabel={[
            "Configure how framing statements will be interpreted.",
            "What types of text responses will be allowed?",
          ]}
          handleClick={setDialogFraming}
        />

        <MyGoto
          icon={<ZoomIn />}
          label="intell"
          subLabel={[
            "Configure how intell will be interpreted.",
            "What types of text responses will be allowed?",
          ]}
          handleClick={setDialogIntell}
        />

        <MyGoto
          icon={<Tsunami />}
          label="waveMakers"
          subLabel={[
            "Rarely do events happen in isolation.",
            "Who are the waveMakers connected to a story?",
          ]}
          handleClick={setDialogWaveMakers}
        />

        <MyGoto
          icon={<Engineering />}
          label="Merit matters"
          subLabel={[
            "Expertise often reflects valuable knowledge and deeper understanding.",
            "Configure how it is requested.",
          ]}
          handleClick={setDialogDig}
        />

        <MyGoto
          icon={<Policy />}
          label="Participant privacy"
          subLabel={["Configure privacy levels.", "What degree of anonymity do participants have?"]}
          handleClick={setDialogPrivacy}
          divider="full"
        />

        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Button variant="text" color="error" size="large" onClick={() => setDialogDelete(true)}>
            Delete this fellowship
          </Button>
        </Box>
      </GlassPanel>
    </Box>
  );
};

FellowshipOptions.propTypes = {
  fellowshipType: PropTypes.number.isRequired,
  setDialogType: PropTypes.func.isRequired,
  setDialogFraming: PropTypes.func.isRequired,
  setDialogIntell: PropTypes.func.isRequired,
  setDialogWaveMakers: PropTypes.func.isRequired,
  setDialogDig: PropTypes.func.isRequired,
  setDialogPrivacy: PropTypes.func.isRequired,
  setDialogDelete: PropTypes.func.isRequired,
};

export default FellowshipOptions;
