import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import NavbarTitle from "../navbar/NavbarTitle";
import DialogNoSave from "../dialog/DialogNoSave";
import ActivityEdit from "./ActivityEdit";
import DialogConfirm from "../dialog/DialogConfirm";
import Spinner from "../layout/Spinner";
import IntellCard from "../intell/IntellCard";

import exists from "../../utils/exists";
import { modifyActivity, loadingActivity, deleteActivity } from "../../actions/activity";
import { getIdea, loadingIdea } from "../../actions/idea";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const encodeChildAllowedType = (
  childAllowedComments,
  childAllowedInsights,
  childAllowedQuotations
) => {
  return (
    (childAllowedComments ? 1 : 0) +
    (childAllowedInsights ? 2 : 0) +
    (childAllowedQuotations ? 4 : 0)
  );
};

const decodeChildAllowedType = (encodedValue) => {
  return {
    childAllowedComments: encodedValue % 2 === 1,
    childAllowedInsights: Math.floor(encodedValue / 2) % 2 === 1,
    childAllowedQuotations: Math.floor(encodedValue / 4) % 2 === 1,
  };
};

const decodeActivityType = (idea) => {
  if (idea && exists(idea.ideaType)) {
    if (idea.ideaType >= 0 && idea.ideaType <= 9) return 1;
    if (idea.ideaType >= 10 && idea.ideaType <= 19) return 2;
    if (idea.ideaType >= 20 && idea.ideaType <= 29) return 0;
  }
  return null;
};

const DialogActivityEdit = ({
  selectedActivity,
  handleDialogClose,
  modifyActivity,
  loadingActivity,
  deleteActivity,
  getIdea,
  loadingIdea,
  activity: { activity },
  idea,
}) => {
  useEffect(() => {
    loadingIdea();
    getIdea(selectedActivity.intell._id);
    loadingActivity(); // Flush the reducer; set activity/activities to null/[]
  }, [selectedActivity]);

  const [editsMade, setEditsMade] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);

  // Decode prior settings of selectedActivity
  const decodedChildAllowedType = decodeChildAllowedType(selectedActivity.childAllowedType);
  const decodedActivityType = decodeActivityType(selectedActivity.intell);

  const [activityType] = useState(decodedActivityType);
  const [childAllowed, setChildAllowed] = useState(selectedActivity.childAllowedAuthors !== 0);
  // 0: None, 1: Any participant, 2: ADMIN
  const [childAllowedAuthors, setChildAllowedAuthors] = useState(
    selectedActivity.childAllowedAuthors
  );
  const [childAllowedExpertise, setChildAllowedExpertise] = useState(
    selectedActivity.childAllowedExpertise
  );
  const [childGoLiveAuto, setChildGoLiveAuto] = useState(selectedActivity.childGoLiveAuto);
  const [childAllowedComments, setChildAllowedComments] = useState(
    decodedChildAllowedType.childAllowedComments
  );
  const [childAllowedInsights, setChildAllowedInsights] = useState(
    decodedChildAllowedType.childAllowedInsights
  );
  const [childAllowedQuotations, setChildAllowedQuotations] = useState(
    decodedChildAllowedType.childAllowedQuotations
  );
  const [instructionsTime, setInstructionsTime] = useState(
    selectedActivity.instructionsTime ? selectedActivity.instructionsTime.trim() : ""
  );
  const [instructionsOther, setInstructionsOther] = useState(
    selectedActivity.instructionsOther ? selectedActivity.instructionsOther.trim() : ""
  );

  useEffect(() => {
    if (
      activityType === 0 ||
      (childAllowed === (selectedActivity.childAllowedAuthors !== 0) &&
        childAllowedAuthors === selectedActivity.childAllowedAuthors &&
        childAllowedExpertise === selectedActivity.childAllowedExpertise &&
        childGoLiveAuto === selectedActivity.childGoLiveAuto &&
        childAllowedComments === decodedChildAllowedType.childAllowedComments &&
        childAllowedInsights === decodedChildAllowedType.childAllowedInsights &&
        childAllowedQuotations === decodedChildAllowedType.childAllowedQuotations &&
        instructionsTime.trim() === selectedActivity.instructionsTime.trim() &&
        instructionsOther.trim() === selectedActivity.instructionsOther.trim())
    ) {
      setEditsMade(false);
    } else {
      setEditsMade(true);
    }
  }, [
    childAllowed,
    childAllowedAuthors,
    childAllowedExpertise,
    childGoLiveAuto,
    childAllowedComments,
    childAllowedInsights,
    childAllowedQuotations,
    instructionsTime,
    instructionsOther,
  ]);

  const handleBackClick = () => {
    //  console.log("handleBackClick");
    if (editsMade) {
      // Need to confirm exit without save
      setConfirmExit(true);
    } else {
      handleDialogClose(false); // no changes made; nothing further to do
    }
  };

  const handleConfirmExit = (e) => {
    //  console.log("handleConfirmExit", e);
    if (e) {
      handleDialogClose(false); // no changes made; nothing further to do
    }
    setConfirmExit(false);
  };

  const handleDialogConfirmClose = (e) => {
    //  console.log("handleDialogConfirmClose", e);
    if (e) {
      deleteActivity(selectedActivity._id);
      handleDialogClose(true); // deleted activity; fellowship.activities needs to be updated as well
    }
    setDialogDelete(false);
  };

  const [saving, setSaving] = useState(false);

  const onSubmit = () => {
    //  console.log("onSubmit: mondifyActivity()");
    setSaving(true);
    modifyActivity(selectedActivity._id, {
      childAllowedAuthors: childAllowed ? childAllowedAuthors : 0,
      childAllowedType: encodeChildAllowedType(
        childAllowedComments,
        childAllowedInsights,
        childAllowedQuotations
      ),
      childAllowedExpertise,
      childGoLiveAuto,
      instructionsTime: instructionsTime.trim(),
      instructionsOther: instructionsOther.trim(),
    });
  };

  // Exit strategy this component
  const [initId, setInitId] = useState(activity && activity._id ? activity._id : 0);
  useEffect(() => {
    if (activity && activity._id) {
      if (activity._id !== initId) {
        handleDialogClose(false); // changes made; but nothing further to do
        //      console.log("Exit strategy: Activity successfully modified");
      }
    } else {
      setInitId(0);
    }
  }, [activity]);

  return (
    <>
      <Dialog open fullScreen>
        <NavbarTitle
          paper
          title={
            activityType === 1
              ? "inTell activity"
              : activityType === 2
                ? "Framing statement activity"
                : "Info message activity"
          }
          backClick={handleBackClick}
          showSave={activityType === 1 || activityType === 2 ? (editsMade ? 1 : 0) : -1}
          saveClick={onSubmit}
        />
        {idea.loading || saving ? (
          <Spinner />
        ) : (
          <div>
            {idea.idea && (
              <Container sx={{ mt: 3, mb: 3 }}>
                <GlassPanel elevation={0}>
                  <IntellCard view={1} intell={idea.idea} />

                  {activityType > 0 && (
                    <ActivityEdit
                      childAllowed={childAllowed}
                      setChildAllowed={setChildAllowed}
                      childAllowedAuthors={childAllowedAuthors}
                      setChildAllowedAuthors={setChildAllowedAuthors}
                      childAllowedExpertise={childAllowedExpertise}
                      setChildAllowedExpertise={setChildAllowedExpertise}
                      childGoLiveAuto={childGoLiveAuto}
                      setChildGoLiveAuto={setChildGoLiveAuto}
                      childAllowedComments={childAllowedComments}
                      setChildAllowedComments={setChildAllowedComments}
                      childAllowedInsights={childAllowedInsights}
                      setChildAllowedInsights={setChildAllowedInsights}
                      childAllowedQuotations={childAllowedQuotations}
                      setChildAllowedQuotations={setChildAllowedQuotations}
                      instructionsTime={instructionsTime}
                      setInstructionsTime={setInstructionsTime}
                      instructionsOther={instructionsOther}
                      setInstructionsOther={setInstructionsOther}
                    />
                  )}

                  <Box sx={{ textAlign: "center", mt: 3 }}>
                    <Button
                      variant="text"
                      color="error"
                      size="large"
                      onClick={() => setDialogDelete(true)}
                    >
                      Delete this activity
                    </Button>
                  </Box>
                </GlassPanel>
              </Container>
            )}
          </div>
        )}
      </Dialog>
      {confirmExit && <DialogNoSave handleDialogClose={handleConfirmExit} />}
      {dialogDelete && (
        <DialogConfirm
          title="Are you sure?"
          subTitles={["This activity will be deleted."]}
          handleDialogClose={handleDialogConfirmClose}
        />
      )}
    </>
  );
};

DialogActivityEdit.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  selectedActivity: PropTypes.shape({
    _id: PropTypes.string,
    intell: PropTypes.shape({
      _id: PropTypes.string,
    }),
    childAllowedAuthors: PropTypes.number,
    childAllowedType: PropTypes.number,
    childAllowedExpertise: PropTypes.number,
    childGoLiveAuto: PropTypes.number,
    instructionsTime: PropTypes.string,
    instructionsOther: PropTypes.string,
  }).isRequired,
  modifyActivity: PropTypes.func.isRequired,
  loadingActivity: PropTypes.func.isRequired,
  deleteActivity: PropTypes.func.isRequired,
  getIdea: PropTypes.func.isRequired,
  loadingIdea: PropTypes.func.isRequired,
  activity: PropTypes.shape({
    activity: PropTypes.shape({
      _id: PropTypes.string,
      // Add other activity properties as needed
    }),
  }),
  idea: PropTypes.shape({
    loading: PropTypes.bool,
    idea: PropTypes.shape({
      _id: PropTypes.string,
      ideaType: PropTypes.number,
      // Add other idea properties as needed
    }),
  }),
};

const mapStateToProps = (state) => ({
  activity: state.activity,
  idea: state.idea,
});

export default connect(mapStateToProps, {
  modifyActivity,
  loadingActivity,
  deleteActivity,
  getIdea,
  loadingIdea,
})(DialogActivityEdit);
