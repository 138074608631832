import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Grid, Accordion, AccordionSummary, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowForwardIosSharp, Mood, MoodBad } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import Typography from "../MaterialKit/MKTypography";
import IntellCard from "../intell/IntellCard";

const MyAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  })
);

const GoodAccordionSummary = styled((props) => (
  <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const EvilAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem", color: grey[300] }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const Wavemakers = ({ interpretations }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");

  const filterGood = (arr) => {
    return arr.filter((e) => !e.y || e.y >= 0);
  };

  const filterEvil = (arr) => {
    return arr.filter((e) => e.y < 0);
  };

  const group = (arr) => {
    const groupedObjects = {};

    // Group objects by name
    arr.forEach((obj) => {
      const { name } = obj.associatedAgent;
      if (!groupedObjects[name]) {
        groupedObjects[name] = [];
      }
      groupedObjects[name].push(obj);
    });

    // Convert the grouped objects to an array of arrays
    const result = Object.values(groupedObjects);

    // Sort the array of arrays based on the number of objects in each sub-array
    result.sort((a, b) => b.length - a.length);

    return result;
  };

  const createComposite = (arr) => {
    const composite = [];
    arr.forEach((obj) => {
      if (obj.children) {
        obj.children.forEach((element) => {
          // Filter out interpretations that do not have an associated agent
          if (obj.associatedAgent) {
            const newObj = element;
            newObj.user = obj.user;
            newObj.associatedAgent = obj.associatedAgent;
            newObj.myExpertiseLevel = obj.myExpertiseLevel;
            newObj.myExpertiseText = obj.myExpertiseText;
            newObj.changedMind = obj.changedMind;
            newObj.changedText = obj.changedText;
            newObj.manipulative = obj.manipulative;
            newObj.x = obj.x;
            newObj.y = obj.y;
            composite.push(newObj);
          }
        });
      }
    });
    return composite;
  };

  const [expanded, setExpanded] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      {smSize && (
        <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
          <Grid item xs>
            {group(filterEvil(createComposite(interpretations))).map((group) => (
              <div key={Math.random()}>
                <MyAccordion
                  expanded={expanded === `EVIL${group[0].associatedAgent.name}`}
                  onChange={handleChange(`EVIL${group[0].associatedAgent.name}`)}
                  sx={{ backgroundColor: grey[900] }}
                >
                  <EvilAccordionSummary aria-controls="panel1d-content" id="panel-header">
                    <MoodBad sx={{ mr: 1, color: grey[300] }} />
                    <Typography
                      textTransform="uppercase"
                      style={{ fontSize: "14px", color: "white" }}
                    >
                      {group[0].associatedAgent.name}
                    </Typography>
                    <Chip
                      label={group.length}
                      color="secondary"
                      size="small"
                      sx={{ ml: 1, mt: -0.1 }}
                    />
                  </EvilAccordionSummary>
                  {group.map((e) => (
                    <div key={Math.random()}>
                      <IntellCard intell={e} />
                    </div>
                  ))}
                </MyAccordion>
              </div>
            ))}
          </Grid>
          <Grid item xs>
            {group(filterGood(createComposite(interpretations))).map((group) => (
              <div key={Math.random()}>
                <MyAccordion
                  expanded={expanded === `GOOD${group[0].associatedAgent.name}`}
                  onChange={handleChange(`GOOD${group[0].associatedAgent.name}`)}
                >
                  <GoodAccordionSummary aria-controls="panel1d-content" id="panel-header">
                    <Mood sx={{ mr: 1 }} />
                    <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                      {group[0].associatedAgent.name}
                    </Typography>
                    <Chip
                      label={group.length}
                      color="secondary"
                      size="small"
                      sx={{ ml: 1, mt: -0.1 }}
                    />
                  </GoodAccordionSummary>
                  {group.map((e) => (
                    <div key={Math.random()}>
                      <IntellCard intell={e} />
                    </div>
                  ))}
                </MyAccordion>
              </div>
            ))}
          </Grid>
        </Grid>
      )}
      {!smSize && (
        <div style={{ marginTop: "40px", marginBottom: "20px" }}>
          {[
            ...group(filterEvil(createComposite(interpretations))),
            ...group(filterGood(createComposite(interpretations))),
          ]
            .sort((a, b) => b.length - a.length)
            .map((group) => (
              <div key={Math.random()}>
                <MyAccordion
                  expanded={
                    expanded ===
                    (group[0].y >= 0
                      ? `GOOD${group[0].associatedAgent.name}`
                      : `EVIL${group[0].associatedAgent.name}`)
                  }
                  onChange={handleChange(
                    group[0].y >= 0
                      ? `GOOD${group[0].associatedAgent.name}`
                      : `EVIL${group[0].associatedAgent.name}`
                  )}
                  sx={{ backgroundColor: group[0].y >= 0 ? "" : grey[900] }}
                >
                  {group[0].y >= 0 && (
                    <GoodAccordionSummary aria-controls="panel1d-content" id="panel-header">
                      <Mood sx={{ mr: 1 }} />
                      <Typography textTransform="uppercase" style={{ fontSize: "14px" }}>
                        {group[0].associatedAgent.name}
                      </Typography>
                      <Chip
                        label={group.length}
                        color="secondary"
                        size="small"
                        sx={{ ml: 1, mt: -0.1 }}
                      />
                    </GoodAccordionSummary>
                  )}
                  {group[0].y < 0 && (
                    <EvilAccordionSummary aria-controls="panel1d-content" id="panel-header">
                      <MoodBad sx={{ mr: 1, color: grey[300] }} />
                      <Typography
                        textTransform="uppercase"
                        style={{ fontSize: "14px", color: "white" }}
                      >
                        {group[0].associatedAgent.name}
                      </Typography>
                      <Chip
                        label={group.length}
                        color="secondary"
                        size="small"
                        sx={{ ml: 1, mt: -0.1 }}
                      />
                    </EvilAccordionSummary>
                  )}

                  {group.map((e) => (
                    <div key={Math.random()}>
                      <IntellCard intell={e} />
                    </div>
                  ))}
                </MyAccordion>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

Wavemakers.propTypes = {
  interpretations: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
};

export default Wavemakers;
