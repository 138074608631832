import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

import Typography from "../MaterialKit/MKTypography";
import Slider from "./Slider";

const SliderDiggingDeeper = ({ userInitLevel, updated = null }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  const SliderDiggingDeeperMarks = [
    {
      value: 0,
      label: (
        <Typography sx={{ fontSize: { xs: "10px", sm: "11px", md: "12px" }, marginLeft: "20px" }}>
          ALL
        </Typography>
      ),
    },
    {
      value: 100,
      label: (
        <Typography
          sx={{
            fontSize: { xs: "10px", sm: "11px", md: "12px" },
            marginLeft: { xs: "-108px", sm: "-115px", md: "-126px" },
          }}
        >
          Those DIGGING DEEPER
        </Typography>
      ),
    },
  ];

  return (
    // Increments: 1
    // Min: 1
    // Max: 100
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < 0 ? 0 : smSize ? 1 : 0.7}
        color="secondary"
        step={1}
        aria-label="Slider"
        value={userInitLevel}
        min={0}
        max={100}
        track="inverted"
        onChange={onSliderChange}
        marks={SliderDiggingDeeperMarks}
        disabled={!updated}
      />
    </div>
  );
};

SliderDiggingDeeper.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

export default SliderDiggingDeeper;
