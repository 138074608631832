import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Images
import michael from "assets/images/faces/michael.jpeg";
import zhen from "assets/images/faces/zhen.jpg";
import bou from "assets/images/faces/bou.jpg";
import lea from "assets/images/faces/lea.jpg";
import gary from "assets/images/faces/gary.jpg";
import andrea from "assets/images/faces/andrea.jpg";
import tomi from "assets/images/faces/tomi.jpg";
import juanita from "assets/images/faces/juanita.jpg";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";
import HorizontalTeamCard from "./HorizontalTeamCard";

const Team = () => {
  return (
    <Box
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <Typography variant="h3" color="white">
              The inSight Team
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={gary}
                name="Gary Kooznetsoff"
                description="There is nothing intrinsically wrong with highly biased opinions or being unaware of other perspectives. It only becomes an issue when one believes and acts as if such positions are fair representations of reality."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={andrea}
                name="Andrea Kyburz Kooznetsoff"
                description="True science is never settled and always welcomes debate."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={michael}
                name="Michael Cheveldave"
                description="When we bring our light into this world and take action for the betterment of all, we inspire others to shine brightly. Together we illuminate a collective wisdom in service of creating a better world."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={lea}
                name="Lea Kooznetsoff"
                description="Wisdom is not a goal but a path."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={tomi}
                name="Tomi Owens"
                description="Thus, the seer, in its own nature, abides."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={bou}
                name="Boudewijn Bertsch"
                description="Meet people where they are."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={zhen}
                name="Zhen Goh"
                i
                description="And into the caverns of tomorrow with just our flashlights and our love we must plunge, we must plunge, we must plunge."
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={juanita}
                name="Juanita Uribe"
                description="Communication is a dance."
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Team;
