import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Dialog, Container, Grid, Paper, IconButton } from "@mui/material";
import { Info, Article, FilterFrames, Add } from "@mui/icons-material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import Typography from "../MaterialKit/MKTypography";
import Box from "../MaterialKit/MKBox";
import NavbarTitle from "../navbar/NavbarTitle";
import DialogNoSave from "../dialog/DialogNoSave";
import DialogIdeaAdd from "./DialogIdeaAdd";
import ActivityEdit from "./ActivityEdit";
import Spinner from "../layout/Spinner";
import IntellCard from "../intell/IntellCard";
import InfoCard from "../layout/InfoCard";

import { addActivity } from "../../actions/activity";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

// Create a reusable button style to avoid theme reference issues
const getButtonStyle = (theme) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  borderRadius: "12px",
  p: 1.5,
  transition: "all 0.3s ease",
  position: "relative",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    transform: "translateY(-3px)",
    boxShadow: `0 8px 16px -4px ${alpha(theme.palette.primary.main, 0.3)}`,
  },
});

const encodeChildAllowedType = (
  childAllowedComments,
  childAllowedInsights,
  childAllowedQuotations
) => {
  return (
    (childAllowedComments ? 1 : 0) +
    (childAllowedInsights ? 2 : 0) +
    (childAllowedQuotations ? 4 : 0)
  );
};

const DialogActivityAdd = ({ handleDialogClose, addActivity, activity: { activity } }) => {
  const theme = useTheme();
  const [intellSelected, setIntellSelected] = useState(null);
  const [confirmExit, setConfirmExit] = useState(false);
  const [activityType, setActivityType] = useState(-1);
  const [childAllowed, setChildAllowed] = useState(false);
  const [childAllowedAuthors, setChildAllowedAuthors] = useState(2); // 0: None, 1: Any participant, 2: ADMIN
  const [childAllowedComments, setChildAllowedComments] = useState(true);
  const [childAllowedInsights, setChildAllowedInsights] = useState(true);
  const [childAllowedQuotations, setChildAllowedQuotations] = useState(true);
  const [childAllowedExpertise, setChildAllowedExpertise] = useState(0);
  const [childGoLiveAuto, setChildGoLiveAuto] = useState(0);
  const [instructionsTime, setInstructionsTime] = useState("");
  const [instructionsOther, setInstructionsOther] = useState("");

  const handleBackClick = () => {
    if (intellSelected) {
      // Need to confirm exit without save
      setConfirmExit(true);
    } else {
      // No changes made; exit directly
      handleDialogClose(null);
    }
  };

  const handleConfirmExit = (e) => {
    if (e) {
      handleDialogClose(null);
    }
    setConfirmExit(false);
  };

  const [saving, setSaving] = useState(false);

  const onSubmit = (intell) => {
    setSaving(true);
    addActivity({
      intell,
      enabled: false,
      childAllowedAuthors: childAllowed ? childAllowedAuthors : 0,
      childAllowedType: encodeChildAllowedType(
        childAllowedComments,
        childAllowedInsights,
        childAllowedQuotations
      ),
      childAllowedExpertise,
      childGoLiveAuto,
      instructionsTime: instructionsTime.trim(),
      instructionsOther: instructionsOther.trim(),
    });
  };

  // Exit strategy this component (a new activity is guaranteed to have a unique id)
  const [initID] = useState(activity && activity._id ? activity._id : 0);
  useEffect(() => {
    if (activity && activity._id) {
      if (activity._id !== initID) {
        handleDialogClose(activity);
      }
    }
  }, [activity]);

  const [addingIdea, setAddingIdea] = useState(false);
  const handleChooseIntell = (e) => {
    setAddingIdea(true);
    setActivityType(e);
  };

  const handleAddIdeaExit = (e) => {
    setAddingIdea(false);
    if (e) {
      if (activityType === 0) {
        // Info -- save directly & return direcly to activity list (info has no activity configuratio
        onSubmit(e);
        // Info -- save directly & return direcly to activity list (info has no activity configuration)
      } else {
        // inTell or framing statement; allow to edit activity configuration -- will require a save
        setIntellSelected(e);
      }
    }
  };

  return (
    <>
      <Dialog open fullScreen>
        <NavbarTitle
          paper
          title={
            intellSelected
              ? activityType === 0
                ? "Info message activity"
                : activityType === 1
                  ? "InTell activity"
                  : "Framing statement activity"
              : "Add a new activity"
          }
          backClick={handleBackClick}
          showSave={intellSelected ? 1 : -1}
          saveClick={() => onSubmit(intellSelected)}
        />
        {saving ? (
          <Spinner />
        ) : (
          <Container sx={{ mt: 3, mb: 3 }}>
            {!intellSelected ? (
              <>
                <InfoCard title="Choose an activity type to add" />

                {/* Info Message Glass Panel */}
                <GlassPanel elevation={0} sx={{ mb: 3 }}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="h6" fontWeight="medium">
                          Info message
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          Provides guidance and instruction to participants.
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          Does not ask participants for feedback and will be dismissed once read.
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="large"
                        color="primary"
                        onClick={(e) => {
                          e.target.blur();
                          handleChooseIntell(0);
                        }}
                        sx={getButtonStyle(theme)}
                      >
                        <Add />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -5,
                            right: -5,
                            borderRadius: "50%",
                            width: 25,
                            height: 25,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: `0 2px 5px ${alpha(theme.palette.primary.main, 0.5)}`,
                          }}
                        >
                          <Info
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                      </IconButton>
                    </Grid>
                  </Grid>
                </GlassPanel>

                {/* inTell Glass Panel */}
                <GlassPanel elevation={0} sx={{ mb: 3 }}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="h6" fontWeight="medium">
                          inTell
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          Typically, inTell is a news story, or online post, etc.
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          Participants will interpret inTell (groundedness and goodness).
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="large"
                        color="primary"
                        onClick={(e) => {
                          e.target.blur();
                          handleChooseIntell(1);
                        }}
                        sx={getButtonStyle(theme)}
                      >
                        <Add />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -5,
                            right: -5,
                            borderRadius: "50%",
                            width: 25,
                            height: 25,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: `0 2px 5px ${alpha(theme.palette.primary.main, 0.5)}`,
                          }}
                        >
                          <Article
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                      </IconButton>
                    </Grid>
                  </Grid>
                </GlassPanel>

                {/* Framing Statement Glass Panel */}
                <GlassPanel elevation={0}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Box display="flex" flexDirection="column" gap={1}>
                        <Typography variant="h6" fontWeight="medium">
                          Framing statement
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          A typical starting point to broadly understand sentiment around a topic.
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          Written as a declarative statement so that participant responses indicate
                          levels of agreement or disagreement.
                        </Typography>
                        <Typography variant="body2" color="secondary" sx={{ lineHeight: 1.1 }}>
                          Example - &quot;Censorship is the obvious fingerprint of tyranny.&quot;
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="large"
                        color="primary"
                        onClick={(e) => {
                          e.target.blur();
                          handleChooseIntell(2);
                        }}
                        sx={getButtonStyle(theme)}
                      >
                        <Add />
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: -5,
                            right: -5,
                            borderRadius: "50%",
                            width: 25,
                            height: 25,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            boxShadow: `0 2px 5px ${alpha(theme.palette.primary.main, 0.5)}`,
                          }}
                        >
                          <FilterFrames
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                      </IconButton>
                    </Grid>
                  </Grid>
                </GlassPanel>
              </>
            ) : (
              <GlassPanel elevation={0}>
                <IntellCard intell={intellSelected} view={1} />

                {activityType > 0 && (
                  <ActivityEdit
                    childAllowed={childAllowed}
                    setChildAllowed={setChildAllowed}
                    childAllowedAuthors={childAllowedAuthors}
                    setChildAllowedAuthors={setChildAllowedAuthors}
                    childAllowedExpertise={childAllowedExpertise}
                    setChildAllowedExpertise={setChildAllowedExpertise}
                    childGoLiveAuto={childGoLiveAuto}
                    setChildGoLiveAuto={setChildGoLiveAuto}
                    childAllowedComments={childAllowedComments}
                    setChildAllowedComments={setChildAllowedComments}
                    childAllowedInsights={childAllowedInsights}
                    setChildAllowedInsights={setChildAllowedInsights}
                    childAllowedQuotations={childAllowedQuotations}
                    setChildAllowedQuotations={setChildAllowedQuotations}
                    instructionsTime={instructionsTime}
                    setInstructionsTime={setInstructionsTime}
                    instructionsOther={instructionsOther}
                    setInstructionsOther={setInstructionsOther}
                  />
                )}
              </GlassPanel>
            )}
          </Container>
        )}
      </Dialog>

      {confirmExit && <DialogNoSave handleDialogClose={handleConfirmExit} />}

      {addingIdea && (
        <DialogIdeaAdd
          title={
            activityType === 2
              ? "Framing statement activity content"
              : activityType === 1
                ? "inTell activity content"
                : "Info message activity content"
          }
          handleDialogClose={handleAddIdeaExit}
          intellSelected={intellSelected}
          setIntellSelected={setIntellSelected}
          activityType={activityType}
        />
      )}
    </>
  );
};

DialogActivityAdd.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  addActivity: PropTypes.func.isRequired,
  activity: PropTypes.shape({
    activity: PropTypes.oneOfType([PropTypes.object]),
  }),
};

const mapStateToProps = (state) => ({
  activity: state.activity,
});

export default connect(mapStateToProps, { addActivity })(DialogActivityAdd);
