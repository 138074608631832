import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { TextField, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";
import exists from "../../utils/exists";
import { modifyFellowship } from "../../actions/fellowship";

const FellowshipName = ({
  typoColor,
  allowEdit = false,
  modifyFellowship,
  fellowship: { fellowship, fellowships },
}) => {
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [editName, setEditName] = useState(false);
  const [originalName] = useState(fellowship.name);

  useEffect(() => {
    // Restore existing Name
    if (fellowship && exists(fellowship.name)) {
      setName(fellowship.name);
      setNameValid(true);
    }
  }, [editName]);

  const updateName = (e) => {
    if (e.length > 0) {
      if (e === originalName || !fellowships.some((obj) => obj.name === e)) {
        // The entered name is the original name, or the entered name does not exist in the fellowships array; update
        modifyFellowship(fellowship._id, { name: e });
      } else {
        setNameValid(false);
      }
    } else {
      // Do not show error; show nothing (do nothing)
      setNameValid(true);
    }
  };

  useEffect(() => {
    if (fellowship && exists(fellowship.name)) {
      if (name === fellowship.name) {
        // The fellowship.name is equal to what is being entered; success!
        setNameValid(true);
      } else {
        // The fellowship.name is not equal to what is being entered; redo (this might happen when keys are pressed in quick succession)
        updateName(name);
      }
    } else {
      setNameValid(false);
    }
  }, [fellowship]);

  const handleName = (e) => {
    setName(e);
    updateName(e);
  };

  return (
    <div>
      {editName ? (
        <Box onChange={(e) => handleName(e.target.value)} sx={{ mb: 2 }}>
          <TextField
            variant="outlined"
            error={name.length > 0 && !nameValid}
            size="small"
            type="inputText"
            label={name.length > 0 && !nameValid ? "Error" : "Edit fellowship name"}
            fullWidth
            value={name}
            helperText={
              name.length > 0 && !nameValid ? "A group with this name already exists" : ""
            }
            inputRef={(input) => input && input.focus()}
            onBlur={() => setEditName(false)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setEditName(false)}>
                  <Close sx={{ mr: -2 }} />
                </IconButton>
              ),
            }}
          />
        </Box>
      ) : (
        <>
          <Typography
            onClick={() => setEditName(allowEdit)}
            variant="button"
            fontWeight="bold"
            color={typoColor}
            sx={{
              fontSize: "14px",
            }}
          >
            {name}
          </Typography>

          <Typography sx={{ fontSize: "9px", color: "grey" }}>
            {fellowship && fellowship.allowed
              ? fellowship.allowed.filter((obj) => obj.myGroup <= fellowship.groupNumber).length > 1
                ? `${fellowship.allowed.filter((obj) => obj.myGroup <= fellowship.groupNumber).length} members`
                : fellowship.allowed.filter((obj) => obj.myGroup <= fellowship.groupNumber)
                      .length === 1
                  ? "1 member"
                  : "0 members"
              : "0 members"}
          </Typography>
        </>
      )}
    </div>
  );
};

FellowshipName.propTypes = {
  allowEdit: PropTypes.bool,
  typoColor: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, {
  modifyFellowship,
})(FellowshipName);
