import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import {
  Dialog,
  Stack,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import Typography from "../MaterialKit/MKTypography";
import Button from "../MaterialKit/MKButton";
import NavbarTitle from "../navbar/NavbarTitle";
import MembersAdd from "./MembersAdd";
import DialogConfirm from "../dialog/DialogConfirm";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  transition: "all 0.2s ease",
  "&:hover": {
    color: theme.palette.error.main,
    transform: "translateY(-2px)",
  },
}));

const DialogMembersEdit = ({
  handleBack,
  fellowshipType,
  privateGroup,
  allowed,
  setAllowed,
  groupNumber,
  setGroupNumber,
  groupEdit, // Possible: 0=Host(s), 1+ Group number (Note myGroup: -1: host-only, 0: host (& participant in any/all groups), 1..x: Participant (only) in Group 1..x)
  auth,
}) => {
  const theme = useTheme();

  const countMyGroup = (arr, groupType) => {
    return arr.reduce((count, obj) => {
      if (obj.myGroup === groupType) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const onMemberRemove = (email) => {
    const index = allowed.findIndex((obj) => obj.email === email);
    if (index >= 0) {
      setAllowed([...allowed.slice(0, index), ...allowed.slice(index + 1)]);
    }
  };

  // Delete member confirmation dialog
  // - By default, all deletion of members requires a confirmation. deleteConfirmNotRequired diables this (for this session)
  const [deleteConfirmNotRequired, setDeleteConfirmNotRequired] = useState(false);
  const [deleteConfirmEmail, setDeleteConfirmEmail] = useState([]);

  const handleDialogCloseDelete = (e) => {
    // true if member removal confirmed, false if canceled
    if (e && deleteConfirmEmail.length > 0) {
      onMemberRemove(deleteConfirmEmail[0]);
    }
    setDeleteConfirmEmail([]);
  };

  const onMemberRemoveRequest = (email) => {
    if (!deleteConfirmNotRequired) {
      setDeleteConfirmEmail([email]);
    } else {
      onMemberRemove(email);
    }
  };

  const handleChangeRole = (email, event) => {
    const index = allowed.findIndex((obj) => obj.email === email);
    if (index >= 0) {
      const newAllowed = allowed;
      newAllowed[index].myGroup = event.target.value;
      setAllowed(newAllowed);
    }
  };

  // Delete group (and associated members) confirmation dialog
  const [deleteConfirmGroupMembers, setDeleteConfirmGroupMembers] = useState([]);

  // A function that takes an array arr of objects and a targetNumber as inputs.
  // The function decrements the myGroup value by 1 for any object where myGroup exceeds the targetNumber, and returns the updated array
  const adjustGroups = (arr, targetNumber) => {
    return arr.map((obj) => {
      if (obj.myGroup > targetNumber) {
        return { ...obj, myGroup: obj.myGroup - 1 };
      }
      return obj;
    });
  };

  const deleteGroup = () => {
    let tempAllowed = allowed;
    for (let i = 0; i < deleteConfirmGroupMembers.length; i += 1) {
      const index = tempAllowed.findIndex(
        (obj) => obj.email === deleteConfirmGroupMembers[i].email
      );
      if (index >= 0) {
        tempAllowed = [...tempAllowed.slice(0, index), ...tempAllowed.slice(index + 1)];
      }
    }

    setAllowed(adjustGroups(tempAllowed, groupEdit));
    setGroupNumber(groupNumber - 1);
    setDeleteConfirmGroupMembers([]);

    // Return back with -1 (no longer editing a group)
    handleBack(-1);
  };

  const handleDialogCloseDeleteGroupMembers = (e) => {
    if (e) {
      // Group/members removal confirmed
      deleteGroup();
    }
    // Cancel; do nothing
    setDeleteConfirmGroupMembers([]);
  };

  const handleDeleteGroup = () => {
    if (groupEdit > 0) {
      if (countMyGroup(allowed, groupEdit) === 0) {
        // console.log("handleDeleteGroup", groupEdit, " with (0) members");
        // No members (no need to requiest confirmation dialog); simply delete & exit
        deleteGroup();
      } else {
        // There are members in this group; create confirmation dialog
        // console.log("handleDeleteGroup", groupEdit, "with members -- dialog required");
        setDeleteConfirmGroupMembers(allowed.filter((obj) => obj.myGroup === groupEdit));
      }
    } else {
      console.log("Should never get here as we cannot delete host group!");
    }
  };

  return (
    <Dialog open fullScreen>
      <NavbarTitle
        paper
        title={
          groupEdit <= 0
            ? "Hosts"
            : fellowshipType === 1
              ? `Class ${groupEdit}`
              : `Group ${groupEdit}`
        }
        backClick={() => handleBack(-1)}
      />
      <Container sx={{ mt: 3, mb: 3 }}>
        <GlassPanel elevation={0}>
          <MembersAdd allowed={allowed} setAllowed={setAllowed} groupEdit={groupEdit} />
        </GlassPanel>

        {/* Table of members */}
        {(groupEdit <= 0 ||
          (groupEdit > 0 && countMyGroup(allowed, 0) + countMyGroup(allowed, groupEdit) > 0)) && (
          <GlassPanel elevation={0}>
            <TableContainer>
              <Table sx={{ minWidth: 300 }} aria-label="a dense table">
                {/* Table header (Email addresses title) */}
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" sx={{ pt: 1, pb: 1 }} />
                    <TableCell sx={{ pt: 1, pb: 1 }}>
                      <Typography sx={{ fontSize: "12px" }} fontWeight="bold">
                        Email
                      </Typography>
                    </TableCell>
                    {privateGroup && <TableCell sx={{ pt: 1, pb: 1 }} align="right" />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* If group>0 (group participant group), show all participant host - e.myGroup === 0 */}
                  {/* Show participants with e.myGroup matching groups */}
                  {/* If group === 0 (host group), show all hosts - e.myGroup <= 0 */}
                  {allowed
                    .filter(
                      (e) =>
                        (groupEdit > 0 && e.myGroup === 0) ||
                        groupEdit === e.myGroup ||
                        (groupEdit === 0 && e.myGroup <= 0)
                    )
                    .map((member) => (
                      <TableRow
                        key={Math.random()}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          transition: "background-color 0.2s ease",
                          "&:hover": {
                            backgroundColor: alpha(theme.palette.primary.main, 0.05),
                          },
                        }}
                      >
                        <TableCell sx={{ pt: 1, pb: 1 }}>
                          {/* Cannot delete self if self is only host */}
                          {auth &&
                            auth.user &&
                            (auth.user.email !== member.email ||
                              (auth.user.email === member.email &&
                                countMyGroup(allowed, -1) + countMyGroup(allowed, 0) > 1) ||
                              (auth.user.email === member.email &&
                                countMyGroup(allowed, -1) + countMyGroup(allowed, 0) === 1 &&
                                member.myGroup > 0)) && (
                              <StyledIconButton
                                sx={{ mb: -1, p: 0 }}
                                onClick={() => onMemberRemoveRequest(member.email)}
                              >
                                <Delete fontSize="small" />
                              </StyledIconButton>
                            )}
                        </TableCell>

                        <TableCell component="th" scope="row" sx={{ pt: 1, pb: 1 }}>
                          {member.email === (auth && auth.user ? auth.user.name : "") && (
                            <Typography sx={{ fontSize: "12px" }}>{member.email} (me)</Typography>
                          )}
                          {member.email !== (auth && auth.user ? auth.user.name : "") && (
                            <Typography sx={{ fontSize: "12px" }}>{member.email}</Typography>
                          )}
                        </TableCell>

                        {/* Show role only if this this a private group. If its a public role, one can only be a host */}
                        {privateGroup && (
                          <TableCell align="right" sx={{ pt: 1, pb: 1 }}>
                            <FormControl variant="standard" sx={{ m: 1 }}>
                              <Select
                                value={member.myGroup}
                                onChange={(e) => handleChangeRole(member.email, e)}
                                sx={{
                                  fontSize: "12px",
                                  transition: "all 0.2s ease",
                                  "&:hover": {
                                    color: theme.palette.primary.main,
                                  },
                                }}
                                disableUnderline
                              >
                                {/* Cannot delete self from Host role if self is only host */}
                                {auth &&
                                  auth.user &&
                                  (auth.user.email !== member.email ||
                                    (auth.user.email === member.email &&
                                      countMyGroup(allowed, -1) + countMyGroup(allowed, 0) > 1) ||
                                    (auth.user.email === member.email &&
                                      countMyGroup(allowed, -1) + countMyGroup(allowed, 0) &&
                                      member.myGroup > 0)) &&
                                  [...Array(groupNumber)].map((_, index) => (
                                    <MenuItem
                                      key={Math.random()}
                                      value={index + 1}
                                      sx={{ fontSize: "14px" }}
                                    >
                                      {`${fellowshipType === 1 ? `Class ${index + 1}` : `Group ${index + 1}`}`}
                                    </MenuItem>
                                  ))}

                                <MenuItem value={0} sx={{ fontSize: "14px" }}>
                                  Host (& participant)
                                </MenuItem>

                                <MenuItem value={-1} sx={{ fontSize: "14px" }}>
                                  Host
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GlassPanel>
        )}

        {groupEdit > 0 && (
          <Stack
            flexWrap="wrap"
            flexDirection="row"
            width={1.0}
            justifyContent="center"
            sx={{ mt: 3 }}
          >
            <Button
              variant="text"
              color="primary"
              size="large"
              onClick={() => handleDeleteGroup()}
              sx={{
                transition: "all 0.3s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  color: theme.palette.error.main,
                },
              }}
            >
              {`Delete this ${fellowshipType === 1 ? "class" : "group"}`}
            </Button>
          </Stack>
        )}

        {deleteConfirmEmail.length > 0 && (
          <DialogConfirm
            title={`${deleteConfirmEmail[0]} will no longer be able to access this group`}
            handleDialogClose={handleDialogCloseDelete}
            checkBoxLabel="Do not ask for confirmation again when removing member access"
            checkBoxValue={deleteConfirmNotRequired}
            setCheckBoxValue={setDeleteConfirmNotRequired}
          />
        )}
        {deleteConfirmGroupMembers.length > 0 && (
          <DialogConfirm
            title="Removing this group will remove access for:"
            subtitles={deleteConfirmGroupMembers.map((member) => member.email)}
            confirmButtonLabel="OK"
            handleDialogClose={handleDialogCloseDeleteGroupMembers}
          />
        )}
      </Container>
    </Dialog>
  );
};

DialogMembersEdit.propTypes = {
  handleBack: PropTypes.func.isRequired,
  fellowshipType: PropTypes.number.isRequired,
  privateGroup: PropTypes.bool.isRequired,
  allowed: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  setAllowed: PropTypes.func.isRequired,
  groupNumber: PropTypes.number.isRequired,
  setGroupNumber: PropTypes.func.isRequired,
  groupEdit: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(DialogMembersEdit);
