/* eslint-disable consistent-return */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";

// MUI
import { Dialog, DialogTitle, DialogContent, DialogActions, Paper, Alert } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import { debugLevel } from "../../config/personalize";
import Typography from "../MaterialKit/MKTypography";
import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import AgentCreateAI from "./AgentCreateAI";
import SelectImage from "../media/SelectImage";
import exists from "../../utils/exists";
import { addAgent, searchAgents } from "../../actions/agent";
import { setAlert } from "../../actions/alert";
import AgentChip from "./AgentChip";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(3),
}));

const AgentCreateDialog = ({
  aiOnInit = false,
  dialogTitle = "Add waveMaker",
  originalDescription = "",
  originalUrl = "",
  handleDialogClose,
  addAgent,
  setAlert,
  searchAgents,
  agent,
  fellowship: { fellowship },
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  if (debugLevel > 0) console.log("AgentCreateDialog enter");

  const [urlField, setUrlField] = useState(originalUrl);
  const [selectedImage, setSelectedImage] = useState("");
  const [agentDescription, setAgentDescription] = useState(originalDescription);
  const [submitting, setSubmitting] = useState(false);
  const [busyTextAI, setBusyTextAI] = useState(false);
  const [busyImageAI, setBusyImageAI] = useState(false);
  const [existingAgent, setExistingAgent] = useState(null);
  const [tabValue, setTabValue] = useState(0); // 0: Use Existing, 1: Create New
  const [initialExistingAgentSet, setInitialExistingAgentSet] = useState(false);
  const [initialAiGenerated, setInitialAiGenerated] = useState(false);
  const [showUrlField, setShowUrlField] = useState(!!originalUrl); // Show URL field only if initial URL exists
  const [nameToSearch, setNameToSearch] = useState("");
  const [matchType, setMatchType] = useState(""); // "url" or "name" to track type of match

  useEffect(() => {
    if (submitting) {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  }, [submitting]);

  // Check if URL already exists in database
  useEffect(() => {
    const checkExistingUrl = async () => {
      if (urlField && urlField.length > 0 && urlField !== originalUrl) {
        try {
          await searchAgents(urlField, 1, "100"); // Special "100" means exact URL match
        } catch (err) {
          if (debugLevel > 0) console.log("Error searching for existing URL:", err);
        }
      } else {
        setExistingAgent(null);
      }
    };

    checkExistingUrl();
  }, [urlField, searchAgents, originalUrl]);

  // Check for existing agent in search results
  useEffect(() => {
    if (agent && agent.agents && Array.isArray(agent.agents) && agent.agents.length > 0) {
      // First check for URL match (higher priority)
      const urlMatchingAgent = urlField ? agent.agents.find((a) => a.url === urlField) : null;

      if (urlMatchingAgent) {
        if (debugLevel > 0) console.log("Found existing agent with same URL:", urlMatchingAgent);
        setExistingAgent(urlMatchingAgent);
        setMatchType("url");

        // Only set the tab to 0 on the first discovery of an existing agent
        if (!initialExistingAgentSet) {
          setTabValue(0);
          setInitialExistingAgentSet(true);
          // We'll avoid triggering refresh here
        }
      } else {
        // If no URL match, check for name match
        const nameMatchingAgent = nameToSearch
          ? agent.agents.find(
              (a) =>
                a.name.toLowerCase() === nameToSearch.toLowerCase() ||
                a.name.toLowerCase().startsWith(`${nameToSearch.toLowerCase()},`)
            )
          : null;

        if (nameMatchingAgent) {
          if (debugLevel > 0)
            console.log("Found existing agent with similar name:", nameMatchingAgent);
          setExistingAgent(nameMatchingAgent);
          setMatchType("name");

          // Only set the tab to 0 on the first discovery of an existing agent
          if (!initialExistingAgentSet) {
            setTabValue(0);
            setInitialExistingAgentSet(true);
            // We'll avoid triggering refresh here
          }
        } else {
          setExistingAgent(null);
          setMatchType("");
          setInitialExistingAgentSet(false);
        }
      }
    }
  }, [agent, urlField, nameToSearch]);

  // Track when AI has already been generated to prevent regeneration
  useEffect(() => {
    if (!busyTextAI && !busyImageAI && agentDescription && agentDescription.length > 0) {
      setInitialAiGenerated(true);
    }
  }, [busyTextAI, busyImageAI, agentDescription]);

  // Extract name before first comma when agent description changes
  useEffect(() => {
    if (agentDescription) {
      const nameBeforeComma = agentDescription.split(",")[0].trim();
      if (nameBeforeComma && nameBeforeComma.length > 2 && nameBeforeComma !== nameToSearch) {
        const nameBeforeColon = nameBeforeComma.split(":")[0].trim();
        if (nameBeforeColon && nameBeforeColon.length > 2 && nameBeforeColon !== nameToSearch) {
          setNameToSearch(nameBeforeColon);
        }
      }
    }
  }, [agentDescription]);

  // Check if name already exists in database
  useEffect(() => {
    const checkExistingName = async () => {
      if (nameToSearch && nameToSearch.length > 2) {
        try {
          await searchAgents(nameToSearch, 1, "101"); // Special "101" means exact name match
        } catch (err) {
          if (debugLevel > 0) console.log("Error searching for existing name:", err);
        }
      }
    };

    checkExistingName();
  }, [nameToSearch, searchAgents]);

  // Now, let's modify the useEffect for state synchronization to be more careful
  useEffect(() => {
    // Only run this effect on the initial mount
    // We'll use a ref to ensure this only runs once when an existing agent is found
    if (existingAgent && (busyTextAI || busyImageAI)) {
      const timer = setTimeout(() => {
        if (debugLevel > 0) console.log("Forcing AI busy states to false for existing agent");
        setBusyTextAI(false);
        setBusyImageAI(false);
        // No need to trigger another refresh
      }, 1000); // 1 second timeout

      return () => clearTimeout(timer);
    }
  }, [existingAgent]);

  const updateUrlDetails = (e) => {
    // image is the most relevant
    if (debugLevel > 0) console.log("updateUrlDetails", e);
    if (exists(e.image)) {
      setSelectedImage(e.image);
    }
    if (exists(e.busyAI)) {
      setBusyImageAI(e.busyAI);
    }
    if (exists(e.finalUrl)) {
      setUrlField(e.finalUrl);
      setShowUrlField(true); // Show URL field when a URL is scraped
    }
  };

  // Updated URL field handler to hide field if URL is cleared and clear selected image
  const handleUrlFieldChange = (value) => {
    // If the URL has significantly changed, clear the selected image
    if (value !== urlField) {
      // Check if it's a substantial change, not just adding https:// or www.
      const cleanOldUrl = urlField.replace(/^https?:\/\//i, "").replace(/^www\./i, "");
      const cleanNewUrl = value.replace(/^https?:\/\//i, "").replace(/^www\./i, "");

      if (cleanOldUrl !== cleanNewUrl) {
        // Clear the image as it's likely no longer relevant
        setSelectedImage("");
        // Set busyImageAI to true to indicate we need to re-scrape
        setBusyImageAI(true);

        // After a brief delay, reset the busy state to allow new scraping
        setTimeout(() => {
          setBusyImageAI(false);
        }, 500);
      }
    }

    setUrlField(value);
    if (value === "") {
      setShowUrlField(false);
    }
  };

  // Finally, let's update the handleSetBusy function to be more careful
  const handleSetBusy = (busy) => {
    if (existingAgent && !busy) {
      setBusyTextAI(false);
      setBusyImageAI(false);
    } else {
      setBusyTextAI(busy);
    }
  };

  const onSubmitAgent = () => {
    if (!(exists(fellowship) && exists(fellowship._id))) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }

    // If we're using an existing agent (tabValue === 0 and existingAgent is set)
    if (existingAgent && tabValue === 0) {
      if (debugLevel > 0) console.log("Using existing agent:", existingAgent);
      setSubmitting(true);
      // Return the existing agent directly
      handleDialogClose(existingAgent);
      return;
    }

    let bValid = true;
    if (agentDescription.length <= 1) {
      setAlert("Invalid agent description. Please be more descriptive.", "danger");
      bValid = false;
    }

    if (debugLevel > 0) console.log("onSubmitAgent", agentDescription, urlField, selectedImage);

    if (bValid) {
      setSubmitting(true);
      if (selectedImage.length < 1) {
        // No image
        if (debugLevel > 0) console.log("addAgent() without image");
        addAgent({
          fellowship: fellowship._id,
          name: agentDescription,
          url: urlField,
        });
      } else {
        // With image
        if (debugLevel > 0) console.log("addAgent() with image");
        addAgent({
          fellowship: fellowship._id,
          name: agentDescription,
          url: urlField,
          image: selectedImage,
        });
      }
    }
  };

  // Exit strategy to exit this component and scale new agent (report back to parent once back-end confirms submission)
  // - Store initial agent (when entering this component)
  const [initAgentID] = useState(agent && agent.agent && agent.agent._id ? agent.agent._id : 0);
  useEffect(() => {
    if (!submitting && agent && agent.agent && agent.agent._id) {
      if (agent.agent._id !== initAgentID) {
        if (debugLevel > 0) console.log("New agent successfully created");
        handleDialogClose(agent.agent);
      }
    }
  }, [agent, submitting]);

  return (
    <Dialog
      open
      fullWidth
      onClose={() => handleDialogClose(null)}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: `0 12px 40px -8px ${alpha(theme.palette.common.black, 0.2)}`,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "1.25rem",
          fontWeight: 600,
          color: theme.palette.text.primary,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          py: 2.5,
        }}
      >
        {dialogTitle}
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {/* Show tab selection when existing agent is found */}
        {existingAgent && (
          <Box sx={{ mb: 2 }}>
            <Alert
              severity="info"
              sx={{
                my: 1,
                borderRadius: "8px",
                boxShadow: `0 4px 12px ${alpha(theme.palette.info.main, 0.15)}`,
              }}
            >
              {matchType === "url"
                ? "This URL already exists for another waveMaker"
                : "A waveMaker with a similar name already exists"}
            </Alert>

            <Box
              sx={{
                mb: 2,
                display: "grid",
                gap: 0,
                gridTemplateColumns: "repeat(2, 1fr)",
              }}
            >
              <Button
                fullWidth
                onClick={() => setTabValue(0)}
                sx={{
                  borderBottom: tabValue === 0 ? "3px solid #0074F4" : "",
                  borderRadius: 0,
                }}
              >
                Use Existing
              </Button>
              <Button
                fullWidth
                onClick={() => setTabValue(1)}
                sx={{
                  borderBottom: tabValue === 1 ? "3px solid #0074F4" : "",
                  borderRadius: 0,
                }}
              >
                Create New
              </Button>
            </Box>
          </Box>
        )}

        <GlassPanel elevation={0}>
          {/* Show existing agent when tab is "Use Existing" - make this always visible regardless of busy state */}
          {existingAgent && tabValue === 0 && (
            <Box sx={{ textAlign: "center", my: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <AgentChip agent={existingAgent} isCreator={false} />
              </Box>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  mt: 1,
                  color: theme.palette.text.secondary,
                }}
              >
                Using this existing waveMaker is recommended to avoid duplicates
              </Typography>
              {(busyTextAI || busyImageAI) && (
                <Typography
                  variant="caption"
                  sx={{
                    display: "block",
                    textAlign: "center",
                    mt: 1,
                    color: theme.palette.info.main,
                  }}
                >
                  Please wait for AI processing to complete...
                </Typography>
              )}
            </Box>
          )}

          {/* Show create form when tab is "Create New" or no existing agent found */}
          {(!existingAgent || tabValue === 1) && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <SelectImage
                  useAI={!busyTextAI}
                  avatarView
                  url={urlField}
                  initialImage={selectedImage}
                  updateUrlDetails={updateUrlDetails}
                  description={agentDescription}
                />
              </Box>

              <Box sx={{ mt: 3 }}>
                {/* Only use aiOnInit on initial load or when no AI has been generated yet */}
                <AgentCreateAI
                  aiOnInit={
                    (aiOnInit && !initialAiGenerated) ||
                    (existingAgent === null && !initialAiGenerated)
                  }
                  aiButton={!busyImageAI}
                  labelField={agentDescription}
                  setLabelField={setAgentDescription}
                  urlField={urlField}
                  setUrlField={handleUrlFieldChange}
                  setBusy={handleSetBusy}
                  showUrlField={showUrlField}
                  isCheckingUrl={false}
                  isCheckingName={false}
                />

                {/* Show add URL button when URL field is hidden */}
                {!showUrlField && (
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <Button
                      variant="text"
                      color="info"
                      size="small"
                      onClick={() => setShowUrlField(true)}
                      startIcon={<Add />}
                      sx={{
                        borderRadius: "20px",
                        padding: "4px 12px",
                        fontSize: "0.75rem",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: `0 4px 8px -2px ${alpha(theme.palette.info.main, 0.25)}`,
                        },
                      }}
                    >
                      Add Representative URL
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          )}
        </GlassPanel>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          pb: 3,
          px: 3,
          backgroundColor: alpha(theme.palette.background.paper, 0.6),
        }}
      >
        {existingAgent && tabValue === 0 && (
          <Button
            variant="contained"
            color="info"
            onClick={() => onSubmitAgent()}
            disabled={busyTextAI || busyImageAI}
            sx={{
              borderRadius: "28px",
              px: 4,
              py: 1.2,
              mr: 2,
              background: `linear-gradient(45deg, ${theme.palette.info.main}, ${alpha(theme.palette.info.light, 0.9)})`,
              boxShadow: `0 8px 20px -4px ${alpha(theme.palette.info.main, 0.4)}`,
              "&:hover": {
                boxShadow: `0 12px 24px -6px ${alpha(theme.palette.info.main, 0.6)}`,
                transform: "translateY(-2px)",
              },
              transition: "all 0.3s ease",
            }}
          >
            {busyTextAI || busyImageAI ? "Loading..." : "Use Existing waveMaker"}
          </Button>
        )}

        {(!existingAgent || tabValue === 1) &&
          !busyTextAI &&
          !busyImageAI &&
          agentDescription.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmitAgent()}
              sx={{
                borderRadius: "28px",
                px: 4,
                py: 1.2,
                mr: 2,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.9)})`,
                boxShadow: `0 8px 20px -4px ${alpha(theme.palette.primary.main, 0.4)}`,
                "&:hover": {
                  boxShadow: `0 12px 24px -6px ${alpha(theme.palette.primary.main, 0.6)}`,
                  transform: "translateY(-2px)",
                },
                transition: "all 0.3s ease",
              }}
            >
              {existingAgent ? "Create New waveMaker" : "Submit"}
            </Button>
          )}

        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleDialogClose(null)}
          sx={{
            borderRadius: "28px",
            px: 4,
            py: 1.2,
            borderWidth: "2px",
            transition: "all 0.2s ease",
            "&:hover": {
              borderWidth: "2px",
              transform: "translateY(-2px)",
              boxShadow: `0 6px 15px -3px ${alpha(theme.palette.primary.main, 0.25)}`,
            },
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AgentCreateDialog.propTypes = {
  aiOnInit: PropTypes.bool,
  dialogTitle: PropTypes.string,
  originalDescription: PropTypes.string,
  originalUrl: PropTypes.string,
  handleDialogClose: PropTypes.func.isRequired,
  searchAgents: PropTypes.func.isRequired,
  addAgent: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  agent: PropTypes.shape({
    agent: PropTypes.shape({}),
    agents: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.string,
      })
    ),
  }),
  fellowship: PropTypes.shape({
    fellowship: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state) => ({
  agent: state.agent,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { addAgent, setAlert, searchAgents })(AgentCreateDialog);
