import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { Button, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import exists from "../../utils/exists";

const BlueLink = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "clickable",
})(({ theme, color, clickable }) =>
  theme.unstable_sx({
    fontSize: "14px",
    fontWeight: 500,
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    color: color || theme.palette.text.primary,
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-line",
    transition: "color 0.2s ease",
    "&:hover, &:focus": clickable
      ? {
          color: theme.palette.primary.main,
          textDecoration: "underline",
        }
      : undefined,
  })
);

const GreenLink = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "clickable",
})(({ theme, color, clickable }) =>
  theme.unstable_sx({
    fontSize: "12px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "13px",
    },
    color: color || theme.palette.text.secondary,
    fontWeight: 400,
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    whiteSpace: "pre-line",
    transition: "color 0.2s ease",
    "&:hover, &:focus": clickable
      ? {
          color: theme.palette.success.main,
          textDecoration: "underline",
        }
      : undefined,
  })
);

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 500,
  borderRadius: "12px",
  padding: "4px 10px",
  fontSize: "12px",
  transition: "all 0.2s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: `0 4px 8px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}));

const Content = ({ intell, view, myPointMaxLength, handleNavigateLink }) => {
  const [showMore, setShowMore] = useState(false);
  const [maxCharactersShowMore] = useState(256);
  const [myPoint] = useState(
    exists(intell.myPoint)
      ? myPointMaxLength >= 0
        ? intell.myPoint.substring(0, myPointMaxLength)
        : intell.myPoint
      : null
  );

  // Use the client-side stored original values if available
  const displayTitle = intell._clientOriginalTitle || intell.title;
  const displayUrl = intell._clientOriginalUrl || intell.url;

  const handleExternalLink = () => {
    console.log("Going to External link...");
    // Use the original URL if available, otherwise fall back to the stored URL
    window.open(displayUrl);
    return null;
  };

  return (
    <div>
      {exists(myPoint) ? (
        <div>
          {myPoint.length > maxCharactersShowMore ? (
            <div>
              {showMore ? (
                <>
                  <BlueLink clickable={view >= 2} onClick={view >= 2 ? handleNavigateLink : null}>
                    {myPoint}
                  </BlueLink>
                  <div style={{ textAlign: "center", marginTop: "8px" }}>
                    <StyledButton size="small" onClick={() => setShowMore(false)} color="primary">
                      Show less
                    </StyledButton>
                  </div>
                </>
              ) : (
                <>
                  <BlueLink clickable={view >= 2} onClick={view >= 2 ? handleNavigateLink : null}>
                    {`${myPoint.substring(0, maxCharactersShowMore)}...`}
                  </BlueLink>
                  {view >= 2 && (
                    <div style={{ marginTop: "4px" }}>
                      <StyledButton size="small" onClick={() => setShowMore(true)} color="primary">
                        Show more
                      </StyledButton>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <BlueLink clickable={view >= 2} onClick={view >= 2 ? handleNavigateLink : null}>
              {myPoint}
            </BlueLink>
          )}
        </div>
      ) : (
        <div>
          {exists(displayTitle) && (
            <>
              <BlueLink clickable={view >= 2} onClick={view >= 2 ? handleNavigateLink : null}>
                {displayTitle}
              </BlueLink>
              <GreenLink clickable={view >= 2} onClick={view >= 2 ? handleExternalLink : null}>
                {displayUrl}
              </GreenLink>
            </>
          )}
        </div>
      )}
    </div>
  );
};

Content.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  view: PropTypes.number.isRequired,
  handleNavigateLink: PropTypes.func.isRequired,
  myPointMaxLength: PropTypes.number.isRequired,
};

export default Content;
