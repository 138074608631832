import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PostView from "./PostView";
import QuestionView from "./QuestionView";
import Spinner from "../layout/Spinner";
import NavbarPost from "../navbar/NavbarPost";
import { getIdea, loadingIdea } from "../../actions/idea";
import exists from "../../utils/exists";

const PostId = ({
  id,
  maxActivityCnt = 0,
  completed = 0,
  currentActivityIndex = 0,
  setCurrentActivityIndex = null,
  activity = null,
  getIdea,
  loadingIdea,
  idea,
}) => {
  useEffect(() => {
    loadingIdea();
    getIdea(id);
  }, [id]);

  return idea.loading ? (
    <Spinner />
  ) : (
    <div>
      {idea.idea && (
        <>
          {/* Show specific post, as part of activity (and sub-menus) */}
          {activity && (
            <NavbarPost
              hrefUp={
                idea.idea.parentIdea && idea.idea.parentIdea._id
                  ? `/activity/${idea.idea.parentIdea._id}`
                  : ""
              }
              hrefHome="/activity"
              maxActivityCnt={maxActivityCnt}
              completed={completed}
              currentActivityIndex={currentActivityIndex}
              setCurrentActivityIndex={setCurrentActivityIndex}
            />
          )}

          {/* Show specific post (and sub-menus) */}
          {!activity && (
            <NavbarPost
              hrefUp={
                idea.idea.parentIdea && idea.idea.parentIdea._id
                  ? `/post/${idea.idea.parentIdea._id}`
                  : ""
              }
              hrefHome="/post"
            />
          )}

          {exists(idea.idea.ideaType) &&
            (idea.idea.ideaType === 10 || idea.idea.ideaType === 11) && (
              <QuestionView initIntell={idea.idea} activity={activity} />
            )}

          {(!exists(idea.idea.ideaType) ||
            idea.idea.ideaType === 0 ||
            idea.idea.ideaType === 1) && <PostView initIntell={idea.idea} activity={activity} />}
        </>
      )}
    </div>
  );
};

PostId.propTypes = {
  id: PropTypes.string.isRequired,
  maxActivityCnt: PropTypes.number,
  completed: PropTypes.number,
  currentActivityIndex: PropTypes.number,
  setCurrentActivityIndex: PropTypes.func,
  activity: PropTypes.oneOfType([PropTypes.object]),
};

const mapStateToProps = (state) => ({
  idea: state.idea,
});

export default connect(mapStateToProps, {
  getIdea,
  loadingIdea,
})(PostId);
