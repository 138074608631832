import axios from "axios";
import { setAlert } from "./alert";
import { AI_ERROR, GET_AI, LOADING_AI, RESET_AI } from "./types";

// Loading
export const loadingAI = () => (dispatch) => {
  dispatch({ type: LOADING_AI });
};

// Reset
export const resetAI = () => (dispatch) => {
  dispatch({ type: RESET_AI });
};

export const getAI = (question) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post("/api/ai", question, config);
    dispatch({ type: RESET_AI });
    dispatch({
      type: GET_AI,
      payload: res.data,
    });
  } catch (err) {
    // err.response.status = error code - ie, 400, 404, 500...
    // err.response.statusText = Text meaning associated with err.response.status
    // err.response.data.errors[] = An array of all errors
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) =>
        dispatch(setAlert(`AI Request Failed: ${error.msg}`, "danger", 1000))
      );
    }
    dispatch({
      type: AI_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
