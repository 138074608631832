import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, DialogActions, DialogContent, Stack, Checkbox } from "@mui/material";

import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";

const DialogConfirm = ({
  title = "",
  subTitles = [],
  confirmButtonLabel = "Confirm", // Associated with handleDialogClose returning true
  otherButtonLabel = "Cancel", // Associated with handleDialogClose returning false
  handleDialogClose, // true (confirmButtonLabwl) or false (otherButtonLabel)
  checkBoxLabel = "", // Optional checkbox below button: label
  checkBoxValue = false, // Optional checkbox below button: initial value
  setCheckBoxValue = undefined,
}) => {
  return (
    <Dialog open>
      <DialogContent sx={{ p: 3 }}>
        {title.length > 0 && (
          <Typography style={{ textAlign: "center", fontSize: "20px" }}>{title}</Typography>
        )}
        {subTitles.length > 0 &&
          subTitles.map(
            (e, index) =>
              e.length > 0 && (
                <Typography
                  key={index} // eslint-disable-line
                  style={{ textAlign: "center", fontSize: "13px", fontWeight: 100 }}
                >
                  {e}
                </Typography>
              )
          )}
      </DialogContent>

      <DialogActions style={{ margin: "0px", border: 1 }}>
        <Stack flexWrap="wrap" justifyContent="center" flexDirection="row" width={1.0}>
          {confirmButtonLabel.length > 0 && (
            <Button onClick={() => handleDialogClose(true)} color="primary" circular sx={{ m: 1 }}>
              {confirmButtonLabel}
            </Button>
          )}
          {otherButtonLabel.length > 0 && (
            <Button
              onClick={() => handleDialogClose(false)}
              color="primary"
              variant="outlined"
              circular
              sx={{ m: 1 }}
            >
              {otherButtonLabel}
            </Button>
          )}
        </Stack>
      </DialogActions>

      {checkBoxLabel.length > 0 && setCheckBoxValue && (
        <Stack
          flexWrap="wrap"
          justifyContent="center"
          flexDirection="row"
          width={1.0}
          sx={{ mb: 1 }}
        >
          <Checkbox
            checked={checkBoxValue}
            onClick={() => setCheckBoxValue(!checkBoxValue)}
            sx={{ mt: -1.7 }}
          />
          <Typography variant="caption">{checkBoxLabel}</Typography>
        </Stack>
      )}
    </Dialog>
  );
};

DialogConfirm.propTypes = {
  title: PropTypes.string,
  subTitles: PropTypes.arrayOf(PropTypes.string),
  confirmButtonLabel: PropTypes.string,
  otherButtonLabel: PropTypes.string,
  handleDialogClose: PropTypes.func.isRequired,
  checkBoxLabel: PropTypes.string,
  checkBoxValue: PropTypes.bool,
  setCheckBoxValue: PropTypes.func,
};

export default DialogConfirm;
