import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

import Typography from "../MaterialKit/MKTypography";
import Slider from "./Slider";

const SliderQuestion = ({ userInitLevel, updated = null }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");

  const marks = [
    {
      value: -1,
      label: (
        <div style={{ marginRight: "-65px" }}>
          <div>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>Completely</Typography>
          </div>
          <div style={{ marginLeft: "5px" }}>
            <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>Disagree</Typography>
          </div>
        </div>
      ),
    },
    {
      value: 1,
      label: (
        <div style={{ marginLeft: "-75px" }}>
          <div>
            <Typography
              sx={{ fontSize: { xs: "10px", sm: "12px" }, ml: { xs: "24px", sm: "18px" } }}
            >
              Completely
            </Typography>
          </div>
          <div style={{ marginLeft: "12px" }}>
            <Typography
              sx={{ fontSize: { xs: "10px", sm: "12px" }, ml: { xs: "24px", sm: "18px" } }}
            >
              Agree
            </Typography>
          </div>
        </div>
      ),
    },
  ];

  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  return (
    // Increments: 0.01
    // Min: -1
    // Max: 1
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < -1 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={-1}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderQuestion.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

export default SliderQuestion;
