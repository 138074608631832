import React from "react";
import PropTypes from "prop-types";

import Input from "../MaterialKit/MKInput";

const CustomExpertiseInput = ({
  onChange,
  type,
  label,
  value,
  maxLength,
  required,
  multiline = true,
  fullWidth = true,
  helperTextPrefix = "",
  inputProps = {},
  sx = {},
}) => {
  const isEmpty = value.trim().length === 0;
  const isError = required && isEmpty;

  // Combine any additional inputProps with maxLength
  const combinedInputProps = {
    ...inputProps,
    maxLength: maxLength !== -1 ? maxLength : undefined,
  };

  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      type={type}
      label={label}
      helperText={
        isError
          ? "REQUIRED FIELD"
          : maxLength !== -1
            ? `${helperTextPrefix}${maxLength} characters max (${value.length}/${maxLength})`
            : ""
      }
      inputProps={combinedInputProps}
      fullWidth={fullWidth}
      multiline={multiline}
      value={value}
      error={isError}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: isError ? "red" : "inherit",
        },
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: isError ? "red" : "#1976d2",
        },
        "& .MuiFormHelperText-root": {
          color: isError ? "red" : "",
          visibility: isError ? "visible" : "hidden", // Default: hide helper text
          fontSize: "0.7rem", // Reduce font size
        },
        "&:focus-within .MuiFormHelperText-root": {
          visibility: "visible", // Show helper text when focused
        },
        ...sx,
      }}
    />
  );
};

CustomExpertiseInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperTextPrefix: PropTypes.string,
  inputProps: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

export default CustomExpertiseInput;
