// MUI
import { Public, AccountCircle, Settings } from "@mui/icons-material";

const RoutesAdmin = (profileID) => {
  // Check if profileID is valid
  const validProfileID = profileID && profileID.length > 0 ? profileID : "me";

  return [
    //  {
    //    name: "Submit new inTell",
    //    icon: <PostAdd />,
    //    collapse: [
    //      {
    //        name: "My insights",
    //        route: "/create1",
    //      },
    //      {
    //        name: "Web link",
    //        route: "/create2",
    //      },
    //    ],
    //  },
    {
      name: "Latest posts",
      icon: <Public />,
      route: "/post",
    },
    {
      name: "About inSight",
      icon: <Public />,
      route: "/about",
    },
    {
      name: "Account",
      icon: <AccountCircle />,
      collapse: [
        {
          name: "My profile",
          route: `/profile/${validProfileID}`,
        },
        {
          name: "Logout",
          route: "/logout",
        },
      ],
    },
    {
      name: "Fellowships",
      icon: <Settings />,
      route: "/fellowships",
    },

    //  {
    //    name: "Suggestions and Bug Reporting",
    //    icon: <BugReport />,
    //    action: 1,
    //  },
  ];
};

export default RoutesAdmin;
