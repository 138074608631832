import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useTheme, alpha } from "@mui/material/styles";
import { Paper, Box } from "@mui/material";

import Typography from "../MaterialKit/MKTypography";
import MultiSelect from "../styles/MultiSelect";
import exists from "../../utils/exists";

const SelectTextLength = ({ textLength = null, setTextLength, messageLabel = "" }) => {
  const theme = useTheme();

  // Drop-down options (default)
  const [options, setOptions] = useState([
    { value: "160", label: "SMS length (160 characters)" },
    { value: "-1", label: "Unlimited character length" },
    { value: "0", label: "No text field (0 characters)" },
  ]);

  // Free-hand input text
  const [inputValue, setInputValue] = useState("");

  const createDefault = () => {
    // If first-time (inputValue.length === 0) & exists(textLength)
    if (inputValue.length === 0 && exists(textLength)) {
      const index = options.findIndex((obj) => obj.value === textLength.toString());
      if (index >= 0) {
        return options[index];
      }

      // Did not find textLength in options; add into 3rd index of options
      setOptions([
        { value: "160", label: "SMS length (160 characters)" },
        { value: "-1", label: "Unlimited character length" },
        { value: "0", label: "This text field will not be offered (0 characters)" },
        { value: textLength.toString(), label: `${textLength} characters` },
      ]);
      return { value: textLength.toString(), label: `${textLength} characters` };
    }
    return null;
  };
  const [defaultValue] = useState(createDefault());

  const handleSelect = (e) => {
    if (e && exists(e.value)) {
      setTextLength(Number(e.value));
    }
  };

  const handleInputChange = (value) => {
    // Allow only numeric input
    if (/^\d*$/.test(value)) {
      setInputValue(value);
      if (value) {
        setOptions([
          { value: "160", label: "SMS length (160 characters)" },
          { value: "-1", label: "Unlimited character length" },
          { value: "0", label: "This text field will not be offered (0 characters)" },
          { value, label: `${value} characters` },
        ]);
      } else {
        setOptions([
          { value: "160", label: "SMS length (160 characters)" },
          { value: "-1", label: "Unlimited character length" },
          { value: "0", label: "This text field will not be offered (0 characters)" },
        ]);
      }
    }
  };

  const customStyles = {
    ...MultiSelect,
    control: (provided) => ({
      ...provided,
      borderRadius: "12px",
      border: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
      boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
      transition: "all 0.3s ease",
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0 4px 8px ${alpha(theme.palette.primary.main, 0.15)}`,
      },
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? alpha(theme.palette.primary.main, 0.1)
        : state.isFocused
          ? alpha(theme.palette.primary.main, 0.05)
          : provided.backgroundColor,
      color: state.isSelected ? theme.palette.primary.main : provided.color,
      transition: "background-color 0.2s ease",
      fontSize: "14px",
      padding: "6px 12px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <Paper
      elevation={0}
      sx={{
        p: 0,
        background: alpha(theme.palette.background.paper, 0.5),
        borderRadius: "12px",
        border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
        transition: "all 0.3s ease",
      }}
    >
      {messageLabel.length > 0 && (
        <Typography
          variant="body2"
          color="secondary"
          sx={{
            fontSize: { xs: "11px", sm: "12px", md: "13px" },
            fontWeight: "100",
          }}
        >
          {messageLabel}
        </Typography>
      )}

      <Box sx={{ flex: "1", mt: 0.5 }}>
        <Select
          defaultValue={defaultValue}
          options={options}
          placeholder="Input number of characters"
          onChange={handleSelect}
          onInputChange={handleInputChange}
          styles={customStyles}
          inputValue={inputValue}
          noOptionsMessage={() => null}
        />
      </Box>
    </Paper>
  );
};

SelectTextLength.propTypes = {
  textLength: PropTypes.number,
  setTextLength: PropTypes.func.isRequired,
  messageLabel: PropTypes.string,
};

export default SelectTextLength;
