import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// MUI
import { Card, IconButton, Box } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Cancel, Edit } from "@mui/icons-material";

import { debugLevel } from "config/personalize";
import AgentCreateAI from "./AgentCreateAI";
import AgentCreateDialog from "./AgentCreateDialog";
import SelectImage from "../media/SelectImage";
import exists from "../../utils/exists";

// Create a styled component for the glass effect
const GlassCard = styled(Card)(({ theme }) => ({
  background: `linear-gradient(120deg, ${alpha("#FFDBBB", 0.8)}, ${alpha("#FFDBBB", 0.95)})`,
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
  boxShadow: `0 8px 20px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(1),
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: `0 12px 24px -4px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const AgentChipAI = ({ disabled = false, agent, setAgent, setBusy = undefined }) => {
  if (debugLevel > 0) console.log("AgentChipAI - agent:", agent);

  const theme = useTheme();
  const [interceptedAgent, setInterceptedAgent] = useState(agent);
  const [revisingAgent, setRevisingAgent] = useState(false);
  const [busyAI, setBusyAI] = useState(true); // assume busy
  const [busyScraping, setBusyScraping] = useState(true); // will begin scraping on component load

  useEffect(() => {
    if (typeof setBusy !== "undefined") {
      setBusy(busyAI || busyScraping);
    }
  }, [busyAI, busyScraping]);

  // Called when <MemoizedSelectImage> initializes, or updates image
  const updateUrlDetails = (e) => {
    if (exists(e.validUrl)) {
      // Done scraping
      if (debugLevel > 0) console.log("SelectImage returned [e]:", e);

      if (!e.validUrl) {
        // Invalid url -- card will be closed
        if (debugLevel > 0) console.log("INVALID AGENT URL - deleting agent");
        setAgent(null);
        setBusyScraping(false);
      } else {
        // Valid url
        if (debugLevel > 0) console.log("Agent url scraped (validUrl) - Updating image/title");
        setBusyScraping(false);
        const updatedAgent = {
          ...interceptedAgent,
          selectedImage: e.image,
          title:
            exists(interceptedAgent.title) && interceptedAgent.title.length > 0
              ? interceptedAgent.title
              : e.title && e.title.length > 0
                ? e.title.substring(0, 50)
                : "",
          validUrl: e.validUrl,
          finalUrl: exists(e.finalUrl) ? e.finalUrl : interceptedAgent.finalUrl,
        };
        setInterceptedAgent(updatedAgent);
        if (!busyAI) {
          setAgent(updatedAgent);
        }
      }
    }
  };

  // Called upon return from <AgentCreateAI/>
  const handleLabelField = (e) => {
    if (debugLevel > 0) console.log("handleLabelField", e);
    const updatedAgent = {
      ...interceptedAgent,
      title: e,
    };
    setInterceptedAgent(updatedAgent);
    setAgent(updatedAgent);
  };

  // Called upon return from <AgentCreateDialog/>
  const handleDialogClose = (e) => {
    if (debugLevel > 0) console.log("handleDialogClose", e);
    setRevisingAgent(false);
    if (e) {
      setInterceptedAgent(e);
      setAgent(e);
    }
  };

  return (
    <Box sx={{ display: "inline-block", m: 0.5 }}>
      <GlassCard>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ pr: 1 }}>
            <SelectImage
              useAI
              onlyInitImage
              avatarView
              url={interceptedAgent.url}
              initialImage={interceptedAgent.selectedImage || ""}
              updateUrlDetails={updateUrlDetails}
              description={interceptedAgent.title || ""}
            />
          </Box>

          {!busyScraping && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <AgentCreateAI
                aiOnInit
                justLabelDisableEdit
                labelField={interceptedAgent.title}
                setLabelField={handleLabelField}
                urlField={interceptedAgent.url}
                setBusy={setBusyAI}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: -0.5,
                  mb: -1,
                }}
              >
                {!busyAI && (
                  <Button
                    disabled={disabled}
                    variant="text"
                    color="primary"
                    onClick={() => setRevisingAgent(true)}
                    startIcon={<Edit fontSize="small" sx={{ width: "14px", height: "14px" }} />}
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      transition: "all 0.2s ease",
                      minWidth: "auto",
                      p: "4px 8px",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    Revise
                  </Button>
                )}
                <IconButton
                  size="small"
                  onClick={() => setAgent(null)}
                  sx={{
                    opacity: 0.7,
                    color: theme.palette.text.secondary,
                    transition: "all 0.2s ease",
                    "&:hover": {
                      opacity: 1,
                      color: theme.palette.error.main,
                      transform: "rotate(90deg)",
                    },
                  }}
                >
                  <Cancel sx={{ width: "15px", height: "15px" }} />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>

        {revisingAgent && (
          <AgentCreateDialog
            dialogTitle="Creating new waveMaker"
            originalDescription={interceptedAgent.title}
            originalUrl={interceptedAgent.url}
            handleDialogClose={handleDialogClose}
          />
        )}
      </GlassCard>
    </Box>
  );
};

AgentChipAI.propTypes = {
  disabled: PropTypes.bool,
  agent: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    selectedImage: PropTypes.string,
    finalUrl: PropTypes.string,
    validUrl: PropTypes.bool,
  }).isRequired,
  setAgent: PropTypes.func.isRequired,
  setBusy: PropTypes.func,
};

export default AgentChipAI;
