import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Card } from "@mui/material";

import bgImage from "assets/images/campfire/desertcampfire-blurred1.png";

import MKBox from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";
import MKInput from "../MaterialKit/MKInput";
import MKButton from "../MaterialKit/MKButton";
import { resetPassword } from "../../actions/auth";
import BasicLayout from "./BasicLayout";

const ForgotPassword = ({ resetPassword, auth }) => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(email));
  }, [email]);

  const onSubmitForgotPassword = () => {
    resetPassword(email);
    console.log("Goto /login");
    return <Navigate to="/login" />;
  };

  // Do not allow password request if already logged in
  if (!auth.loading && auth.isAuthenticated && auth.user) {
    console.log("Goto /fellowships");
    return <Navigate to="/fellowships" />;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && isEmailValid) {
      onSubmitForgotPassword();
      event.preventDefault();
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
            Forgot Password?
          </Typography>
          <Typography display="block" variant="button" color="white" my={1}>
            Enter your email address to reset your password
          </Typography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2} onChange={(e) => setEmail(e.target.value)}>
              <MKInput label="Email" fullWidth onKeyDown={handleKeyDown} />
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="primary"
                fullWidth
                onClick={onSubmitForgotPassword}
                disabled={!isEmailValid}
              >
                Reset Password
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <Typography
                component={Link}
                to="/login"
                variant="button"
                color="primary"
                fontWeight="medium"
                textGradient
              >
                Go back to Login
              </Typography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
};

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { resetPassword })(ForgotPassword);
