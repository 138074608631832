import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import Typography from "components/MaterialKit/MKTypography";
import Input from "components/MaterialKit/MKInput";
import { TextField, Card, Grid, Stack, Dialog, Container } from "@mui/material";
import { LocalLibrary, Star, Search } from "@mui/icons-material";
import { styled } from "@mui/system";

import getTimeAgo from "../../utils/getTimeAgo";
import NavbarTitle from "../navbar/NavbarTitle";
import { searchExpertises } from "../../actions/expertise";

const EllipsisTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
});

const DialogPriorExpertise = ({ setExpertisePrior, searchExpertises, auth, expertise }) => {
  const [inputField, setInputField] = useState("");
  const [hoveredCard, setHoveredCard] = useState(null); // State to track which card is hovered

  useEffect(() => {
    if (auth && auth.user && auth.user._id) {
      if (inputField.length > 0) {
        searchExpertises(auth.user._id, 5, inputField);
      } else {
        searchExpertises(auth.user._id, 5);
      }
    }
  }, [inputField]);

  const handleOnChange = (e) => {
    if (e.charAt(e.length - 1) !== "\n") {
      setInputField(e);
    }
  };

  const handleOnClick = (e) => {
    setExpertisePrior(e);
  };

  const formatBulletList = (arrString) => {
    if (arrString && arrString.length > 0 && arrString[0].length > 0) {
      return arrString
        .map((item, index) => (index === arrString.length - 1 ? `• ${item}` : `• ${item}\n`))
        .join("");
    }
    return "";
  };

  return (
    <Dialog open fullScreen>
      <NavbarTitle paper title="Past wisdom" backClick={() => setExpertisePrior(null)} />
      <Container sx={{ mt: 5, mb: 3 }}>
        <Input
          type="message"
          onChange={(e) => handleOnChange(e.target.value)}
          label="Select a prior submitted response"
          fullWidth
          defaultValue={inputField}
          InputProps={{
            startAdornment: <Search />,
          }}
          inputRef={(input) => {
            if (input != null) {
              input.focus();
            }
          }}
          sx={{ mt: 1, mb: 3 }}
        />

        {expertise.expertises.map((option, index) => (
          <Card
            onClick={() => handleOnClick(option)}
            key={Math.random()}
            onMouseEnter={() => setHoveredCard(index)} // Set hovered card index
            onMouseOver={() => setHoveredCard(index)} // Set hovered card index
            onMouseLeave={() => setHoveredCard(null)} // Reset on mouse leave
            sx={{
              pl: 1,
              pr: 1,
              pt: 0.5,
              pb: 0.5,
              mb: 1.5,
              boxShadow: hoveredCard === index ? "1px 1px 3px 2px grey" : "1px 1px 1px 1px grey",
              transition: "box-shadow 0.3s ease", // Smooth transition for hover effect
            }}
          >
            {/* Make the Paper take full width */}
            <Grid container columnSpacing={1}>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {option.myExpertiseLevel >= 2 && option.myExpertiseLevel < 3 && (
                  <Stack direction="row" alignItems="center">
                    <LocalLibrary sx={{ color: "orange" }} />
                    <Typography sx={{ ml: 0.2, mb: -0.3, fontSize: "8px", color: "orange" }}>
                      14%
                    </Typography>
                  </Stack>
                )}
                {option.myExpertiseLevel >= 3 && (
                  <Stack direction="row" alignItems="center">
                    <Star sx={{ color: "red" }} />
                    <Typography sx={{ ml: 0.2, mb: -0.3, fontSize: "8px", color: "red" }}>
                      Expert
                    </Typography>
                  </Stack>
                )}

                <div>
                  <Typography sx={{ fontSize: "8px", color: "grey", textAlign: "center" }}>
                    {option.createdAt ? getTimeAgo(option.createdAt) : "Date stamp unavailable"}
                  </Typography>
                  {option.createdAt && (
                    <Typography sx={{ fontSize: "8px", color: "grey", textAlign: "center" }}>
                      ago
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs>
                {option.steelMan && option.steelMan.length > 0 && (
                  <EllipsisTextField
                    label="Steel-manning counter-arguments"
                    size="small"
                    fullWidth
                    defaultValue={formatBulletList(option.steelMan)}
                    sx={{ mt: 1, mb: 0.5 }}
                  />
                )}
                {option.myExpertiseText.length > 0 && (
                  <EllipsisTextField
                    label="My competence was acquired by"
                    size="small"
                    fullWidth
                    defaultValue={option.myExpertiseText}
                    sx={{ mt: 1, mb: 0.5 }}
                  />
                )}
                {option.changedText.length > 0 && (
                  <EllipsisTextField
                    label={
                      option.changedMind
                        ? "I changed my position when"
                        : "I would change by position if"
                    }
                    size="small"
                    fullWidth
                    defaultValue={option.changedText}
                    sx={{ mt: 1 }}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        ))}
      </Container>
    </Dialog>
  );
};

DialogPriorExpertise.propTypes = {
  setExpertisePrior: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  expertise: state.expertise,
  auth: state.auth,
});

export default connect(mapStateToProps, { searchExpertises })(DialogPriorExpertise);
