import React from "react";
import PropTypes from "prop-types";

// MUI
import { Box, Typography } from "@mui/material";

const InfoCard = ({ title = "", message = "" }) => {
  return (
    <Box
      sx={{
        position: "relative",
        mb: 4,
        p: 3,
        bgcolor: "#2c2c2c",
        borderRadius: "4px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
        border: "1px solid #3e3e3e",
        borderBottom: "3px solid #3e3e3e",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            mb: 0.5,
            color: "#ffffff",
            letterSpacing: 0.3,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "0.95rem",
            lineHeight: 1.6,
            color: "#d0d0d0",
          }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

InfoCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node, // node allows JSX, elements, etc.
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node, // node allows JSX, elements, etc.
  ]),
};

export default InfoCard;
