import React, { useState, useEffect, useMemo } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Card, Checkbox } from "@mui/material";

import bgImage from "assets/images/campfire/desertcampfire-blurred1.png";
import { register } from "../../actions/auth";
import { setAlert } from "../../actions/alert";

import MKBox from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";
import MKInput from "../MaterialKit/MKInput";
import MKButton from "../MaterialKit/MKButton";
import BasicLayout from "./BasicLayout";
import DialogMessage from "../dialog/DialogMessage";

const Register = ({ register, setAlert, auth }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [password2Success, setPassword2Success] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);

  const [termsDialog, setTermsDialog] = useState(false);

  const [checked, setChecked] = useState(false);
  const handleToggle = () => {
    setChecked(!checked);
  };

  // Check if string is email
  const checkIfEmail = (str) => {
    // Regular expression to check if string is email
    const regexExp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
    return regexExp.test(str);
  };

  useEffect(() => {
    if (password1.length >= 6 && password2.length >= 6) {
      if (password1 === password2) {
        setPassword2Success(true);
        setPassword2Error(false);
      } else {
        setPassword2Success(false);
        if (password2.length >= password1.length) {
          setPassword2Error(true);
        } else {
          setPassword2Error(false);
        }
      }
    } else {
      setPassword2Success(false);
      setPassword2Error(false);
    }
  }, [password1, password2]);

  // Calculate form validity - this determines when the submit button should be enabled
  const isFormValid = useMemo(() => {
    // All requirements must be met for form to be valid
    return (
      // Name must be provided
      name.trim().length > 0 &&
      // Email must be valid
      checkIfEmail(email) &&
      // Password must be at least 6 characters
      password1.length >= 6 &&
      // Passwords must match
      password1 === password2 &&
      // Terms must be accepted
      checked
    );
  }, [name, email, password1, password2, checked]);

  const onSubmitRegister = () => {
    if (name.length < 1) {
      setAlert('Invalid "Shall be known as..." - mandatory field', "danger");
    } else if (!checkIfEmail(email)) {
      setAlert("Please enter a valid email address", "danger");
    } else if (password1.length < 6) {
      setAlert("Password too short - must be at least 6 characters", "danger");
    } else if (password1 !== password2) {
      setAlert("Passwords entered do not match. Please enter again.", "danger");
      setPassword2Error(true);
    } else if (!checked) {
      setAlert('Registration requires acceptance of our "Terms of Use"', "danger");
    } else if (name.length > 0) {
      register({ name, email, password: password1 });
    } else {
      // Register without a user name (use email address)
      register({ name: email, email, password: password1 });
    }
  };

  if (!auth.loading && auth.isAuthenticated && auth.user) {
    return <Navigate to="/about" />;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSubmitRegister();
      event.preventDefault();
    }
  };

  const handlePassword1 = (e) => {
    setPassword1(e.trim());
  };

  const handlePassword2 = (e) => {
    setPassword2(e.trim());
  };

  const handleEmail = (e) => {
    setEmail(e.trim());
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Typography variant="h4" fontWeight="medium" color="white" mt={1}>
            Create an inSight account
          </Typography>
          <Typography display="block" variant="button" color="white" my={1}>
            Enter your details below to register
          </Typography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2} onChange={(e) => setName(e.target.value)}>
              <MKInput
                label="At inSight, I shall be known as..."
                fullWidth
                onKeyDown={handleKeyDown}
              />
            </MKBox>
            <MKBox mb={2} onChange={(e) => handleEmail(e.target.value)}>
              <MKInput label="Email" value={email} fullWidth onKeyDown={handleKeyDown} />
            </MKBox>
            <MKBox mb={2} onChange={(e) => handlePassword1(e.target.value)}>
              <MKInput
                value={password1}
                label="Password (at least 6 characters)"
                fullWidth
                onKeyDown={handleKeyDown}
              />
            </MKBox>
            {password1.length >= 6 && (
              <MKBox mb={2} onChange={(e) => handlePassword2(e.target.value)}>
                <MKInput
                  value={password2}
                  success={password2Success}
                  error={password2Error}
                  label="Confirm Password"
                  fullWidth
                  onKeyDown={handleKeyDown}
                  helperText={password2Error ? "Passwords do not match" : ""}
                />
              </MKBox>
            )}
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onClick={handleToggle} onKeyDown={handleKeyDown} />
              <Typography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree to the&nbsp;
              </Typography>
              <Typography
                component="a"
                onClick={() => setTermsDialog(true)}
                variant="button"
                fontWeight="bold"
                color="primary"
                textGradient
              >
                Terms of Use
              </Typography>
            </MKBox>
            <MKBox mt={4} mb={1}>
              <MKButton
                variant="gradient"
                color="primary"
                fullWidth
                onClick={onSubmitRegister}
                disabled={!isFormValid}
              >
                Submit
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <Typography variant="button" color="text">
                Already have an account?{" "}
                <Typography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign in
                </Typography>
              </Typography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>

      {termsDialog && <DialogMessage terms handleDialogClose={() => setTermsDialog(false)} />}
    </BasicLayout>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { register, setAlert })(Register);
