import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Dialog } from "@mui/material";

import MKAlert from "../MaterialKit/MKAlert";

import { removeAlert } from "../../actions/alert";

const Alert = ({ removeAlert, alerts }) => {
  const handleClick = (e) => {
    removeAlert(e);
  };

  return (
    <Dialog
      sx={{ zIndex: 2000 }}
      open={alerts.length > 0}
      keepMounted
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      {alerts.map((alert) => (
        <div key={alert.id}>
          {alert.alertType === "danger" && (
            <MKAlert color="error" onClick={() => handleClick(alert.id)}>
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
            </MKAlert>
          )}

          {alert.alertType === "warning" && (
            <MKAlert color="warning" onClick={() => handleClick(alert.id)}>
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
              <b>{alert.msg}</b>
            </MKAlert>
          )}

          {alert.alertType === "success" && (
            <MKAlert color="success" onClick={() => handleClick(alert.id)}>
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
            </MKAlert>
          )}

          {alert.alertType === "info" && (
            <MKAlert color="info" onClick={() => handleClick(alert.id)}>
              <b style={{ marginLeft: "10px" }}>{alert.msg}</b>
            </MKAlert>
          )}
        </div>
      ))}
    </Dialog>
  );
};

Alert.propTypes = {
  removeAlert: PropTypes.func.isRequired,
  alerts: PropTypes.array.isRequired, // eslint-disable-line
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, { removeAlert })(Alert);
