import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// MUI
import { Box, Typography, Stack } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import Button from "../MaterialKit/MKButton";
import AgentSelectorDialog from "../agent/AgentSelectorDialog";
import AgentChipAI from "../agent/AgentChipAI";
import AgentChip from "../agent/AgentChip";
import exists from "../../utils/exists";

const NewsMakers = ({ defaultAlreadyInDB = false, disabled = false, idea, setIdea }) => {
  const theme = useTheme();

  const [busyDefaultAgent, setBusyDefaultAgent] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resolvedAgents, setResolvedAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Create an agent cache at the component level
  const agentCache = new Map();

  const handleDeleteAuthor = (index) => {
    const arr = exists(idea.creators) ? [...idea.creators] : [];
    if (arr.length > index) {
      arr.splice(index, 1);
    }
    setIdea({
      ...idea,
      creators: arr,
    });
  };

  const handleSelectedWavemaker = (e) => {
    if (!e) return;

    // Make a copy of the current creators, or start with an empty array
    const creators = idea.creators ? [...idea.creators] : [];

    // Only add if there isn't already a creator with the same _id
    if (!creators.some((creator) => creator._id === e._id)) {
      creators.push(e);
      setIdea({
        ...idea,
        creators,
      });
    }
  };

  const handleIsMineChanged = (isMine) => {
    if (idea.isMine !== isMine) {
      setIdea({
        ...idea,
        isMine,
      });
    }
  };

  const handleDefaultAgent = (e) => {
    if (!exists(e)) {
      // Deleting defaultAgent
      setIdea({ ...idea, defaultAgent: null });
    } else if (exists(e._id)) {
      // defaultAgent saved to db as wavemaker
      const arr = exists(idea.creators) ? [...idea.creators] : [];
      // Keep the full object for rendering
      arr.push(e);
      setIdea({
        ...idea,
        defaultAgent: null,
        creators: arr,
      });
    } else {
      // updating defaultAgent
      setIdea({
        ...idea,
        defaultAgent: e,
      });
    }
  };

  // Utility function to fetch agent data
  const ensureAgentObject = async (agent) => {
    if (!agent) return null;

    // If it's a string ID, we should look up the actual agent data
    if (typeof agent === "string") {
      try {
        // Check if we have a cached version first
        if (agentCache.has(agent)) {
          return agentCache.get(agent);
        }

        // Otherwise, fetch from server
        const response = await axios.get(`/api/agents/${agent}`);
        if (response.data) {
          agentCache.set(agent, response.data);
          return response.data;
        }
      } catch (error) {
        console.error("Error fetching agent data:", error);
      }

      // Fallback if fetch fails
      return { _id: agent, name: "Loading..." };
    }

    return agent;
  };

  // Load all agent data when creators change
  useEffect(() => {
    async function loadAgentData() {
      if (!idea.creators || idea.creators.length === 0) {
        setResolvedAgents([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        // Resolve all agents in parallel
        const agentPromises = idea.creators.map((agent) => ensureAgentObject(agent));
        const resolvedResults = await Promise.all(agentPromises);

        // Filter out any null results
        const validAgents = resolvedResults.filter((agent) => agent !== null);
        setResolvedAgents(validAgents);
      } catch (error) {
        console.error("Error resolving agents:", error);
      } finally {
        setIsLoading(false);
      }
    }

    loadAgentData();
  }, [idea.creators]); // Reload when creators change

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
          mt: -1,
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: theme.palette.text.secondary,
            ml: 1,
          }}
        >
          Written or published by
        </Typography>

        {!disabled && !defaultAlreadyInDB && !busyDefaultAgent && (
          <Button
            variant="text"
            color="primary"
            size="small"
            startIcon={<AddCircle sx={{ width: "14px", height: "14px" }} />}
            onClick={() => setDialogOpen(true)}
            sx={{
              fontSize: "10px",
              py: 0.5,
              "&:hover": {
                transform: "translateY(-1px)",
              },
            }}
          >
            Add
          </Button>
        )}
      </Box>

      <Box sx={{ p: 1, mb: 1 }}>
        {/* Agent selection dialog */}
        {dialogOpen && (
          <AgentSelectorDialog
            open={dialogOpen}
            setSelectedAgent={handleSelectedWavemaker}
            onAgentSelected={handleSelectedWavemaker}
            onClose={() => setDialogOpen(false)}
            onIsMineChanged={handleIsMineChanged}
            isMine={idea.isMine || false}
            promptMessage="Add a person, group, organization"
          />
        )}

        {/* Container for all agent chips with reduced spacing */}
        <Stack
          direction="row"
          spacing={0.1}
          flexWrap="wrap"
          sx={{
            mt: -1, // Reduce top margin
            mb: -0.5,
            gap: "1px", // Reduce gap between chips (was 2px)
            "& > *": {
              // Target all direct children
              marginBottom: 0, // Remove bottom margin from chips
              marginTop: 0, // Remove top margin from chips
            },
          }}
        >
          {/* Render the resolved agent objects instead of trying to use promises */}
          {!isLoading &&
            resolvedAgents.length > 0 &&
            resolvedAgents.map((agent, index) => (
              <AgentChip
                key={agent._id || `temp-agent-${Math.random().toString(36).substring(2, 11)}`}
                agent={agent}
                onDelete={defaultAlreadyInDB || disabled ? null : () => handleDeleteAuthor(index)}
              />
            ))}

          {/* Show loading indicator while fetching agent data */}
          {isLoading && idea.creators && idea.creators.length > 0 && (
            <Typography variant="caption" sx={{ py: 1 }}>
              Loading author information...
            </Typography>
          )}

          {/* Show default waveMaker (where was this inTell published?) */}
          {!defaultAlreadyInDB && idea && idea.defaultAgent && (
            <AgentChipAI
              disabled={disabled}
              agent={idea.defaultAgent}
              setAgent={handleDefaultAgent}
              setBusy={setBusyDefaultAgent}
            />
          )}
        </Stack>

        {/* Is this intell mine? Display confirmation if yes */}
        {idea.isMine && (
          <Typography
            sx={{
              fontSize: "11px",
              fontStyle: "italic",
              color: theme.palette.text.secondary,
              mt: 1,
            }}
          >
            You authored this inTell
          </Typography>
        )}
      </Box>
    </>
  );
};

NewsMakers.propTypes = {
  defaultAlreadyInDB: PropTypes.bool,
  disabled: PropTypes.bool,
  idea: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setIdea: PropTypes.func.isRequired,
};

export default NewsMakers;
