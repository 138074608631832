import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useDebounce } from "use-debounce";

// MUI
import { Backdrop, Container, Paper } from "@mui/material";
import { Add } from "@mui/icons-material";

import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import truncate from "../../utils/truncate";
import Transcribe from "./Transcribe";
import References from "./References";
import ViewAttachments from "../files/ViewAttachments";
import Expertise from "../expertise/Expertise";
import exists from "../../utils/exists";
import AttachFile from "../files/Attach";
import Spinner from "../layout/Spinner";
import { addIdea } from "../../actions/idea";

const CreateIdea = ({
  ideaType,
  initialPoint = "",
  newCreatedIdea,
  setFieldsValid,
  setEditsMade,
  parentIdea = null,
  requestSave,
  disableExpertise = false,
  createIdeaBusy,
  setCreateIdeaBusy,
  addIdea,
  textLength = -1,
  requiredField = false,
  idea: { idea },
  fellowship: { fellowship },
}) => {
  const [myPoint, setMyPoint] = useState(initialPoint); // A string representing the user-provided text input
  const [debouncedPoint] = useDebounce(myPoint, 300);
  const [allUrls, setAllUrls] = useState([]); // A list of URL strings extracted from myPoint
  const [confirmedUrls, setConfirmedUrls] = useState([]); // list of URL objects that have been confirmed
  const [urlInitializing, setUrlInitializing] = useState(""); // A string representing a URL currently being processed/validated
  const [invalidUrls, setInvalidUrls] = useState([]); // A list of URLs determined to be invalid
  const [confirmedUrlsCnt, setConfirmedUrlsCnt] = useState(0); // A counter to trigger updates to confirmedUrls
  const [referencedAgents, setReferencedAgents] = useState([]);
  const [referencedIdeas, setReferencedIdeas] = useState([]);
  const [myExpertise, setMyExpertise] = useState({
    myExpertiseLevel: 1, // 1=non-expert, 2/3/
    myExpertiseText: "",
    changedText: "",
    changedMind: false,
    steelMan: [""],
  });
  const [filePaths, setFilePaths] = useState([]);
  const [addReference, setAddReference] = useState(0);
  const [addExpertise, setAddExpertise] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [expertiseFieldsValid, setExpertiseFieldsValid] = useState(true);

  const handleMyExpertise = (e) => {
    if (!e || e.myExpertiseLevel < 2) {
      setMyExpertise({
        myExpertiseLevel: 1, // 1=non-expert, 2/3/
        myExpertiseText: "",
        changedText: "",
        changedMind: false,
        steelMan: [""],
      });
    } else {
      setMyExpertise(e);
    }
    setExpertiseFieldsValid(true);
    setAddExpertise(false);
  };

  useEffect(() => {
    if (!submitting && urlInitializing.length === 0) {
      setCreateIdeaBusy(false);
    } else {
      setCreateIdeaBusy(true);
    }
  }, [submitting, urlInitializing]);

  // Converts a URL to a lowercase, simplified version by removing protocols (http://, https://), www., and trailing slashes.
  // Ensures uniformity when comparing URLs
  const normalize = (url) => {
    try {
      const parsed = new URL(url);
      return parsed.hostname + parsed.pathname;
    } catch {
      return url
        .toLowerCase()
        .replace(/^https?:\/\//, "")
        .replace(/^www\./, "")
        .replace(/\/$/, "");
    }
  };

  // Compares two arrays for strict equality
  const areArraysEqual = (arr1, arr2) => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return arr1.length === arr2.length && [...set1].every((item) => set2.has(item));
  };

  // allUrls[] -> confirmedUrls[]; this function will also update <urlInitializing> if necesary
  // - triggered after text input (myPoint) processing & a new possible url has been found; or
  // - after handleConfirmedUrls() (when validUrl changes to true)
  useEffect(() => {
    if (confirmedUrls.length > 0 || allUrls.length > 0 || urlInitializing.length > 0) {
      // Create list of URLs already confirmed: filters confirmedUrls for URLs matching either urlInitializing or URLs already in allUrls
      const urlsAlreadyConfirmed = confirmedUrls.filter(
        (obj) => obj.url === urlInitializing || allUrls.includes(obj.url)
      );

      // Check: Ensure all URLs already confirmed are valid or being initialized. Logs inconsistencies if not.
      const urlsNotValidated = urlsAlreadyConfirmed.filter(
        (obj) => !obj.validUrl && obj.url !== urlInitializing
      );
      if (urlsNotValidated.length > 0) {
        console.log(
          "UNEXPECTED consistency check failure: urlsNotValidated.length>0",
          urlsNotValidated
        );
      }

      // If no urlInitializing, searches for a new URL in allUrls not yet confirmed or marked invalid. Assigns it to urlInitializing.
      if (urlInitializing.length === 0) {
        const urlsAlreadyConfirmedStrings = urlsAlreadyConfirmed.map((obj) => obj.url);
        const newUrl = allUrls.find(
          (str) => !urlsAlreadyConfirmedStrings.includes(str) && !invalidUrls.includes(str)
        );
        if (newUrl) {
          urlsAlreadyConfirmed.push({
            url: newUrl,
            isMine: false,
          });
          setUrlInitializing(newUrl);
        }
      }

      // Updates confirmedUrls with already confirmed URLs
      setConfirmedUrls(urlsAlreadyConfirmed);
    }
  }, [confirmedUrlsCnt]);

  // debouncedPoint -> allUrls[]
  const navigate = useNavigate();
  useEffect(() => {
    // Alert parent status of input fields
    setEditsMade(
      debouncedPoint.trim().length > 0 || (filePaths.length > 0 && filePaths[0].length > 0)
    );
    setFieldsValid(
      fellowship.expertiseLink === 1 && !disableExpertise
        ? expertiseFieldsValid &&
            (debouncedPoint.trim().length > 0 || (filePaths.length > 0 && filePaths[0].length > 0))
        : debouncedPoint.trim().length > 0 || (filePaths.length > 0 && filePaths[0].length > 0)
    );

    // Unable to find fellowship information
    if (
      (debouncedPoint.trim().length > 0 || (filePaths.length > 0 && filePaths[0].length > 0)) &&
      !(exists(fellowship) && exists(fellowship._id))
    ) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }

    // Extracts from debouncedPoint: substrings having standard URL formats
    const substrings = debouncedPoint.trim().split(/[\s\n]+/); // space or enter
    const urlRegex = new RegExp(
      "^(https?:\\/\\/)?" + // protocol (optional)
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // path with support for @
        "(\\?[;&a-z\\d%_.~+=-@]*)?" + // query string with support for @
        "(\\#[-a-z\\d_]*)?$", // fragment
      "i"
    );
    const possibleUrls = substrings
      .map((substring) => substring.toLowerCase())
      .filter((substring) => urlRegex.test(substring));

    // Ensures new URLs are not duplicates in validUrls or invalidUrls (using normalize for comparison)
    // Populates validUrls with valid, non-duplicate URLs
    const normalizedSet = new Set();
    const validUrls = [];
    possibleUrls.forEach((e) => {
      const normalizedUrl = normalize(e);
      if (!normalizedSet.has(normalizedUrl) && !invalidUrls.includes(normalizedUrl)) {
        normalizedSet.add(normalizedUrl);
        validUrls.push(e);
      }
    });

    // Compares validUrls to allUrls. If they differ, updates allUrls and triggers confirmedUrlsCnt
    if (!areArraysEqual(allUrls, validUrls)) {
      setAllUrls(validUrls);
      setConfirmedUrlsCnt((cnt) => cnt + 1);
    }
  }, [debouncedPoint]);

  // Handler from <References/>
  const handleConfirmedUrls = (index, e) => {
    if (exists(e.validUrl) && !e.validUrl) {
      // URL is invalid
      setConfirmedUrls((prev) => prev.filter((_, i) => i !== index));
      setUrlInitializing("");
      setInvalidUrls((prev) => [...prev, urlInitializing]);
      setConfirmedUrlsCnt((cnt) => cnt + 1);
    } else {
      // Update confirmedUrls
      setConfirmedUrls((prev) => {
        const updatedUrls = [...prev];
        updatedUrls[index] = { ...updatedUrls[index], ...e };
        return updatedUrls;
      });

      // If validUrl changed to true, reset initialization and trigger update
      if (e.validUrl && !confirmedUrls[index]?.validUrl) {
        setUrlInitializing("");
        setConfirmedUrlsCnt((cnt) => cnt + 1);
      }
    }
  };

  // -------------------------------------------- Save Idea -------------------------------------------

  const orderQueryLowerCase = (url) => {
    const [baseUrl, queryString] = url.toLowerCase().split("?"); // Separate base URL and query string

    if (!queryString) return baseUrl; // Return if no query string

    const sortedQuery = queryString
      .split("&")
      .map((param) => param.split("="))
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by keys
      .map(([key, value]) => `${key}=${value || ""}`) // Reconstruct params
      .join("&");

    return `${baseUrl}?${sortedQuery}`;
  };

  // Parent requested save
  useEffect(() => {
    if (requestSave && !submitting) {
      setSubmitting(true);

      // Common idea properties
      const ideaData = {
        myPoint: debouncedPoint.trim(),
        fellowshipId: fellowship._id,
        ideaType,
        parentIdeaId: parentIdea?._id, // Add optional chaining
        referencedIdeas,
        referencedAgents,
        myExpertiseLevel: myExpertise.myExpertiseLevel,
        myExpertiseText: truncate(
          myExpertise.myExpertiseText,
          fellowship.expertiseLinkMyTextLength
        ),
        changedMind: myExpertise.changedMind,
        changedText: truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength),
        steelMan: truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength),
      };

      // Single url specified (valid) & no other attachments; no commentary
      if (
        debouncedPoint.trim().split(/[\s\n]+/).length === 1 &&
        filePaths.length === 0 &&
        confirmedUrls.length === 1 &&
        confirmedUrls[0].validUrl &&
        referencedIdeas.length === 0 &&
        referencedAgents.length === 0
      ) {
        if (confirmedUrls[0].validUrl && confirmedUrls[0].inDB) {
          console.log("Submitting just 1 url (& it aleady exists)");
          // TODO: Equivalent to selecting intell
        }

        //      console.log("Submitting single url", debouncedPoint.trim());
        addIdea({
          ...ideaData,
          myPoint: undefined,
          isMine: confirmedUrls[0].isMine,
          url: orderQueryLowerCase(confirmedUrls[0].finalUrl),
          title: confirmedUrls[0].title,
          filePaths: [confirmedUrls[0].selectedImage],
          creators: confirmedUrls[0].creators,
          creatorUnsaved: confirmedUrls[0].defaultAgent,
        });
      } else {
        //      console.log("Submitting complex idea(s)", debouncedPoint.trim().split(" "), confirmedUrls);
        addIdea({
          ...ideaData,
          isMine: true,
          filePaths,
          creators: [],
          referencedIdeasUnsaved: confirmedUrls,
        });
      }
    }
  }, [requestSave]);

  // Exit strategy this component
  const [initID] = useState(idea && idea._id ? idea._id : 0);
  useEffect(() => {
    if (submitting && idea && idea._id && idea._id !== initID) {
      setSubmitting(false);
      if (newCreatedIdea) {
        newCreatedIdea(idea);
      }
    }
  }, [idea]);

  return submitting ? (
    <Spinner />
  ) : (
    <div>
      {transcribing ? (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Container maxWidth="sm">
            <Paper
              sx={{
                p: 2,
                zIndex: (theme) => theme.zIndex.modal + 1,
              }}
            >
              <Transcribe
                transcribing={transcribing}
                setTranscribing={setTranscribing}
                message={myPoint}
                setMessage={setMyPoint}
                textLength={textLength}
                requiredField={requiredField}
              />
            </Paper>
          </Container>
        </Backdrop>
      ) : (
        <Box sx={{ mt: 2 }}>
          <References
            confirmedUrls={confirmedUrls}
            setConfirmedUrls={handleConfirmedUrls}
            referencedAgents={referencedAgents}
            setReferencedAgents={setReferencedAgents}
            referencedIdeas={referencedIdeas}
            setReferencedIdeas={setReferencedIdeas}
            addReference={addReference}
            setAddReference={setAddReference}
            disabled={createIdeaBusy}
          />

          {(debouncedPoint.trim().length > 0 ||
            (filePaths.length > 0 && filePaths[0].length > 0)) && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Button
                disabled={createIdeaBusy}
                variant="text"
                color="primary"
                size="large"
                onClick={() => setAddReference(1)}
              >
                <Add sx={{ width: "10px", height: "10px" }} />
                {confirmedUrls.length > 0 ||
                referencedAgents.length > 0 ||
                referencedIdeas.length > 0
                  ? "Another Reference"
                  : "References"}
              </Button>
            </Box>
          )}

          {/* Input box */}
          <Box>
            <Transcribe
              transcribing={transcribing}
              setTranscribing={setTranscribing}
              message={myPoint}
              setMessage={setMyPoint}
              textLength={textLength}
              requiredField={requiredField}
            />
          </Box>

          {/* Button icons below input box */}
          <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
            <AttachFile
              setFilePath={(e) => {
                if (e && !filePaths.includes(e)) {
                  setFilePaths((prev) => [...prev, e]);
                }
              }}
            />
            {fellowship.expertiseLink !== -1 &&
              !disableExpertise &&
              !(addExpertise || myExpertise.myExpertiseLevel > 1) &&
              (debouncedPoint.trim().length > 0 ||
                (filePaths.length > 0 && filePaths[0].length > 0)) && (
                <Button
                  disabled={createIdeaBusy}
                  variant="text"
                  color="primary"
                  onClick={() => setAddExpertise(true)}
                >
                  <Add sx={{ width: "10px", height: "10px" }} />
                  Wisdom
                </Button>
              )}
          </Box>

          {/* Display upload file details */}
          <Box sx={{ mt: 1 }}>
            <ViewAttachments attachments={filePaths} setAttachments={setFilePaths} />
          </Box>

          {/* Edit expertise: not busy, not "no expertiseLink", response.length>0 */}
          {!createIdeaBusy &&
            !disableExpertise &&
            fellowship.expertiseLink !== -1 &&
            (addExpertise || myExpertise.myExpertiseLevel > 1) &&
            (debouncedPoint.trim().length > 0 ||
              (filePaths.length > 0 && filePaths[0].length > 0)) && (
              <Box sx={{ mt: 1 }}>
                <Expertise
                  myExpertise={myExpertise}
                  setMyExpertise={handleMyExpertise}
                  setExpertiseFieldsValid={setExpertiseFieldsValid}
                />
              </Box>
            )}
        </Box>
      )}
    </div>
  );
};

CreateIdea.propTypes = {
  ideaType: PropTypes.number.isRequired,
  initialPoint: PropTypes.string,
  parentIdea: PropTypes.oneOfType([PropTypes.object]),
  newCreatedIdea: PropTypes.func.isRequired,
  setFieldsValid: PropTypes.func.isRequired,
  setEditsMade: PropTypes.func.isRequired,
  requestSave: PropTypes.bool.isRequired,
  disableExpertise: PropTypes.bool,
  createIdeaBusy: PropTypes.bool.isRequired,
  setCreateIdeaBusy: PropTypes.func.isRequired,
  textLength: PropTypes.number,
  requiredField: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { addIdea })(CreateIdea);
