import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Card } from "@mui/material";

import bgImage from "assets/images/campfire/desertcampfire.png";

import { ADMIN, BETA } from "config/personalize";
import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";
import Input from "../MaterialKit/MKInput";
import Button from "../MaterialKit/MKButton";
import LoginLayout from "./LoginLayout";
import { login } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
import getSecondsAgo from "../../utils/getSecondsAgo";

// ACTIONS
// - this is the first route when entering inSight
// - user can login or register or already login, re-direct to /welcome or /activity

const Login = ({ login, setAlert, auth, fellowship }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if (password.length >= 6) {
      setPasswordError(false);
    }
  }, [password]);

  useEffect(() => {
    if (email.length >= 1) {
      setEmailError(false);
    }
  }, [email]);

  const isFormValid = email !== "" && password.length >= 6;

  const onSubmitLogin = () => {
    if (email.length < 1) {
      setAlert("Invalid - mandatory field", "danger");
      setEmailError(true);
    } else if (password.length < 6) {
      setAlert("Invalid Password - must be at least 6 characters", "danger");
      setPasswordError(true);
    } else {
      // Clear previous errors
      setEmailError(false);
      setPasswordError(false);

      // Use the login action directly, which returns a promise
      login(email, password).catch((err) => {
        // Error handling is already done in the action, this is just for any additional handling if needed
        console.log("Login error:", err);
      });
    }
  };

  if (!auth.loading && auth.isAuthenticated && auth.user) {
    if (auth.user.createdAt && getSecondsAgo(auth.user.createdAt) > 600) {
      return <Navigate to="/fellowships" />;
    }
    return <Navigate to="/about" />;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isFormValid) {
        onSubmitLogin();
      }
    }
  };
  // console.log(fellowship);

  return (
    <LoginLayout image={bgImage}>
      <Card>
        <Box pt={4} pb={3} px={3}>
          <Box component="form" role="form" onSubmit={(e) => e.preventDefault()}>
            <Box mb={2} onChange={(e) => setFormData({ ...formData, email: e.target.value })}>
              <Input error={emailError} label="Email" fullWidth onKeyDown={handleKeyDown} />
            </Box>
            <Box mb={2} onChange={(e) => setFormData({ ...formData, password: e.target.value })}>
              <Input
                error={passwordError}
                label="Password"
                type="password"
                fullWidth
                onKeyDown={handleKeyDown}
                helperText={
                  password !== "" && password.length < 6
                    ? "Password must be at least 6 characters"
                    : ""
                }
              />
            </Box>
            <Box mt={4} mb={1}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                disabled={!isFormValid}
                fullWidth
                onClick={onSubmitLogin}
              >
                Login
              </Button>
            </Box>
            <Box mt={3} mb={1} textAlign="center">
              <Typography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <Typography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Register
                </Typography>
              </Typography>
            </Box>
            {(ADMIN.includes(email.trim()) ||
              BETA.includes(email.trim()) ||
              (fellowship &&
                fellowship.fellowships &&
                fellowship.fellowships.some((fellowshipObj) =>
                  fellowshipObj.allowed.some((memberObj) => memberObj.email === email.trim())
                ))) && (
              <Box mt={3} mb={1} textAlign="center" style={{ marginTop: "0px" }}>
                <Typography
                  component={Link}
                  to="/forgot-password"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot my password
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </LoginLayout>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    loading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.shape({
      createdAt: PropTypes.string,
      // Add other user properties as needed
    }),
  }),
  fellowship: PropTypes.shape({
    fellowships: PropTypes.arrayOf(
      PropTypes.shape({
        allowed: PropTypes.arrayOf(
          PropTypes.shape({
            email: PropTypes.string,
          })
        ),
      })
    ),
  }),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { login, setAlert })(Login);
