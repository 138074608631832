/**
 * Utility functions for fellowship authorization checks
 */

/**
 * Determines if a user has permission to modify a fellowship
 *
 * @param {Object} user - The current user
 * @param {Object} fellowship - The fellowship to check
 * @returns {Object} - Object containing isAuthorized flag and reason
 */
const canModifyFellowship = (user, fellowship) => {
  if (!user || !user.email) {
    return { isAuthorized: false, reason: "User not authenticated" };
  }

  if (!fellowship) {
    return { isAuthorized: false, reason: "Fellowship not found" };
  }

  // Check if user is in the allowed list with proper permissions (host or admin)
  const isHost =
    fellowship.allowed &&
    Array.isArray(fellowship.allowed) &&
    fellowship.allowed.some(
      (entry) => entry.email === user.email && (entry.myGroup === -1 || entry.myGroup === 0)
    );

  if (isHost) {
    return { isAuthorized: true, reason: "User is fellowship host" };
  }

  // Add any other authorization rules here
  // For example, check if user is global admin

  return { isAuthorized: false, reason: "User lacks required permissions" };
};

/**
 * Ensures all IDs in the fellowship data are proper strings
 * This helps prevent authorization errors caused by object references
 *
 * @param {Object} fellowshipData - The fellowship data to sanitize
 * @returns {Object} - Sanitized fellowship data with proper ID strings
 */
const ensureStringIds = (fellowshipData) => {
  if (!fellowshipData) return {};

  const sanitized = { ...fellowshipData };

  // Convert ID fields to strings if they exist
  if (sanitized._id) {
    sanitized._id = sanitized._id.toString();
  }

  // Convert user IDs to strings
  if (sanitized.allowed && Array.isArray(sanitized.allowed)) {
    sanitized.allowed = sanitized.allowed.map((entry) => {
      if (entry._id) {
        return {
          ...entry,
          _id: entry._id.toString(),
        };
      }
      return entry;
    });
  }

  return sanitized;
};

export { canModifyFellowship, ensureStringIds };
