import PropTypes from "prop-types";

// MUI
import { Grid } from "@mui/material";

import MKBox from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const LoginLayout = ({ image, children }) => {
  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.2),
              rgba(gradients.dark.state, 0.2)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <div style={{ textAlign: "center", marginTop: "-40px", marginBottom: "40px" }}>
              <div>
                <Typography color="white" style={{ fontSize: "80px" }}>
                  inSight
                </Typography>
              </div>
              <div>
                <Typography color="white" style={{ fontSize: "24px" }}>
                  the way of the people
                </Typography>
              </div>
            </div>

            {children}
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
};

// Typechecking props for the LoginLayout
LoginLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
