import React from "react";
import PropTypes from "prop-types";

// MUI
import { Stack } from "@mui/material";

import Button from "../MaterialKit/MKButton";

const Submit = ({ buttonLabel = "Submit", onSubmit, size = "medium" }) => {
  return (
    <Stack
      flexWrap="wrap"
      flexDirection="row"
      width={1.0}
      justifyContent="center"
      style={{ marginTop: "10px" }}
    >
      <Button onClick={() => onSubmit()} color="primary" size={size} circular>
        {buttonLabel}
      </Button>
    </Stack>
  );
};

Submit.propTypes = {
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  size: PropTypes.string, // small, medium, large
};

export default Submit;
