import React, { useState } from "react";
import PropTypes from "prop-types";
import WavesurferPlayer from "@wavesurfer/react";

// MUI
import { Box, IconButton, Paper } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { PlayArrow, Pause } from "@mui/icons-material";

// Create a styled component for the audio player
const GlassPlayer = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(2),
  "&:hover": {
    boxShadow: `0 12px 32px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    transform: "translateY(-2px)",
  },
}));

const AudioPlayer = ({ audioUrl }) => {
  const theme = useTheme();
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = (ws) => {
    setWavesurfer(ws);
    setIsPlaying(false);
  };

  const onPlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  };

  return (
    <GlassPlayer elevation={0}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <IconButton
          onClick={onPlayPause}
          sx={{
            background: isPlaying
              ? alpha(theme.palette.warning.main, 0.1)
              : alpha(theme.palette.primary.main, 0.1),
            color: isPlaying ? theme.palette.warning.main : theme.palette.primary.main,
            transition: "all 0.2s ease",
            "&:hover": {
              background: isPlaying
                ? alpha(theme.palette.warning.main, 0.2)
                : alpha(theme.palette.primary.main, 0.2),
              transform: "scale(1.05)",
            },
          }}
        >
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>

        <Box sx={{ flexGrow: 1, height: "40px" }}>
          <WavesurferPlayer
            height={40}
            waveColor={alpha(theme.palette.primary.main, 0.6)}
            progressColor={theme.palette.primary.main}
            cursorColor={alpha(theme.palette.primary.dark, 0.8)}
            url={audioUrl}
            onReady={onReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            barWidth={2}
            barGap={3}
            barRadius={3}
          />
        </Box>
      </Box>
    </GlassPlayer>
  );
};

AudioPlayer.propTypes = {
  audioUrl: PropTypes.string.isRequired,
};

export default AudioPlayer;
