import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Option 1: Using future prop with BrowserRouter
root.render(
  <BrowserRouter future={{ 
    v7_startTransition: true,
    v7_relativeSplatPath: true 
  }}>
    <App />
  </BrowserRouter>
);
