import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isImage from "is-image";

// MUI
import { Card, Grid, Divider, IconButton } from "@mui/material";
import { Cancel, HourglassEmpty } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

import useScreenSize from "../util/useScreenSize";
import exists from "../../utils/exists";
import Expertise from "../expertise/Expertise";
import FirstLine from "./FirstLine";
import Content from "./Content";
import Parent from "./Parent";
import Footer from "./Footer";
import ViewAttachments from "../files/ViewAttachments";
import renderMedia from "../media/renderMedia";

// Create a styled component for the glass effect
const GlassCard = styled(Card, {
  shouldForwardProp: (prop) => !["cardOutline", "ideaType"].includes(prop),
})(({ theme, cardOutline, ideaType }) => ({
  background:
    ideaType && (ideaType === 10 || ideaType === 11)
      ? alpha(theme.palette.warning.light, 0.3)
      : alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow:
    cardOutline === "bold"
      ? `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.15)}`
      : cardOutline === "none"
        ? "none"
        : `0 4px 12px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  marginBottom: "16px",
  "&:hover": {
    boxShadow:
      cardOutline !== "none" ? `0 12px 28px 0 ${alpha(theme.palette.common.black, 0.15)}` : "none",
    transform: cardOutline !== "none" ? "translateY(-2px)" : "none",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const MediaContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  borderRadius: "8px",
  margin: theme.spacing(1),
  "& img": {
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
}));

const ValidIntellCard = ({
  verified,
  activity = null,
  view,
  cardOutline,
  setNavigationClicked,
  handleNavigateLink,
  onDelete = undefined,
  fellowship: { fellowship },
}) => {
  const screenSize = useScreenSize();
  const [anonymous] = useState(false);
  const [expandParent, setExpandParent] = useState(false);

  // Add defensive checks for verified and filePaths
  const filePaths = verified?.filePaths || [];

  const imageValid = filePaths.length === 1 && isImage(filePaths[0].split("?")[0]);

  const showAttachments =
    (filePaths.length === 1 && !isImage(filePaths[0].split("?")[0])) || filePaths.length > 1;

  // Add defensive check for verified
  if (!verified) {
    return null; // or return some loading/error state
  }

  const renderParentIdea = () => (
    <>
      <Parent
        parentIdea={verified.parentIdea}
        expandParent={expandParent}
        setExpandParent={setExpandParent}
        view={view}
        anonymous={anonymous}
      />
      {anonymous && (
        <Grid container sx={{ mt: expandParent ? -0.7 : -2.7, mb: -2.0 }}>
          <Grid item>
            <Divider orientation="vertical" sx={{ ml: 3.8 }} />
          </Grid>
          <Grid item xs sx={{ ml: -1 }}>
            <Typography sx={{ fontSize: "16px", opacity: 0 }}>Invisible</Typography>
          </Grid>
        </Grid>
      )}
    </>
  );

  const getMyPointMaxLength = () => {
    if (verified.parentIdea && exists(verified.parentIdea.ideaType) && exists(fellowship)) {
      if (verified.parentIdea.ideaType >= 0 && verified.parentIdea.ideaType <= 9) {
        // intell response
        return fellowship.intellLinkTextLength;
      }
      if (verified.parentIdea.ideaType >= 10 && verified.parentIdea.ideaType <= 19) {
        // framing statement response
        return fellowship.ftLinkTextLength;
      }
    }
    return -1; // no limit
  };

  return (
    <GlassCard cardOutline={cardOutline} ideaType={verified.ideaType}>
      {view >= 2 && verified.parentIdea && renderParentIdea()}

      <ContentContainer>
        {view >= 1 && <FirstLine intell={verified} anonymous={anonymous} showCreators />}

        <Box
          display="flex"
          flexDirection={screenSize.width > 600 ? "row" : "column"}
          sx={{ mt: 1.5 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{ width: screenSize.width > 600 ? (imageValid ? "70%" : "100%") : "100%" }}
          >
            <Content
              intell={verified}
              view={view}
              myPointMaxLength={getMyPointMaxLength()}
              handleNavigateLink={handleNavigateLink}
            />
          </Box>

          {imageValid && (
            <MediaContainer
              sx={{
                width: screenSize.width > 600 ? "30%" : "100%",
                maxHeight: view > 0 ? "240px" : "120px",
              }}
            >
              {renderMedia(filePaths[0])}
            </MediaContainer>
          )}
        </Box>

        {/* Expertise accordion */}
        {verified.expertise && <Expertise myExpertise={verified.expertise} />}

        {/* Attachments */}
        {showAttachments && (
          <Box sx={{ mt: 2, mb: 1 }}>
            <ViewAttachments attachments={filePaths} />
          </Box>
        )}

        {/* Activity */}
        {(activity?.instructionsTime?.length > 0 || activity?.instructionsOther?.length > 0) && (
          <Box
            sx={{
              mt: 2,
              mb: 1,
              p: 1.5,
              borderRadius: "8px",
              backgroundColor: (theme) => alpha(theme.palette.info.light, 0.7),
            }}
          >
            {activity?.instructionsTime?.length > 0 && (
              <Typography
                sx={{
                  fontSize: view > 0 ? "14px" : "12px",
                  display: "flex",
                  alignItems: "center",
                  color: (theme) => alpha(theme.palette.common.white, 0.95),
                  fontWeight: 500,
                }}
              >
                <HourglassEmpty
                  sx={{
                    width: view > 0 ? "16px" : "14px",
                    height: view > 0 ? "16px" : "14px",
                    mr: 0.8,
                    color: (theme) => theme.palette.common.white,
                  }}
                />
                {activity.instructionsTime}
              </Typography>
            )}
            {activity?.instructionsOther?.length > 0 && (
              <Typography
                sx={{
                  fontSize: view > 0 ? "14px" : "12px",
                  mt: 0.5,
                  color: (theme) => alpha(theme.palette.common.white, 0.95),
                  fontWeight: 500,
                }}
              >
                {`Instructions: ${activity.instructionsOther}`}
              </Typography>
            )}
          </Box>
        )}

        {/* Navigation & Footer */}
        <Box sx={{ mt: 1 }}>
          {/* Navigation */}
          {!activity &&
            view >= 2 &&
            setNavigationClicked &&
            verified._id &&
            verified.expertise &&
            verified.expertise.myExpertiseLevel &&
            verified.expertise.myExpertiseLevel > 1 && (
              <Footer
                activity={activity}
                intell={verified}
                view={view}
                setNavigationClicked={setNavigationClicked}
              />
            )}

          {/* Optional delete button */}
          {!activity && onDelete && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", mt: -2 }}>
              <IconButton
                onClick={onDelete}
                sx={{
                  color: (theme) => theme.palette.error.main,
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <Cancel />
              </IconButton>
            </Box>
          )}
        </Box>
      </ContentContainer>
    </GlassCard>
  );
};

ValidIntellCard.propTypes = {
  verified: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activity: PropTypes.oneOfType([PropTypes.object]),
  cardOutline: PropTypes.string.isRequired,
  view: PropTypes.number.isRequired,
  setNavigationClicked: PropTypes.func.isRequired,
  handleNavigateLink: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
});

export default connect(mapStateToProps)(ValidIntellCard);
