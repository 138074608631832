import { AI_ERROR, GET_AI, RESET_AI, LOADING_AI } from "../actions/types";

const initialState = {
  ai: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AI:
      return {
        ...state,
        ai: payload,
        loading: false,
      };
    case AI_ERROR:
      return {
        ...state,
        ai: null,
        error: payload,
        loading: false,
      };
    case RESET_AI:
      return {
        ai: null,
        loading: false,
        error: {},
      };
    case LOADING_AI:
      return {
        ...state,
        ai: null,
        loading: true,
      };
    default:
      return state;
  }
}
