import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { Block, QuestionMark, Abc } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import NavbarTitle from "../navbar/NavbarTitle";
import MySwitch from "../layout/MySwitch";
import MyGoto from "../layout/MyGoto";
import SelectTextLength from "./SelectTextLength";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DialogDig = ({
  handleBack,
  expertiseLink,
  setExpertiseLink,
  expertiseLinkSteelManTextLength,
  setExpertiseLinkSteelManTextLength,
  expertiseLinkMyTextLength,
  setExpertiseLinkMyTextLength,
  expertiseLinkChangedTextLength,
  setExpertiseLinkChangedTextLength,
}) => {
  const [allowWisdom, setAllowWisdom] = useState(expertiseLink >= 0);
  const [mandatory, setMandatory] = useState(expertiseLink >= 1);

  const handleAllowWisdom = (e) => {
    setExpertiseLink(e ? 1 : -1);
    setMandatory(e);
    setAllowWisdom(e);
  };

  const handleMandatory = (e) => {
    setExpertiseLink(e ? 1 : 0);
    setMandatory(e);
  };

  return (
    <Dialog open fullScreen>
      <NavbarTitle
        paper
        title="How will higher-level wisdom be requested?"
        backClick={() => handleBack(false)}
      />
      <Container sx={{ mt: 4, mb: 3 }}>
        <ContentPanel elevation={0}>
          {/* expertiseLink: -1: no expertise requested, 0=expertise text fields optional, 1:expertise text fields mandatory) */}
          <MySwitch
            label={
              allowWisdom
                ? "Allow participants to add wisdom"
                : "Do not allow participants to add wisdom"
            }
            subLabel={
              allowWisdom
                ? [
                    "Enhance participant responses by allowing one to self-proclaim higher-level wisdom related to a topic area.",
                    "Providing personal wisdom is always optional, never mandatory.",
                  ]
                : [
                    "Do not allow participants to self-proclaim higher-level wisdom related to a topic area ",
                  ]
            }
            select={allowWisdom}
            setSelect={handleAllowWisdom}
            icon={<Block />}
            divider={allowWisdom ? "partial" : "none"}
          />

          {allowWisdom && (
            <>
              <MySwitch
                label={
                  mandatory
                    ? "Responses to all text fields mandatory"
                    : "Responses to text fields are optional"
                }
                subLabel={
                  mandatory
                    ? [
                        "When a higher level of wisdom is self-proclaimed, participants will be offered text fields.",
                        "Responses in every field are mandatory",
                      ]
                    : [
                        "When a higher level of wisdom is self-proclaimed, participants will be offered text fields.",
                        "Participants can skip fields.",
                      ]
                }
                select={mandatory}
                setSelect={handleMandatory}
                icon={<QuestionMark />}
              />
              <MyGoto label="Length of text fields limited to..." icon={<Abc />} divider="none">
                <div style={{ marginTop: "10px" }}>
                  <SelectTextLength
                    messageLabel="Self-analysis describing one's relevant expertise"
                    textLength={expertiseLinkMyTextLength}
                    setTextLength={setExpertiseLinkMyTextLength}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <SelectTextLength
                    messageLabel="Steel-manning"
                    textLength={expertiseLinkSteelManTextLength}
                    setTextLength={setExpertiseLinkSteelManTextLength}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <SelectTextLength
                    messageLabel="Flip moment - what changed their mind? (or what could change their mind?)"
                    textLength={expertiseLinkChangedTextLength}
                    setTextLength={setExpertiseLinkChangedTextLength}
                  />
                </div>
              </MyGoto>
            </>
          )}
        </ContentPanel>
      </Container>
    </Dialog>
  );
};

DialogDig.propTypes = {
  handleBack: PropTypes.func.isRequired,
  expertiseLink: PropTypes.number.isRequired,
  setExpertiseLink: PropTypes.func.isRequired,
  expertiseLinkSteelManTextLength: PropTypes.number.isRequired,
  setExpertiseLinkSteelManTextLength: PropTypes.func.isRequired,
  expertiseLinkMyTextLength: PropTypes.number.isRequired,
  setExpertiseLinkMyTextLength: PropTypes.func.isRequired,
  expertiseLinkChangedTextLength: PropTypes.number.isRequired,
  setExpertiseLinkChangedTextLength: PropTypes.func.isRequired,
};

export default DialogDig;
