import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import Input from "../MaterialKit/MKInput";

const TruncatedInput = ({ label, value }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Check if the text is truncated
    if (inputRef.current) {
      const { offsetWidth, scrollWidth } = inputRef.current;
      setIsTruncated(scrollWidth > offsetWidth);
    }
  }, [value]);

  const handleToggle = () => setShowFullText((prev) => !prev);

  return (
    <Box sx={{ mt: 1.5 }}>
      <Input
        disabled
        fullWidth
        label={label}
        value={value}
        multiline={showFullText} // Toggle multiline
        inputRef={inputRef}
        sx={{
          zIndex: 1,
          // Dynamically adjust height based on content
          "& .MuiInputBase-root": {
            height: showFullText ? "auto" : "35px", // Adjust height for multiline
            minHeight: "35px", // Set minimum height
            transition: "height 0.3s ease-in-out", // Smooth transition for resizing
            pt: showFullText ? "-16px" : "0px", // Adjust padding for multiline
          },
          "& .MuiInputBase-input": {
            textOverflow: showFullText ? "unset" : "ellipsis",
            whiteSpace: showFullText ? "normal" : "nowrap",
            overflow: showFullText ? "visible" : "hidden",
            display: "block",
            lineHeight: showFullText ? "1.4" : "normal", // Adjust line height for multiline
            padding: showFullText ? "10px 12px" : "12px", // Remove unnecessary padding in multiline
          },
          "& .MuiInputLabel-root": { fontSize: "14px" },
        }}
      />
      {isTruncated && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: -0.5,
            mb: -0.5,
          }}
        >
          <Button
            size="small"
            color="primary"
            variant="text"
            onClick={handleToggle}
            sx={{
              textTransform: "none",
              fontSize: "12px",
              display: "block",
              fontWeight: 200,
            }}
          >
            {showFullText ? "Less" : "More"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

TruncatedInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TruncatedInput;
