// This code was adapted from "Udemy MERN Stack Front to Back"

import React from "react";
import PropTypes from "prop-types";
import spinner from "./spinner.gif";

const Spinner = ({ size = 200 }) => (
  <img
    src={spinner}
    style={{ width: `${size}px`, margin: "auto", display: "block" }}
    alt="Loading..."
  />
);

Spinner.propTypes = {
  size: PropTypes.number,
};

export default Spinner;
