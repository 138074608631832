import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { Whatshot, Reply, LinearScale, Abc } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import NavbarTitle from "../navbar/NavbarTitle";
import MyRadioMulti from "../layout/MyRadioMulti";
import MySwitch from "../layout/MySwitch";
import MyGoto from "../layout/MyGoto";
import SelectTextLength from "./SelectTextLength";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DialogFraming = ({
  handleBack,
  ftLink0,
  setFtLink0,
  ftLink11,
  setFtLink11,
  ftLinkTextLength,
  setFtLinkTextLength,
}) => {
  const options = [
    { label: "mandatory on first interpretation (optional afterwards)", value: 2 },
    { label: "optional on first interpretation (and optional afterwards)", value: 1 },
    { label: "not requested on first interpretation (but optional afterwards)", value: 0 },
    { label: "never requested", value: -1 },
  ];

  return (
    <Dialog open fullScreen>
      <NavbarTitle
        paper
        title="How will framing statements be interpreted?"
        backClick={() => handleBack(false)}
      />
      <Container sx={{ mt: 4, mb: 3 }}>
        <ContentPanel elevation={0}>
          <MySwitch
            label="Initially, request level of support"
            subLabel={[
              "Participants select a position on a slider.",
              "The position on the slider represents their level of support of the framing statement.",
            ]}
            icon={<LinearScale />}
            select
          />

          {/* (ftLink0): Reply (-1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-after) */}
          <MyRadioMulti
            label="Adding text comments are..."
            subLabel={["Perspectives, digging into the details, zooming in."]}
            icon={<Reply />}
            options={options}
            select={ftLink0}
            setSelect={setFtLink0}
          />

          {/* (ftLink11): Improved framing statement (-1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-afte) */}
          <div style={{ marginTop: "30px" }}>
            <MyRadioMulti
              label="Improvements to the framing statement text are..."
              subLabel={[
                "Breaking-frame, attempting to zoom out to see the issue from a more relevant and/or nuanced frame.",
              ]}
              icon={<Whatshot />}
              options={options}
              select={ftLink11}
              setSelect={setFtLink11}
              divider={ftLink0 >= 0 || ftLink11 >= 0 ? "partial" : "none"}
            />
          </div>

          {(ftLink0 >= 0 || ftLink11 >= 0) && (
            <MyGoto label="Length of text fields limited to..." icon={<Abc />} divider="none">
              <div style={{ marginTop: "10px" }}>
                <SelectTextLength
                  textLength={ftLinkTextLength}
                  setTextLength={setFtLinkTextLength}
                />
              </div>
            </MyGoto>
          )}
        </ContentPanel>
      </Container>
    </Dialog>
  );
};

DialogFraming.propTypes = {
  handleBack: PropTypes.func.isRequired,
  ftLink0: PropTypes.number.isRequired,
  setFtLink0: PropTypes.func.isRequired,
  ftLink11: PropTypes.number.isRequired,
  setFtLink11: PropTypes.func.isRequired,
  ftLinkTextLength: PropTypes.number.isRequired,
  setFtLinkTextLength: PropTypes.func.isRequired,
};

export default DialogFraming;
