import React, { useRef } from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/material/styles";
import { Attachment } from "@mui/icons-material";

import handleUpload from "./handleUpload";

const HiddenInput = styled("input")({
  display: "none",
});

const Attach = ({ setFilePath }) => {
  // from https://github.com/creativetimofficial/ct-material-kit-pro-react/issues/64
  const onFileInputChange = (e) => {
    if (e && e.length) {
      // Upload selected file
      handleUpload(e[0], setFilePath);
    } else {
      // File removed
      setFilePath("");
    }
  };

  const fileUpload = useRef(null);

  return (
    <>
      <HiddenInput
        ref={fileUpload}
        type="file"
        onChange={(e) => onFileInputChange(e.target.files)}
      />
      <Attachment
        style={{ color: "#0074F4", width: "20px", height: "20px" }}
        onClick={() => fileUpload.current.click()}
      />
    </>
  );
};

Attach.propTypes = {
  setFilePath: PropTypes.func.isRequired,
};

export default Attach;
