import axios from "axios";
import { setAlert } from "./alert";
import { IDEA_ERROR, ADD_IDEA, MODIFY_IDEA, GET_IDEA, GET_IDEAS, LOADING_IDEA } from "./types";

// Loading
export const loadingIdea = () => (dispatch) => {
  dispatch({ type: LOADING_IDEA });
};

// Get/search ideas
// instructions:
// - undefined (no special instructions)
// - "-1" (return nothing - set ideas[])
// - "0"  (ideaType: standard inTell/reply)
// - "1"  (ideaType: insightful inTell)
// - "9"  (any inTell 0-8)
// - "10" (ideaType: standard framing statement)
// - "11" (ideaType: insightful framing statement)
// - "19" (any framing inTell, ideaType: 10-18)
// - "20" (ideaType: info)
// - "99" (any inTell) [FUTURE]
// - "100" (search urls only - needs to be 100% match)
// - "101" (search urls only - partial match ok)
// If user defined, get ideas attributed only to user
// If fellowshipId defined, get ideas attributed only to fellowship
// Note: Will always provide most recent search results first
export const getIdeas =
  (
    fellowshipId = undefined,
    instructions = undefined,
    searchString = undefined,
    limitSize = 10,
    user = undefined
  ) =>
  async (dispatch) => {
    try {
      const res = await axios.get("/api/ideas", {
        params: {
          fellowshipId,
          instructions,
          searchString,
          limitSize,
          user,
        },
      });

      dispatch({
        type: GET_IDEAS,
        payload: res.data,
      });
    } catch (err) {
      console.error("Error fetching ideas:", err.response?.data || err.message);

      dispatch({
        type: IDEA_ERROR,
        payload: {
          msg: err.response?.data || "Authentication error - please log in",
          status: err.response?.status || 401,
        },
      });

      dispatch(
        setAlert(
          `Failed To Retrieve Ideas From Server: ${err.response?.data || "Authentication required"}`,
          "danger"
        )
      );
    }
  };

// Get idea
export const getIdea = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ideas/${id}`);

    dispatch({
      type: GET_IDEA,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: IDEA_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Idea From Server: ${err.response.data}`, "danger"));
  }
};

// Advanced URL sanitization function
const sanitizeUrl = (url) => {
  if (!url) return "";

  try {
    // Start with basic cleanup
    const cleanUrl = url.trim();

    // Create a URL object to properly parse the components
    const urlObj = new URL(cleanUrl);

    // Remove any auth information
    urlObj.username = "";
    urlObj.password = "";

    // Ensure proper protocol
    if (urlObj.protocol !== "http:" && urlObj.protocol !== "https:") {
      urlObj.protocol = "https:";
    }

    // Handle specific problematic query parameters
    // Get the search params and create a new URLSearchParams object
    const params = new URLSearchParams(urlObj.search);

    // Remove any tracking parameters that might cause issues
    const trackingParams = ["awt_a", "awt_l", "awt_m", "utm_source", "utm_medium", "utm_campaign"];
    trackingParams.forEach((param) => {
      if (params.has(param)) {
        params.delete(param);
      }
    });

    // Set the cleaned search params
    urlObj.search = params.toString();

    return urlObj.toString();
  } catch (error) {
    // If URL parsing fails, try a basic approach
    console.error("Error sanitizing URL:", error);
    let cleanUrl = url.trim();

    // Add protocol if missing
    if (!cleanUrl.startsWith("http")) {
      cleanUrl = `https://${cleanUrl}`;
    }

    // Remove query string entirely if present
    const queryIndex = cleanUrl.indexOf("?");
    if (queryIndex !== -1) {
      cleanUrl = cleanUrl.substring(0, queryIndex);
    }

    return cleanUrl;
  }
};

// Add a new function to fetch agent details
const fetchAgentDetails = async (agentId) => {
  try {
    const response = await axios.get(`/api/agents/${agentId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching details for agent ${agentId}:`, error);
    return { _id: agentId, name: "Unknown Author" };
  }
};

// Helper to populate creators in an idea
const populateIdeaCreators = async (idea) => {
  if (!idea || !idea.creators || !idea.creators.length) {
    return idea;
  }

  const populatedIdea = { ...idea };

  // Create a cache to avoid fetching the same agent multiple times
  const agentCache = new Map();

  // Fetch details for all creators that are just IDs
  const populatedCreators = await Promise.all(
    idea.creators.map(async (creator) => {
      // If already an object with name, return as is
      if (typeof creator !== "string" && creator.name) {
        return creator;
      }

      // Get the ID
      const agentId = typeof creator === "string" ? creator : creator._id;

      // Check cache first
      if (agentCache.has(agentId)) {
        return agentCache.get(agentId);
      }

      // Fetch from server
      const agentDetails = await fetchAgentDetails(agentId);
      agentCache.set(agentId, agentDetails);
      return agentDetails;
    })
  );

  populatedIdea.creators = populatedCreators;
  return populatedIdea;
};

export const addIdea = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    // Process creators array to extract IDs
    const processedData = { ...formData };

    // Track if we have unsaved creators that will need special handling
    const hasUnsavedCreators =
      processedData.creatorUnsaved ||
      (processedData.creators && processedData.creators.some((c) => c._id === undefined));

    // If there are unsaved creators, we need to handle them explicitly
    if (hasUnsavedCreators && processedData.creatorUnsaved) {
      try {
        // Make sure the creator has a valid name
        const creatorToSave = { ...processedData.creatorUnsaved };

        // If name is empty but we have a title, use the title as the name
        if (!creatorToSave.name || creatorToSave.name.trim() === "") {
          if (creatorToSave.title && creatorToSave.title.trim() !== "") {
            creatorToSave.name = creatorToSave.title;
          } else if (creatorToSave.url && creatorToSave.url.trim() !== "") {
            // Extract domain name from URL as a fallback name
            try {
              const urlObj = new URL(creatorToSave.url);
              creatorToSave.name = urlObj.hostname.replace(/^www\./, "");
            } catch (urlError) {
              creatorToSave.name = creatorToSave.url;
            }
          } else {
            creatorToSave.name = "Unknown Source";
          }
        }

        // First save the unsaved creator
        console.log("Saving unsaved creator first:", creatorToSave);

        const creatorResponse = await axios.post("/api/agents", creatorToSave, config);

        if (creatorResponse.data && creatorResponse.data._id) {
          // Add the newly saved creator to the creators array
          if (!processedData.creators) {
            processedData.creators = [];
          }

          processedData.creators.push(creatorResponse.data._id);
          console.log("Added newly saved creator to creators array:", creatorResponse.data._id);
        }

        // Remove the creatorUnsaved field as it's now processed
        delete processedData.creatorUnsaved;
      } catch (creatorErr) {
        console.error("Error saving unsaved creator:", creatorErr);
        // Continue with the idea creation even if creator save failed
      }
    }

    // Process remaining creators array to extract IDs
    if (processedData.creators && Array.isArray(processedData.creators)) {
      processedData.creators = processedData.creators.map((creator) =>
        creator._id ? creator._id : creator
      );
    }

    // Process referencedIdeas array to extract IDs
    if (processedData.referencedIdeas && Array.isArray(processedData.referencedIdeas)) {
      // Extract just the ID from each referenced idea object
      processedData.referencedIdeas = processedData.referencedIdeas.map((ref) =>
        typeof ref === "string" ? ref : ref._id
      );

      // Filter out any undefined or null values
      processedData.referencedIdeas = processedData.referencedIdeas.filter((id) => id);
    }

    // Process referencedAgents array to extract IDs
    if (processedData.referencedAgents && Array.isArray(processedData.referencedAgents)) {
      // Extract just the ID from each referenced agent object
      processedData.referencedAgents = processedData.referencedAgents.map((agent) =>
        typeof agent === "string" ? agent : agent._id
      );

      // Filter out any undefined or null values
      processedData.referencedAgents = processedData.referencedAgents.filter((id) => id);
    }

    // Apply advanced URL sanitization
    if (processedData.url) {
      processedData.url = sanitizeUrl(processedData.url);
      console.log("Sanitized URL:", processedData.url);
    }

    // console.log("Submitting idea with sanitized URL");

    // Add timeout handling for the request
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // 30 seconds timeout

    try {
      const response = await axios.post("/api/ideas", processedData, {
        ...config,
        signal: controller.signal,
      });

      clearTimeout(timeoutId); // Clear the timeout
      // console.log("Idea created successfully:", response.data);

      // If the response doesn't include creators but we sent them,
      // enrich the response with our processed creators
      if (
        processedData.creators &&
        (!response.data.creators || response.data.creators.length === 0)
      ) {
        response.data.creators = processedData.creators;
      }

      // Populate creator details before dispatching
      const populatedIdea = await populateIdeaCreators(response.data);
      console.log("Idea with populated creators:", populatedIdea);

      dispatch({
        type: ADD_IDEA,
        payload: populatedIdea,
      });
    } catch (requestErr) {
      clearTimeout(timeoutId); // Clear the timeout even on error
      throw requestErr; // Rethrow for the outer catch block
    }
  } catch (err) {
    console.error("Error submitting idea:", err.response?.data || err.message);

    if (err.name === "AbortError" || err.message === "Request timed out after 30 seconds") {
      dispatch(
        setAlert("Idea creation timed out. The request might still be processing.", "warning")
      );
    } else {
      dispatch({
        type: IDEA_ERROR,
        payload: {
          msg: err.response?.data?.message || err.message || "Error creating idea",
          status: err.response?.status || 500,
        },
      });

      dispatch(
        setAlert(
          `Failed To Submit Idea: ${err.response?.data?.message || err.message || "Unknown error"}`,
          "danger"
        )
      );
    }
  }
};

export const modifyIdea = (id, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    // Apply the same processing as in addIdea
    const processedData = { ...formData };

    if (processedData.creators && Array.isArray(processedData.creators)) {
      processedData.creators = processedData.creators.map((creator) =>
        creator._id ? creator._id : creator
      );
    }

    // Apply the same advanced URL sanitization as in addIdea
    if (processedData.url) {
      processedData.url = sanitizeUrl(processedData.url);
      console.log("Sanitized URL for modification:", processedData.url);
    }

    // Use PATCH for partial updates
    const response = await axios.patch(`/api/ideas/${id}`, processedData, config);

    dispatch({
      type: MODIFY_IDEA,
      payload: response.data,
    });

    dispatch(setAlert("Idea updated successfully", "success"));
  } catch (err) {
    console.error("Error modifying idea:", err.response?.data || err.message);

    if (err.message === "Request timed out after 20 seconds") {
      dispatch(
        setAlert("Idea modification timed out. The request might still be processing.", "warning")
      );
    } else {
      dispatch({
        type: IDEA_ERROR,
        payload: {
          msg: err.response?.data?.message || err.message || "Error modifying idea",
          status: err.response?.status || 500,
        },
      });

      dispatch(
        setAlert(
          `Failed To Modify Idea: ${err.response?.data?.message || err.message || "Unknown error"}`,
          "danger"
        )
      );
    }
  }
};
