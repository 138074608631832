import React from "react";
import PropTypes from "prop-types";

// MUI
import { Avatar, Box } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import Button from "../MaterialKit/MKButton";
import isBaseDomain from "../../utils/isBaseDomain";

// Create a styled component for the image selection
const ImageOption = styled(Avatar)(({ theme, selected }) => ({
  margin: 0,
  width: "40px",
  height: "auto",
  cursor: "pointer",
  transition: "all 0.3s ease",
  border: selected ? `3px solid ${theme.palette.primary.main}` : "3px solid transparent",
  boxShadow: selected ? `0 0 0 2px ${alpha(theme.palette.primary.main, 0.3)}` : "none",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 8px 16px -4px ${alpha(theme.palette.common.black, 0.25)}`,
  },
}));

const SelectImageOptions = ({
  selectedImage,
  setSelectedImage,
  url,
  useAI,
  aiError,
  aiPending,
  description,
  aiImage,
  allImages,
  generateAiImage,
  initialImageCopy,
}) => {
  const theme = useTheme();

  // Check if we should show the generate AI image button
  // Now we'll show it as long as we're not currently viewing an AI image
  // and there's no pending AI generation
  const showGenerateAIButton =
    useAI &&
    !aiError &&
    !aiPending &&
    description.length > 0 &&
    (!selectedImage || selectedImage !== aiImage); // This is the key change - only hide if AI image is selected

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        justifyContent: "center",
        mt: 1,
      }}
    >
      {selectedImage && (
        <Button
          variant="outlined"
          color="dark"
          onClick={() => setSelectedImage("")}
          size="small"
          sx={{
            borderRadius: "12px",
            transition: "all 0.2s ease",
            boxShadow: `0 4px 8px -2px ${alpha(theme.palette.common.black, 0.1)}`,
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: `0 6px 12px -3px ${alpha(theme.palette.common.black, 0.2)}`,
            },
          }}
        >
          No image
        </Button>
      )}

      {showGenerateAIButton && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => generateAiImage()}
          size="small"
          sx={{
            borderRadius: "12px",
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.9)})`,
            boxShadow: `0 4px 10px -2px ${alpha(theme.palette.primary.main, 0.4)}`,
            transition: "all 0.2s ease",
            "&:hover": {
              transform: "translateY(-2px)",
              boxShadow: `0 6px 14px -3px ${alpha(theme.palette.primary.main, 0.6)}`,
            },
          }}
        >
          Generate AI Image
        </Button>
      )}

      {allImages &&
        allImages.length > 0 &&
        allImages.map(
          (image, index) =>
            image !== selectedImage && (
              <ImageOption
                key={Math.random()}
                onClick={() => setSelectedImage(image)}
                variant="square"
                alt={`Image ${index + 1}`}
                src={image}
                selected={false}
              />
            )
        )}

      {/* Initially selected image */}
      {initialImageCopy && initialImageCopy.length > 0 && initialImageCopy !== selectedImage && (
        <ImageOption
          onClick={() => setSelectedImage(initialImageCopy)}
          variant="square"
          alt="initialImageCopy"
          src={initialImageCopy}
          selected={false}
        />
      )}

      {/* AI Image */}
      {isBaseDomain(url) && aiImage.length > 0 && aiImage !== selectedImage && (
        <ImageOption
          onClick={() => setSelectedImage(aiImage)}
          variant="square"
          alt="aiImage"
          src={aiImage}
          selected={false}
        />
      )}
    </Box>
  );
};

SelectImageOptions.propTypes = {
  selectedImage: PropTypes.string.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  useAI: PropTypes.bool.isRequired,
  aiError: PropTypes.bool.isRequired,
  aiPending: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  aiImage: PropTypes.string.isRequired,
  allImages: PropTypes.arrayOf(PropTypes.string),
  generateAiImage: PropTypes.func.isRequired,
  initialImageCopy: PropTypes.string.isRequired,
};

export default SelectImageOptions;
