import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

import Typography from "../MaterialKit/MKTypography";
import Slider from "./Slider";

const SliderGoodFaith = ({ userInitLevel, updated = null }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");

  const marks = [
    {
      value: -1,
      label: (
        <div style={{ marginRight: "-63px" }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: "0px" }}>
            Purposefully
          </Typography>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>manipulating</Typography>
        </div>
      ),
    },
    {
      value: 1,
      label: (
        <div style={{ marginLeft: "-50px" }}>
          <Typography
            sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: { xs: "12px", sm: "7px" } }}
          >
            Acting in
          </Typography>
          <Typography
            sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: { xs: "7px", sm: "0px" } }}
          >
            good faith
          </Typography>
        </div>
      ),
    },
  ];

  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  return (
    // Increments: 0.01
    // Min: -1
    // Max: 1
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < -1 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={-1}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderGoodFaith.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

export default SliderGoodFaith;
