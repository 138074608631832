import {
  SEARCH_AGENTS,
  AGENT_ERROR,
  ADD_AGENT,
  GET_AGENT,
  GET_AGENTS,
  LOADING_AGENT,
} from "../actions/types";

const initialState = {
  agents: [],
  agent: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_AGENTS:
    case SEARCH_AGENTS:
      return {
        ...state,
        agents: payload,
        loading: false,
      };
    case GET_AGENT:
    case ADD_AGENT:
      return {
        ...state,
        agent: payload,
        agents: [],
        loading: false,
      };
    case AGENT_ERROR:
      return {
        ...state,
        agents: [],
        agent: null,
        error: payload,
        loading: false,
      };
    case LOADING_AGENT:
      return {
        ...state,
        agents: [],
        agent: null,
        loading: true,
      };
    default:
      return state;
  }
}
