import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { AdminPanelSettings, Group, School, Lock, Add } from "@mui/icons-material";
import { Stack, Paper } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import DialogMembersEdit from "./DialogMembersEdit";
import MyGoto from "../layout/MyGoto";
import MySwitch from "../layout/MySwitch";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4),
  marginBottom: theme.spacing(3),
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const Members = ({
  privateGroup,
  setPrivateGroup,
  allowed,
  setAllowed,
  groupNumber,
  setGroupNumber,
  fellowshipType,
}) => {
  const [dialogMembersEdit, setDialogMembersEdit] = useState(-1);

  const countMyGroup = (arr, hostType) => {
    return arr.reduce((count, obj) => {
      if (obj.myGroup === hostType) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const handlePrivateGroup = (e) => {
    setPrivateGroup(e);
    if (e) {
      // Setting to private group (host + any participant groups)
      const validGroups = allowed.map((obj) => obj.myGroup).filter((num) => num !== undefined);
      setGroupNumber(Math.max(...validGroups) > 0 ? Math.max(...validGroups) : 0);
    } else {
      // setting to public group (no groups, just host)
      setGroupNumber(0);
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <GlassPanel elevation={0}>
        <MySwitch
          icon={<Lock />}
          label={privateGroup ? "This fellowship is private" : "This fellowship is public"}
          subLabel={
            privateGroup
              ? ["Access to this fellowship is restricted", "Membership list is managed by host(s)"]
              : ["Anyone who logs onto this inSight server can see and log into this fellowship"]
          }
          select={privateGroup}
          setSelect={handlePrivateGroup}
        />

        {/* Public fellowship: Hosts (only), no participant groups as anybody can join */}
        {/* Private fellowship: Hosts + participants/classes */}
        <MyGoto
          icon={<AdminPanelSettings />}
          label="Hosts"
          subLabel={
            countMyGroup(allowed, -1) + countMyGroup(allowed, 0) > 1
              ? [
                  `${countMyGroup(allowed, -1) + countMyGroup(allowed, 0)} members can configure this fellowship`,
                ]
              : ["1 member can configure this fellowship"]
          }
          handleClick={() => setDialogMembersEdit(0)}
          divider={privateGroup && groupNumber >= 1 ? "partial" : "full"}
        />

        {privateGroup && (
          <>
            {/* Private fellowship: Multiple classes, show add button */}
            {groupNumber >= 1 &&
              [...Array(groupNumber + 1)].map((_, index) => (
                <div key={Math.random()}>
                  {index > 0 && (
                    <MyGoto
                      icon={fellowshipType === 1 ? <School /> : <Group />}
                      label={fellowshipType === 1 ? `Class ${index}` : `Group ${index}`}
                      subLabel={
                        countMyGroup(allowed, 0) + countMyGroup(allowed, index) !== 1
                          ? [
                              `There are ${countMyGroup(allowed, 0) + countMyGroup(allowed, index)} members in this ${fellowshipType === 1 ? "class" : "group"}`,
                            ]
                          : [
                              `There is only 1 member in this ${fellowshipType === 1 ? "class" : "group"}`,
                            ]
                      }
                      handleClick={() => setDialogMembersEdit(index)}
                      divider={index === groupNumber ? "full" : "partial"}
                    />
                  )}
                </div>
              ))}
            <Stack
              flexWrap="wrap"
              flexDirection="row"
              width={1.0}
              justifyContent="center"
              sx={{ mt: 3 }}
            >
              <Button
                variant="text"
                color="primary"
                size="large"
                onClick={() => setGroupNumber(groupNumber + 1)}
                startIcon={<Add />}
                sx={{
                  transition: "all 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-2px)",
                  },
                }}
              >
                {`Add ${groupNumber > 0 ? "another" : "a"} ${fellowshipType === 1 ? "class" : "group"}`}
              </Button>
            </Stack>
          </>
        )}
      </GlassPanel>

      {dialogMembersEdit >= 0 && (
        <DialogMembersEdit
          handleBack={setDialogMembersEdit}
          fellowshipType={fellowshipType}
          privateGroup={privateGroup}
          allowed={allowed}
          setAllowed={setAllowed}
          groupNumber={groupNumber}
          setGroupNumber={setGroupNumber}
          groupEdit={dialogMembersEdit}
        />
      )}
    </Box>
  );
};

Members.propTypes = {
  privateGroup: PropTypes.bool.isRequired,
  setPrivateGroup: PropTypes.func.isRequired,
  allowed: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      myGroup: PropTypes.number,
    })
  ).isRequired,
  setAllowed: PropTypes.func.isRequired,
  groupNumber: PropTypes.number.isRequired,
  setGroupNumber: PropTypes.func.isRequired,
  fellowshipType: PropTypes.number.isRequired,
};

export default Members;
