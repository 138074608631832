import axios from "axios";
import { setAlert } from "./alert";
import { LOADING_EXPERTISE, SEARCH_EXPERTISES, EXPERTISE_ERROR } from "./types";

// Loading
export const loadingExpertise = () => (dispatch) => {
  dispatch({ type: LOADING_EXPERTISE });
};

// Get/search expertises attributed to userId
export const searchExpertises = (userId, limitSize, searchString) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/expertises/user/${userId}`, {
      params: {
        searchString,
        limitSize,
      },
    });
    dispatch({
      type: SEARCH_EXPERTISES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EXPERTISE_ERROR,
      payload: { msg: err.response.data, status: err.response.status },
    });
    dispatch(setAlert(`Failed To Retrieve Expertises From Server: ${err.response.data}`, "danger"));
  }
};
