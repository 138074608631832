import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Dialog, DialogActions, DialogContent, Stack } from "@mui/material";

import Button from "../MaterialKit/MKButton";
import Input from "../MaterialKit/MKInput";
import Box from "../MaterialKit/MKBox";
import QuestionText from "../styles/QuestionText";
import { setAlert } from "../../actions/alert";
import { addReport } from "../../actions/report";

const CreateBugReport = ({ handleDialogClose, addReport, setAlert, report }) => {
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (submitting) {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  }, [submitting]);

  const [message, setMessage] = useState("");

  const onSubmitReport = () => {
    let bValid = true;
    if (!message || message.length <= 2) {
      setAlert("Invalid message. Please be more descriptive.", "danger");
      bValid = false;
    }
    if (bValid) {
      setSubmitting(true);
      addReport({ message });
    }
  };

  // Exit strategy to exit this component
  // - Store initial report ID (when entering this component)
  const [initReportID] = useState(
    report && report.report && report.report._id ? report.report._id : 0
  );

  useEffect(() => {
    if (!submitting && report && report.report && report.report._id) {
      if (report.report._id !== initReportID) {
        handleDialogClose(true);
      }
    }
  }, [report, submitting]);

  return (
    <Dialog open>
      <DialogContent style={{ padding: "30px" }}>
        <QuestionText large={1} style={{ textAlign: "center" }}>
          BUG REPORTING & SUGGESTIONS
        </QuestionText>
        <QuestionText style={{ marginTop: "20px" }}>
          We are doing our best to try improve the inSight experience. Let us know if something
          seems broken, not quite right, or how your experience might be otherwise improved...
        </QuestionText>
        <QuestionText style={{ marginTop: "10px", textAlign: "center" }}>
          Thanks, <i>the inSight team</i>
        </QuestionText>

        <div style={{ marginTop: "30px" }}>
          <Box mb={2} mt={2} onChange={(e) => setMessage(e.target.value)}>
            <Input type="message" label="Your message..." fullWidth multiline />
          </Box>
        </div>
      </DialogContent>
      <DialogActions style={{ margin: "0px", border: 1 }}>
        {!submitting && (
          <Stack
            flexWrap="wrap"
            justifyContent="space-evenly"
            flexDirection="row"
            width={1.0}
            style={{ marginTop: "-20px" }}
          >
            <Button
              onClick={() => handleDialogClose(true)}
              color="primary"
              variant="outlined"
              circular
            >
              Cancel
            </Button>

            {message && message.length > 2 && (
              <Button onClick={() => onSubmitReport()} color="primary" circular>
                Submit report
              </Button>
            )}
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
};

CreateBugReport.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  report: state.report,
});

export default connect(mapStateToProps, { addReport, setAlert })(CreateBugReport);
