/**
 * Safely converts an object ID to string
 * Prevents "Cannot read properties of undefined (reading 'toString')" errors
 *
 * @param {any} id - The ID to convert
 * @returns {string} - String representation of the ID or empty string if invalid
 */
const safeToString = (id) => {
  if (!id) return "";

  try {
    return id.toString();
  } catch (error) {
    console.error("Error converting ID to string:", error);
    return "";
  }
};

/**
 * Prepares activity data for API submission by converting nested objects to IDs
 *
 * This utility ensures that any nested objects with _id properties are
 * properly converted to just the ID before API submission, preventing
 * issues with circular references and ensuring proper data format.
 *
 * @param {Object} activityData - The activity data to prepare
 * @returns {Object} - The prepared activity data
 */
const prepareActivityData = (activityData) => {
  if (!activityData) return {};

  const preparedData = { ...activityData };

  // Convert intell from object to ID if needed
  if (preparedData.intell && typeof preparedData.intell === "object" && preparedData.intell._id) {
    preparedData.intell = safeToString(preparedData.intell._id);
  }

  // Convert studentsCompleted from objects to IDs
  if (preparedData.studentsCompleted && Array.isArray(preparedData.studentsCompleted)) {
    preparedData.studentsCompleted = preparedData.studentsCompleted.map((student) =>
      typeof student === "object" && student._id ? safeToString(student._id) : student
    );
  }

  // Convert childActivities from objects to IDs
  if (preparedData.childActivities && Array.isArray(preparedData.childActivities)) {
    preparedData.childActivities = preparedData.childActivities.map((activity) =>
      typeof activity === "object" && activity._id ? safeToString(activity._id) : activity
    );
  }

  // Convert any other nested object arrays that might need conversion
  // e.g., defaultWaveMakers, activities, etc.
  if (preparedData.defaultWaveMakers && Array.isArray(preparedData.defaultWaveMakers)) {
    preparedData.defaultWaveMakers = preparedData.defaultWaveMakers.map((waveMaker) =>
      typeof waveMaker === "object" && waveMaker._id ? safeToString(waveMaker._id) : waveMaker
    );
  }

  if (preparedData.activities && Array.isArray(preparedData.activities)) {
    preparedData.activities = preparedData.activities.map((activity) =>
      typeof activity === "object" && activity._id ? safeToString(activity._id) : activity
    );
  }

  return preparedData;
};

export default prepareActivityData;
