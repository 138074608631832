import React from "react";
import PropTypes from "prop-types";

// MUI
import { Switch, Grid, Divider } from "@mui/material";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const MySwitch = ({
  icon = null,
  label = "",
  subLabel = [""],
  select = false,
  setSelect = null,
  indent = false,
  divider = "partial", // full, partial, or none
  children,
}) => {
  const renderSubLabel = () => (
    <Box sx={{ pt: label ? 0 : 0.5 }}>
      {subLabel.map((text, index) => (
        <Typography
          key={index} // eslint-disable-line
          sx={{
            fontSize: { xs: "11px", sm: "12px", md: "13px" },
            fontWeight: "100",
            color: setSelect ? "black" : "grey",
          }}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ ml: 1 }}>
        <Grid container columnSpacing={{ xs: icon || indent ? 1 : 0, sm: icon || indent ? 2 : 0 }}>
          {icon && (
            <Grid item sx={{ opacity: 0.7, mr: 2, ml: 2, mt: 0.5 }}>
              {icon}
            </Grid>
          )}
          <Grid item xs>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {label.length > 0 || (label.length > 0 && subLabel.length > 0) ? (
                <Typography
                  sx={{
                    fontSize: { xs: "13px", sm: "15px", md: "17px" },
                    fontWeight: 500,
                    color: setSelect ? "black" : "grey",
                  }}
                >
                  {label}
                </Typography>
              ) : (
                <Box sx={{ width: "100%" }}>
                  {subLabel.length > 0 && (
                    <>
                      {renderSubLabel()}
                      {children}
                    </>
                  )}
                </Box>
              )}
              {setSelect ? (
                <Switch
                  checked={select}
                  onChange={(e) => setSelect(e.target.checked)}
                  value="select"
                />
              ) : (
                <Switch checked={select} disabled value="select-disabled" />
              )}
            </Box>
            {label.length > 0 && subLabel.length && (
              <>
                {renderSubLabel()}
                {children}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* Divider without any conditional margin adjustments */}
      {divider === "partial" && <Divider sx={{ mt: 2.5, ml: { xs: 8.5, sm: 9.5 } }} />}
      {divider === "full" && <Divider sx={{ mt: 2.5 }} />}
    </>
  );
};

MySwitch.propTypes = {
  icon: PropTypes.node,
  select: PropTypes.bool,
  setSelect: PropTypes.func,
  label: PropTypes.string,
  subLabel: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.oneOf(["none", "partial", "full"]),
  children: PropTypes.node,
};

export default MySwitch;
