import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

// MUI
import {
  TextField,
  Box,
  Typography,
  Card,
  CircularProgress,
  Alert,
  InputAdornment,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { Search, ErrorOutline } from "@mui/icons-material";
import { useTheme, styled, alpha } from "@mui/material/styles";

// Components
import IntellCard from "../intell/IntellCard";
import exists from "../../utils/exists";
import { getIdea, getIdeas, loadingIdea } from "../../actions/idea";

// Create a styled component for the glass effect, similar to activity components
const GlassPanel = styled(Card)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "8px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: 0,
  width: "100%",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
  "&:focus": {
    outline: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0 0 10px ${alpha(theme.palette.primary.main, 0.5)}`,
  },
}));

/**
 * IdeaSelect component for searching and selecting ideas
 *
 * @component
 */
const IdeaSelect = ({
  setSelectedIdea, // user selected idea object (null if cancel)
  instructions = undefined, // undefined means no special search instructions
  getIdea,
  getIdeas,
  loadingIdea,
  idea: { idea, ideas = [], loading },
  fellowship: { fellowship },
}) => {
  const theme = useTheme();

  // Component state
  const [lastSearchQty, setLastSearchQty] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 500); // Debounce search input by 500ms
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [initID, setInitID] = useState(idea ? idea._id : 0);
  const [error, setError] = useState(null);

  // Safely handle ideas data from Redux
  const safeIdeas = useMemo(() => (Array.isArray(ideas) ? ideas : []), [ideas]);

  const navigate = useNavigate();

  // Check if fellowship exists
  const isFellowshipValid = useMemo(
    () => exists(fellowship) && exists(fellowship._id),
    [fellowship]
  );
  useEffect(() => {
    if (!isFellowshipValid) {
      console.error("Invalid fellowship!");
      navigate("/fellowships");
    }
  }, [navigate, isFellowshipValid]);

  // Update the selected idea when idea changes in the Redux store
  useEffect(() => {
    if (idea && idea._id && idea._id !== initID) {
      setSelectedIdea(idea);
      setInitID(idea._id);
    }
  }, [idea, initID, setSelectedIdea]);

  // Effect to fetch ideas when debounced search text changes
  useEffect(() => {
    if (!isFellowshipValid) return;

    try {
      getIdeas(
        fellowship._id,
        instructions,
        debouncedSearchText.replaceAll("\\", ""),
        lastSearchQty
      );
      setError(null);
    } catch (err) {
      setError("Search failed. Please try again.");
      console.error("Error during search:", err);
    }
  }, [
    debouncedSearchText,
    fellowship._id,
    instructions,
    lastSearchQty,
    isFellowshipValid,
    getIdeas,
  ]);

  // Input change handler (update state only, API call happens in the effect)
  const handleInputChange = useCallback((value) => {
    setSearchText(value || "");
  }, []);

  // "Show more" handler
  const handleMore = useCallback(() => {
    if (!isFellowshipValid) {
      console.error("Invalid fellowship!");
      navigate("/fellowships");
      return;
    }

    setLastSearchQty((prevQty) => prevQty + 10);
  }, [isFellowshipValid, navigate]);

  // Handle idea selection
  const handleOnSelect = useCallback(
    (e) => {
      if (e && e._id) {
        // User selected idea from list
        loadingIdea(); // Show loading state
        getIdea(e._id); // This will trigger the useEffect above when idea is loaded

        // Immediately call setSelectedIdea to provide quick feedback
        setSelectedIdea(e);
      }
    },
    [loadingIdea, getIdea, setSelectedIdea]
  );

  // Keyboard navigation handler
  const handleKeyDown = useCallback(
    (e, idea) => {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        handleOnSelect(idea);
      }
    },
    [handleOnSelect]
  );

  return (
    <Box role="region" aria-label="Idea search and selection">
      <TextField
        type="text"
        onChange={(e) => handleInputChange(e.target.value)}
        value={searchText}
        fullWidth
        autoComplete="off"
        placeholder="Search for an idea..."
        label={
          safeIdeas.length > 0
            ? "Select from list below"
            : searchText.length === 0
              ? "Select inTell"
              : ""
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? <CircularProgress size={20} color="inherit" /> : <Search />}
            </InputAdornment>
          ),
          sx: {
            borderRadius: "12px",
            transition: "box-shadow 0.3s ease",
            "&:hover": {
              boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
            },
            "&.Mui-focused": {
              boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.25)}`,
            },
          },
        }}
        inputRef={(input) => {
          if (input != null) {
            input.focus();
          }
        }}
        sx={{
          mt: 1,
          mb: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "12px",
          },
        }}
        aria-label="Search for ideas"
      />

      {error && (
        <Alert
          severity="error"
          sx={{
            mb: 2,
            borderRadius: "12px",
            boxShadow: `0 4px 12px ${alpha(theme.palette.error.main, 0.15)}`,
          }}
          icon={<ErrorOutline fontSize="inherit" />}
        >
          {error}
        </Alert>
      )}

      {!loading && safeIdeas.length === 0 ? (
        <Typography
          sx={{
            fontSize: "14px",
            textAlign: "center",
            color: "text.secondary",
            py: 2,
          }}
          role="status"
        >
          {searchText.length > 0 ? "No matches found" : ""}
        </Typography>
      ) : (
        <Box
          component="div"
          sx={{
            overflow: "auto",
            borderRadius: "12px",
            pt: 1,
            pl: 1,
            pr: 1,
            pb: 0,
            mb: 1.5,
            maxHeight: "400px",
            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.05)}`,
            backdropFilter: "blur(10px)",
            background: alpha(theme.palette.background.paper, 0.7),
          }}
          role="listbox"
          aria-label="Available ideas"
          tabIndex={0}
        >
          <List disablePadding>
            {loading && safeIdeas.length === 0
              ? // Loading skeleton
                ["skeleton-top", "skeleton-middle", "skeleton-bottom"].map((skeletonId) => (
                  <ListItem key={skeletonId} disablePadding sx={{ mb: 1.5 }}>
                    <Card
                      sx={{
                        p: 0,
                        width: "100%",
                        height: "120px",
                        opacity: 0.7,
                        background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                        backgroundSize: "200% 100%",
                        animation: "pulse 1.5s infinite",
                        "@keyframes pulse": {
                          "0%": { backgroundPosition: "0% 0%" },
                          "100%": { backgroundPosition: "-200% 0%" },
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </ListItem>
                ))
              : // Actual content
                safeIdeas.map((e) => (
                  <ListItem
                    key={e._id}
                    disablePadding
                    sx={{ mb: 1.5 }}
                    role="option"
                    aria-selected={hoveredItemId === e._id}
                  >
                    <GlassPanel
                      onClick={() => handleOnSelect(e)}
                      onKeyDown={(event) => handleKeyDown(event, e)}
                      onMouseEnter={() => setHoveredItemId(e._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      tabIndex={0}
                      sx={{
                        boxShadow:
                          hoveredItemId === e._id
                            ? `0 8px 24px ${alpha(theme.palette.primary.main, 0.2)}`
                            : `0 4px 12px ${alpha(theme.palette.common.black, 0.05)}`,
                        cursor: "pointer",
                        transform: hoveredItemId === e._id ? "translateY(-3px)" : "none",
                      }}
                    >
                      <IntellCard view={1} intell={e} />
                    </GlassPanel>
                  </ListItem>
                ))}
          </List>

          {safeIdeas.length === lastSearchQty && safeIdeas.length > 0 && (
            <>
              <Divider sx={{ my: 1 }} />
              <Typography
                color="primary"
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  mb: 1,
                  cursor: "pointer",
                  p: 1.5,
                  borderRadius: "8px",
                  fontWeight: "medium",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.08),
                    transform: "translateY(-2px)",
                  },
                }}
                onClick={handleMore}
                onKeyDown={(e) => e.key === "Enter" && handleMore()}
                tabIndex={0}
                role="button"
                aria-label="Show more ideas"
              >
                Show more
              </Typography>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

IdeaSelect.propTypes = {
  setSelectedIdea: PropTypes.func.isRequired,
  instructions: PropTypes.number,
  getIdea: PropTypes.func.isRequired,
  getIdeas: PropTypes.func.isRequired,
  loadingIdea: PropTypes.func.isRequired,
  idea: PropTypes.shape({
    idea: PropTypes.shape({
      _id: PropTypes.string,
    }),
    ideas: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          user: PropTypes.shape({
            name: PropTypes.string,
            avatar: PropTypes.string,
          }),
          fellowship: PropTypes.shape({
            name: PropTypes.string,
          }),
          ideaType: PropTypes.number,
          myPoint: PropTypes.string,
          url: PropTypes.string,
          title: PropTypes.string,
          quotedText: PropTypes.string,
          createdAt: PropTypes.string,
        })
      ),
      PropTypes.array,
    ]),
    loading: PropTypes.bool,
  }).isRequired,
  fellowship: PropTypes.shape({
    fellowship: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { getIdea, getIdeas, loadingIdea })(IdeaSelect);
