import React from "react";
import PropTypes from "prop-types";

// MUI
import { CardMedia, Paper } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import Box from "../MaterialKit/MKBox";

// Create a styled component for the glass effect
const GlassAvatar = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.5),
  backdropFilter: "blur(10px)",
  borderRadius: "50%",
  overflow: "hidden",
  display: "flex",
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "scale(1.03)",
    boxShadow: `0 12px 32px 0 ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const SelectImagePrimary = ({ selectedImage, avatarView, small = false }) => {
  const theme = useTheme();

  return (
    <div>
      {avatarView ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <GlassAvatar
            elevation={0}
            sx={{
              width: { xs: small ? 60 : 120, sm: small ? 60 : 160 },
              height: { xs: small ? 60 : 120, sm: small ? 60 : 160 },
            }}
          >
            <Box
              component="img"
              src={selectedImage}
              alt="image"
              sx={{
                width: "100%",
                height: "auto",
                minHeight: "100%",
                objectFit: "cover",
                transition: "transform 0.5s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            />
          </GlassAvatar>
        </Box>
      ) : (
        <CardMedia
          component="img"
          sx={{
            p: 1,
            height: "120px",
            objectFit: "contain",
            margin: "0 auto",
            borderRadius: "12px",
            border: small ? "none" : `3px solid ${theme.palette.primary.main}`,
            boxShadow: small ? "none" : `0 8px 16px -4px ${alpha(theme.palette.primary.main, 0.2)}`,
            transition: "all 0.3s ease",
            "&:hover": {
              transform: "scale(1.02)",
              boxShadow: small
                ? "none"
                : `0 12px 20px -6px ${alpha(theme.palette.primary.main, 0.3)}`,
            },
          }}
          image={selectedImage}
          alt="Image"
        />
      )}
    </div>
  );
};

SelectImagePrimary.propTypes = {
  selectedImage: PropTypes.string.isRequired,
  avatarView: PropTypes.bool,
  small: PropTypes.bool,
};

export default SelectImagePrimary;
