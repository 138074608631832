import React from "react";
import PropTypes from "prop-types";

// MUI
import { Grid, Divider } from "@mui/material";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const MyGoto = ({
  icon = null,
  label = "",
  subLabel = [""],
  defaultLabel = "",
  divider = "partial", // full, partial (or none)
  handleClick,
  children,
}) => {
  const onClick = () => {
    if (handleClick) {
      handleClick(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ ml: 1 }}
        onClick={() => onClick(true)}
      >
        <Grid container columnSpacing={{ xs: icon ? 1 : 0, sm: icon ? 2 : 0 }}>
          {icon && (
            <Grid item sx={{ opacity: 0.7, mr: 2, ml: 2 }}>
              {icon}
            </Grid>
          )}

          <Grid item xs>
            {label.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ fontSize: { xs: "13px", sm: "15px", md: "17px" }, fontWeight: 500 }}
                >
                  {label}
                </Typography>
                {defaultLabel.length > 0 && (
                  <Typography
                    sx={{ fontSize: { xs: "13px", sm: "15px", md: "17px" }, fontWeight: 200 }}
                  >
                    {defaultLabel}
                  </Typography>
                )}
              </Box>
            )}

            {subLabel.length > 0 && (
              <>
                <Box sx={{ pt: label.length > 0 ? 0 : 0.5 }}>
                  {subLabel.map((e, index) => (
                    <div key={Math.random()}>
                      <Typography
                        sx={{ fontSize: { xs: "11px", sm: "12px", md: "13px" }, fontWeight: 100 }}
                      >
                        {subLabel[index]}
                      </Typography>
                    </div>
                  ))}
                </Box>
                {children}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      {divider === "partial" && <Divider sx={{ mt: 2.5, ml: { xs: 8.5, sm: 9.5 } }} />}
      {divider === "full" && <Divider sx={{ mt: 2.5 }} />}
    </>
  );
};

MyGoto.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string,
  defaultLabel: PropTypes.string,
  subLabel: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.string,
  handleClick: PropTypes.func,
  children: PropTypes.node,
};

export default MyGoto;
