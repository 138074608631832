export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_FAIL_UNINVITED = "REGISTER_FAIL_UNINVITED";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const LOADING_PROFILE = "LOADING_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";

export const CONFIRMATION_WAITING = "CONFIRMATION_WAITING";
export const CONFIRMATION_SUCCESS = "CONFIRMATION_SUCCESS";
export const CONFIRMATION_PASSWORD_ACK = "CONFIRMATION_PASSWORD_ACK";
export const CONFIRMATION_OLD = "CONFIRMATION_OLD";
export const CONFIRMATION_FAIL_RESENT = "CONFIRMATION_FAIL_RESENT";
export const CONFIRMATION_FAIL_UNINVITED = "CONFIRMATION_FAIL_UNINVITED";
export const CONFIRMATION_FAIL_CHANGE = "CONFIRMATION_FAIL_CHANGE";
export const CONFIRMATION_UNKNOWN = "CONFIRMATION_UNKNOWN";

export const ADD_IDEA = "ADD_IDEA";
export const GET_IDEA = "GET_IDEA";
export const GET_IDEAS = "GET_IDEAS";
export const MODIFY_IDEA = "MODIFY_IDEA";
export const IDEA_ERROR = "IDEA_ERROR";
export const LOADING_IDEA = "LOADING_IDEA";

export const ADD_AGENT = "ADD_AGENT";
export const GET_AGENT = "GET_AGENT";
export const GET_AGENTS = "GET_AGENTS";
export const SEARCH_AGENTS = "SEARCH_AGENTS";
export const AGENT_ERROR = "AGENT_ERROR";
export const LOADING_AGENT = "LOADING_AGENT";

export const GET_SCALES = "GET_SCALES";
export const SCALE_ERROR = "SCALE_ERROR";
export const LOADING_SCALES = "LOADING_SCALES";

export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const MODIFY_ACTIVITY = "MODIFY_ACTIVITY";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const ACTIVITY_ERROR = "ACTIVITY_ERROR";
export const LOADING_ACTIVITY = "LOADING_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";

export const ADD_REPORT = "ADD_REPORT";
export const REPORT_ERROR = "REPORT_ERROR";

export const FELLOWSHIP_ERROR = "FELLOWSHIP_ERROR";
export const MODIFY_FELLOWSHIP = "MODIFY_FELLOWSHIP";
export const ADD_FELLOWSHIP = "ADD_FELLOWSHIP";
export const GET_FELLOWSHIP = "GET_FELLOWSHIP";
export const GET_FELLOWSHIPS = "GET_FELLOWSHIPS";
export const LOADING_FELLOWSHIP = "LOADING_FELLOWSHIP";
export const DELETE_FELLOWSHIP = "DELETE_FELLOWSHIP";

export const GET_AI = "GET_AI";
export const LOADING_AI = "LOADING_AI";
export const RESET_AI = "RESET_AI";
export const AI_ERROR = "AI_ERROR";

export const LOADING_EXPERTISE = "LOADING_EXPERTISE";
export const SEARCH_EXPERTISES = "SEARCH_EXPERTISES";
export const EXPERTISE_ERROR = "EXPERTISE_ERROR";
