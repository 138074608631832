import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// MUI
import { Container, Grid, IconButton, Stack, Paper } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { ManageAccounts, ChevronRight } from "@mui/icons-material";

import { ADMIN, BETA } from "../../config/personalize";
import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import Spinner from "../layout/Spinner";
import NavbarTop from "../navbar/NavbarTop";
import DialogFellowshipAdd from "./DialogFellowshipAdd";
import { loadingFellowship, getFellowships, getFellowship } from "../../actions/fellowship";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
  marginBottom: "16px",
}));

const Fellowships = ({
  loadingFellowship,
  getFellowships,
  getFellowship,
  fellowship: { fellowship, fellowships, loading },
  auth,
}) => {
  const theme = useTheme();

  // Auto-navigate to /post or /fellowships if user is only registered on a participant in a single fellowship
  const [autoNavigate] = useState(false);
  const [goToPost, setGoToPost] = useState(false);

  // Load on first render
  useEffect(() => {
    loadingFellowship();
    getFellowships();
  }, []);

  const navigate = useNavigate();

  const handleEdit = (e) => {
    if (
      (auth && auth.user && ADMIN.includes(auth.user.email)) ||
      e.allowed.some(
        (obj) => auth && auth.user && obj.email === auth.user.email && obj.myGroup <= 0
      )
    ) {
      // The current user is a host of this fellowship (or ADMIN); go edit this fellowship
      navigate(`/fellowships/${e._id}`);
    } else if (
      e.allowed.some((obj) => auth && auth.user && obj.email === auth.user.email && obj.myGroup > 0)
    ) {
      // Participant found
      getFellowship(e._id);
      setGoToPost(true);
    }
  };

  const handleView = (e) => {
    if (
      (auth && auth.user && ADMIN.includes(auth.user.email)) ||
      e.allowed.some(
        (obj) => auth && auth.user && obj.email === auth.user.email && obj.myGroup >= 0
      )
    ) {
      // Participant found (or ADMIN)
      getFellowship(e._id);
      setGoToPost(true);
    } else if (
      e.allowed.some((obj) => auth && auth.user && obj.email === auth.user.email && obj.myGroup < 0)
    ) {
      // The current user is a host of this fellowship; go edit this fellowship
      navigate(`/fellowships/${e._id}`);
    }
  };

  const [fellowshipAdd, setFellowshipAdd] = useState(false);
  const handleNew = () => {
    // Check for unrestricted mode
    const isUnrestrictedMode = !BETA || BETA.length === 0;

    if (
      auth &&
      auth.user &&
      (isUnrestrictedMode || ADMIN.includes(auth.user.email) || BETA.includes(auth.user.email))
    ) {
      setFellowshipAdd(true);
    } else {
      console.log("No permission to create new; ignore request - should never get here.");
    }
  };

  // If autoNavigate & single fellowship (where user is a participant only), go straight to fellowship
  const [initComplete, setInitComplete] = useState(false);
  // If one can edit at least one fellowship, make space in th label
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    if (auth && auth.user && fellowships) {
      let lastViewExploration = null;
      let views = 0;
      let edits = 0;

      for (let i = 0; i < fellowships.length; i += 1) {
        if (ADMIN.includes(auth.user.email)) {
          edits += 1;
        } else if (fellowships[i].allowed) {
          for (let j = 0; j < fellowships[i].allowed.length; j += 1) {
            if (fellowships[i].allowed[j].email === auth.user.email) {
              if (fellowships[i].allowed[j].myGroup >= 1) {
                views += 1;
                lastViewExploration = fellowships[i];
              }
              if (fellowships[i].allowed[j].myGroup <= 0) {
                edits += 1;
              }
              break;
            }
          }
        }
      }

      if (
        autoNavigate &&
        views === 1 &&
        lastViewExploration &&
        !ADMIN.includes(auth.user.email) &&
        !BETA.includes(auth.user.email)
      ) {
        console.log("Not an ADMIN, not a BETA, only one exploration & a particant");
        handleView(lastViewExploration);
      } else {
        setEditCount(edits);
        setInitComplete(true);
      }
    } else {
      setInitComplete(false);
      setEditCount(0);
    }
  }, [auth, fellowships]);

  const handleDialogClose = () => {
    setFellowshipAdd(false);
  };

  // Exit strategy to exit this component (goto /post)
  const [initId, setInitId] = useState(fellowship ? fellowship._id : 0);
  useEffect(() => {
    if (goToPost && fellowship && fellowship._id !== initId) {
      navigate("/post");
    } else {
      setInitId(fellowship ? fellowship._id : 0);
    }
  }, [fellowship]);

  // Add logic to check if a fellowship is joinable in unrestricted mode
  const canJoinFellowship = (fellowship) => {
    // If user is an ADMIN, they can see all fellowships
    if (auth && auth.user && ADMIN.includes(auth.user.email)) {
      return true;
    }

    const isUnrestrictedMode = !BETA || BETA.length === 0;

    // In unrestricted mode, non-private fellowships are joinable by anyone
    if (isUnrestrictedMode && !fellowship.privateGroup) {
      return true;
    }

    // Otherwise, check if user is a member
    return fellowship.allowed.some((obj) => auth && auth.user && obj.email === auth.user.email);
  };

  return loading || !initComplete || goToPost ? (
    <Spinner />
  ) : (
    <>
      <NavbarTop paper title="Select a Fellowship" dropdown={2} />

      <Container sx={{ p: 2, mb: 3 }}>
        <Box sx={{ mt: 7, mb: 4 }}>
          {fellowships &&
            fellowships.map((e) => (
              <div key={Math.random()}>
                {canJoinFellowship(e) && (
                  <GlassPanel elevation={0} sx={{ mb: 2, position: "relative" }}>
                    <Grid container alignItems="center" sx={{ p: 1.5 }}>
                      {editCount > 0 && (
                        <Grid item>
                          {auth &&
                            auth.user &&
                            (ADMIN.includes(auth.user.email) ||
                              e.allowed.some(
                                (obj) => obj.email === auth.user.email && obj.myGroup <= 0
                              )) && (
                              <IconButton
                                color="primary"
                                onClick={() => handleEdit(e)}
                                sx={{
                                  ml: 0.5,
                                  mr: 1,
                                  background: alpha(theme.palette.primary.main, 0.1),
                                  "&:hover": {
                                    background: alpha(theme.palette.primary.main, 0.2),
                                  },
                                }}
                              >
                                <ManageAccounts fontSize="small" />
                              </IconButton>
                            )}
                        </Grid>
                      )}

                      <Grid item xs>
                        <Grid
                          container
                          alignItems="center"
                          onClick={() => handleView(e)}
                          sx={{
                            cursor: "pointer",
                            borderRadius: "12px",
                            p: 1,
                            "&:hover": {
                              background: alpha(theme.palette.primary.main, 0.05),
                            },
                          }}
                        >
                          <Grid item xs>
                            <Stack spacing={0.5} align="left">
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {e.name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: alpha(theme.palette.text.secondary, 0.9),
                                  letterSpacing: "0.5px",
                                }}
                              >
                                {e.allowed
                                  ? e.allowed.filter((obj) => obj.myGroup <= e.groupNumber).length >
                                    1
                                    ? `${e.allowed.filter((obj) => obj.myGroup <= e.groupNumber).length} members`
                                    : e.allowed.filter((obj) => obj.myGroup <= e.groupNumber)
                                          .length === 1
                                      ? "1 member"
                                      : "0 members"
                                  : "0 members"}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item>
                            {e.allowed.some(
                              (obj) =>
                                auth &&
                                auth.user &&
                                obj.email === auth.user.email &&
                                obj.myGroup >= 0
                            ) && (
                              <IconButton
                                color="primary"
                                sx={{
                                  borderRadius: "50%",
                                  background: alpha(theme.palette.primary.main, 0.1),
                                  width: 36,
                                  height: 36,
                                  "&:hover": {
                                    background: alpha(theme.palette.primary.main, 0.2),
                                  },
                                }}
                              >
                                <ChevronRight />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </GlassPanel>
                )}
              </div>
            ))}

          {auth &&
            auth.user &&
            (!BETA ||
              BETA.length === 0 ||
              ADMIN.includes(auth.user.email) ||
              BETA.includes(auth.user.email)) && (
              <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleNew()}
                  sx={{
                    borderRadius: "28px",
                    px: 4,
                    py: 1.5,
                    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.9)})`,
                    boxShadow: `0 8px 20px -4px ${alpha(theme.palette.primary.main, 0.4)}`,
                    "&:hover": {
                      boxShadow: `0 12px 24px -6px ${alpha(theme.palette.primary.main, 0.6)}`,
                      transform: "translateY(-2px)",
                    },
                    transition: "all 0.3s ease",
                  }}
                >
                  Create a new fellowship
                </Button>
              </Box>
            )}
        </Box>
      </Container>

      {fellowshipAdd && <DialogFellowshipAdd handleDialogClose={handleDialogClose} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  loadingFellowship,
  getFellowships,
  getFellowship,
})(Fellowships);
