import React, { useState } from "react";
import PropTypes from "prop-types";

// MUI
import { Alert, Stack, Paper, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import { styled, alpha, useTheme } from "@mui/material/styles";

import Input from "../MaterialKit/MKInput";
import Typography from "../MaterialKit/MKTypography";
import Button from "../MaterialKit/MKButton";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const MembersAdd = ({
  allowed,
  setAllowed,
  groupEdit, // Possible values: 0=Host(s), 1+ Group number
  // Note: maps to .myGroup: -1: host-only, 0: host (& participant in any/all groups), 1..x: Participant (only) in Group 1..x
}) => {
  const theme = useTheme();
  const [emailList, setEmailList] = useState("");
  const [duplicatedInput, setDuplicatedInput] = useState([]);
  const [uniqueEmails, setUniqueEmails] = useState([]);
  const [duplicatedEmails, setDuplicatedEmails] = useState([]);

  const getUniqueInput = (inputList) => {
    // Return unique items from <inputList>
    if (!Array.isArray(inputList)) {
      return [];
    }
    return inputList.reduce((uniqueEmails, email) => {
      if (!uniqueEmails.includes(email)) {
        uniqueEmails.push(email);
      }
      return uniqueEmails;
    }, []);
  };

  const getDuplicatedInput = (inputList) => {
    // Return duplicated items from <inputList>
    if (!Array.isArray(inputList)) {
      return [];
    }
    const emailCount = inputList.reduce((count, email) => {
      count[email] = (count[email] || 0) + 1;
      return count;
    }, {});
    return Object.keys(emailCount).filter((email) => emailCount[email] > 1);
  };

  const getUniqueEmails = (emailList) => {
    // Return unique items in <emailList> that are not yet in <allowed>
    if (!Array.isArray(emailList)) {
      return [];
    }
    const allowedEmails = new Set(allowed.map((item) => item.email));
    return emailList.filter((email) => !allowedEmails.has(email));
  };

  const getDuplicatedEmails = (emailList) => {
    // Return duplicated items in both <emailList> and <allowed>
    if (!Array.isArray(emailList)) {
      return [];
    }
    const allowedEmails = new Set(allowed.map((item) => item.email));
    return emailList.filter((email) => allowedEmails.has(email));
  };

  const handleEmailList = (e) => {
    setEmailList(e.toLowerCase());

    // Define the regular expression for matching email addresses
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;

    // Use the match method to find all email addresses in the text
    const rawEmailList = e.toLowerCase().match(emailRegex);

    const validUniqueInput = getUniqueInput(rawEmailList);

    setDuplicatedInput(getDuplicatedInput(rawEmailList));
    setUniqueEmails(getUniqueEmails(validUniqueInput));
    setDuplicatedEmails(getDuplicatedEmails(validUniqueInput));
  };

  const onMemberAdd = (e) => {
    const updatedAllowed = allowed;
    uniqueEmails.forEach((item) => {
      updatedAllowed.push({
        email: item,
        myGroup: e,
      });
    });
    setAllowed(updatedAllowed);

    handleEmailList("");
  };

  const onMemberCancel = () => {
    handleEmailList("");
  };

  return (
    <>
      <ContentPanel elevation={0} onChange={(e) => handleEmailList(e.target.value)}>
        {/* Add members (text box) */}
        <Input
          type="inputText"
          label={groupEdit !== 0 ? "Add participant(s)" : "Add another host"}
          helperText="Enter one or more email addresses, or copy & paste a list of emails"
          fullWidth
          value={emailList}
          multiline
        />

        {/* Add members (feedback messages regarding members list input) */}
        {uniqueEmails.map((e) => (
          <Typography
            key={Math.random()}
            variant="body2"
            sx={{
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              color: theme.palette.primary.main,
              mb: 0.5,
            }}
            fontWeight="bold"
          >
            <Add sx={{ ml: 1, mr: 0.5, fontSize: "16px" }} />
            {e}
          </Typography>
        ))}

        {(duplicatedInput.length > 0 || duplicatedEmails.length > 0) && (
          <Box sx={{ mt: 2 }}>
            {duplicatedInput.map((e) => (
              <Alert
                key={Math.random()}
                severity="error"
                sx={{
                  mb: 1,
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                {e} was input multiple times; duplicate ignored
              </Alert>
            ))}

            {duplicatedEmails.map((e) => (
              <Alert
                key={Math.random()}
                severity="error"
                sx={{
                  mb: 1,
                  borderRadius: "8px",
                  fontSize: "12px",
                }}
              >
                {e} already exists in member list; duplicate ignored
              </Alert>
            ))}
          </Box>
        )}
      </ContentPanel>

      {/* Add members (submit/cancel buttons): group <= 0 will always be mapped to myGroup=-1 (host-only) */}
      {uniqueEmails.length > 0 && (
        <Stack
          flexWrap="wrap"
          flexDirection="row"
          width={1.0}
          justifyContent="center"
          sx={{ mb: 2 }}
        >
          <Button
            onClick={() => onMemberAdd(groupEdit > 0 ? groupEdit : -1)}
            color="primary"
            size="small"
            circular
            sx={{
              ml: 1,
              mr: 1,
              transition: "all 0.3s ease",
              boxShadow: `0 4px 12px -2px ${alpha(theme.palette.primary.main, 0.3)}`,
              "&:hover": {
                transform: "translateY(-2px)",
                boxShadow: `0 6px 16px -2px ${alpha(theme.palette.primary.main, 0.5)}`,
              },
            }}
          >
            Add {uniqueEmails.length} new{" "}
            {groupEdit !== 0
              ? uniqueEmails.length > 1
                ? "participants"
                : "participant"
              : uniqueEmails.length > 1
                ? "hosts"
                : "host"}
          </Button>
          <Button
            onClick={() => onMemberCancel()}
            color="primary"
            variant="outlined"
            size="small"
            circular
            sx={{
              ml: 1,
              mr: 1,
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-2px)",
              },
            }}
          >
            Cancel
          </Button>
        </Stack>
      )}
    </>
  );
};

MembersAdd.propTypes = {
  allowed: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      myGroup: PropTypes.number,
    })
  ).isRequired,
  setAllowed: PropTypes.func.isRequired,
  groupEdit: PropTypes.number.isRequired,
};

export default MembersAdd;
