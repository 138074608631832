import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Container, Icon, Grow, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { ArrowBack, ArrowDropDown } from "@mui/icons-material";

import { ADMIN, BETA } from "config/personalize";
import inSightLogoBlack from "assets/images/icons/logo.svg";
import inSightLogoWhite from "assets/images/icons/insight-icon-white.png";
import MKBox from "../MaterialKit/MKBox";
import MKTypography from "../MaterialKit/MKTypography";
import Spinner from "../layout/Spinner";
import Dropdown from "./Dropdown";
import RoutesStudent from "./RoutesStudent";
import RoutesAdmin from "./RoutesAdmin";
import RoutesUser from "./RoutesUser";
import FellowshipName from "./FellowshipName";

const RoundedBox = styled(MKBox)(({ theme }) =>
  theme.unstable_sx({
    borderRadius: "0px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "12px",
    },
  })
);

const NavbarTop = ({
  allowEdit = false,
  transparent = false,
  white = false,
  paper = false,
  title = "",
  content1 = "",
  content2 = "",
  sticky = false,
  dropdown = 1, // 0=none, 1=standard (default), 2=user
  bottomSpacePixels = 0,
  auth,
  fellowship,
}) => {
  // Not an ADMIN, not a BETA, particating in only one fellowship & user is a participant (participant has no real choice to make between fellowships)
  // If so, do not display fellowship name; instead simply display inSight logo
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    if (!fellowship || !fellowship.fellowship) {
      // No fellowship, show inSight logo (no fellowship selected yet)
      setShowLogo(true);
    } else if (auth && auth.user && fellowship && fellowship.fellowships) {
      if (ADMIN.includes(auth.user.email) || BETA.includes(auth.user.email)) {
        // current user is a BETA user or ADMIN, show current fellowship
        setShowLogo(false);
      } else {
        let lastFellowship = null;
        let lastMyGroup = -1;
        let foundCnt = 0;

        for (let i = 0; i < fellowship.fellowships.length; i += 1) {
          if (fellowship.fellowships[i].allowed) {
            for (let j = 0; j < fellowship.fellowships[i].allowed.length; j += 1) {
              if (fellowship.fellowships[i].allowed[j].email === auth.user.email) {
                foundCnt += 1;
                lastFellowship = fellowship.fellowships[i];
                lastMyGroup = fellowship.fellowships[i].allowed[j].myGroup;
                break;
              }
            }
          }
        }
        if (foundCnt === 1 && lastMyGroup === 1 && lastFellowship) {
          // Only one fellowship & use is a participant, show inSight logo (no fellowship choices)
          setShowLogo(true);
        } else {
          setShowLogo(false);
        }
      }
    } else {
      // no auth or fellowships, show inSight logo
      setShowLogo(true);
    }
  }, [auth, fellowship]);

  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    if (auth && auth.user) {
      // Add safe check and fallback for userId
      const userId = auth.user.id || auth.user._id || "";

      // Create safe string representation for the routes
      const userIdString = userId ? userId.toString() : "";

      if (dropdown === 2) {
        setRoutes(RoutesUser(userIdString));
      } else if (ADMIN.includes(auth.user.email) || BETA.includes(auth.user.email)) {
        setRoutes(RoutesAdmin(userIdString));
      } else {
        setRoutes(RoutesStudent);
      }
    } else {
      // Set default routes if no auth or user
      setRoutes(RoutesStudent);
    }
  }, [auth, dropdown]);

  const [showDropdownNavbar, setShowDropdownNavbar] = useState(false);

  // Routes dropdown menu
  const dropdownMenu = (
    <Grow
      sx={{
        transformOrigin: "left top",
        background: ({ palette: { white } }) => white.main,
      }}
    >
      <MKBox borderRadius="lg">
        <MKTypography variant="h1" color="white">
          <Icon sx={{ mt: -3 }}>arrow_drop_up</Icon>
        </MKTypography>
      </MKBox>
    </Grow>
  );

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/fellowships");
  };

  const goAbout = () => {
    navigate("/about");
  };

  return routes === null ? (
    <Spinner />
  ) : (
    <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
      <RoundedBox
        py={{ xs: 1.6, sm: 1.6 }}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={{ xs: 0, sm: 0 }}
        mx={{ xs: 0, sm: 3 }}
        width={{ xs: "100%", sm: "calc(100% - 48px)" }}
        shadow={transparent || paper ? "none" : "md"}
        color="dark"
        position="absolute"
        left={0}
        zIndex={13}
        sx={({
          palette: { transparent: transparentColor, primary, white },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : paper
              ? rgba(white.main, 1)
              : rgba(primary.main, 1),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={transparent ? 0 : { xs: 0, lg: 1 }}
          >
            {!showLogo ? (
              <Grid container spacing={2}>
                <Grid item>
                  <ArrowBack sx={{ color: "grey" }} onClick={goBack} />
                </Grid>
                {fellowship && fellowship.fellowship && (
                  <Grid item xs>
                    <Grid container direction="column" sx={{ mb: 0 }}>
                      <FellowshipName
                        allowEdit={allowEdit}
                        typoColor={(transparent || paper) && !white ? "dark" : "white"}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ) : (
              <MKBox display="flex" alignItems="center" flexDirection="column">
                <MKBox style={{ marginTop: "-6px" }} onClick={goAbout}>
                  <img
                    src={(transparent || paper) && !white ? inSightLogoBlack : inSightLogoWhite}
                    style={{
                      width: "22px",
                      opacity: 0.9,
                      marginRight: "3px",
                      marginBottom: "-5px",
                    }}
                    alt="Logo loading..."
                  />
                  <MKTypography
                    variant="button"
                    fontWeight="bold"
                    color={(transparent || paper) && !white ? "dark" : "white"}
                    sx={{
                      display: { xs: "none", md: "inline-block" },
                    }}
                  >
                    inSight
                  </MKTypography>
                </MKBox>
              </MKBox>
            )}
          </MKBox>

          {dropdown === 1 && (
            <MKBox
              display="inline-block"
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={(transparent || paper) && !white ? "dark" : "white"}
              sx={{ cursor: "pointer" }}
              onClick={() => setShowDropdownNavbar(!showDropdownNavbar)}
            >
              <Icon fontSize="default">{showDropdownNavbar ? "close" : "menu"}</Icon>
            </MKBox>
          )}

          {dropdown === 2 && (
            <MKBox
              display="inline-block"
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={(transparent || paper) && !white ? "dark" : "white"}
              sx={{ cursor: "pointer" }}
              onClick={() => setShowDropdownNavbar(!showDropdownNavbar)}
            >
              <MKTypography
                color={!paper ? "white" : "dark"}
                sx={{
                  fontStyle: "oblique",
                  fontSize: { xs: "10px", sm: "11px", md: "12px" },
                  mt: -1.2,
                }}
              >
                {auth.user.name}
                <Icon fontSize="default" sx={{ ml: 0.5, mb: -1 }}>
                  {showDropdownNavbar ? "close" : <ArrowDropDown />}
                </Icon>
              </MKTypography>
            </MKBox>
          )}
        </MKBox>
        <div style={{ marginTop: "-5px", marginBottom: `${bottomSpacePixels}px` }}>
          {!transparent && (title.length > 0 || content1.length > 0 || content2.length > 0) && (
            <>
              <MKBox display="flex" justifyContent="center">
                <MKTypography
                  color={!paper ? "white" : "dark"}
                  sx={{
                    fontStyle: "oblique",
                    fontSize: { xs: "14px", sm: "15px", md: "16px" },
                  }}
                >
                  {title}
                </MKTypography>
              </MKBox>
              {content1.length > 0 && (
                <MKBox display="flex" justifyContent="center">
                  <MKTypography
                    color={!paper ? "white" : "dark"}
                    sx={{ fontSize: { xs: "12px", md: "14px" }, mt: 0 }}
                  >
                    {content1}
                  </MKTypography>
                </MKBox>
              )}
              {content2.length > 0 && (
                <MKBox display="flex" justifyContent="center">
                  <MKTypography
                    color={!paper ? "white" : "dark"}
                    sx={{ fontSize: { xs: "11px", md: "12px" }, mt: 0 }}
                  >
                    {content2}
                  </MKTypography>
                </MKBox>
              )}
            </>
          )}
        </div>

        {dropdown > 0 && (
          <MKBox
            bgColor="white"
            shadow="lg"
            px={2}
            borderRadius="xl"
            width="200px"
            display="flex"
            ml="auto"
          >
            <Dropdown routes={routes} open={showDropdownNavbar} />
          </MKBox>
        )}
      </RoundedBox>
      {dropdownMenu}
    </Container>
  );
};

NavbarTop.propTypes = {
  allowEdit: PropTypes.bool,
  transparent: PropTypes.bool,
  white: PropTypes.bool,
  paper: PropTypes.bool,
  title: PropTypes.string,
  content1: PropTypes.string,
  content2: PropTypes.string,
  sticky: PropTypes.bool,
  dropdown: PropTypes.number,
  bottomSpacePixels: PropTypes.number,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps)(NavbarTop);
