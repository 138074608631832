import React from "react";
import PropTypes from "prop-types";

import Box from "../MaterialKit/MKBox";
import Input from "../MaterialKit/MKInput";

const UntruncatedInput = ({ label, value }) => {
  return (
    <Box sx={{ mt: 1.5 }}>
      <Input
        disabled
        fullWidth
        label={label}
        value={value}
        multiline
        sx={{
          zIndex: 1,
          "& .MuiInputBase-input": {
            lineHeight: "1.4",
            padding: "8px 12px",
          },
          "& .MuiInputLabel-root": { fontSize: "14px" },
        }}
      />
    </Box>
  );
};

UntruncatedInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default UntruncatedInput;
