import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

// MUI
import { Grid } from "@mui/material";

import pic1 from "../../assets/images/engagement/learn.jpeg";
import pic2 from "../../assets/images/engagement/guide.jpg";
import pic3 from "../../assets/images/engagement/community.jpg";
import pic4 from "../../assets/images/engagement/decide.jpg";

import Typography from "../MaterialKit/MKTypography";
import MyRadioMulti from "../layout/MyRadioMulti";
import InfoCard from "../layout/InfoCard";

const EditType = ({ fellowshipType, handleFellowshipType, sequential, setSequential }) => {
  const smSize = useMediaQuery("only screen and (min-width: 450px");

  const options1 = [
    { label: "LEARN", value: 1 },
    { label: "GUIDE", value: 2 },
    { label: "DISCUSS", value: 3 },
    { label: "ACT", value: 4 },
  ];

  const options2 = [
    {
      label:
        "SEQUENTIAL (participants are required to complete each activity in a predetermined sequence",
      value: 1,
    },
    { label: "FLEXIBLE (participants engage only in activities that interest them)", value: 0 },
  ];

  return (
    <>
      <Grid container alignItems="center" sx={{ mt: 1, mb: 2 }}>
        <Grid item>
          {/* Choose fellowship type from 4 choices (picture) */}
          <img
            src={
              fellowshipType === 2
                ? pic2
                : fellowshipType === 3
                  ? pic3
                  : fellowshipType === 4
                    ? pic4
                    : pic1
            }
            style={{
              width: smSize ? "280px" : "210px",
              height: smSize ? "170px" : "130px",
              marginRight: smSize ? "20px" : "10px",
            }}
            alt="..."
          />
        </Grid>
        <Grid item xs>
          {/* Choose fellowship type from 4 choices (radio buttons) */}
          <MyRadioMulti
            options={options1}
            select={fellowshipType}
            setSelect={handleFellowshipType}
            divider="none"
          />
        </Grid>
      </Grid>
      {/* LEARN description */}
      {fellowshipType === 1 && (
        <>
          <Typography sx={{ mt: -0.5, fontSize: "20px" }}>Create a digital classroom</Typography>
          <Typography sx={{ fontSize: "12px" }}>Guided community-powered e-learning.</Typography>
          <Typography sx={{ fontSize: "12px" }}>Empower critical thinking.</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Simply select the exercises, and let inSight deliver your lesson plan.
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <InfoCard title="FOR EDUCATORS" />
          </div>
          <MyRadioMulti
            select={sequential ? 1 : 0}
            options={options2}
            setSelect={(e) => setSequential(e === 1)}
            divider="none"
          />
        </>
      )}

      {/* GUIDE description */}
      {fellowshipType === 2 && (
        <>
          <Typography sx={{ mt: -0.5, fontSize: "20px" }}>Lead explorations</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Guide discussions, engage your community.
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>Seek deeper meaning together.</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Frame discussions, and let inSight do the rest.
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <InfoCard title="FOR THOUGHT LEADERS, BLOGS-WRITERS, AUTHORS" />
          </div>
          <MyRadioMulti
            select={sequential ? 1 : 0}
            options={options2}
            setSelect={(e) => setSequential(e === 1)}
            divider="none"
          />
        </>
      )}

      {/* DISCUSS description */}
      {fellowshipType === 3 && (
        <>
          <Typography sx={{ mt: -0.5, fontSize: "20px" }}>Filter out the noise</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Finally, a place to make sense of it all!
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Stop allowing others to push their agendas (and our buttons).
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Let inSight help your community track reality.
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <InfoCard title="FOR FAMILY, FRIENDS, COLLEAGUES, COMMUNITY" />
          </div>
        </>
      )}

      {/* ACT description */}
      {fellowshipType === 4 && (
        <>
          <Typography sx={{ mt: -0.5, fontSize: "20px" }}>
            Better decision-making, better actions
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>Let competence and ethics lead the way.</Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Tap into the wisdom of crowds, avoid the ignorance of the herd.
          </Typography>
          <Typography sx={{ fontSize: "12px" }}>
            Let inSight help deliver transparency, accountability, security, and trust.
          </Typography>
          <div style={{ marginTop: "30px" }}>
            <InfoCard title="FOR DISTRIBUTED GOVERNANCE, ORGANIZATIONS, DAOs" />
          </div>
        </>
      )}
    </>
  );
};

EditType.propTypes = {
  fellowshipType: PropTypes.number.isRequired,
  handleFellowshipType: PropTypes.func.isRequired,
  sequential: PropTypes.bool.isRequired,
  setSequential: PropTypes.func.isRequired,
};

export default EditType;
