import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

// MUI
import { IconButton, Checkbox, Paper, Badge } from "@mui/material";
import { AddCircle, Group, DragIndicator, Info, Article, FilterFrames } from "@mui/icons-material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import { debugLevel } from "../../config/personalize";
import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";
import exists from "../../utils/exists";
import LabelText from "../styles/LabelText";
import DialogActivityAdd from "./DialogActivityAdd";
import DialogActivityEdit from "./DialogActivityEdit";
import IntellCard from "../intell/IntellCard";
import Spinner from "../layout/Spinner";
import InfoCard from "../layout/InfoCard";
import { modifyFellowship, getFellowship } from "../../actions/fellowship";
import { modifyActivity } from "../../actions/activity";

// Adjust GlassPanel to be wider and allow top and right overhangs
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.15),
  backdropFilter: "blur(4px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.05)}`,
  transition: "all 0.3s ease",
  overflow: "visible",
  paddingLeft: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginBottom: "24px",
  marginTop: "32px",
  marginRight: "24px",
}));

/**
 * Activity List component with drag-and-drop functionality
 *
 * Implementation notes:
 * - Using @hello-pangea/dnd library for drag and drop functionality
 * - Careful handling of null/undefined values to avoid "children is null" errors
 * - Always returning non-null elements from all render functions
 */

// Helper function to determine activity type - updated to accept color parameter
const getActivityTypeIcon = (activity, iconColor = "action") => {
  if (activity && activity.intell && exists(activity.intell.ideaType)) {
    const { ideaType } = activity.intell;
    if (ideaType >= 0 && ideaType <= 9)
      return <Article color={iconColor} sx={{ width: "14px", height: "14px" }} />;
    if (ideaType >= 10 && ideaType <= 19)
      return <FilterFrames color={iconColor} sx={{ width: "14px", height: "14px" }} />;
    if (ideaType >= 20 && ideaType <= 29)
      return <Info color={iconColor} sx={{ width: "14px", height: "14px" }} />;
  }
  // Return an empty div placeholder instead of null to avoid DnD library errors
  return <div style={{ width: 24, height: 24 }} />;
};

const StyledBadge = styled(Badge)(({ theme }) =>
  theme.unstable_sx({
    "& .MuiBadge-badge": {
      right: -6,
      top: 9,
    },
  })
);

const ActivityList = ({
  modifyFellowship,
  getFellowship,
  modifyActivity,
  fellowship: { fellowship },
  activity: { activity },
}) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [indexWaiting, setIndexWaiting] = useState(-1);
  const [activityAddIndex, setActivityAddIndex] = useState(-1);
  const [activityEditIndex, setActivityEditIndex] = useState(-1);

  // console.log(rows);

  useEffect(() => {
    if (fellowship && fellowship.activities) {
      setRows(fellowship.activities);
    } else {
      setRows([]);
    }
    setIndexWaiting(-1);
  }, [fellowship]);

  const [initComplete, setInitComplete] = useState(false);
  useEffect(() => {
    if (initComplete && activity && activity._id) {
      getFellowship(fellowship._id); // force re-load
    } else {
      setInitComplete(true);
    }
  }, [activity]);

  const handleDragEnd = (e) => {
    if (!e.destination) {
      return;
    }

    // Creates a shallow copy of the rows array and stores it in tempData
    const tempData = Array.from(rows);

    // Extracts one element from tempData at the position specified by e.source.index
    const [sourceData] = tempData.splice(e.source.index, 1);

    // Inserts the sourceData item back into tempData at the position specified by e.destination.index
    tempData.splice(e.destination.index, 0, sourceData);
    setRows(tempData);

    // Mirror order changes in fellowship.activities
    const updatedActivities = [...fellowship.activities];
    const [removedObject] = updatedActivities.splice(e.source.index, 1);
    updatedActivities.splice(e.destination.index, 0, removedObject);
    modifyFellowship(fellowship._id, {
      ...fellowship,
      activities: updatedActivities,
    });
  };

  const handleDialogActivityAddClose = (e) => {
    // New activity created? If so, add to fellowship
    if (e && e._id && activityAddIndex >= 0 && exists(fellowship.activities)) {
      if (debugLevel > 0)
        console.log(
          "handleDialogClose: Adding new activity that was created at index",
          activityAddIndex,
          e
        );
      modifyFellowship(fellowship._id, {
        ...fellowship,
        activities: [
          ...fellowship.activities.slice(0, activityAddIndex),
          e,
          ...fellowship.activities.slice(activityAddIndex),
        ],
      });
    } else if (debugLevel > 0) {
      console.log("handleDialogClose cancel");
    }
    // Completed
    setActivityAddIndex(-1);
  };

  const handleDeleteActivity = (e) => {
    if (e) {
      // only if true, delete... otherwise do nothing
      modifyFellowship(fellowship._id, {
        ...fellowship,
        activities: [
          ...fellowship.activities.slice(0, activityEditIndex),
          ...fellowship.activities.slice(activityEditIndex + 1),
        ],
      });
    }
    setActivityEditIndex(-1);
  };

  const onSetEnabled = (index, enabled) => {
    if (index < 0 || index >= fellowship.activities.length) {
      console.error("Invalid index.");
    } else {
      if (debugLevel > 0) console.log("onSetEnabled index:", index);
      setIndexWaiting(index);

      // Send only the 'enabled' property for update
      modifyActivity(fellowship.activities[index]._id, {
        enabled,
      })
        .then(() => {
          // After activity is updated, refresh the fellowship data with no caching
          getFellowship(fellowship._id, true);
        })
        .catch((error) => {
          console.error("Error updating activity:", error);
          setIndexWaiting(-1); // Reset waiting state on error
        });
    }
  };

  // Calculate sequence number for live activities
  const getLiveActivityNumber = (index) => {
    if (!fellowship || !fellowship.activities) return "";

    let count = 0;
    for (let i = 0; i < fellowship.activities.length; i += 1) {
      if (fellowship.activities[i].enabled) {
        count += 1;
        if (i === index) return String(count);
      }
    }
    return "";
  };

  const renderDraggableContent = (e, index, provider) => {
    // Always return a non-null JSX element with all required props
    return (
      // eslint-disable-next-line
      <div ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
        <GlassPanel>
          {/* Main content area - using flexbox for consistent spacing */}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "28px",
                mr: 2,
                ml: 1,
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <DragIndicator
                sx={{
                  color: alpha(theme.palette.text.secondary, 0.5),
                  transition: "color 0.2s",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              />

              {/* Activity counter - non-conditional rendering with display styling */}
              <Box
                sx={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: e.enabled ? 1 : 0,
                  backgroundColor: theme.palette.success.main,
                  boxShadow: `0 2px 5px 0 ${alpha(theme.palette.success.main, 0.3)}`,
                }}
              >
                {/* Always render this element, never conditionally */}
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  sx={{
                    color: theme.palette.common.white,
                    fontSize: "14px",
                    lineHeight: 1,
                  }}
                >
                  {getLiveActivityNumber(index)}
                </Typography>
              </Box>
            </Box>

            {/* Content structure */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* Intell Card */}
              <Box
                sx={{
                  flexGrow: 1,
                  mr: -3,
                  mt: -2, // Create top overhang
                  mb: 1,
                  zIndex: 1,
//                  transition: "all 0.3s ease",
//                  "&:hover": {
//                    boxShadow: "0 8px 16px -4px rgba(0,0,0,0.15)",
//                  },
                }}
              >
                <IntellCard intell={e.intell} activity={e} />
              </Box>

              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                {/* Footer - floating above the card */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    width: "280px",
                    mb: -2.5,
                    mr: -3,
                    backgroundColor: e.enabled
                      ? alpha(theme.palette.success.light, 0.18)
                      : alpha(theme.palette.background.paper, 0.22),
                    backdropFilter: "blur(10px)",
                    borderRadius: "12px",
                    boxShadow: `0 4px 20px 0 ${alpha(theme.palette.common.black, 0.1)}`,
                    border: `1px solid ${alpha(e.enabled ? theme.palette.success.light : theme.palette.divider, 0.15)}`,
                    pl: 2,
                    zIndex: 2,
                    transition: "all 0.3s ease",
                    "&:hover": {
                      boxShadow: "0 8px 16px -4px rgba(0,0,0,0.15)",
                      backgroundColor: e.enabled
                        ? alpha(theme.palette.success.light, 0.24)
                        : alpha(theme.palette.background.paper, 0.52),
                    },
                  }}
                >
                  {/* Student Badge */}
                  <StyledBadge
                    badgeContent={e.studentsCompleted.length}
                    showZero
                    color="primary"
                    sx={{
                      mt: -0.7,
                      mb: -1,
                      "& .MuiBadge-badge": {
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                        boxShadow: `0 2px 6px -1px ${alpha(theme.palette.primary.main, 0.3)}`,
                      },
                    }}
                  >
                    <Group color="action" />
                  </StyledBadge>

                  {/* Live/Offline Toggle */}
                  <Box sx={{ mr: -4, mt: -0.9, mb: -1 }}>
                    {indexWaiting === index ? (
                      <Spinner size={50} />
                    ) : (
                      <LabelText>
                        <Checkbox
                          checked={e.enabled}
                          onChange={() => onSetEnabled(index, !e.enabled)}
                          value="enabled"
                          sx={{
                            color: theme.palette.secondary.main,
                            "&.Mui-checked": {
                              color: theme.palette.success.main,
                            },
                          }}
                        />
                        <Typography
                          variant="button"
                          fontWeight="medium"
                          color={e.enabled ? "success" : "text"}
                          sx={{
                            color: e.enabled
                              ? theme.palette.success.main
                              : theme.palette.secondary.main,
                            fontSize: "12px",
                          }}
                        >
                          {e.enabled ? "Live" : "Offline"}
                        </Typography>
                      </LabelText>
                    )}
                  </Box>

                  {/* Edit Button - Updated to use activity type icon with primary color */}
                  <Box sx={{ m: -1 }}>
                    <Button
                      onClick={() => setActivityEditIndex(index)}
                      variant="text"
                      color="primary"
                      startIcon={getActivityTypeIcon(e, "primary")}
                      sx={{
                        minWidth: "auto",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          transform: "translateY(-2px)",
                        },
                      }}
                    >
                      Edit
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </GlassPanel>

        <Box sx={{ ml: 2.2, mt: -1, mb: -1 }}>
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              opacity: 0.3,
              background: alpha(theme.palette.primary.main, 0.1),
              "&:hover": {
                opacity: 1,
                background: alpha(theme.palette.primary.main, 0.2),
              },
            }}
            onClick={() => setActivityAddIndex(index + 1)}
          >
            <AddCircle />
          </IconButton>
        </Box>
      </div>
    );
  };

  return (
    <>
      <Box sx={{ mb: 5 }}>
        {rows.length === 0 && (
          <InfoCard title="Activity List Empty" message="Add (+) first activity" />
        )}
        {rows.length === 1 && (
          <InfoCard title="List of Current Activities" message="Add (+) another activity" />
        )}
        {rows.length > 1 && (
          <InfoCard
            title="List of Current Activities"
            message={
              <>
                Drag and drop <DragIndicator fontSize="small" sx={{ mb: -0.5 }} /> to reorder, or
                add (+) another activity
              </>
            }
          />
        )}

        {/* Add activity button BELOW the InfoCard */}
        <Box sx={{ ml: 2, mb: -1 }}>
          <IconButton
            size="small"
            sx={{
              color: theme.palette.primary.main,
              opacity: 0.3,
              background: alpha(theme.palette.primary.main, 0.1),
              "&:hover": {
                opacity: 1,
                background: alpha(theme.palette.primary.main, 0.2),
              },
            }}
            onClick={() => setActivityAddIndex(0)}
          >
            <AddCircle />
          </IconButton>
        </Box>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppableActivities">
            {(droppableProvider) => (
              <div
                ref={droppableProvider.innerRef}
                {...droppableProvider.droppableProps} // eslint-disable-line
                style={{ minHeight: "10px" }}
              >
                {rows.map((e, index) => (
                  // Each draggable needs a unique wrapper with a key
                  <div key={e._id}>
                    <Draggable draggableId={e._id} index={index} key={e._id}>
                      {/* Use the separate render function to ensure consistent, non-null rendering */}
                      {(draggableProvider) => renderDraggableContent(e, index, draggableProvider)}
                    </Draggable>
                  </div>
                ))}
                {/* Always ensure placeholder is not null */}
                {droppableProvider.placeholder || <div style={{ display: "none" }} />}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {activityAddIndex >= 0 && (
        <DialogActivityAdd handleDialogClose={handleDialogActivityAddClose} />
      )}
      {activityEditIndex >= 0 && (
        <DialogActivityEdit
          selectedActivity={fellowship.activities[activityEditIndex]}
          handleDialogClose={handleDeleteActivity}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  activity: state.activity,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, {
  modifyActivity,
  modifyFellowship,
  getFellowship,
})(ActivityList);
