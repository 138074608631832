import React from "react";
import { connect } from "react-redux";
import { Routes, Route } from "react-router-dom";

// Public Routes
import Alert from "../layout/Alert";
import Register from "../auth/Register";
import Login from "../auth/Login";
import Logout from "../auth/Logout";
import ForgotPassword from "../auth/ForgotPassword";
import Confirm from "../auth/Confirm";
import NotFound from "../layout/NotFound";

// Private routes: Must be authenticated
// import Activity from "../activity/Activity";

// Private routes: Must be authenticated, && must also be non-education build OR or non-student/instructor
import About from "../about/About";
import Fellowships from "../fellowships/Fellowships";
import EditFellowship from "../fellowships/EditFellowship";
import Profiles from "../profiles/Profiles";
import Profile from "../profile/Profile";
import Post from "../post/Post";

const Routing = ({ auth }) => {
  return (
    <>
      {/* Display alert messages */}
      <Alert />

      <Routes>
        {/* Routes available to everyone */}
        <Route path="/" element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/confirm/:id/:code" element={<Confirm />} />
        <Route exact path="/fellowships" element={<Fellowships />} />

        {/* Private routes: Must be authenticated */}
        {(auth.isAuthenticated || auth.loading) && auth.user && (
          <>
            {/* All authenticated */}
            <Route exact path="/about" element={<About />} />
            <Route exact path="/post" element={<Post />} />
            <Route exact path="/post/:id" element={<Post />} key={Math.random()} />

            <Route exact path="/profiles" element={<Profiles />} />
            <Route exact path="/profile/:id" element={<Profile />} />

            <Route exact path="/fellowships/:id" element={<EditFellowship />} />
          </>
        )}

        {/* Catch-all route  */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Routing);
