import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

import Typography from "../MaterialKit/MKTypography";
import Slider from "./Slider";

const SliderTheGood = ({ userInitLevel, updated = null }) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px");
  const mdSize = useMediaQuery("only screen and (min-width: 900px)");

  const marks = [
    {
      value: -1,
      label: (
        <div style={{ marginRight: "-110px" }}>
          <Typography sx={{ fontSize: { xs: "10px", sm: "12px" } }}>Darkness</Typography>
        </div>
      ),
    },
    {
      value: 1,
      label: (
        <div style={{ marginLeft: mdSize ? "-45px" : "-40px" }}>
          <Typography
            sx={{ fontSize: { xs: "10px", sm: "12px" }, marginLeft: { xs: "2px", sm: "0px" } }}
          >
            The Light
          </Typography>
        </div>
      ),
    },
  ];

  const onSliderChange = (e, value) => {
    if (updated) {
      updated(value);
    }
  };

  // Increments: 0.01
  // Min: -1
  // Max: 1
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
      <Slider
        visible={userInitLevel < -1 ? 0 : smSize ? 1 : 0.7}
        step={0.01}
        aria-label="Slider"
        value={userInitLevel}
        min={-1}
        max={1}
        onChange={onSliderChange}
        marks={marks}
        disabled={!updated}
      />
    </div>
  );
};

SliderTheGood.propTypes = {
  userInitLevel: PropTypes.number.isRequired,
  updated: PropTypes.func,
};

export default SliderTheGood;
