import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";

// MUI
import { Dialog, Container, Divider, Avatar } from "@mui/material";
import { SelfImprovement, MilitaryTech, Engineering, ChangeCircle } from "@mui/icons-material";

import { debugLevel } from "config/personalize";
import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import exists from "../../utils/exists";
import truncate from "../../utils/truncate";
import NavbarTitle from "../navbar/NavbarTitle";
import DialogPriorExpertise from "./DialogPriorExpertise";
import MyGoto from "../layout/MyGoto";
import MySelect from "../layout/MySelect";
import MySwitch from "../layout/MySwitch";
import DialogNoSave from "../dialog/DialogNoSave";
import CustomExpertiseInput from "./CustomExpertiseInput";

// myExpertise: initial expertise
// setMyExpertise: updated when exiting this dialog
// - null if no changes
// - update expertise if changes made
const DialogEditExpertise = ({ myExpertise, setMyExpertise, fellowship: { fellowship } }) => {
  const [usePrior, setUsePrior] = useState(false);
  const [myExpertiseLevel, setMyExpertiseLevel] = useState(myExpertise.myExpertiseLevel);
  const [myExpertiseText, setMyExpertiseText] = useState(
    truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength)
  );
  const [changedText, setChangedText] = useState(
    truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength)
  );
  const [changedMind, setChangedMind] = useState(myExpertise.changedMind);
  const [steelMan, setSteelMan] = useState(
    truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength)
  );
  const [displaySubmit, setDisplaySubmit] = useState(false);
  const [expertiseChanged, setExpertiseChanged] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedSteelMan = Array.from(steelMan);
    const [removed] = reorderedSteelMan.splice(result.source.index, 1);
    reorderedSteelMan.splice(result.destination.index, 0, removed);

    setSteelMan(reorderedSteelMan);
  };

  console.log(fellowship);

  const handleSteelManChange = (index, newValue) => {
    setSteelMan((prevArray) => {
      if (index >= 0 && index < prevArray.length) {
        const newArray = [...prevArray];
        newArray[index] = newValue;

        if (index > 0 && newValue.trim().length === 0) {
          // If the input is empty, remove the input field
          newArray.splice(index, 1);
        }
        return newArray;
      }

      console.log("handleSteelMan: Index out of bounds");
      return prevArray;
    });
  };

  const handleBackClick = () => {
    if (debugLevel > 0) console.log("handleBackClick");
    if (expertiseChanged) {
      // Need to confirm exit without save
      setConfirmExit(true);
    } else {
      // No changes made; exit directly
      setMyExpertise(null);
    }
  };

  const handleConfirmExit = (e) => {
    if (debugLevel > 0) console.log("handleConfirmExit", e);
    if (e) {
      setMyExpertise(null);
    }
    setConfirmExit(false);
  };

  const areArraysEqual = (array1, array2) => {
    // Trim whitespace from all strings in both arrays
    const trimmedArray1 = array1.map((str) => str.trim());
    const trimmedArray2 = array2.map((str) => str.trim());

    // Check if the lengths are the same
    if (trimmedArray1.length !== trimmedArray2.length) return false;

    // Check if every element matches in the sorted arrays
    return trimmedArray1.every((value, index) => value === trimmedArray2[index]);
  };

  useEffect(() => {
    if (debugLevel > 0) console.log("Triggered useEffect");
    if (
      myExpertiseLevel !== myExpertise.myExpertiseLevel ||
      (myExpertiseLevel > 1 &&
        (truncate(myExpertiseText, fellowship.expertiseLinkMyTextLength) !==
          truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength) ||
          truncate(changedText, fellowship.expertiseLinkChangedTextLength) !==
            truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength) ||
          changedMind !== myExpertise.changedMind ||
          !areArraysEqual(
            truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength),
            truncate(steelMan, fellowship.expertiseLinkSteelManTextLength)
          )))
    ) {
      setExpertiseChanged(true);
    } else {
      setExpertiseChanged(false);
    }

    if (myExpertiseLevel > 1) {
      if (fellowship.expertiseLink !== 1) {
        if (debugLevel > 0) console.log("Expertise not mandatory");
        setDisplaySubmit(true);
      } else if (
        fellowship.expertiseLinkSteelManTextLength !== 0 &&
        (steelMan.length < 1 || steelMan[0].trim().length === 0)
      ) {
        // Expertise mandatory; no steel-man text
        if (debugLevel > 0) console.log("Waiting for <steelMan>");
        setDisplaySubmit(false); // Cannot submit
      } else if (fellowship.expertiseLinkMyTextLength !== 0 && myExpertiseText.length === 0) {
        // Expertise mandatory; no competence text
        if (debugLevel > 0) console.log("Waiting for <myExpertiseText>");
        setDisplaySubmit(false);
      } else if (
        fellowship.expertiseLinkChangedTextLength !== 0 &&
        changedText.trim().length === 0
      ) {
        // Expertise mandatory; no changed text
        if (debugLevel > 0) console.log("Waiting for <changedText>");
        setDisplaySubmit(false);
      } else {
        // Expertise mandatory; all fields non-empty
        setDisplaySubmit(true);
      }
    } else {
      if (debugLevel > 0) console.log("Non expert");
      setDisplaySubmit(true);
    }
  }, [myExpertiseLevel, myExpertiseText, changedMind, changedText, steelMan]);

  const handleExpertisePrior = (e) => {
    if (e) {
      if (debugLevel > 0) console.log("handleExpertisePrior(e)", e);
      setSteelMan(
        e.steelMan && e.steelMan.length > 0 && e.steelMan[0].trim().length > 0
          ? truncate(e.steelMan, fellowship.expertiseLinkSteelManTextLength)
          : [""]
      );
      setMyExpertiseLevel(
        e.myExpertiseLevel && e.myExpertiseLevel >= 3
          ? 3
          : e.myExpertiseLevel && e.myExpertiseLevel >= 2
            ? 2
            : 1
      );
      setMyExpertiseText(
        e.myExpertiseText && e.myExpertiseText.trim().length > 0 ? e.myExpertiseText.trim() : ""
      );
      setChangedText(e.changedText && e.changedText.trim().length > 0 ? e.changedText.trim() : "");
      setChangedMind(e.changedMind);
    } else if (debugLevel > 0) {
      console.log("handleExpertisePrior(null)");
    }
    setUsePrior(false);
  };

  const handleChangedText = (e) => {
    if (debugLevel > 0) console.log("handleChangedText");
    setChangedText(e.trim().length > 0 ? e : "");
  };

  const handleMyExpertiseText = (e) => {
    if (debugLevel > 0) console.log("handleMyExpertiseText", e);
    setMyExpertiseText(e.trim().length > 0 ? e : "");
  };

  const handleMyExpertiseLevel = (e) => {
    if (debugLevel > 0) console.log("handleMyExpertiseLevel");
    setMyExpertiseLevel(e);
  };

  const allSteelManStringsNonEmpty = () => {
    // Ensure the input is an array of strings; return false if it's not
    if (!Array.isArray(steelMan) || !steelMan.every((item) => typeof item === "string")) {
      return false;
    }
    if (debugLevel > 0) console.log(steelMan.every((str) => str.trim().length > 0));
    // Check if all trimmed strings are non-empty
    return steelMan.every((str) => str.trim().length > 0);
  };

  return (
    <>
      <Dialog open fullScreen>
        <NavbarTitle
          paper
          title="Your wisdom"
          backClick={handleBackClick}
          showSave={displaySubmit && expertiseChanged ? 1 : 0}
          saveClick={() =>
            setMyExpertise({
              myExpertiseLevel,
              myExpertiseText: myExpertiseText.trim(),
              changedMind,
              changedText: changedText.trim(),
              steelMan,
            })
          }
        />
        <Container sx={{ mt: 5, mb: 3 }}>
          <MySelect
            icon={<Engineering />}
            menuList={[
              {
                value: 1,
                label: "Ordinary",
              },
              {
                value: 2,
                label: "Nuanced (14%)",
              },
              {
                value: 3,
                label: "Expert",
              },
            ]}
            select={myExpertiseLevel}
            setSelect={handleMyExpertiseLevel}
            label="My level of understanding of this topic is..."
            subLabel={[
              "(1) Ordinary",
              "(2) On any issue, typically 14% can effectively argue the opposite view; I possess such nuance here",
              "(3) In a room of authentic experts, I could hold my own",
            ]}
            divider="full"
          />

          {((exists(fellowship.expertiseLinkSteelManTextLength) &&
            fellowship.expertiseLinkSteelManTextLength !== 0) ||
            (exists(fellowship.expertiseLinkMyTextLength) &&
              fellowship.expertiseLinkMyTextLength !== 0) ||
            (exists(fellowship.expertiseLinkChangedTextLength) &&
              fellowship.expertiseLinkChangedTextLength !== 0)) && (
            // Expert text allowed
            <div>
              {myExpertiseLevel >= 2 && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="text"
                      color="primary"
                      size="large"
                      onClick={() => setUsePrior(true)}
                    >
                      Leverage wisdom from a response I previously submitted
                    </Button>
                  </Box>
                  <Divider />

                  {exists(fellowship.expertiseLinkSteelManTextLength) &&
                    fellowship.expertiseLinkSteelManTextLength !== 0 && (
                      <Box sx={{ mt: 2 }}>
                        <MyGoto
                          icon={<SelfImprovement />}
                          label="Steel man"
                          subLabel={
                            steelMan.length > 2 ||
                            (steelMan.length === 2 && allSteelManStringsNonEmpty())
                              ? [
                                  "These are well-reasoned and legitimate counter-arguments to my position...",
                                  "Drag number labels to reorder",
                                ]
                              : [
                                  "My most respected and credible critics on this topic would offer these counter-arguments...",
                                  "I acknowledge that these are well-reasoned and legitimate viewpoints",
                                ]
                          }
                        >
                          <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="steelManList">
                              {(provided) => (
                                <Box
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  sx={{ mt: 2 }}
                                >
                                  {steelMan.map((item, index) => (
                                    <Draggable
                                      key={index} // eslint-disable-line
                                      draggableId={`steelMan-${index}`}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <Box
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mb: -1.5,
                                            pt: 1,
                                            pb: 1,
                                          }}
                                        >
                                          {steelMan.length > 1 && (
                                            <Avatar
                                              sx={{
                                                backgroundColor: "primary.main",
                                                color: "white",
                                                mr: 2,
                                                mt: -2,
                                              }}
                                            >
                                              {index + 1}
                                            </Avatar>
                                          )}

                                          <CustomExpertiseInput
                                            value={item}
                                            onChange={(value) => handleSteelManChange(index, value)}
                                            type="steelMan"
                                            label={index > 0 ? "" : "Best counter-argument"}
                                            maxLength={fellowship.expertiseLinkSteelManTextLength}
                                            required={index === 0 && fellowship.expertiseLink === 1}
                                          />
                                        </Box>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </Box>
                              )}
                            </Droppable>
                          </DragDropContext>
                          {allSteelManStringsNonEmpty() && (
                            <Button
                              sx={{ mt: 1 }}
                              variant="text"
                              color="primary"
                              onClick={() => setSteelMan((prev) => [...prev, ""])}
                            >
                              Add another counter-argument (optional)
                            </Button>
                          )}
                        </MyGoto>
                      </Box>
                    )}

                  {exists(fellowship.expertiseLinkMyTextLength) &&
                    fellowship.expertiseLinkMyTextLength !== 0 && (
                      <Box sx={{ mt: 2 }}>
                        <MyGoto icon={<MilitaryTech />} label="Competence">
                          <Box sx={{ mt: 1.5 }}>
                            <CustomExpertiseInput
                              onChange={handleMyExpertiseText}
                              type="myExpertiseText"
                              label="My expertise on this topic was acquired by..."
                              maxLength={fellowship.expertiseLinkMyTextLength}
                              required={fellowship.expertiseLink === 1}
                              value={myExpertiseText}
                            />
                          </Box>
                        </MyGoto>
                      </Box>
                    )}

                  {exists(fellowship.expertiseLinkChangedTextLength) &&
                    fellowship.expertiseLinkChangedTextLength !== 0 && (
                      <Box sx={{ mt: 2 }}>
                        <MySwitch
                          icon={<ChangeCircle />}
                          select={changedMind}
                          setSelect={setChangedMind}
                          label={
                            changedMind ? "My position has changed" : "My position has not changed"
                          }
                          subLabel={
                            changedMind
                              ? [
                                  "My position on this topic now has significantly changed from what it was before",
                                ]
                              : [
                                  "My position on this topic now has not significantly changed over time",
                                ]
                          }
                          subLabelFalse={[
                            "My position on this topic now has significantly changed from what it was before",
                          ]}
                          align="left"
                          divider="full"
                        >
                          <Box sx={{ mt: 1.5 }}>
                            <CustomExpertiseInput
                              onChange={handleChangedText}
                              type={changedMind ? "haveChanged" : "wouldChange"}
                              label={
                                changedMind
                                  ? "I changed my position when..."
                                  : "But I would change my position if..."
                              }
                              maxLength={fellowship.expertiseLinkChangedTextLength}
                              required={fellowship.expertiseLink === 1}
                              value={changedText}
                            />
                          </Box>
                        </MySwitch>
                      </Box>
                    )}
                </>
              )}
              {usePrior && <DialogPriorExpertise setExpertisePrior={handleExpertisePrior} />}
            </div>
          )}
        </Container>
      </Dialog>
      {confirmExit && <DialogNoSave handleDialogClose={handleConfirmExit} />}
    </>
  );
};

DialogEditExpertise.propTypes = {
  myExpertise: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setMyExpertise: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
});

export default connect(mapStateToProps)(DialogEditExpertise);
