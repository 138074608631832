import PropTypes from "prop-types";

// MUI
import { Card, Grid } from "@mui/material";

import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const HorizontalTeamCard = ({ image, name, description }) => {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} md={6} lg={4} sx={{ mt: -6 }}>
          <Box width="100%" pt={2} pb={1} px={2}>
            <Box
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={8} sx={{ my: "auto" }}>
          <Box pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <Typography variant="h5">{name}</Typography>
            <Typography variant="body2" color="text">
              {description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
