import React from "react";
import PropTypes from "prop-types";

// MUI
import { Card, Box } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { ErrorOutline } from "@mui/icons-material";

import Typography from "../MaterialKit/MKTypography";

const GlassCard = styled(Card)(({ theme, cardOutline }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.error.light, 0.3)}`,
  boxShadow:
    cardOutline === "bold"
      ? `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.15)}`
      : cardOutline === "none"
        ? "none"
        : `0 4px 12px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const InvalidIntellCard = ({ cardOutline }) => (
  <GlassCard cardOutline={cardOutline}>
    <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
      <ErrorOutline color="error" fontSize="medium" />
      <Typography
        sx={{
          fontSize: "15px",
          color: (theme) => theme.palette.error.main,
          fontWeight: 500,
        }}
      >
        Content unavailable (deleted or access denied)
      </Typography>
    </Box>
  </GlassCard>
);

InvalidIntellCard.propTypes = {
  cardOutline: PropTypes.string.isRequired,
};

export default InvalidIntellCard;
