import React from "react";
import PropTypes from "prop-types";

// MUI
import { Grid } from "@mui/material";

import defaultAvatar from "assets/images/faces/placeholder.jpg";

import Typography from "../MaterialKit/MKTypography";
import Button from "../MaterialKit/MKButton";

const ProfileView = ({ profile, canEdit = false, editProfile }) => {
  return (
    <div>
      <div>
        <div>
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <img src={profile.user.avatar ? profile.user.avatar : defaultAvatar} alt="..." />
              </div>

              {/* inSighter Name and links to associated waveMaker(s) */}
              {profile.user.agent.length > 1 && (
                <>
                  <div style={{ textAlign: "center" }}>
                    <h3>{profile.user.name}</h3>
                    {profile.aka && <h6>`aka ${profile.aka}`</h6>}
                  </div>

                  {profile.user.agent.map((e) => (
                    <div key={e._id} style={{ textAlign: "center" }}>
                      <a href={`/agent/${e._id}`}>
                        <Typography
                          color="secondary"
                          style={{
                            color: "#291AE0",
                            fontSize: "12px",
                            padding: "0px",
                            paddingTop: "5px",
                            textAlign: "center",
                          }}
                        >
                          {e.name}
                        </Typography>
                      </a>
                      <Typography
                        color="secondary"
                        style={{ fontSize: "12px", padding: "0px", textAlign: "center" }}
                      >
                        Associated waveMaker
                      </Typography>
                    </div>
                  ))}
                </>
              )}

              {/* inSighter Name which directly links to associated waveMaker */}
              {profile.user.agent.length <= 1 && (
                <>
                  {profile.user.agent.map((e) => (
                    <div key={e._id} style={{ textAlign: "center" }}>
                      <a
                        href={`/agent/${e._id}`}
                        style={{
                          fontSize: "30px",
                          textDecoration: "none",
                          color: "#291AE0",
                          "&:hover,&:focus": { textDecoration: "underline" },
                        }}
                      >
                        {profile.user.name}
                      </a>
                    </div>
                  ))}
                  {profile.aka && <h6 style={{ textAlign: "center" }}>`aka ${profile.aka}`</h6>}
                </>
              )}

              <div style={{ textAlign: "center" }}>
                <div>
                  <p>{profile.bio}</p>
                </div>

                {canEdit && (
                  <Button
                    style={{ margin: "20px" }}
                    color="primary"
                    circular
                    onClick={() => editProfile(true)}
                  >
                    Edit My Public Profile
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

ProfileView.propTypes = {
  profile: PropTypes.oneOfType([PropTypes.oneOfType([PropTypes.object])]).isRequired,
  canEdit: PropTypes.bool,
  editProfile: PropTypes.func.isRequired,
};

export default ProfileView;
