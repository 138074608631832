/**
 * Normalize objects with _id fields to just their IDs
 * This makes API calls more efficient by reducing payload size
 *
 * @param {Object} data - Object containing arrays with objects or IDs
 * @returns {Object} - Object with arrays containing only IDs
 */
const normalizeObjectsToIds = (data) => {
  if (!data) return data;

  const normalizedData = { ...data };

  // Fields that should be normalized to IDs
  const fieldsToNormalize = ["defaultWaveMakers", "activities"];

  fieldsToNormalize.forEach((field) => {
    if (normalizedData[field] && Array.isArray(normalizedData[field])) {
      normalizedData[field] = normalizedData[field].map((item) =>
        typeof item === "object" && item._id ? item._id : item
      );
    }
  });

  return normalizedData;
};

export default normalizeObjectsToIds;
