import React from "react";
import { useParams } from "react-router-dom";

import PostId from "./PostId";
import PostAll from "./PostAll";

const Post = () => {
  const { id } = useParams();

  return (
    <>
      {/* Show specific post */}
      {id && <PostId id={id} />}

      {/* List of all posts */}
      {!id && <PostAll />}
    </>
  );
};

export default Post;
