import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// MUI
import { Stack, Box, IconButton } from "@mui/material";
import { FiberManualRecord, Mic } from "@mui/icons-material";

import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import TextField from "../MaterialKit/MKInput";
import Visualizer from "../media/Visualizer";

const Transcribe = ({
  transcribing = false,
  textLength,
  requiredField,
  setTranscribing = undefined,
  message = "",
  label = "Write a message...",
  setMessage,
  disabled = false,
}) => {
  const [transcribingMessage, setTranscribingMessage] = useState("");
  const [loudness, setLoudness] = useState(0);

  // If sound does not exceed minimalSoundLevel (25) for minimalSoundSeconds (10), cancel transcription
  const [minimalSoundLevel] = useState(25);
  const [minimalSoundSeconds] = useState(10);
  const timerRef = useRef(null); // Ref to store the timer ID

  // References that store the WebSocket connection and MediaRecorder instance, respectively.
  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [audioStream, setAudioStream] = useState(null);

  useEffect(() => {
    setTranscribingMessage(textLength < 0 ? message : message.substring(0, textLength));
  }, [message, transcribing]);

  const deactivateMicrophone = () => {
    //  console.log("deactivateMicrophone");
    clearInterval(timerRef.current);
    timerRef.current = null;

    // Stop the MediaRecorder and release the microphone
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();

      // Stop all tracks to ensure the microphone is fully released
      mediaRecorderRef.current.stream.getTracks().forEach((track) => {
        track.stop();
      });

      // Clear the MediaRecorder reference
      mediaRecorderRef.current = null;
    }

    // Close the WebSocket connection
    if (socketRef.current) {
      socketRef.current.close();

      // Clear the WebSocket reference
      socketRef.current = null;
    }

    // Clear the audio stream for the visualizer
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
      setAudioStream(null);
    }
  };

  const activateMicrophone = () => {
    console.log("activateMicrophone");
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioStream(stream); // Save the audio stream for the visualizer
        if (!MediaRecorder.isTypeSupported("audio/webm")) {
          console.log("Browser not supported");
          deactivateMicrophone();
          if (transcribing) {
            setTranscribing(false);
          }
          return;
        }

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });

        // Create a websocket connection
        const socket = new WebSocket("ws://localhost:5000");
        socket.onopen = () => {
          mediaRecorder.addEventListener("dataavailable", async (event) => {
            if (event.data.size > 0 && socket.readyState === 1) {
              socket.send(event.data);
            }
          });
          mediaRecorder.start(1000);
        };

        socket.onmessage = (message) => {
          const received = JSON.parse(message.data);
          const { transcript } = received.channel.alternatives[0];
          if (transcript) {
            setTranscribingMessage(
              textLength < 0
                ? (prevTextMessage) => `${prevTextMessage} ${transcript}`
                : (prevTextMessage) => `${prevTextMessage} ${transcript}`.substring(0, textLength)
            );
          }
        };

        // Store references to the MediaRecorder and WebSocket
        mediaRecorderRef.current = mediaRecorder;
        socketRef.current = socket;
      })
      .catch((error) => {
        console.error("Error accessing the microphone:", error);
        deactivateMicrophone();
        if (transcribing) {
          setTranscribing(false);
        }
      });
  };

  const handleCancel = () => {
    console.log("handleCancel");
    deactivateMicrophone();
    if (transcribing) {
      setTranscribing(false);
    }
  };

  const handleConfirm = () => {
    console.log("handleConfirm", transcribingMessage);
    deactivateMicrophone();
    setMessage(transcribingMessage);
    if (transcribing) {
      setTranscribing(false);
    }
  };

  useEffect(() => {
    if (transcribing) {
      activateMicrophone();
    } else {
      //    console.log("Not transcribing anymore...");
      deactivateMicrophone();
    }

    // Cleanup on unmount to prevent any leaks
    return () => {
      deactivateMicrophone();
    };
  }, [transcribing]);

  // Automatically shut down the transcription after a period of relative quiet
  // NOTE: If wishing to have a mic listening but not transcribing (and waiting for a voice), suggested approach might be:
  // - Add microphone without transcription (#1), Record sound clip in <latestSec> (max 1 second); once timer expires, store expired clip in <oldSec> & record latest sound in <latestSec>
  // - When sound exceeds minimum: Kill #1, Start microphone with transcription (#2), Send recorded <latestSec> & <oldSec> for transcrption
  // - When quiet for period of time: Kill #2, Start microphone without transcription (#1), Loop back to original mode & wait for sound...
  useEffect(() => {
    if (transcribing) {
      if (loudness > minimalSoundLevel) {
        // Reset countdown timer whenever loudness exceeds minimalSoundLevel
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(() => {
          handleCancel();
        }, minimalSoundSeconds * 1000);
      }
    }
  }, [loudness]);

  return (
    <Box>
      {transcribing ? (
        <>
          {audioStream && (
            <Stack flexWrap="wrap" justifyContent="center" flexDirection="row" width={1.0}>
              <Box sx={{ width: "40px" }}>
                <Visualizer audioStream={audioStream} setLoudness={setLoudness} />
              </Box>
            </Stack>
          )}
          <Typography sx={{ fontSize: "20px", textAlign: "center" }}>Speak now</Typography>

          <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
            <FiberManualRecord sx={{ mb: -0.2, mr: 0.3, color: "red" }} />
            Transcribing...
          </Typography>

          <Box>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              label="Transcribed message..."
              helperText={
                requiredField && transcribingMessage.length === 0
                  ? "REQUIRED FIELD"
                  : textLength !== -1
                    ? `${textLength} characters max (${transcribingMessage.length}/${textLength})`
                    : ""
              }
              inputProps={{
                maxLength: textLength !== -1 ? textLength : undefined,
              }}
              value={transcribingMessage}
              onChange={(e) => setTranscribingMessage(e.target.value)}
              error={transcribingMessage.length === 0 && requiredField}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor:
                    transcribingMessage.length === 0 && requiredField ? "red" : "inherit",
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor:
                    transcribingMessage.length === 0 && requiredField ? "red" : "#1976d2",
                },
                "& .MuiFormHelperText-root": {
                  color: transcribingMessage.length === 0 && requiredField ? "red" : "",
                },
              }}
            />
          </Box>

          <Stack flexWrap="wrap" justifyContent="center" flexDirection="row" width={1.0}>
            {message !== transcribingMessage && (
              <Button onClick={() => handleConfirm()} color="primary" circular sx={{ m: 2 }}>
                Confirm
              </Button>
            )}
            <Button
              onClick={() => handleCancel()}
              color="primary"
              variant="outlined"
              circular
              sx={{ m: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </>
      ) : (
        <Box>
          <TextField
            disabled={disabled}
            variant="outlined"
            fullWidth
            multiline
            label={label}
            value={message}
            helperText={
              requiredField && message.length === 0
                ? "REQUIRED FIELD"
                : textLength !== -1
                  ? `${textLength} characters max (${message.length}/${textLength})`
                  : ""
            }
            onChange={(e) => setMessage(e.target.value)}
            inputProps={{
              maxLength: textLength !== -1 ? textLength : undefined,
            }}
            // eslint-disable-next-line
            InputProps={{
              endAdornment:
                disabled || typeof setTranscribing === "undefined" ? null : (
                  <IconButton
                    onClick={() => setTranscribing(true)}
                    onMouseDown={() => setTranscribing(true)}
                  >
                    <Mic style={{ color: "#0074F4" }} />
                  </IconButton>
                ),
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: message.length === 0 && requiredField ? "red" : "inherit",
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: message.length === 0 && requiredField ? "red" : "#1976d2",
              },
              "& .MuiFormHelperText-root": {
                color: message.length === 0 && requiredField ? "red" : "",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

Transcribe.propTypes = {
  transcribing: PropTypes.bool,
  textLength: PropTypes.number.isRequired,
  requiredField: PropTypes.bool.isRequired,
  setTranscribing: PropTypes.func,
  message: PropTypes.string,
  label: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Transcribe;
