import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import profile from "./profile";
import idea from "./idea";
import scales from "./scales";
import agent from "./agent";
import activity from "./activity";
import report from "./report";
import fellowship from "./fellowship";
import ai from "./ai";
import expertise from "./expertise";

// eslint-disable-next-line
export default combineReducers({
  alert,
  auth,
  profile,
  idea,
  scales,
  agent,
  activity,
  report,
  fellowship,
  ai,
  expertise,
});
