import React from "react";
import PropTypes from "prop-types";

// MUI
import { Avatar, Chip, Box } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { Campaign } from "@mui/icons-material";

// Create a styled component for the glass effect
const GlassChip = styled(Chip)(({ theme, iscreator }) => ({
  background:
    iscreator === "true"
      ? `linear-gradient(120deg, ${alpha("#eaff00", 0.7)}, ${alpha("#eaff00", 0.9)})`
      : `linear-gradient(120deg, ${alpha("#FFDBBB", 0.7)}, ${alpha("#FFDBBB", 0.9)})`,
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 4px 12px 0 ${alpha(theme.palette.common.black, 0.08)}`,
  transition: "all 0.3s ease",
  height: "auto",
  marginBottom: "0.75rem",
  marginLeft: 0,
  marginRight: 0,
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: `0 6px 16px -2px ${alpha(theme.palette.common.black, 0.12)}`,
  },
  "& .MuiChip-avatar": {
    width: { xs: 60, sm: 45 },
    height: { xs: 60, sm: 45 },
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    border: `2px solid ${alpha(theme.palette.common.white, 0.7)}`,
    boxShadow: `0 2px 8px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  },
  "& .MuiChip-label": {
    display: "block",
    fontSize: { xs: "8px", sm: "9px" },
    lineHeight: "1.3",
    whiteSpace: "normal",
    paddingTop: "0.5rem",
    paddingBottom: "0.4rem",
    fontWeight: 500,
  },
  "& .MuiChip-deleteIcon": {
    color: theme.palette.text.secondary,
    opacity: 0.7,
    transition: "all 0.2s ease",
    "&:hover": {
      opacity: 1,
      color: theme.palette.error.main,
    },
  },
}));

const AgentChip = ({ agent, onDelete = undefined, isCreator = false }) => {
  const doNothing = () => {
    console.log("onDelete");
  };

  return (
    <Box sx={{ display: "inline-block", m: 0.5 }}>
      <GlassChip
        key={agent._id}
        avatar={agent.image ? <Avatar alt="waveMaker" src={agent.image} /> : null}
        label={agent.name}
        onDelete={isCreator ? doNothing : onDelete}
        deleteIcon={
          isCreator ? (
            <Campaign sx={{ width: { xs: 20, sm: 30 }, height: { xs: 20, sm: 30 } }} />
          ) : undefined
        }
        iscreator={isCreator.toString()}
      />
    </Box>
  );
};

AgentChip.propTypes = {
  agent: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
  }).isRequired,
  isCreator: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default AgentChip;
