import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useDebounce } from "use-debounce";

// MUI
import { Alert, IconButton } from "@mui/material";
import { Redo } from "@mui/icons-material";

import Input from "../MaterialKit/MKInput";
import Typography from "../MaterialKit/MKTypography";
import Spinner from "../layout/Spinner";
import { setAlert } from "../../actions/alert";
import { getAI } from "../../actions/ai";
import { getUrl, getLabelFromUrl, getLabelFromHint } from "./AIPrompts";

const AgentCreateAI = ({
  aiOnInit = false, // AI request will be made when component loads (rely on fields below to determine appropriate AI request)
  aiButton = false, // AI button to to refresh a field will be displayed
  justLabelDisableEdit = false, // Just show label field (cannot edit this field)
  urlField = "", // If <urlField> provided (and requesting AI assistance), ask for AI generated label representative of this url
  setUrlField = undefined, // if undefined, we expect an intitial valid urlField to have been provided (url field cannot be modified)
  labelField = "", // If <urlField> was provided (above), assume <labelField> contains the previously scraped title from the url page
  // If <urlField> not provided (above), assume <labelField> contains the initial user text description of the agent
  setLabelField,
  setBusy = undefined, // if defined, will output if AI is busy
  showUrlField = true, // New prop to control URL field visibility
  setAlert,
  getAI,
  ai: { ai, error },
}) => {
  const [urlInputField, setUrlInputField] = useState(urlField);
  const [debouncedUrlInputField] = useDebounce(urlInputField, 1000); // Debounce search input by 1000ms

  useEffect(() => {
    setUrlInputField(urlField);
  }, [urlField]);

  // console.log("AgentCreateAI -- urlField:", urlField, urlInputField);

  useEffect(() => {
    if (typeof setUrlField !== "undefined") {
      setUrlField(debouncedUrlInputField);
    }
  }, [debouncedUrlInputField]);

  // First time -- not busy if AI not requested on input
  useEffect(() => {
    if (!aiOnInit) {
      setBusy(false);
    }
  }, []);

  const [initLabelField] = useState(labelField);
  const [aiPending, setAiPending] = useState(0); // 0: no-AI-pending,
  // 1: label (<aiOnInit> = true)
  // 2: url (<aiOnInit> = true)
  // 3: label (<aiButton> = true
  // 4: url (<aiButton> = true
  const [aiError, setAiError] = useState(false);
  // const [aiIsOperational, setAiIsOperational] = useState(aiOnInit || aiButton);
  const [aiIsOperational] = useState(aiOnInit || aiButton);

  const theme = useTheme();

  useEffect(() => {
    if (aiPending === 1 || aiPending === 3) {
      // Label AI pending request (<aiOnInit> = true and/or <aiButton> = true)
      if (error && error.status) {
        // AI error
        // setAiIsOperational(false); // allow re-attempt
        setAiPending(0);
        setBusy(false);
        setAiError(true);
        setAlert("AI is unable to help right now", "danger");
      } else if (ai && ai.length > 0) {
        // Completed AI request asking for a label
        setLabelField(ai.substring(0, 50));

        if (aiPending === 3 || typeof setUrlField === "undefined") {
          // This was a button request or ia URL was already specified; regardless, do nothing further
          setAiPending(0);
          setBusy(false);
        } else {
          // Do a second (sequential) AI re-question -- ask for a reference website based on the genereated description
          setAiPending(2);
          getAI({
            model: "gpt-4o", // "gpt-4o-2024-08-06",
            clientRequest: getUrl(ai),
          });
        }
      } else {
        // No response yet
        setLabelField("");
      }
    } else if (aiPending === 2 || aiPending === 4) {
      // url AI pending request (<aiOnInit> = true and/or <aiButton> = true)
      if (error && error.status) {
        // AI error
        //      setAiIsOperational(false); // allow re-attempt
        setAiPending(0);
        setBusy(false);
        setAiError(true);
        setAlert("AI is unable to help right now", "danger");
      } else if (ai && ai.length > 0) {
        // Completed AI request asking for a reference website
        if (typeof setUrlField !== "undefined") {
          // Safe to use function
          setUrlField(ai === "unknown" ? "" : ai);
        }
        setAiPending(0);
        setBusy(false);
      } else if (typeof setUrlField !== "undefined") {
        // No response yet
        setUrlField("");
      }
    }
  }, [ai, error]);

  const handleUrlAI = () => {
    if (
      aiIsOperational &&
      aiPending === 0 &&
      typeof setUrlField !== "undefined" &&
      labelField.length > 0
    ) {
      setAiPending(4);
      setBusy(true);
      getAI({
        model: "gpt-4o", // "gpt-4o-2024-08-06",
        clientRequest: getUrl(labelField),
      });
    }
  };

  const handleLabelAI = (onInit) => {
    if (aiIsOperational && aiPending === 0) {
      if (typeof setUrlField === "undefined") {
        if (urlField.length > 0) {
          // Ask for AI generated label based on url provided (and if <initLabelField> provided, assume it to be the previously scraped title from the url page)
          setAiPending(onInit ? 1 : 3);
          setBusy(true);
          getAI({
            model: "gpt-4o-mini",
            clientRequest: getLabelFromUrl(urlField, initLabelField),
          });
        } else {
          console.log("Could not execute getAI() - UNEXPECTED: urlField empty");
        }
      } else if (initLabelField.length > 0) {
        // typeof setUrlField !== "undefined" && initLabelField.length > 0; ask for AI generated label based on the user text provided
        setAiPending(onInit ? 1 : 3);
        setBusy(true);
        getAI({
          model: "gpt-4o-mini",
          clientRequest: getLabelFromHint(initLabelField),
        });
      } else {
        console.log("Could not execute getAI() - UNEXPECTED: initLabelField is empty");
      }
    }
  };

  // First time entry; ask AI for label
  useEffect(() => {
    if (aiOnInit) {
      handleLabelAI(true);
    }
  }, []);

  const handleLabelField = (e) => {
    //  console.log("handleLabelField", e);
    setLabelField(e);
  };

  return (
    <div>
      {aiError && (
        <Alert severity="warning" style={{ marginBottom: "10px" }}>
          Unable to contact AI assistant
        </Alert>
      )}

      {aiPending > 0 ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
          <Typography>Checking with our AI assitant</Typography>
        </div>
      ) : (
        <div>
          {justLabelDisableEdit ? (
            <Typography
              sx={{
                fontSize:
                  labelField.length > 40 ? "10px" : labelField.length > 20 ? "11px" : "12px",
                fontWeight: 500,
                lineHeight: 1.3,
                color: theme.palette.text.primary,
              }}
            >
              {labelField}
            </Typography>
          ) : (
            <>
              <div>
                {(aiPending === 0 || aiPending === 2) && (
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <Input
                      type="requestWhoAI"
                      label="waveMaker description"
                      multiline
                      fullWidth
                      value={labelField}
                      onChange={(e) => handleLabelField(e.target.value.substring(0, 50))}
                      helperText={
                        labelField.length === 0
                          ? "REQUIRED FIELD"
                          : `50 characters max (${labelField.length}/50)`
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderWidth: "2px", // Increases only the fieldset border
                            borderColor: labelField.length === 0 ? "red" : "secondary.main",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: labelField.length === 0 ? "red" : "primary.main",
                          },
                        },
                        "& .MuiFormHelperText-root": {
                          color: labelField.length === 0 ? "red" : "",
                        },
                        "& .MuiFormLabel-root": {
                          color: labelField.length === 0 ? "red" : "",
                        },
                      }}
                      InputProps={{
                        endAdornment: aiButton ? (
                          <IconButton
                            onClick={() => handleLabelAI(false)}
                            onMouseDown={() => handleLabelAI(false)}
                          >
                            <Redo style={{ color: "#0074F4" }} />
                          </IconButton>
                        ) : null,
                      }}
                    />
                  </div>
                )}
              </div>

              {/* Only display url field if showUrlField is true or a URL was originally provided */}
              {showUrlField && typeof setUrlField !== "undefined" && (
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <Input
                    type="referenceUrl"
                    label={
                      urlField.length > 0
                        ? `Representative url of: ${labelField}`
                        : "Representative url"
                    }
                    fullWidth
                    value={urlInputField}
                    onChange={(e) => {
                      if (typeof setUrlField !== "undefined") {
                        setUrlInputField(e.target.value.replace(/\s/g, "")); // Remove spaces
                      }
                    }}
                    helperText="Optional"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderWidth: "2px", // Increases only the fieldset border
                          borderColor: "secondary.main", // Sets the border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "primary.main", // Focus color
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: aiButton ? (
                        <IconButton onClick={() => handleUrlAI()} onMouseDown={() => handleUrlAI()}>
                          <Redo style={{ color: "#0074F4" }} />
                        </IconButton>
                      ) : null,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

AgentCreateAI.propTypes = {
  aiOnInit: PropTypes.bool,
  aiButton: PropTypes.bool,
  justLabelDisableEdit: PropTypes.bool,
  labelField: PropTypes.string,
  setLabelField: PropTypes.func.isRequired,
  urlField: PropTypes.string,
  setUrlField: PropTypes.func,
  setBusy: PropTypes.func,
  setAlert: PropTypes.func.isRequired,
  getAI: PropTypes.func.isRequired,
  ai: PropTypes.shape({
    ai: PropTypes.string,
    error: PropTypes.shape({
      status: PropTypes.number,
      statusText: PropTypes.string,
      message: PropTypes.string,
    }),
  }),
  showUrlField: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  ai: state.ai,
});

export default connect(mapStateToProps, { getAI, setAlert })(AgentCreateAI);
