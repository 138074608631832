import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";

import exists from "../../utils/exists";

const ElephantRationalGood = ({
  myStarIndex,
  data,
  showChangeLines,
  setSelected,
  updatedCount,
}) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");
  const svgRef = useRef(null);

  const selectable = (index) => {
    if (
      (data[index].associatedAgent &&
        data[index].associatedAgent.name &&
        data[index].associatedAgent.name.length > 0) ||
      data[index].myExpertiseLevel > 1
    ) {
      return true;
    }
    if (data[index].children) {
      for (let k = 0; k < data[index].children.length; k += 1) {
        if (
          data[index].children[k].ideaType === 0 &&
          data[index].children[k].myPoint &&
          data[index].children[k].myPoint.trim().length > 0
        ) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    // Set the dimensions and margins of the graph
    const innerSize = smSize ? 395 : 195; // Max width = 480px, max height = 450px
    const widthYLegend = 55; // 55 pixels are added on left size to show the Y-axis legend
    const extraHeightTop = 5; // 5 pixels are added on top so as not to chop off "1.0" label
    const extraHeightBottom = 25; // 20 pixels are added on bottom so as not to chop off x-axis label
    const margin = { top: 15, right: 15, bottom: 15, left: 15 };
    const width = innerSize + margin.left + margin.right + widthYLegend; // 395 + 15 + 15 + 55
    const height = innerSize + margin.top + margin.bottom + extraHeightTop + extraHeightBottom; // 395 + 15 + 15 + 5 + 20

    const svgElement = d3.select(svgRef.current);

    svgElement.selectAll("*").remove(); // Clear svg content before adding new elements

    const svg = svgElement
      .attr("width", width)
      .attr("height", height)
      .attr("transform", `translate(${margin.left}, ${margin.bottom})`);

    // Y Axis
    const y = d3
      .scaleLinear()
      .domain([-1.15, 1.15])
      .range([innerSize + extraHeightTop, extraHeightTop]); // axis height
    svg
      .append("g")
      .attr("transform", `translate(${widthYLegend},0)`)
      .call(d3.axisLeft(y).tickSizeOuter(0).ticks(5));
    svg
      .append("g")
      .attr("transform", `translate(${widthYLegend + innerSize / 2},0)`)
      .call(d3.axisLeft(y).tickValues([]).tickSizeOuter(0));
    svg
      .append("g")
      .attr("transform", `translate(${widthYLegend + innerSize},0)`)
      .call(d3.axisLeft(y).tickValues([]).tickSizeOuter(0));
    svg
      .append("text") // "Light" label
      .attr("transform", "rotate(-90)")
      .attr("y", 15)
      .attr("x", (-3 * innerSize) / 10)
      .style("text-anchor", "middle")
      .text("Light")
      .attr("font-size", "16");
    svg
      .append("text") // "Dark" label
      .attr("transform", "rotate(-90)")
      .attr("y", 15)
      .attr("x", (-72 * innerSize) / 100)
      .style("text-anchor", "middle")
      .text("Dark")
      .attr("font-size", "16");

    // X Axis
    const x = d3
      .scaleLinear()
      .domain([-1.15, 1.15])
      .range([widthYLegend, widthYLegend + innerSize]); // axis width
    svg
      .append("g")
      .attr("transform", `translate(0, ${innerSize + extraHeightTop})`)
      .call(d3.axisBottom(x).tickSizeOuter(0).ticks(5));
    svg
      .append("g")
      .attr("transform", `translate(0, ${innerSize / 2 + extraHeightTop})`)
      .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0));
    svg
      .append("g")
      .attr("transform", `translate(0, ${extraHeightTop})`)
      .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0));
    svg
      .append("text") // "Distortion" label
      .attr(
        "transform",
        `translate(${(smSize ? -85 : -45) + widthYLegend + innerSize / 2}, ${height - 5})`
      )
      .style("text-anchor", "middle")
      .text("Distortion")
      .attr("font-size", "16");
    svg
      .append("text") // "Signal" label
      .attr(
        "transform",
        `translate(${(smSize ? 85 : 45) + widthYLegend + innerSize / 2}, ${height - 5})`
      )
      .style("text-anchor", "middle")
      .text("Signal")
      .attr("font-size", "16");

    const densityData = d3
      .contourDensity()
      .y((d) => {
        return y(d.y);
      })
      .x((d) => {
        return x(d.x);
      })
      .size([width, height])
      .bandwidth(smSize ? 30 : 20)(data);

    // Prepare a color palette
    const color = d3
      .scaleLinear()
      .domain([0, 0.65 * Math.max(...densityData.map((o) => o.value))]) // 0.65 provides darker colors on top (1.0 represenst max)
      .range(["white", "#0074F4"]);

    // Draw density image
    svg
      .selectAll("path")
      .data(densityData)
      .enter()
      .append("path")
      .attr("d", d3.geoPath())
      .attr("fill", (d) => {
        return color(d.value);
      });

    // Community member dots (unfilled)
    for (let j = 0; j < data.length; j += 1) {
      const Xpx = widthYLegend + ((data[j].x + 1.15) / 2.3) * innerSize;
      const Ypx = extraHeightTop + ((-data[j].y + 1.15) / 2.3) * innerSize;
      if (myStarIndex !== j && !selectable(j)) {
        svg
          .append("circle")
          .attr("cx", Xpx)
          .attr("cy", Ypx)
          .attr("r", 3)
          // Extra space around the dot that is still active
          .attr("stroke-width", 1)
          .attr("stroke", "black")
          .style("fill", "white");
      }
    }

    // Community member dots (filled)
    for (let j = 0; j < data.length; j += 1) {
      const Xpx = widthYLegend + ((data[j].x + 1.15) / 2.3) * innerSize;
      const Ypx = extraHeightTop + ((-data[j].y + 1.15) / 2.3) * innerSize;
      if (myStarIndex !== j && selectable(j)) {
        svg
          .append("circle")
          .attr("cx", Xpx)
          .attr("cy", Ypx)
          .attr("r", 3)
          // Extra space around the dot that is still active
          .attr("stroke-width", 1)
          .attr("stroke", "invisible")
          .style("fill", "black");
      }
    }

    // Change lines
    if (showChangeLines) {
      for (let j = 0; j < data.length; j += 1) {
        if (exists(data[j].x1) || exists(data[j].y1)) {
          const Xpx = widthYLegend + ((data[j].x + 1.15) / 2.3) * innerSize;
          const Ypx = extraHeightTop + ((-data[j].y + 1.15) / 2.3) * innerSize;
          const X1px =
            widthYLegend +
            (((exists(data[j].x1) ? data[j].x1 : data[j].x) + 1.15) / 2.3) * innerSize;
          const Y1px =
            extraHeightTop +
            ((-(exists(data[j].y1) ? data[j].y1 : data[j].y) + 1.15) / 2.3) * innerSize;

          svg
            .append("line")
            .attr("x1", X1px)
            .attr("y1", Y1px)
            .attr("x2", Xpx)
            .attr("y2", Ypx)
            .attr(
              "stroke",
              myStarIndex === j ? "yellow" : Xpx > X1px ? "green" : Xpx < X1px ? "red" : "black"
            )
            .attr("stroke-width", 4);
        }
      }
    }

    // My star
    if (myStarIndex >= 0) {
      const Xpx = widthYLegend + ((data[myStarIndex].x + 1.15) / 2.3) * innerSize;
      const Ypx = extraHeightTop + ((-data[myStarIndex].y + 1.15) / 2.3) * innerSize;

      const sym = d3.symbol().type(d3.symbolStar).size(200);
      d3.select(svgRef.current)
        .append("path")
        .attr("d", sym)
        .attr("fill", "yellow")
        .attr("transform", `translate(${Xpx}, ${Ypx})`)
        .attr("stroke-width", 1)
        .attr("stroke", "black");
    }

    const goBrush = (e) => {
      const rows = [];
      for (let j = 0; j < data.length; j += 1) {
        const Xpx = widthYLegend + ((data[j].x + 1.15) / 2.3) * innerSize;
        const Ypx = extraHeightTop + ((-data[j].y + 1.15) / 2.3) * innerSize;

        if (
          Xpx >= e.selection[0][0] &&
          Xpx <= e.selection[1][0] &&
          Ypx >= e.selection[0][1] &&
          Ypx <= e.selection[1][1] &&
          selectable(j)
        ) {
          rows.push(data[j]);
        }
      }
      setSelected(rows);
    };

    // Pause for half second before generating table
    let timer = null;
    const handleBrush = (e) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        goBrush(e);
      }, 500);
    };
    svg.append("g").call(d3.brush().on("brush", handleBrush));
  }, [smSize, updatedCount, showChangeLines]);

  //    useEffect(() => {
  //      setSelected([]);
  //    }, [updatedCount]);

  return <svg ref={svgRef} />;
};

ElephantRationalGood.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  myStarIndex: PropTypes.number.isRequired,
  showChangeLines: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  updatedCount: PropTypes.number.isRequired,
};

export default ElephantRationalGood;
