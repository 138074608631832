module.exports = Object.freeze({
  MY_DOMAIN: "http://10.0.0.157:3000",
  ADMIN: ["b@bigidealab.com", "kooznet@yahoo.com", "spark@insight.click"],
  BETA: [
    "gary.kooznetsoff@gmail.com",
    "andrea.kyburz@gmail.com",
    "mcheveldave@nuoptiks.com",
    "skye.kooznetsoff@icloud.com",
    "bertsch@spimanagement.com",
  ],
  debugLevel: 0,
});
// MY_DOMAIN: "https://www.insight.click",
// MY_DOMAIN: "http://localhost:3000",
