import React, { memo } from "react";
import PropTypes from "prop-types";

// MUI
import {
  Grid,
  Chip,
  Backdrop,
  Paper,
  Container,
  Divider,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import { debugLevel } from "config/personalize";
import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import AgentSelect from "../agent/AgentSelect";
import IdeaSelect from "./IdeaSelect";
import RotatingIdeaCard from "./RotatingIdeaCard";
import IntellCard from "../intell/IntellCard";

// MemoizedRotatingIdeaCard will only re-render when the ideaOBj or disabled changes
// NOTE: This was memoized due to the RotatingIdeaCard being re-renderd each time new text was entered
const MemoizedRotatingIdeaCard = memo(
  RotatingIdeaCard,
  (prevProps, nextProps) =>
    prevProps.ideaObj === nextProps.ideaObj && prevProps.disabled === nextProps.disabled
);

const References = ({
  confirmedUrls,
  setConfirmedUrls,
  referencedAgents,
  setReferencedAgents,
  referencedIdeas,
  setReferencedIdeas,
  addReference,
  setAddReference,
  disabled = false,
}) => {
  const handleSelectedWavemaker = (e) => {
    if (!e) return;
    if (debugLevel > 0) console.log("handleSelectedWavemaker", e);
    setReferencedAgents((prev) => {
      if (!prev.some((agent) => agent._id === e._id)) {
        return [...prev, e];
      }
      return prev; // Return unchanged if e._id already exists
    });
    setAddReference(0);
  };

  const handleDeleteWavemaker = (index) => {
    if (debugLevel > 0) console.log("handleDeleteWavemaker", index);
    setReferencedAgents((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSelectedIdea = (e) => {
    if (!e) return;
    if (debugLevel > 0) console.log("handleSelectedIdea", e);
    setReferencedIdeas((prev) => {
      if (!prev.some((idea) => idea._id === e._id)) {
        return [...prev, e];
      }
      return prev; // Return unchanged if e._id already exists
    });
    setAddReference(0);
  };

  const handleDeleteIdea = (index) => {
    if (debugLevel > 0) console.log("handleDeleteIdea", index);
    setReferencedIdeas((prev) => prev.filter((_, i) => i !== index));
  };

  const GRID_SIZES = { 1: 9, 2: 6, default: 12 };
  const getGridSize = (length) => GRID_SIZES[length] || GRID_SIZES.default;

  return (
    <>
      {addReference > 0 && (
        <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Container
            maxWidth="sm"
            sx={{
              height: "100%", // Ensure the container takes full height
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center content vertically
              alignItems: "center",
            }}
          >
            <Paper
              sx={{
                width: "100%",
                maxHeight: "90vh", // Prevent content from exceeding the viewport height
                overflow: "hidden", // Contain overflow within the Paper
                display: "flex",
                flexDirection: "column", // Ensure children stack vertically
                zIndex: (theme) => theme.zIndex.modal + 1,
                pl: 2,
                pr: 2,
                pt: 2,
                pb: 3,
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography gutterBottom variant="body2">
                    Add reference
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={addReference}
                    size="small"
                    sx={{
                      "& .MuiToggleButton-root": {
                        fontWeight: "bold", // Increases font weight
                        borderWidth: "2px", // Increases border weight
                        textTransform: "none", // Prevents automatic capitalization
                      },
                    }}
                  >
                    <ToggleButton value={1} onChange={() => setAddReference(1)}>
                      inTell
                    </ToggleButton>
                    <ToggleButton value={2} onChange={() => setAddReference(2)}>
                      waveMaker
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Button
                  onClick={() => setAddReference(0)}
                  color="primary"
                  variant="outlined"
                  circular
                  size="small"
                >
                  Cancel
                </Button>
              </Box>
              <Divider />

              {addReference === 1 && <IdeaSelect setSelectedIdea={handleSelectedIdea} />}

              {addReference === 2 && (
                <AgentSelect
                  setSelectedWavemaker={handleSelectedWavemaker}
                  promptMessage="Select waveMaker"
                />
              )}
            </Paper>
          </Container>
        </Backdrop>
      )}

      <Box sx={{ mb: 2 }}>
        <Grid container direction="row" spacing={1} alignItems="flex-end">
          {confirmedUrls.map((card, index) => (
            <Grid item key={card.url} xs={12} sm={6} md={4} lg={3} style={{ marginTop: "5px" }}>
              <MemoizedRotatingIdeaCard
                disabled={disabled}
                ideaObj={card}
                setIdeaObj={(e) => setConfirmedUrls(index, e)}
              />
            </Grid>
          ))}
          <Grid
            item
            xs
            md={confirmedUrls.length === 1 ? 8 : 12}
            lg={getGridSize(confirmedUrls.length)}
          >
            {referencedIdeas.map((e, index) => (
              <Box
                key={e._id}
                sx={{
                  m: 1,
                  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.23), 0px 8px 23px rgba(0, 0, 0, 0.21)",
                }}
              >
                <IntellCard view={1} intell={e} onDelete={() => handleDeleteIdea(index)} />
              </Box>
            ))}

            {referencedAgents.map((e, index) => (
              <Chip
                key={e._id}
                avatar={
                  e.image ? (
                    <Avatar
                      alt="waveMaker"
                      src={e.image}
                      sx={{
                        width: 50,
                        height: 50,
                      }}
                    />
                  ) : null
                }
                label={e.name}
                onDelete={() => handleDeleteWavemaker(index)}
                sx={{
                  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.23), 0px 8px 23px rgba(0, 0, 0, 0.21)",
                  border: "1px solid rgba(255, 255, 255, 0.3)",
                  m: 1,
                  backgroundColor: "#FFDBBB",
                  minHeight: "60px", // Ensure enough space for Avatar and label
                  paddingRight: "5px",
                  "& .MuiChip-avatar": {
                    width: 50,
                    height: 50,
                  },
                  "& .MuiChip-label": {
                    fontSize: "11px",
                    lineHeight: "1.3",
                    whiteSpace: "normal",
                  },
                }}
              />
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

References.propTypes = {
  confirmedUrls: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  setConfirmedUrls: PropTypes.func.isRequired,
  referencedAgents: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  setReferencedAgents: PropTypes.func.isRequired,
  referencedIdeas: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  setReferencedIdeas: PropTypes.func.isRequired,
  addReference: PropTypes.number.isRequired,
  setAddReference: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default References;
