import React, { useRef, useEffect } from "react";

const Visualizer = ({ audioStream, setLoudness }) => {
  const canvasRef = useRef(null);
  const analyserRef = useRef(null);

  useEffect(() => {
    if (!audioStream) return undefined;

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;

    const source = audioContext.createMediaStreamSource(audioStream);
    source.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      requestAnimationFrame(draw);

      analyser.getByteFrequencyData(dataArray);

      // Calculate the loudness as the average of frequency data
      const sum = dataArray.reduce((a, b) => a + b, 0);
      const average = sum / dataArray.length;
      setLoudness(Math.round(average)); // Set loudness value

      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 2.5;
      let barHeight;
      let x = 0;

      for (let i = 0; i < bufferLength; i += 1) {
        barHeight = dataArray[i] / 2;

        canvasContext.fillStyle = `rgb(${barHeight + 100},50,50)`;
        canvasContext.fillRect(x, canvas.height - barHeight / 2, barWidth, barHeight);

        x += barWidth + 1;
      }
    };

    draw();

    analyserRef.current = analyser;
    return () => {
      if (analyserRef.current) {
        analyser.disconnect(); // Always disconnect the analyser when the component unmounts
      }
    };
  }, [audioStream]);

  return <canvas ref={canvasRef} width="300" height="100" style={{ width: "100%" }} />;
};

export default Visualizer;
