import { LOADING_EXPERTISE, SEARCH_EXPERTISES, EXPERTISE_ERROR } from "../actions/types";

const initialState = {
  expertises: [],
  expertise: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_EXPERTISES:
      return {
        ...state,
        expertises: payload,
        loading: false,
      };
    case EXPERTISE_ERROR:
      return {
        ...state,
        expertises: [],
        expertise: null,
        error: payload,
        loading: false,
      };
    case LOADING_EXPERTISE:
      return {
        ...state,
        expertises: [],
        expertise: null,
        loading: true,
      };
    default:
      return state;
  }
}
