import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";
import isImage from "is-image";

// MUI
import { ExpandMore } from "@mui/icons-material";
import { Grid, Avatar, Divider, Accordion, AccordionSummary, Box } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import defaultAvatar from "assets/images/faces/placeholder.jpg";
import Typography from "../MaterialKit/MKTypography";
import FirstLine from "./FirstLine";

const MyParentLink = styled(Typography)(({ theme, view }) => ({
  fontSize: view < 2 ? "12px" : "14px",
  fontWeight: view < 2 ? 400 : 500,
  color: view < 2 ? theme.palette.text.secondary : theme.palette.text.primary,
  whiteSpace: "pre-line",
  lineHeight: 1.4,
  [theme.breakpoints.up("sm")]: {
    fontSize: view < 2 ? "13px" : "15px",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  boxShadow: `0 2px 8px ${alpha(theme.palette.common.black, 0.1)}`,
  border: `2px solid ${alpha(theme.palette.background.paper, 0.8)}`,
  transition: "all 0.2s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: `0 4px 10px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const StyledAccordion = styled(Accordion)(({ theme, ideaType }) => ({
  marginBottom: "16px",
  borderRadius: "16px",
  backgroundColor:
    ideaType === 10 || ideaType === 11
      ? alpha(theme.palette.info.light, 0.1)
      : alpha(theme.palette.background.paper, 0.6),
  backdropFilter: "blur(8px)",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.08)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  "&:hover": {
    boxShadow: `0 6px 16px ${alpha(theme.palette.common.black, 0.12)}`,
    backgroundColor:
      ideaType === 10 || ideaType === 11
        ? alpha(theme.palette.info.light, 0.15)
        : alpha(theme.palette.background.paper, 0.75),
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.main,
    transition: "all 0.3s ease",
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(1),
  borderRadius: "8px",
  overflow: "hidden",
  "& img": {
    maxWidth: "100%",
    borderRadius: "8px",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
}));

const Parent = ({ parentIdea, view, anonymous = false, expandParent, setExpandParent }) => {
  const isLargeScreen = useMediaQuery("(min-width:600px)");

  const renderImage = () =>
    parentIdea.filePaths &&
    parentIdea.filePaths.length > 0 &&
    isImage(parentIdea.filePaths[0].split("?")[0]) && (
      <ImageContainer>
        <img
          src={parentIdea.filePaths[0]}
          alt="Attached content"
          style={{
            maxWidth: isLargeScreen ? "150px" : "100%",
          }}
        />
      </ImageContainer>
    );

  return (
    <Grid container spacing={1}>
      {!anonymous && (
        <Grid item>
          <StyledAvatar src={parentIdea.user?.avatar || defaultAvatar} alt="User avatar" />
          <Divider orientation="vertical" />
        </Grid>
      )}

      <Grid item xs>
        <StyledAccordion
          expanded={expandParent}
          onChange={() => setExpandParent(!expandParent)}
          ideaType={parentIdea.ideaType}
        >
          <StyledAccordionSummary expandIcon={<ExpandMore />}>
            <Grid container spacing={2}>
              <Grid item xs>
                <div>
                  {expandParent && !(parentIdea.ideaType === 10 || parentIdea.ideaType === 11) && (
                    <FirstLine intell={parentIdea} anonymous={anonymous} showCreators />
                  )}
                  <MyParentLink
                    view={view}
                    sx={{
                      display: !expandParent ? "-webkit-box" : "block",
                      overflow: !expandParent ? "hidden" : "visible",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: !expandParent ? 1 : "none",
                    }}
                  >
                    {parentIdea.myPoint}
                  </MyParentLink>
                </div>
              </Grid>
              {expandParent && isLargeScreen && <Grid item>{renderImage()}</Grid>}
            </Grid>
            {expandParent && !isLargeScreen && renderImage()}
          </StyledAccordionSummary>
        </StyledAccordion>
      </Grid>
    </Grid>
  );
};

Parent.propTypes = {
  parentIdea: PropTypes.oneOfType([PropTypes.object]).isRequired,
  expandParent: PropTypes.bool.isRequired,
  setExpandParent: PropTypes.func.isRequired,
  view: PropTypes.number.isRequired,
  anonymous: PropTypes.bool,
};

export default Parent;
