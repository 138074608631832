import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import PropTypes from "prop-types";

const MyInterpretation = ({ xLevel, yLevel, size }) => {
  const svgRef = useRef(null);
  const [sizePixels] = useState(
    size === "xs" ? 20 : size === "sm" ? 30 : size === "md" ? 40 : size === "lg" ? 50 : 60
  );
  const [dotRadius] = useState(
    size === "xs" ? 2 : size === "sm" ? 3 : size === "md" ? 4 : size === "lg" ? 5 : 6
  );
  const [margin] = useState(
    size === "xs" ? 3 : size === "sm" ? 4 : size === "md" ? 7 : size === "lg" ? 9 : 11
  );

  useEffect(() => {
    const svgElement = d3.select(svgRef.current);
    svgElement.selectAll("*").remove(); // Clear svg content before adding new elements
    const svg = svgElement.attr("width", sizePixels).attr("height", sizePixels);

    // Y Axis
    //   const y = d3.scaleLinear().domain([-1.0, 1.0]).range([sizePixels-dotRadius, dotRadius/2]); // axis height
    const y = d3
      .scaleLinear()
      .domain([-1.0, 1.0])
      .range([sizePixels - margin, margin]);
    svg
      .append("g")
      .attr("transform", `translate(${sizePixels / 2},0)`)
      .call(d3.axisLeft(y).tickValues([]).tickSizeOuter(0));

    // X Axis
    //   const x = d3.scaleLinear().domain([-1.0, 1.0]).range([dotRadius/2, sizePixels-dotRadius]); // axis width
    const x = d3
      .scaleLinear()
      .domain([-1.0, 1.0])
      .range([margin, sizePixels - margin]); // axis width
    svg
      .append("g")
      .attr("transform", `translate(0, ${sizePixels / 2})`)
      .call(d3.axisBottom(x).tickValues([]).tickSizeOuter(0));

    // Dot (filled)
    const Xpx = ((xLevel + 1.5) / 3.0) * sizePixels;
    const Ypx = ((-yLevel + 1.5) / 3.0) * sizePixels;
    svg
      .append("circle")
      .attr("cx", Xpx)
      .attr("cy", Ypx)
      .attr("r", dotRadius)
      .attr("stroke-width", 1)
      .attr("stroke", "invisible")
      //    .style("fill", "black");
      .style("fill", "white");
    svg.append("g");
  }, []);

  return <svg ref={svgRef} />;
};

MyInterpretation.propTypes = {
  xLevel: PropTypes.number.isRequired,
  yLevel: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

export default MyInterpretation;
