import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import truncate from "../../utils/truncate";
import Spinner from "../layout/Spinner";
import SliderQuestion from "../slider/SliderQuestion";
import DialogMessage from "../dialog/DialogMessage";
import QuestionText from "../styles/QuestionText";
import ClickText from "../styles/ClickText";
import Submit from "./Submit";
import Expertise from "../expertise/Expertise";
import Transcribe from "../idea/Transcribe";
import exists from "../../utils/exists";
import { setAlert } from "../../actions/alert";
import { addIdea, modifyIdea } from "../../actions/idea";

const InterpretQuestion = ({
  intell,
  setIntell, // Update intell updated inTell
  updatedCount,
  setUpdatedCount,
  readOnly = false,
  addIdea,
  modifyIdea,
  idea,
  auth,
  fellowship: { fellowship },
}) => {
  //  console.log(intell);
  const [debugLevel] = useState(1);

  // Parallel does not exist for: const [dummyScale, setDummyScale] = useState(false); (as all framing statements can be interpreted by authors)

  // Whenever intell changes, check whether current user has interpreted it
  const [myInterpretationExists, setMyInterpretationExists] = useState(false);
  const [initComplete] = useState(false);
  const [originalSaved, setOriginalSaved] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    console.log(auth);
    console.log(intell);
    if (
      auth &&
      auth.user &&
      auth.user._id &&
      intell.scales &&
      intell.scales.find((e) => e.user._id === auth.user._id)
    ) {
      setMyInterpretationExists(true);
      console.log("This is mine!");
    } else {
      setMyInterpretationExists(false);
    }
  }, [intell]);

  // CONSTANTS
  const [requestExpertise] = useState(true);
  const [mandatoryMyPoint] = useState(fellowship.ftLink0 >= 0); // TODO -1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-after

  // DIALOGS
  const [goodFaithDialog, setGoodFaithDialog] = useState(false);
  const [intentDialog, setIntentDialog] = useState(false);

  // All items controllable by user
  const [supportTopic, setSupportTopic] = useState(-2); // Typically ranges -1->1 (-2 represents uninitialized)
  const [myPoint, setMyPoint] = useState("");
  const [myExpertise, setMyExpertise] = useState({
    myExpertiseLevel: 1, // 1=non-expert, 2/3/
    myExpertiseText: "",
    changedText: "",
    changedMind: false,
    steelMan: [""],
  });

  // TODO steelManOrig required
  const [supportTopicOrig, setSupportTopicOrig] = useState(-2);
  const [myPointOrig, setMyPointOrig] = useState("");
  const [myExpertiseLevelOrig, setMyExpertiseLevelOrig] = useState(1);
  const [myExpertiseTextOrig, setMyExpertiseTextOrig] = useState("");
  const [changedTextOrig, setChangedTextOrig] = useState("");
  const [changedMindOrig, setChangedMindOrig] = useState(false);

  const [displayMyPoint, setDisplayMyPoint] = useState(false);
  const [displayExpertise, setDisplayExpertise] = useState(false);
  const [displaySubmit, setDisplaySubmit] = useState(false);

  const [editsMade, setEditsMade] = useState(false);

  useEffect(() => {
    if (originalSaved) {
      if (
        supportTopicOrig === supportTopic &&
        myPoint === myPointOrig &&
        myExpertiseLevelOrig === myExpertise.myExpertiseLevel &&
        (myExpertise.myExpertiseLevel < 2 || myExpertiseTextOrig === myExpertise.myExpertiseText) &&
        (myExpertise.myExpertiseLevel < 2 || changedTextOrig === myExpertise.changedText) &&
        (myExpertise.myExpertiseLevel < 2 || changedMindOrig === myExpertise.changedMind)
      ) {
        setEditsMade(false);
      } else {
        setEditsMade(true);
      }
    }
  }, [
    supportTopic,
    myPoint,
    //  myExpertiseLevel,
    //  myExpertiseText,
    //  changedText,
    //  changedMind,
    //  steelMan,
    myExpertise, // TODO - is this sufficient?
    originalSaved,
  ]);

  useEffect(() => {
    if (initComplete) {
      setSupportTopicOrig(supportTopic);
      setMyPointOrig(myPoint);
      setMyExpertiseLevelOrig(myExpertise.myExpertiseLevel);
      setMyExpertiseTextOrig(myExpertise.myExpertiseText.trim());
      setChangedTextOrig(myExpertise.changedText.trim());
      setChangedMindOrig(myExpertise.changedMind);
      setOriginalSaved(true);
    }
  }, [initComplete]);

  const displayMyPointAndFieldsBelow = () => {
    // Display my point only if adding new interpretation
    if (!myInterpretationExists) {
      setDisplayMyPoint(true);
    }

    if (myPoint.trim().length > 0 || !mandatoryMyPoint || myInterpretationExists) {
      if (requestExpertise) {
        // console.log("Expertise is being requested");
        setDisplayExpertise(true);
      } else {
        // console.log("Expertise NOT being requested");
        setDisplayExpertise(false);
        setDisplaySubmit(!readOnly);
      }
    } else {
      // console.log("Waiting for <myPoint>");
      setDisplayExpertise(false);
      setDisplaySubmit(
        !mandatoryMyPoint || (mandatoryMyPoint && myPoint.trim().length > 0) ? !readOnly : false
      ); // Can submit only if myPoint not mandatory [if not readOnly]
    }
  };

  useEffect(() => {
    if (supportTopic > -2) {
      // <supportTopic> defined
      displayMyPointAndFieldsBelow();
    } else {
      // Waiting for <supportTopic>
      setDisplayMyPoint(false);
      setDisplayExpertise(false);
      setDisplaySubmit(false);
    }
  }, [
    supportTopic,
    myPoint,
    //  myExpertiseLevel,
    //  myExpertiseText,
    //  changedText,
    //  changedMind,
    //  steelMan,
    myExpertise, // TODO - is this sufficient?
  ]);

  //  useEffect(() => {
  // First time entering & not submitting
  //    if (intell && intell.scales && !submitting) {
  //      for (let i = 0; i < intell.scales.length; i += 1) {
  //        if (
  //          intell.scales[i].user &&
  //          intell.scales[i].user._id &&
  //          JSON.stringify(intell.scales[i].user._id) === JSON.stringify(auth.user._id)
  //        ) {
  //          setSupportTopic(intell.scales[i].supportTopic);
  //
  //          if (intell.scales[i].expertise) {
  //            setMyExpertiseLevel(
  //              intell.scales[i].expertise.myExpertiseLevel
  //               ? intell.scales[i].expertise.myExpertiseLevel
  //              : 1
  //            );
  //            if (
  //              intell.scales[i].expertise.myExpertiseText &&
  //              intell.scales[i].expertise.myExpertiseText.trim().length > 0
  //            ) {
  //              setMyExpertiseText(intell.scales[i].expertise.myExpertiseText.trim());
  //            }
  //            if (intell.scales[i].expertise.changedMind) {
  //              setChangedMind(intell.scales[i].expertise.changedMind);
  //            }
  //            if (
  //              intell.scales[i].expertise.changedText &&
  //              intell.scales[i].expertise.changedText.trim().length > 0
  //            ) {
  //              setChangedText(intell.scales[i].expertise.changedText.trim());
  //            }
  //          } else {
  //            setMyExpertiseLevel(
  //              intell.scales[i].myExpertiseLevel ? intell.scales[i].myExpertiseLevel : 1
  //            );
  //            if (
  //              intell.scales[i].myExpertiseText &&
  //              intell.scales[i].myExpertiseText.trim().length > 0
  //            ) {
  //              setMyExpertiseText(intell.scales[i].myExpertiseText.trim());
  //            }
  //            if (intell.scales[i].changedMind) {
  //              setChangedMind(intell.scales[i].changedMind);
  //            }
  //            if (intell.scales[i].changedText && intell.scales[i].changedText.trim().length > 0) {
  //              setChangedText(intell.scales[i].changedText.trim());
  //            }
  //          }
  //        }
  //      }
  //    }
  //    setInitComplete(true);
  //  }, [intell]);

  // Everytime there is any user interaction, notify parent by updating a counter
  //  useEffect(() => {
  //    if (!myInterpretationExists && myPoint.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [supportTopic]);

  //  useEffect(() => {
  //    if (myExpertiseText.trim().length === 0 && !changedMind && changedText.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [myExpertiseLevel]);

  //  useEffect(() => {
  //    if (!changedMind && changedText.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [myExpertiseText]);

  //  useEffect(() => {
  //    if (changedText.trim().length === 0) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [changedMind]);

  //  useEffect(() => {
  //    setUpdatedCount(updatedCount + 1);
  //  }, [changedText]);

  useEffect(() => {
    if (editsMade && myInterpretationExists) setUpdatedCount(updatedCount + 1);
  }, [editsMade]);

  //  useEffect(() => {
  //    if (
  //      myExpertiseLevel === 1 &&
  //     myExpertiseText.trim().length === 0 &&
  //      !changedMind &&
  //      changedText.trim().length === 0 &&
  //      !myInterpretationExists
  //    ) {
  //      setUpdatedCount(updatedCount + 1);
  //    }
  //  }, [myPoint]);

  const onSubmit = () => {
    const navigate = useNavigate();
    if (!(exists(fellowship) && exists(fellowship._id))) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }

    let bValid = true;
    if (myInterpretationExists && !editsMade) {
      setAlert("You can only submit if changes made", "danger");
      bValid = false;
    }

    if (bValid) {
      setSubmitting(true);

      if (!myInterpretationExists) {
        if (debugLevel > 0) console.log("addIdea");
        if (debugLevel > 0) console.log("supportTopic:", supportTopic);
        if (debugLevel > 0) console.log("myPoint:", myPoint);

        addIdea({
          fellowshipId: fellowship._id,
          parentIdeaId: intell._id,
          supportTopic,
          myExpertiseLevel: myExpertise.myExpertiseLevel > -2 ? myExpertise.myExpertiseLevel : null,
          myExpertiseText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength)
              : "",
          changedMind: myExpertise.changedMind,
          changedText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength)
              : "",
          steelMan:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength)
              : [""],
          myPoint,
          ideaType: 0,
        });
      } else {
        // Modify existing
        // modifyIdea() can only modify existing <expertise> props, and cannot replace one expertise object with another
        modifyIdea(intell._id, {
          supportTopic,
          myExpertiseLevel: myExpertise.myExpertiseLevel > -2 ? myExpertise.myExpertiseLevel : null,
          myExpertiseText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.myExpertiseText, fellowship.expertiseLinkMyTextLength)
              : "",
          changedMind: myExpertise.changedMind,
          changedText:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.changedText, fellowship.expertiseLinkChangedTextLength)
              : "",
          steelMan:
            myExpertise.myExpertiseLevel > 1
              ? truncate(myExpertise.steelMan, fellowship.expertiseLinkSteelManTextLength)
              : [""],
        });
      }
    }
  };

  // Exit conditions:
  // (1) If no initial user interpretation exists, simply finding one is sufficient
  // (2) If an initial user interpretation exists, has it changed?
  useEffect(() => {
    if (debugLevel > 0) console.log("useEffect idea.idea has changed", idea.idea);
    if (idea.idea && idea.idea.scales && submitting) {
      for (let i = 0; i < idea.idea.scales.length; i += 1) {
        if (idea.idea.scales[i].user && idea.idea.scales[i].user._id) {
          if (myInterpretationExists && idea.idea.scales[i].user._id === auth.user._id) {
            if (
              exists(idea.idea.scales[i].supportTopic) &&
              idea.idea.scales[i].supportTopic >= -1 && // Valid
              supportTopicOrig !== idea.idea.scales[i].supportTopic
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <supportTopic> changed from ",
                  supportTopicOrig,
                  " to ",
                  idea.idea.scales[i].supportTopic
                );
              setIntell(idea.idea);
              break;
            } else if (
              idea.idea.scales[i].myPoint &&
              idea.idea.scales[i].myPoint.length > 0 && // Valid
              myPointOrig !== idea.idea.scales[i].myPoint
            ) {
              if (debugLevel > 0)
                console.log(
                  "Idea changed: submission confirmation received from backend - <myPoint> changed from ",
                  myPointOrig,
                  " to ",
                  idea.idea.scales[i].myPoint
                );
              setIntell(idea.idea);
              break;
            } else if (idea.idea.scales[i].expertise) {
              if (
                exists(idea.idea.scales[i].expertise.myExpertiseLevel) &&
                idea.idea.scales[i].expertise.myExpertiseLevel >= 0 && // Valid
                myExpertiseLevelOrig !== idea.idea.scales[i].expertise.myExpertiseLevel
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <myExpertiseLevel> changed from ",
                    myExpertiseLevelOrig,
                    " to ",
                    idea.idea.scales[i].expertise.myExpertiseLevel
                  );
                setIntell(idea.idea);
                break;
              } else if (
                idea.idea.scales[i].expertise.myExpertiseText &&
                idea.idea.scales[i].expertise.myExpertiseText.length > 0 && // Valid
                myExpertiseTextOrig !== idea.idea.scales[i].expertise.myExpertiseText
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <myExpertiseText> changed from ",
                    myExpertiseTextOrig,
                    " to ",
                    idea.idea.scales[i].expertise.myExpertiseText
                  );
                setIntell(idea.idea);
                break;
              } else if (
                idea.idea.scales[i].expertise.changedText &&
                idea.idea.scales[i].expertise.changedText.length > 0 && // Valid
                changedTextOrig !== idea.idea.scales[i].expertise.changedText
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <changedText> changed from ",
                    changedTextOrig,
                    " to ",
                    idea.idea.scales[i].expertise.changedText
                  );
                setIntell(idea.idea);
                break;
              } else if (
                exists(idea.idea.scales[i].expertise.changedMind) &&
                changedMindOrig !== idea.idea.scales[i].expertise.changedMind
              ) {
                if (debugLevel > 0)
                  console.log(
                    "Idea changed: submission confirmation received from backend - <changedMind> changed from ",
                    changedMindOrig,
                    " to ",
                    idea.idea.scales[i].expertise.changedMind
                  );
                setIntell(idea.idea);
                break;
              }
            }
          } else if (!myInterpretationExists) {
            // At least one scale exists
            if (debugLevel > 0)
              console.log(
                "Idea changed: submission confirmation received from backend - 1st time scale created"
              );
            setIntell(idea.idea);
            break;
          }
        }
      }
    }
  }, [idea.idea]);

  return submitting ? (
    <Spinner />
  ) : (
    <div style={{ marginBottom: "30px" }}>
      {/* inTell supportTopic? */}
      <div style={{ marginTop: "40px", marginBottom: "10px", textAlign: "center" }}>
        <QuestionText large={supportTopic <= -2 ? 1 : 0}>
          Position the slider so that it reflects your position...
        </QuestionText>
        {supportTopic <= -2 && <ClickText>CLICK ON THE SCALE BELOW</ClickText>}
      </div>
      <SliderQuestion userInitLevel={supportTopic} updated={readOnly ? null : setSupportTopic} />

      {displayMyPoint && !myInterpretationExists && (
        <Transcribe
          disabled={readOnly}
          textLength={fellowship.ftLinkTextLength}
          requiredField={mandatoryMyPoint}
          message={myPoint}
          label="On this issue, what has shaped your view most?"
          setMessage={setMyPoint}
        />
      )}

      {displayExpertise && (
        <Expertise
          myExpertise={myExpertise}
          setMyExpertise={readOnly ? null : setMyExpertise}
          // allowSelectPrior={!myInterpretationExists}
          setExpertiseFieldsValid={!readOnly && setDisplaySubmit}
        />
      )}

      {displaySubmit && (
        <>
          {myInterpretationExists && editsMade && (
            <div style={{ marginTop: "30px" }}>
              <Submit buttonLabel="Submit changes" onSubmit={onSubmit} />
            </div>
          )}
          {!myInterpretationExists && (
            <div style={{ marginTop: "30px" }}>
              <Submit onSubmit={onSubmit} />
            </div>
          )}
        </>
      )}

      {goodFaithDialog && (
        <DialogMessage goodFaith handleDialogClose={() => setGoodFaithDialog(false)} />
      )}
      {intentDialog && <DialogMessage intent handleDialogClose={() => setIntentDialog(false)} />}
    </div>
  );
};

InterpretQuestion.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  updatedCount: PropTypes.number.isRequired,
  setUpdatedCount: PropTypes.func.isRequired,
  setIntell: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  auth: state.auth,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { addIdea, modifyIdea })(InterpretQuestion);
