const getBaseDomain = (url, includeProtocol = false) => {
  if (includeProtocol) {
    // Extract protocol and domain only
    const match = url.match(/^(https?:\/\/)?(www\.)?([^/]+)/);
    return match ? match[0] : url;
  }

  // Remove protocol (http, https, etc.) and 'www.'
  const strippedUrl = url.replace(/^https?:\/\//, "").replace(/^www\./, "");

  // Split the remaining URL by '/' to isolate the domain part
  const domain = strippedUrl.split("/")[0];

  // Split the domain by '.' to analyze its parts
  const parts = domain.split(".");

  // Handle known subdomains that should not be stripped
  const knownSubdomains = ["developer.chrome.com", "news.google.com"];
  if (knownSubdomains.includes(domain)) {
    return domain; // Return the full subdomain as the publisher
  }

  // Remove generic subdomains like 'www', 'ftp', etc.
  const genericSubdomains = ["www", "ftp", "mail", "blog", "api", "m"];
  if (genericSubdomains.includes(parts[0])) {
    parts.shift(); // Remove the first part if it's generic
  }

  // Return the last two parts as the base domain (e.g., "example.com")
  return parts.slice(-2).join(".");
};

export default getBaseDomain;
