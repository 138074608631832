import {
  IDEA_ERROR,
  ADD_IDEA,
  MODIFY_IDEA,
  GET_IDEA,
  GET_IDEAS,
  LOADING_IDEA,
} from "../actions/types";

const initialState = {
  ideas: [],
  idea: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  const updateIdea = (originalIdea, I, J, newObject) => {
    originalIdea.scales[I].children[J] = newObject;
    if (
      payload.parentScale &&
      payload.parentScale.expertise &&
      payload.parentScale.expertise._id &&
      originalIdea.scales[I].expertise &&
      originalIdea.scales[I].expertise._id &&
      payload.parentScale.expertise._id === originalIdea.scales[I].expertise._id
    ) {
      //    console.log("parent expertise match!");
      originalIdea.scales[I].expertise = payload.parentScale.expertise;
    }

    // If the returned-expertise-id (was possibly updated) matches existing child expertise, replace child expertise(s) as well
    if (originalIdea && originalIdea.scales) {
      for (let i = 0; i < originalIdea.scales.length; i += 1) {
        for (let j = 0; j < originalIdea.scales[i].children.length; j += 1) {
          if (
            payload.parentScale &&
            payload.parentScale.expertise &&
            payload.parentScale.expertise._id &&
            originalIdea.scales[i].children[j].expertise &&
            originalIdea.scales[i].children[j].expertise._id &&
            payload.parentScale.expertise._id === originalIdea.scales[i].children[j].expertise._id
          ) {
            //          console.log("child expertise match!");
            originalIdea.scales[i].children[j].expertise = payload.parentScale.expertise;
          }
        }
      }
    }
    return originalIdea;
  };

  const updateIdeaArray = (originalIdeas, i, newObject) => {
    originalIdeas[i] = newObject;
    return originalIdeas;
  };

  // console.log("type", type);

  switch (type) {
    case GET_IDEAS:
      return {
        ...state,
        ideas: payload,
        loading: false,
      };
    case MODIFY_IDEA:
      // If the returned idea exists as a child on the existing state.idea, replace the child
      if (state.idea && state.idea.scales) {
        for (let i = 0; i < state.idea.scales.length; i += 1) {
          for (let j = 0; j < state.idea.scales[i].children.length; j += 1) {
            if (state.idea.scales[i].children[j]._id === payload._id) {
              // console.log("Found id in state.idea children; replacing");
              return {
                ...state,
                idea: updateIdea(state.idea, i, j, payload),
                ideas: [],
                loading: false,
              };
            }
          }
        }
      }

      // Else if the returned idea exists as one the ideas is the existing array state.ideas, replace the idea
      if (state.ideas) {
        for (let i = 0; i < state.ideas.length; i += 1) {
          if (state.ideas[i]._id === payload._id) {
            console.log("Found id in state.ideas; replacing idea");
            return {
              ...state,
              idea: null,
              ideas: updateIdeaArray(state.ideas, i, payload),
              loading: false,
            };
          }
        }
      }

      // Else, the returned idea could not be found within the existing state.idea or state.ideas objects, replace state.idea
      // console.log("Did not found id in children. Replace all");
      return {
        ...state,
        idea: payload,
        ideas: [],
        loading: false,
      };
    case GET_IDEA:
    case ADD_IDEA:
      return {
        ...state,
        idea: payload,
        ideas: [],
        error: {},
        loading: false,
      };

    case IDEA_ERROR:
      return {
        ...state,
        idea: null,
        ideas: [],
        error: payload,
        loading: false,
      };
    case LOADING_IDEA:
      return {
        ...state,
        ideas: [],
        idea: null,
        loading: true,
      };
    default:
      return state;
  }
}
