import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDebounce } from "use-debounce";

// MUI
import {
  TextField,
  Box,
  Typography,
  Card,
  CircularProgress,
  Alert,
  InputAdornment,
  List,
  ListItem,
  Avatar,
} from "@mui/material";
import { Search, ErrorOutline } from "@mui/icons-material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import Button from "../MaterialKit/MKButton";
import AgentCreateDialog from "./AgentCreateDialog";
import { searchAgents, loadingAgent } from "../../actions/agent";

// Create a styled component for the glass effect
const GlassPanel = styled(Card)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "8px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(1.5),
  width: "100%",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
  "&:focus": {
    outline: `2px solid ${theme.palette.primary.main}`,
    boxShadow: `0 0 10px ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  flexWrap: "nowrap",
}));

/**
 * AgentSelect component for searching and selecting wavemakers
 *
 * @component
 */
const AgentSelect = ({
  setSelectedWavemaker, // user selected waveMaker object (null if cancel)
  handleCreatedWavemaker = null, // optional handler for created agents
  promptMessage = "Who? (person, group, organization)",
  clearFieldUponSelect = true,
  searchAgents,
  loadingAgent,
  agent: { agents, loading },
}) => {
  const theme = useTheme();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 500); // Debounce search input by 500ms
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [createNewAgent, setCreateNewAgent] = useState(false);
  const [error, setError] = useState(null);

  // Safely handle agents data from Redux
  const safeAgents = useMemo(() => (Array.isArray(agents) ? agents : []), [agents]);

  // Effect to fetch agents when component mounts
  useEffect(() => {
    try {
      searchAgents("", 0);
    } catch (err) {
      setError("Failed to load agents. Please try again.");
      console.error("Error loading agents:", err);
    }
  }, [searchAgents]);

  // Effect to fetch agents when debounced search text changes
  useEffect(() => {
    try {
      searchAgents(debouncedSearchText.replaceAll("\\", ""), 10, 200);
      setError(null);
    } catch (err) {
      setError("Search failed. Please try again.");
      console.error("Error during search:", err);
    }
  }, [debouncedSearchText, searchAgents]);

  // Input change handler
  const handleInputChange = useCallback((value) => {
    setSearchText(value || "");
  }, []);

  // Handle agent selection
  const handleOnSelect = useCallback(
    (e) => {
      if (e) {
        // User selected agent from list
        setSelectedWavemaker(e);
        if (clearFieldUponSelect) {
          setSearchText("");
        }
        loadingAgent(); // Flush the reducer on exit; set agent/agents to null
      }
    },
    [clearFieldUponSelect, loadingAgent, setSelectedWavemaker]
  );

  // Handle agent creation result
  const handleAgentCreated = useCallback(
    (e) => {
      if (e) {
        if (handleCreatedWavemaker) {
          // Use the direct handler if provided
          handleCreatedWavemaker(e);
        } else {
          // Fall back to normal selection handler
          setSelectedWavemaker(e);
        }
      }
      setCreateNewAgent(false);
      setSearchText("");
    },
    [handleCreatedWavemaker, setSelectedWavemaker]
  );

  // Keyboard navigation handler
  const handleKeyDown = useCallback(
    (e, agent) => {
      if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        handleOnSelect(agent);
      }
    },
    [handleOnSelect]
  );

  return (
    <Box role="region" aria-label="WaveMaker search and selection">
      <TextField
        type="text"
        onChange={(e) => handleInputChange(e.target.value)}
        value={searchText}
        fullWidth
        autoComplete="off"
        placeholder="Search for a waveMaker..."
        label={
          safeAgents.length > 0
            ? "Select from list below"
            : searchText.length === 0
              ? promptMessage
              : ""
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {loading ? <CircularProgress size={20} color="inherit" /> : <Search />}
            </InputAdornment>
          ),
          sx: {
            borderRadius: "12px",
            transition: "box-shadow 0.3s ease",
            "&:hover": {
              boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
            },
            "&.Mui-focused": {
              boxShadow: `0 4px 20px ${alpha(theme.palette.primary.main, 0.25)}`,
            },
          },
        }}
        inputRef={(input) => {
          if (input != null) {
            input.focus();
          }
        }}
        sx={{
          mt: 1,
          "& .MuiOutlinedInput-root": {
            borderRadius: "12px",
          },
        }}
        aria-label="Search for waveMakers"
      />

      {error && (
        <Alert
          severity="error"
          sx={{
            mb: 2,
            borderRadius: "12px",
            boxShadow: `0 4px 12px ${alpha(theme.palette.error.main, 0.15)}`,
          }}
          icon={<ErrorOutline fontSize="inherit" />}
        >
          {error}
        </Alert>
      )}

      {!loading && safeAgents.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
          {searchText.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setCreateNewAgent(true)}
              sx={{
                borderRadius: "28px",
                px: 4,
                py: 1.5,
                mt: 1,
                mb: 1,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.9)})`,
                boxShadow: `0 8px 20px -4px ${alpha(theme.palette.primary.main, 0.4)}`,
                "&:hover": {
                  boxShadow: `0 12px 24px -6px ${alpha(theme.palette.primary.main, 0.6)}`,
                  transform: "translateY(-2px)",
                },
                transition: "all 0.3s ease",
              }}
            >
              {`Create a new waveMaker: "${searchText}"`}
            </Button>
          )}
        </Box>
      ) : (
        <Box
          component="div"
          sx={{
            overflow: "auto",
            borderRadius: "12px",
            pt: 1,
            pl: 1,
            pr: 1,
            pb: 0,
            mb: 0.5,
            mt: 1,
            maxHeight: "400px",
            border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.05)}`,
            backdropFilter: "blur(10px)",
            background: alpha(theme.palette.background.paper, 0.7),
          }}
          role="listbox"
          aria-label="Available waveMakers"
          tabIndex={0}
        >
          <List disablePadding>
            {loading && safeAgents.length === 0
              ? // Loading skeleton
                ["skeleton-top", "skeleton-middle", "skeleton-bottom"].map((skeletonId) => (
                  <ListItem key={skeletonId} disablePadding sx={{ mb: 1.5 }}>
                    <Card
                      sx={{
                        p: 0,
                        width: "100%",
                        height: "60px",
                        opacity: 0.7,
                        background: "linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)",
                        backgroundSize: "200% 100%",
                        animation: "pulse 1.5s infinite",
                        "@keyframes pulse": {
                          "0%": { backgroundPosition: "0% 0%" },
                          "100%": { backgroundPosition: "-200% 0%" },
                        },
                        borderRadius: "8px",
                      }}
                    />
                  </ListItem>
                ))
              : // Actual content
                safeAgents.map((e) => (
                  <ListItem
                    key={e._id}
                    disablePadding
                    sx={{ mb: 1.5 }}
                    role="option"
                    aria-selected={hoveredItemId === e._id}
                  >
                    <GlassPanel
                      onClick={() => handleOnSelect(e)}
                      onKeyDown={(event) => handleKeyDown(event, e)}
                      onMouseEnter={() => setHoveredItemId(e._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      tabIndex={0}
                      sx={{
                        boxShadow:
                          hoveredItemId === e._id
                            ? `0 8px 24px ${alpha(theme.palette.primary.main, 0.2)}`
                            : `0 4px 12px ${alpha(theme.palette.common.black, 0.05)}`,
                        cursor: "pointer",
                        transform: hoveredItemId === e._id ? "translateY(-3px)" : "none",
                      }}
                    >
                      {e.image && (
                        <Avatar
                          src={e.image}
                          alt={e.name}
                          sx={{
                            mr: 2,
                            width: 36,
                            height: 36,
                            border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                          }}
                        >
                          {e.name?.charAt(0) || "?"}
                        </Avatar>
                      )}
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: { xs: "14px", sm: "16px" },
                          fontWeight: "medium",
                          flexShrink: 1,
                          minWidth: 0,
                        }}
                      >
                        {e.name}
                      </Typography>
                    </GlassPanel>
                  </ListItem>
                ))}
          </List>
        </Box>
      )}

      {createNewAgent && (
        <AgentCreateDialog
          aiOnInit
          originalDescription={searchText}
          handleDialogClose={handleAgentCreated}
        />
      )}
    </Box>
  );
};

AgentSelect.propTypes = {
  setSelectedWavemaker: PropTypes.func.isRequired,
  handleCreatedWavemaker: PropTypes.func,
  promptMessage: PropTypes.string,
  clearFieldUponSelect: PropTypes.bool,
  searchAgents: PropTypes.func.isRequired,
  loadingAgent: PropTypes.func.isRequired,
  agent: PropTypes.shape({
    agents: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          image: PropTypes.string,
        })
      ),
      PropTypes.array,
    ]),
    loading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  agent: state.agent,
});

export default connect(mapStateToProps, { searchAgents, loadingAgent })(AgentSelect);
