import ViewPdf from "../idea/ViewPdf";
import AudioPlayer from "./AudioPlayer";

const renderMedia = (filePath) => {
  if (!filePath) return null;

  const cleanedFilePath = filePath.split("?")[0];
  const extension = cleanedFilePath.split(".").pop().toLowerCase();
  const mediaRenderers = {
    image: (path) => <img src={path} alt="..." style={{ maxWidth: "100%", borderRadius: "4px" }} />,
    video: (path) => (
      <video width="600" controls>
        <source src={path} type="video/mp4" />
        <track kind="captions" srcLang="en" src="path/to/captions.vtt" label="English captions" />
        Your browser does not support the video tag.
      </video>
    ),
    audio: (path) => <AudioPlayer audioUrl={path} />,
    pdf: (path) => <ViewPdf url={path} maxWidth={250} />,
  };

  const extensionMapping = {
    jpg: "image",
    jpeg: "image",
    png: "image",
    gif: "image",
    bmp: "image",
    webp: "image",
    tiff: "image",
    mp4: "video",
    mkv: "video",
    avi: "video",
    mov: "video",
    wmv: "video",
    flv: "video",
    webm: "video",
    mp3: "audio",
    wav: "audio",
    aac: "audio",
    flac: "audio",
    ogg: "audio",
    wma: "audio",
    pdf: "pdf",
  };

  const mediaType = extensionMapping[extension];

  return mediaType ? mediaRenderers[mediaType](cleanedFilePath) : <p>Other</p>;
};

export default renderMedia;
