import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// MUI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Checkbox,
  Typography,
  Paper,
} from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

import { debugLevel } from "../../config/personalize";
import AgentSelect from "./AgentSelect";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const AgentSelectorDialog = ({
  open,
  onClose,
  onAgentSelected,
  promptMessage = "Add a person, group, organization",
  isMine = false,
  onIsMineChanged = () => {},
}) => {
  const theme = useTheme();
  const [isMyIntell, setIsMyIntell] = useState(isMine);
  const previousFocusRef = useRef(null);

  // Store the element that had focus before dialog opened
  React.useEffect(() => {
    if (open) {
      previousFocusRef.current = document.activeElement;
      setIsMyIntell(isMine);
    }
  }, [open, isMine]);

  // Define the focus restoration function first
  const restoreFocusAndClose = () => {
    // Return focus to the element that had focus before dialog opened
    if (previousFocusRef.current) {
      previousFocusRef.current.focus();
    }
    onClose();
  };

  const handleSelectedWavemaker = (agent) => {
    if (debugLevel > 0) console.log("handleSelectedWavemaker", agent);
    if (agent) {
      // If we have a valid agent, immediately return it and close dialog
      onAgentSelected(agent);
      onIsMineChanged(isMyIntell);
      restoreFocusAndClose();
    }
  };

  const handleCreatedWavemaker = (agent) => {
    if (agent) {
      // If we have a valid agent from creation, immediately return it and close dialog
      onAgentSelected(agent);
      onIsMineChanged(isMyIntell);
      restoreFocusAndClose();
    }
  };

  const handleIsMineChange = () => {
    const newValue = !isMyIntell;
    setIsMyIntell(newValue);
    onIsMineChanged(newValue);
    restoreFocusAndClose();
  };

  const handleCancel = () => {
    restoreFocusAndClose();
  };

  const handleExited = () => {
    if (previousFocusRef.current) {
      previousFocusRef.current.focus();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      // Ensure dialog takes focus on mount and manages focus trap properly
      disableRestoreFocus
      disableEnforceFocus={!open}
      // Correctly pass transition props to handle exit animations
      TransitionProps={{
        onExited: handleExited,
      }}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          boxShadow: `0 12px 40px -8px ${alpha(theme.palette.common.black, 0.2)}`,
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "1.25rem",
          fontWeight: 600,
          color: theme.palette.text.primary,
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          py: 2.5,
        }}
      >
        Add Author or Publisher
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3, pb: 5 }}>
        <GlassPanel elevation={0}>
          <AgentSelect
            setSelectedWavemaker={handleSelectedWavemaker}
            handleCreatedWavemaker={handleCreatedWavemaker}
            promptMessage={promptMessage}
            clearFieldUponSelect={false}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mt: 2,
              borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              pt: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "12px",
                  md: "13px",
                },
                fontWeight: 500,
              }}
            >
              I authored this inTell
            </Typography>
            <Checkbox
              checked={isMyIntell}
              onChange={handleIsMineChange}
              sx={{ ml: 1 }}
              size="small"
            />
          </Box>
        </GlassPanel>
      </DialogContent>
    </Dialog>
  );
};

AgentSelectorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAgentSelected: PropTypes.func.isRequired,
  promptMessage: PropTypes.string,
  isMine: PropTypes.bool,
  onIsMineChanged: PropTypes.func,
};

export default AgentSelectorDialog;
