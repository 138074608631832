/* eslint-disable import/prefer-default-export */

import axios from "axios";
import {
  CONFIRMATION_SUCCESS,
  CONFIRMATION_PASSWORD_ACK,
  CONFIRMATION_OLD,
  CONFIRMATION_FAIL_UNINVITED,
  CONFIRMATION_FAIL_RESENT,
  CONFIRMATION_FAIL_CHANGE,
  CONFIRMATION_UNKNOWN,
} from "./types";

// Check Email Confirmation
export const getConfirm = (userId, confirmationString) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/confirm/${userId}/${confirmationString}`);

    // Success
    if (res.data.includes("Email address confirmed")) {
      // Initial registration email address confirmed
      dispatch({ type: CONFIRMATION_SUCCESS });
    } else if (res.data.includes("acknowledged")) {
      // Change of password requested (user forgot password?). Email sent to user
      dispatch({ type: CONFIRMATION_PASSWORD_ACK });
    } else {
      // Unexexpected message
      dispatch({ type: CONFIRMATION_UNKNOWN });
    }
  } catch (err) {
    // Dispatch action so that message window can be displayed (do not display error message alert to user)
    if (err.response.data.includes("already been confirmed")) {
      dispatch({ type: CONFIRMATION_OLD });
    } else if (err.response.data.includes("Resent")) {
      dispatch({ type: CONFIRMATION_FAIL_RESENT });
    } else if (err.response.data.includes("Password change attempt failed")) {
      dispatch({ type: CONFIRMATION_FAIL_CHANGE });
    } else if (err.response.data.includes("not invited")) {
      dispatch({ type: CONFIRMATION_FAIL_UNINVITED });
    } else {
      dispatch({ type: CONFIRMATION_UNKNOWN });
    }
  }
};
