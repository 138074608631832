import React from "react";
import PropTypes from "prop-types";

// MUI
import { Container } from "@mui/material";
import { Home, KeyboardDoubleArrowUp, NavigateBefore, NavigateNext } from "@mui/icons-material";

import Button from "../MaterialKit/MKButton";
import MKBox from "../MaterialKit/MKBox";
import MKTypography from "../MaterialKit/MKTypography";

const NavbarPost = ({
  hrefUp = "",
  hrefHome = "",
  maxActivityCnt = 0,
  completed = 0,
  currentActivityIndex = 0,
  setCurrentActivityIndex = null,
}) => {
  return (
    <Container sx={{ p: 0, position: "sticky", top: 0, zIndex: 13 }}>
      <MKBox
        bgColor="white"
        shadow="xs"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {maxActivityCnt > 0 && (
          <>
            <Button
              iconOnly
              variant="text"
              size="large"
              color="primary"
              onClick={() => setCurrentActivityIndex(currentActivityIndex - 1)}
              style={{ marginRight: "-10px" }}
              disabled={currentActivityIndex <= 0}
            >
              <NavigateBefore />
            </Button>

            <MKBox display="flex" flexDirection="column" alignItems="center">
              <MKTypography sx={{ fontSize: "10px", fontWeight: "fontWeightMedium" }}>
                ACTIVITY
              </MKTypography>
              <MKTypography sx={{ fontSize: "8px" }}>
                {`(${currentActivityIndex + 1} of ${maxActivityCnt})`}
              </MKTypography>
            </MKBox>

            <Button
              iconOnly
              variant="text"
              size="large"
              color="primary"
              onClick={() => setCurrentActivityIndex(currentActivityIndex + 1)}
              style={{ marginLeft: "-10px" }}
              disabled={
                currentActivityIndex >= maxActivityCnt - 1 || currentActivityIndex > completed - 1
              }
            >
              <NavigateNext />
            </Button>
          </>
        )}

        {hrefHome && hrefHome.length > 0 && (
          <Button variant="text" size="large" color="primary" href={hrefHome}>
            <Home />
          </Button>
        )}
      </MKBox>

      {hrefUp && hrefUp.length > 0 && (
        <MKBox
          bgColor="white"
          shadow="xs"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          style={{ marginTop: "-50px" }}
        >
          <Button variant="text" size="large" color="primary" href={hrefUp}>
            <KeyboardDoubleArrowUp style={{ marginLeft: "-10px" }} />
          </Button>
        </MKBox>
      )}
    </Container>
  );
};

NavbarPost.propTypes = {
  hrefUp: PropTypes.string,
  hrefHome: PropTypes.string,
  maxActivityCnt: PropTypes.number,
  completed: PropTypes.number,
  currentActivityIndex: PropTypes.number,
  setCurrentActivityIndex: PropTypes.func,
};

export default NavbarPost;
