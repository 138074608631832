import React from "react";
import PropTypes from "prop-types";

// MUI
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Stack,
  Avatar,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTheme, styled, alpha } from "@mui/material/styles";

import Typography from "../MaterialKit/MKTypography";
import AgentSelect from "../agent/AgentSelect";

// Create a styled component for the glass effect
const GlassPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DefaultWaveMakers = ({ defaultWaveMakers, newWaveMaker, removeWaveMaker }) => {
  const theme = useTheme();

  return (
    <GlassPanel elevation={0}>
      <TableContainer>
        <Table sx={{ minWidth: 300 }} aria-label="default wavemakers table">
          {/* Table header */}
          <TableHead>
            <TableRow>
              <TableCell sx={{ pt: 1, pb: 1 }} colSpan={defaultWaveMakers.length === 0 ? 1 : 2}>
                <Stack flexDirection="row" alignItems="center">
                  <Typography sx={{ fontSize: "12px" }} fontWeight="bold">
                    Default waveMakers
                  </Typography>
                  <Chip
                    label={defaultWaveMakers.length}
                    color="secondary"
                    size="small"
                    sx={{ ml: 0.5, mt: -0.2 }}
                  />
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="agent-select-row">
              <TableCell sx={{ pt: 1, pb: 1 }} colSpan={defaultWaveMakers.length === 0 ? 1 : 2}>
                <AgentSelect
                  promptMessage={
                    defaultWaveMakers.length > 0
                      ? "Add another default waveMaker"
                      : "Add a default waveMaker"
                  }
                  setSelectedWavemaker={newWaveMaker}
                />
              </TableCell>
            </TableRow>

            {/* Render all waveMakers */}
            {defaultWaveMakers.map((member) => (
              <TableRow key={member._id}>
                <TableCell sx={{ pt: 1, pb: 1 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {member.image && (
                      <Avatar
                        src={member.image}
                        alt={member.name}
                        sx={{
                          width: 28,
                          height: 28,
                          border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                        }}
                      >
                        {member.name?.charAt(0) || "?"}
                      </Avatar>
                    )}
                    <Typography sx={{ fontSize: "12px" }}>{member.name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell align="right" sx={{ pt: 1, pb: 1 }}>
                  <IconButton
                    sx={{
                      mb: -1,
                      p: 0,
                      transition: "all 0.2s ease",
                      "&:hover": {
                        color: theme.palette.error.main,
                        transform: "translateY(-2px)",
                      },
                    }}
                    onClick={() => removeWaveMaker(member._id)}
                    aria-label={`Remove ${member.name}`}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </GlassPanel>
  );
};

// More specific PropTypes to avoid ESLint "object" and "array" forbidden warnings
DefaultWaveMakers.propTypes = {
  defaultWaveMakers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ).isRequired,
  newWaveMaker: PropTypes.func.isRequired,
  removeWaveMaker: PropTypes.func.isRequired,
};

export default DefaultWaveMakers;
