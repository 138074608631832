import React from "react";
import PropTypes from "prop-types";

// MUI
import { Radio, Grid, Divider } from "@mui/material";
import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const MyRadioMulti = ({
  options = [],
  icon = null,
  label = "",
  subLabel = [""],
  select,
  setSelect,
  indent = false,
  divider = "partial", // full, partial, or none
  children,
}) => {
  const renderSubLabel = () => (
    <Box sx={{ pt: label ? 0 : 0.5 }}>
      {subLabel.map((text, index) => (
        <Typography
          key={index} // eslint-disable-line
          sx={{
            fontSize: { xs: "11px", sm: "12px", md: "13px" },
            fontWeight: "100",
            color: setSelect ? "black" : "grey",
          }}
        >
          {text}
        </Typography>
      ))}
    </Box>
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" sx={{ ml: 1 }}>
        <Grid container columnSpacing={{ xs: icon || indent ? 1 : 0, sm: icon || indent ? 2 : 0 }}>
          {icon && (
            <Grid item sx={{ opacity: 0.7, mr: 2, ml: 2 }}>
              {icon}
            </Grid>
          )}
          <Grid item xs>
            {label.length > 0 && (
              <Typography
                sx={{
                  fontSize: { xs: "13px", sm: "15px", md: "17px" },
                  fontWeight: 500,
                  color: setSelect ? "black" : "grey",
                }}
              >
                {label}
              </Typography>
            )}
            {subLabel.length > 0 && (
              <>
                {renderSubLabel()}
                {children}
              </>
            )}
            <div style={{ textAlign: "left", marginTop: "5px" }}>
              {options.map(({ label, value }) => (
                <div
                  key={value}
                  style={{ marginBottom: "10px", display: "flex", alignItems: "flex-start" }}
                >
                  <Radio
                    checked={select === value}
                    onClick={() => setSelect(value)}
                    style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    disabled={!setSelect}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                      },
                      color: setSelect ? "black" : "grey",
                      paddingTop: {
                        xs: "3px",
                        sm: "2px",
                        md: "1px",
                      },
                    }}
                  >
                    {label}
                  </Typography>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Box>

      {/* Divider without any conditional margin adjustments */}
      {divider === "partial" && <Divider sx={{ mt: 2.5, ml: { xs: 8.5, sm: 9.5 } }} />}
      {divider === "full" && <Divider sx={{ mt: 2.5 }} />}
    </>
  );
};

MyRadioMulti.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  icon: PropTypes.node,
  select: PropTypes.number,
  setSelect: PropTypes.func,
  label: PropTypes.string,
  subLabel: PropTypes.arrayOf(PropTypes.string),
  indent: PropTypes.bool,
  divider: PropTypes.oneOf(["none", "partial", "full"]),
  children: PropTypes.node,
};

export default MyRadioMulti;
