import React from "react";
import PropTypes from "prop-types";

import DialogConfirm from "./DialogConfirm";

const DialogNoSave = ({
  handleDialogClose, // true (leave, discard changes); false (no not leave; continue editing
}) => {
  return (
    <DialogConfirm
      title="Leave without saving?"
      subTitles={["All changes will be discarded"]}
      confirmButtonLabel="Leave"
      otherButtonLabel="Keep editing"
      handleDialogClose={handleDialogClose}
    />
  );
};

DialogNoSave.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
};

export default DialogNoSave;
