import React from "react";
import PropTypes from "prop-types";

// MUI
import { Badge, Avatar, AvatarGroup } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import defaultAvatar from "../../assets/images/faces/placeholder.jpg";

import QuadBadge from "./QuadBadge";

// Enhanced styled components for modern look
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -10,
    left: 10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${alpha(theme.palette.background.paper, 0.8)}`,
  boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.2s ease",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  "& .MuiAvatar-root": {
    border: `2px solid ${alpha(theme.palette.background.paper, 0.8)}`,
    boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
    transition: "all 0.2s ease",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: `0 4px 12px ${alpha(theme.palette.common.black, 0.15)}`,
    },
  },
}));

const Author = ({ intell, anonymous = false, accountable = [] }) => {
  return (
    <div>
      {!anonymous ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={intell.badgeType ? <QuadBadge intell={intell} size={28} /> : null}
        >
          {accountable.length > 1 ? (
            <StyledAvatarGroup spacing="small">
              {accountable.map((author, index) => (
                <StyledAvatar
                  // eslint-disable-next-line
                  key={index}
                  alt={author.name || "Avatar"}
                  src={author.avatar || author.image || defaultAvatar}
                />
              ))}
            </StyledAvatarGroup>
          ) : (
            <StyledAvatar
              alt={intell.user.name || ""}
              src={
                (accountable.length > 0 && accountable[0].avatar) ||
                (accountable.length > 0 && accountable[0].image) ||
                intell.user.avatar ||
                defaultAvatar
              }
            />
          )}
        </StyledBadge>
      ) : (
        intell.badgeType && <QuadBadge intell={intell} size={45} />
      )}
    </div>
  );
};

Author.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  anonymous: PropTypes.bool,
  accountable: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

export default Author;
