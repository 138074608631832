import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { Security, Visibility } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import MySwitch from "../layout/MySwitch";
import NavbarTitle from "../navbar/NavbarTitle";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DialogPrivacy = ({
  handleBack,
  profileView,
  setProfileView,
  profileLinkable,
  setProfileLinkable,
}) => {
  return (
    <Dialog open fullScreen>
      <NavbarTitle paper title="Participant privacy" backClick={() => handleBack(false)} />
      <Container sx={{ mt: 4, mb: 3 }}>
        <ContentPanel elevation={0}>
          {/* profileView: -1:none, 0:just-user-name, 1:full-details(curretly N/A) */}
          <MySwitch
            icon={<Visibility />}
            label={profileView >= 0 ? "Public profile name visible" : "Public profile name hidden"}
            subLabel={
              profileView >= 0
                ? ["Show public profile name (often a nickname) of author on every post."]
                : ["Keep posts/authors completely anonymous."]
            }
            select={profileView >= 0}
            setSelect={(e) => setProfileView(e ? 0 : -1)}
            divider={profileView >= 0 ? "partial" : "none"}
          />

          {profileView >= 0 && (
            <MySwitch
              label={
                profileLinkable ? "Public profile names linked" : "Public profile names not linked"
              }
              subLabel={
                profileLinkable
                  ? [
                      "Public profile names clickable, and linked to a public profile pages.",
                      "Allows others to visit the public profile page of any author/participant.",
                    ]
                  : ["Not linked to public profile pages"]
              }
              icon={<Security />}
              select={profileLinkable}
              setSelect={setProfileLinkable}
              divider="none"
            />
          )}
        </ContentPanel>
      </Container>
    </Dialog>
  );
};

DialogPrivacy.propTypes = {
  handleBack: PropTypes.func.isRequired,
  profileView: PropTypes.number.isRequired,
  setProfileView: PropTypes.func.isRequired,
  profileLinkable: PropTypes.bool.isRequired,
  setProfileLinkable: PropTypes.func.isRequired,
};

export default DialogPrivacy;
