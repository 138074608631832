import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// MUI
import { Container, Dialog } from "@mui/material";

import { ADMIN, BETA } from "../../config/personalize";
import Spinner from "../layout/Spinner";
import NavbarTitle from "../navbar/NavbarTitle";
import EditType from "./EditType";
import EditName from "./EditName";
import DialogNoSave from "../dialog/DialogNoSave";
import { addFellowship, getFellowships } from "../../actions/fellowship";
import prepareActivityData from "../../utils/prepareActivityData";
import { ensureStringIds } from "../../utils/fellowshipAuthorization";

const DialogFellowshipAdd = ({
  handleDialogClose,
  addFellowship,
  getFellowships,
  fellowship: { fellowships, fellowship },
  auth,
}) => {
  const [confirmExit, setConfirmExit] = useState(false);
  const [fellowshipType, setFellowshipType] = useState(1);
  const [privateGroup, setPrivateGroup] = useState(true);
  const [sequential, setSequential] = useState(true);
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);

  const handleBackClick = () => {
    console.log("handleBackClick");
    if (nameChanged) {
      // Need to confirm exit without save
      setConfirmExit(true);
    } else {
      // No changes made; exit directly
      handleDialogClose(null);
    }
  };

  const [saving, setSaving] = useState(false);
  // const handleSaveClick = () => {
  //   console.log("handleSaveClick");
  //   handleDialogClose(true);
  // };

  const handleConfirmExit = (e) => {
    console.log("handleConfirmExit", e);
    if (e) {
      handleDialogClose(true);
    }
    setConfirmExit(false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    // Add check for unrestricted mode
    const isUnrestrictedMode = !BETA || BETA.length === 0;

    if (
      isUnrestrictedMode ||
      (auth && auth.user && (ADMIN.includes(auth.user.email) || BETA.includes(auth.user.email)))
    ) {
      // User can create a fellowship
    } else {
      // Do not have privileges to create a new fellowship
      console.log("Lack privileges to create a new fellowship");
      navigate("/fellowships");
    }
  }, [auth]);

  const onSubmit = async () => {
    if (auth && auth.user && auth.user.email && nameChanged) {
      setSaving(true);
      try {
        // Create the fellowship data object
        const fellowshipData = {
          name,
          fellowshipType,
          privateGroup,
          sequential,
          groupNumber: 1,
          allowed: [
            {
              email: auth.user.email,
              myGroup: ADMIN.includes(auth.user.email) ? -1 : 0,
            },
          ],
        };

        // First ensure all IDs are proper strings to avoid authorization issues
        const sanitizedData = ensureStringIds(fellowshipData);

        // Then use the utility function to prepare any activity data
        const preparedData = prepareActivityData(sanitizedData);

        // Add the auth token to the request
        const config = {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        };

        // Include the config with auth token in the request
        const response = await axios.post("/api/fellowships", preparedData, config);
        console.log("Fellowship created:", response.data);

        // Refresh the fellowships list after successful creation
        getFellowships();

        // Then close the dialog
        handleDialogClose(true);
      } catch (error) {
        console.error("Error creating fellowship:", error);
        // Add proper error handling here
        setSaving(false);
      }
    } else {
      // Invalid auth (should never get here)
      console.log("Should never get here");
      addFellowship({ name, fellowshipType, privateGroup, sequential });
    }
  };

  const handleName = (e) => {
    setName(e);
    if (e.length > 0) {
      if (
        fellowships &&
        fellowships.some((obj) => obj.name === e && !(fellowship && obj._id === fellowship._id))
      ) {
        setNameValid(false);
      } else {
        setNameValid(true);
      }
    } else {
      setNameValid(true);
    }

    if (e.length === 0) {
      setNameChanged(false);
    } else {
      setNameChanged(true);
    }
  };

  const handleFellowshipType = (e) => {
    setSequential(e === 1); // Set true only if LEARN
    setFellowshipType(e);
  };

  // Exit strategy to exit this component (Store initial idea when first entering this component)
  const [initID] = useState(fellowship ? fellowship._id : 0);
  useEffect(() => {
    if (fellowship) {
      if (fellowship._id !== initID) {
        handleDialogClose(true);
        //      console.log("Exit strategy: New fellowship created", fellowship._id);
        navigate(`/fellowships/${fellowship._id}`);
      }
    }
  }, [fellowship]);

  return saving ? (
    <Spinner />
  ) : (
    <>
      <Dialog open fullScreen>
        <NavbarTitle
          paper
          title="Add a new fellowship"
          backClick={handleBackClick}
          showSave={nameChanged && nameValid ? 1 : 0}
          saveClick={onSubmit}
        />
        <Container sx={{ mt: 5 }}>
          <EditType
            fellowshipType={fellowshipType}
            handleFellowshipType={handleFellowshipType}
            sequential={sequential}
            setSequential={setSequential}
            privateGroup={privateGroup}
            setPrivateGroup={setPrivateGroup}
          />

          <div style={{ marginTop: "30px" }}>
            <EditName
              name={name}
              handleName={handleName}
              nameValid={nameValid}
              inputLabelEmpty="Fellowship name"
            />
          </div>
        </Container>
      </Dialog>

      {confirmExit && <DialogNoSave handleDialogClose={handleConfirmExit} />}
    </>
  );
};

DialogFellowshipAdd.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addFellowship,
  getFellowships,
})(DialogFellowshipAdd);
