import React from "react";
import PropTypes from "prop-types";

// MUI
import { Container, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import Typography from "../MaterialKit/MKTypography";

const NavbarTitle = ({
  transparent = false,
  white = false,
  paper = false,
  title,
  backClick,
  showSave = -1, // -1:hide, 0:show-disabled, 1:showActive
  saveClick = null,
}) => {
  return (
    <Container sx={{ p: 0, position: "sticky", top: 0, zIndex: 13 }}>
      <Box bgColor="white" shadow="md" display="flex" justifyContent="space-between">
        <Grid container spacing={4}>
          <Grid item sx={{ ml: 2, mt: 1.5 }}>
            <ArrowBack sx={{ color: "grey" }} onClick={backClick} />
          </Grid>
          <Grid item xs sx={{ mt: 1.2 }}>
            <Typography
              variant="button"
              color={(transparent || paper) && !white ? "dark" : "white"}
              sx={{
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Button
          variant="text"
          size="large"
          color="primary"
          sx={{ mt: 0.4, opacity: showSave >= 0 ? 1 : 0 }}
          onClick={() => saveClick()}
          style={{ marginLeft: "-10px" }}
          disabled={showSave === 0}
        >
          Save
        </Button>
      </Box>
    </Container>
  );
};

NavbarTitle.propTypes = {
  transparent: PropTypes.bool,
  white: PropTypes.bool,
  paper: PropTypes.bool,
  title: PropTypes.string.isRequired,
  backClick: PropTypes.func.isRequired,
  showSave: PropTypes.number,
  saveClick: PropTypes.func,
};

export default NavbarTitle;
