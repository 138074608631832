import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// MUI
import { Stack, Paper, Fab, Box } from "@mui/material";
import { ChevronRight, ChevronLeft, PictureAsPdf } from "@mui/icons-material";

import Typography from "../MaterialKit/MKTypography";
import useScreenSize from "../util/useScreenSize";

const ViewPdf = ({ url, setLoaded = null, maxWidth = 950 }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
  ).toString();

  const screenSize = useScreenSize();
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfWidth, setPdfWidth] = useState(0);

  useEffect(() => {
    if (screenSize.width > maxWidth) {
      setPdfWidth(maxWidth - 10);
    } else {
      setPdfWidth(screenSize.width - 10);
    }
  }, [screenSize]);

  const onDocumentLoadSuccess = (e) => {
    if (setLoaded) {
      console.log("pdf loaded");
      setLoaded(true);
    }
    setNumPages(e.numPages);
    setPageNumber(1);
  };

  const onDocumentLoadError = () => {
    setNumPages(0);
    if (setLoaded) {
      console.log("pdf error");
      setLoaded(false);
    }
  };

  return (
    <div>
      <Stack
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        width={1.0}
      >
        {numPages > 1 && (
          <Fab
            size="small"
            sx={{
              color: "blue",
              mr: 2,
              mb: pdfWidth > 800 ? -10 : -pdfWidth / 80,
            }}
            disabled={pageNumber <= 1}
            onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : 1)}
          >
            <ChevronLeft sx={{ width: "20px", height: "20px" }} />
          </Fab>
        )}
        <PictureAsPdf
          sx={{
            width: "20px",
            height: "20px",
            mr: 1,
            mb: pdfWidth > 800 ? -10 : -pdfWidth / 80,
            zIndex: 2,
          }}
        />

        <Box sx={{ mb: pdfWidth > 800 ? -10 : -pdfWidth / 80, zIndex: 2 }}>
          <Typography style={{ fontSize: "14px" }}>
            {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </Typography>
        </Box>
        {numPages > 1 && (
          <Fab
            size="small"
            sx={{
              ml: 2,
              mb: pdfWidth > 800 ? -10 : -pdfWidth / 80,
              color: "blue",
            }}
            disabled={pageNumber >= numPages}
            onClick={() => setPageNumber(pageNumber < numPages ? pageNumber + 1 : numPages)}
          >
            <ChevronRight sx={{ width: "20px", height: "20px" }} />
          </Fab>
        )}
      </Stack>

      <Paper elevation={3} sx={{ mt: 1, mb: 1 }}>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          style={{ margin: "40px" }}
        >
          <Page width={pdfWidth} pageNumber={pageNumber} />
        </Document>
      </Paper>
    </div>
  );
};

ViewPdf.propTypes = {
  url: PropTypes.string.isRequired,
  maxWidth: PropTypes.number,
  setLoaded: PropTypes.func,
};

export default ViewPdf;
