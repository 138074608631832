import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// MUI
import { Accordion, AccordionSummary, AccordionDetails, Card } from "@mui/material";
import { Engineering, ExpandMore } from "@mui/icons-material";

import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import Box from "../MaterialKit/MKBox";
import truncate from "../../utils/truncate";
import DialogEditExpertise from "./DialogEditExpertise";
import TruncatedInput from "./TruncatedInput";
import UntruncatedInput from "./UntruncatedInput";

const formatBulletList = (arrString) => {
  if (arrString && arrString.length > 0 && arrString[0].length > 0) {
    return arrString
      .map((item, index) => (index === arrString.length - 1 ? `• ${item}` : `• ${item}\n`))
      .join("");
  }
  return "";
};

const ExpertiseContent = ({ steelMan, myExpertiseText, changedText, changedMind, fellowship }) => (
  <>
    {fellowship.expertiseLinkSteelManTextLength !== 0 && (
      <div>
        {fellowship.expertiseLinkSteelManTextLength > 160 ||
        fellowship.expertiseLinkSteelManTextLength < 0 ? (
          steelMan.map((text, index) =>
            text.trim() ? (
              <TruncatedInput
                key={index} // eslint-disable-line
                label={`#${index + 1} steel-man counter-argument`}
                value={truncate(text, fellowship.expertiseLinkSteelManTextLength)}
              />
            ) : null
          )
        ) : (
          <UntruncatedInput
            label="Steel-man counter-arguments..."
            value={formatBulletList(steelMan)}
          />
        )}
      </div>
    )}
    {fellowship.expertiseLinkMyTextLength !== 0 && myExpertiseText.trim() && (
      <div>
        {myExpertiseText.trim().length > 320 &&
        (fellowship.expertiseLinkMyTextLength > 160 || fellowship.expertiseLinkMyTextLength < 0) ? (
          <TruncatedInput
            label="My expertise was acquired..."
            value={truncate(myExpertiseText, fellowship.expertiseLinkMyTextLength)}
          />
        ) : (
          <UntruncatedInput label="My expertise was acquired..." value={myExpertiseText.trim()} />
        )}
      </div>
    )}
    {fellowship.expertiseLinkChangedTextLength !== 0 && changedText.trim() && (
      <div>
        {(changedText.trim().length > 320 && fellowship.expertiseLinkChangedTextLength > 160) ||
        fellowship.expertiseLinkChangedTextLength < 0 ? (
          <TruncatedInput
            label={
              changedMind ? "I changed my position when..." : "I would change my position if..."
            }
            value={truncate(changedText, fellowship.expertiseLinkChangedTextLength)}
          />
        ) : (
          <UntruncatedInput
            label={
              changedMind ? "I changed my position when..." : "I would change my position if..."
            }
            value={changedText.trim()}
          />
        )}
      </div>
    )}
  </>
);

const Expertise = ({
  myExpertise,
  setMyExpertise,
  setExpertiseFieldsValid,
  fellowship: { fellowship },
}) => {
  const { myExpertiseLevel, steelMan, myExpertiseText, changedText, changedMind } = myExpertise;
  const [editExpertiseDialog, setEditExpertiseDialog] = useState(
    setMyExpertise && (!myExpertiseLevel || myExpertiseLevel < 2)
  );

  // Validate expertise fields
  useEffect(() => {
    if (setExpertiseFieldsValid) {
      const fieldsValid =
        myExpertiseLevel > 1 &&
        (fellowship.expertiseLink === 0 ||
          (fellowship.expertiseLink > 0 &&
            (fellowship.expertiseLinkSteelManTextLength === 0 ||
              (fellowship.expertiseLinkSteelManTextLength > 0 && steelMan[0]?.trim().length > 0)) &&
            (fellowship.expertiseLinkMyTextLength === 0 ||
              (fellowship.expertiseLinkMyTextLength > 0 && myExpertiseText.trim().length > 0)) &&
            (fellowship.expertiseLinkChangedTextLength === 0 ||
              (fellowship.expertiseLinkChangedTextLength > 0 && changedText.trim().length > 0))));
      setExpertiseFieldsValid(fieldsValid);
    }
  }, [
    myExpertiseLevel,
    steelMan,
    myExpertiseText,
    changedText,
    fellowship,
    setExpertiseFieldsValid,
  ]);

  // Handle expertise updates
  const handleMyExpertise = (e) => {
    if (e && setMyExpertise) {
      const allFieldsValid =
        fellowship.expertiseLink === 0 || // fields optional
        ((fellowship.expertiseLinkSteelManTextLength === 0 || e.steelMan[0]?.trim().length > 0) &&
          (fellowship.expertiseLinkMyTextLength === 0 || e.myExpertiseText.trim().length > 0) &&
          (fellowship.expertiseLinkChangedTextLength === 0 || e.changedText.trim().length > 0));
      setMyExpertise(allFieldsValid ? e : { ...myExpertise, myExpertiseLevel: 1 });
    } else if (setMyExpertise) {
      setMyExpertise(myExpertise);
    }
    setEditExpertiseDialog(false);
  };

  return (
    <div>
      {fellowship.expertiseLink >= 0 && (
        <>
          {myExpertiseLevel >= 2 && (
            <div>
              {setMyExpertise ? (
                <Card sx={{ p: 1 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: -1 }}
                  >
                    <Typography sx={{ fontSize: "14px" }}>
                      <Engineering sx={{ width: "16px", height: "16px", ml: 1, mr: 1, mb: -0.3 }} />
                      {myExpertiseLevel >= 3 ? "Expert" : "Nuanced 14%"}
                    </Typography>
                    <Button
                      color="primary"
                      variant="text"
                      size="large"
                      onClick={() => setEditExpertiseDialog(true)}
                    >
                      Revise wisdom
                    </Button>
                  </Box>
                  <ExpertiseContent
                    steelMan={steelMan}
                    myExpertiseText={myExpertiseText}
                    changedText={changedText}
                    changedMind={changedMind}
                    fellowship={fellowship}
                  />
                </Card>
              ) : (
                <Accordion disableGutters sx={{ mb: 1 }}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography sx={{ fontSize: "14px" }}>
                      <Engineering sx={{ width: "16px", height: "16px", mr: 1, mb: -0.3 }} />
                      {myExpertiseLevel >= 3 ? "Expert" : "Nuanced 14%"}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ p: 1, mt: -2 }}>
                    <ExpertiseContent
                      steelMan={steelMan}
                      myExpertiseText={myExpertiseText}
                      changedText={changedText}
                      changedMind={changedMind}
                      fellowship={fellowship}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          )}

          {editExpertiseDialog && (
            <DialogEditExpertise myExpertise={myExpertise} setMyExpertise={handleMyExpertise} />
          )}
        </>
      )}
    </div>
  );
};

Expertise.propTypes = {
  myExpertise: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setMyExpertise: PropTypes.func,
  setExpertiseFieldsValid: PropTypes.func,
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
});

export default connect(mapStateToProps)(Expertise);
