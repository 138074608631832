import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { styled, alpha } from "@mui/material/styles";
import hero from "assets/images/hero.png";
import villain from "assets/images/villain.png";
import Typography from "../MaterialKit/MKTypography";
import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import getTimeAgo from "../../utils/getTimeAgo";
import Author from "./Author";

// Styled components for better aesthetics
const UserInfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const TimeStampText = styled(Typography)(({ theme }) => ({
  fontSize: "11px",
  color: alpha(theme.palette.text.secondary, 0.8),
  whiteSpace: "nowrap",
}));

const AgentButton = styled(Button)(({ theme, thegood }) => ({
  padding: "2px 6px",
  borderRadius: "12px",
  minWidth: "auto",
  backgroundColor:
    thegood >= 0 ? alpha(theme.palette.success.light, 0.1) : alpha(theme.palette.error.light, 0.1),
  "&:hover": {
    backgroundColor:
      thegood >= 0
        ? alpha(theme.palette.success.light, 0.2)
        : alpha(theme.palette.error.light, 0.2),
  },
}));

const AgentName = styled(Typography)(({ theme, thegood }) => ({
  fontSize: "11px",
  marginLeft: theme.spacing(0.5),
  color: thegood >= 0 ? theme.palette.success.main : theme.palette.error.main,
  fontWeight: 500,
}));

// Helper function to normalize agent objects
const normalizeAgentObject = (agent) => {
  if (!agent) return null;
  // If it's just an ID string, create a minimal object with that ID
  if (typeof agent === "string") {
    return { _id: agent, name: "Unknown Author" };
  }
  return agent;
};

const FirstLine = ({ intell, anonymous = false, showCreators = false }) => {
  const [accountable, setAccountable] = useState([]);

  useEffect(() => {
    if (intell.creators && intell.creators.length > 0) {
      // Normalize creators to ensure they're all objects
      const normalizedCreators = intell.creators.map(normalizeAgentObject);
      const completeList = normalizedCreators;

      if (intell.isMine) {
        completeList.push(intell.user);
      }
      setAccountable(completeList);
    }
  }, [intell]);

  const renderUserDetails = () => {
    const timeAgo = intell?.createdAt ? getTimeAgo(intell.createdAt) : "";
    const userName = intell?.user?.name;

    if (!anonymous && userName && userName.length > 0) {
      return `${userName} · ${timeAgo}`;
    }
    return timeAgo ? `${timeAgo} ago` : "";
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 1 }}
    >
      {!anonymous && showCreators && accountable.length > 0 && (
        <UserInfoContainer>
          <Author intell={intell} anonymous={anonymous} accountable={accountable} />
          <Box display="flex" flexDirection="column">
            {accountable.map((creator) => (
              <div key={creator._id}>
                {creator.name && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: (theme) => alpha(theme.palette.text.secondary, 0.9),
                      fontWeight: 500,
                    }}
                  >
                    {creator.name}
                  </Typography>
                )}
              </div>
            ))}
          </Box>
        </UserInfoContainer>
      )}

      <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
        {intell.associatedAgent?.name && (
          <AgentButton size="small" thegood={intell.theGood}>
            <img
              src={intell.theGood >= 0 ? hero : villain}
              style={{ width: "14px", height: "14px" }}
              alt="..."
            />
            <AgentName thegood={intell.theGood}>{intell.associatedAgent.name}</AgentName>
          </AgentButton>
        )}

        <TimeStampText sx={{ ml: intell.associatedAgent?.name ? 1 : 0 }}>
          {renderUserDetails()}
        </TimeStampText>
      </Box>
    </Box>
  );
};

FirstLine.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  anonymous: PropTypes.bool,
  showCreators: PropTypes.bool,
};

export default FirstLine;
