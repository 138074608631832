const truncate = (input, truncLength) => {
  if (Array.isArray(input)) {
    // Array of strings
    return input.map((str) =>
      typeof str === "string"
        ? truncLength >= 0
          ? str.trim().slice(0, truncLength)
          : str.trim()
        : str
    );
  }

  // Otherwise, assume input is a string
  return typeof input === "string"
    ? truncLength >= 0
      ? input.trim().slice(0, truncLength)
      : input.trim()
    : input;
};

export default truncate;
