import React from "react";
import PropTypes from "prop-types";

// MUI
import { Box, AppBar } from "@mui/material";

import Input from "../MaterialKit/MKInput";
import Submit from "../interpret/Submit";

const InputBottom = ({ label, helperText, value, setValue, maxNoteLength, onSubmit }) => {
  return (
    <AppBar position="fixed" color="white" sx={{ top: "auto", bottom: 0 }}>
      <Box
        onChange={(e) => setValue(e.target.value)}
        style={{ marginBottom: helperText.length > 0 ? "5px" : "0px" }}
      >
        <Input
          type="inputText"
          label={label}
          helperText={helperText}
          fullWidth
          multiline
          value={value}
          inputProps={{ maxLength: maxNoteLength > 0 ? maxNoteLength : 99999 }}
        />

        {value.length > 0 && (
          <div
            style={{
              marginBottom: helperText.length > 0 ? "5px" : "10px",
              marginTop: helperText.length > 0 ? "15px" : "20px",
            }}
          >
            <Submit size="small" onSubmit={onSubmit} />
          </div>
        )}
      </Box>
    </AppBar>
  );
};

InputBottom.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  maxNoteLength: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InputBottom;
