import { debugLevel } from "config/personalize";

export const getUrl = (description) => {
  if (debugLevel > 0) console.log("AI reference website request for:", description);
  return `What is the official or most widely recognized internet domain (URL) specifically associated with "${description}"? (Provide just the url in your answer -- no descriptive text). If no specific domain exists, respond simply with the word "unknown".`;
  // `What is the official or most widely recognized internet domain (URL) specifically associated with "${description}"? (Provide just the url in your answer -- no descriptive text) If "${description}" refers to a broad category of individuals (such as journalists, teachers, plumbers, etc.), or if no specific domain exists, respond simply with the word "unknown".`,
};

export const getLabelFromUrl = (urlField, initLabelField) => {
  if (debugLevel > 0)
    console.log("AI label request: urlField:", urlField, " & current label:", initLabelField);
  if (initLabelField.length > 0) {
    return `In 50 characters or less (preferably 25 characters or less), provide a concise and precise label (composed of the most commonly known and distinguishable name -- if there might be confusion, also include the single most notable/distinguishing accomplishment and jurisdiction if necessary) of the person or organization being referred to by the url: ${urlField} (current label: ${initLabelField})`;
  }
  return `In 50 characters or less (preferably 25 characters or less), provide a concise and precise label (composed of the most commonly known and distinguishable name -- if there might be confusion, also include the single most notable/distinguishing accomplishment and jurisdiction if necessary") of the person or organization being referred to by the url: ${urlField}`;
};

export const getLabelFromHint = (hint) => {
  if (debugLevel > 0) console.log("AI label request based on user hint:", hint);
  return `In 50 characters or less (preferably 25 characters or less), provide a concise and precise label (composed of the most commonly known and distinguishable name -- if there might be confusion, also include the single most notable/distinguishing accomplishment and jurisdiction if necessary") of the person or organization being referred to by: ${hint}`;
};
