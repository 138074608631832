import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMediaQuery } from "@react-hook/media-query";
import { useNavigate } from "react-router-dom";

// MUI
import { Grid } from "@mui/material";

import Input from "../MaterialKit/MKInput";
import Box from "../MaterialKit/MKBox";
import InputBottom from "./InputBottom";
import Submit from "../interpret/Submit";
import IntellCard from "../intell/IntellCard";
import Spinner from "../layout/Spinner";
import { setAlert } from "../../actions/alert";
import { addIdea } from "../../actions/idea";
import exists from "../../utils/exists";

const ChildrenView = ({
  ideaType,
  interpretations,
  intell,
  setIntell, // Update intell updated inTell
  label,
  helperText = "",
  addIdea,
  setAlert,
  idea,
  fellowship: { fellowship },
  auth,
}) => {
  const smSize = useMediaQuery("only screen and (min-width: 600px)");

  const [maxNoteLength] = useState(0); // if > 0, set limit
  const [myPoint, setMyPoint] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [myChildrenLength, setMyChildrenLength] = useState(0);

  useEffect(() => {
    for (let i = 0; i < intell.scales.length; i += 1) {
      if (intell.scales[i].user._id === auth.user._id) {
        if (intell.scales[i].children && intell.scales[i].children.length > 0) {
          setMyChildrenLength(intell.scales[i].children.length);
        }
        break;
      }
    }
  }, [intell]);

  const filterSupport = (arr) => {
    return arr.filter(
      (e) =>
        ((exists(e.supportTopic) && e.supportTopic >= 0) ||
          (e.parentScale &&
            exists(e.parentScale.supportTopic) &&
            e.parentScale.supportTopic >= 0) ||
          (exists(e.rational) && e.rational >= 0) ||
          (e.parentScale && exists(e.parentScale.rational) && e.parentScale.rational >= 0)) &&
        e.myPoint &&
        e.myPoint.trim().length > 0
    );
  };

  const filterOppose = (arr) => {
    return arr.filter(
      (e) =>
        ((exists(e.supportTopic) && e.supportTopic < 0) ||
          (e.parentScale && exists(e.parentScale.supportTopic) && e.parentScale.supportTopic < 0) ||
          (exists(e.rational) && e.rational < 0) ||
          (e.parentScale && exists(e.parentScale.rational) && e.parentScale.rational < 0)) &&
        e.myPoint &&
        e.myPoint.trim().length > 0
    );
  };

  // Each interpretation can have multiple children (each representing a inTell); extract all replies
  const extractAllReplies = (arr) => {
    const allReplies = [];
    arr.forEach((interpretationScale) => {
      if (interpretationScale.children && interpretationScale.children.length > 0) {
        interpretationScale.children.forEach((childIdea) => {
          if (ideaType === childIdea.ideaType) {
            // childIdea.ideaType !==10  && childIdea.ideaType !==11
            const newIdeaObj = childIdea;
            allReplies.push(newIdeaObj);
          }
        });
      } else if (interpretationScale.ideaType === ideaType) {
        allReplies.push(interpretationScale);
      }
    });
    return allReplies;
  };

  const onSubmit = () => {
    const navigate = useNavigate();
    if (!(exists(fellowship) && exists(fellowship._id))) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }

    let bValid = true;
    if (myPoint.trim().length === 0) {
      setAlert("Invalid reply text", "danger");
      bValid = false;
    }

    if (bValid) {
      setSubmitting(true);
      console.log("addIdea", myPoint);
      addIdea({
        fellowshipId: fellowship._id,
        parentIdeaId: intell._id,
        ideaType,
        myPoint,
      });
      setMyPoint("");
    }
  };

  // Exit conditions: An initial user interpretation exists, has it changed?
  useEffect(() => {
    //  console.log("useEffect idea.idea has changed", idea.idea);
    if (idea.idea && idea.idea.scales && submitting) {
      for (let i = 0; i < idea.idea.scales.length; i += 1) {
        if (idea.idea.scales[i].user && idea.idea.scales[i].user._id) {
          if (
            idea.idea.scales[i].user._id === auth.user._id &&
            idea.idea.scales[i].children &&
            idea.idea.scales[i].children.length > myChildrenLength
          ) {
            console.log(
              "Idea changed: submission confirmation received from backend - children length increased"
            );
            setSubmitting(false);
            setIntell(idea.idea);
          }
        }
      }
    }
  }, [idea.idea]);

  return submitting ? (
    <Spinner />
  ) : (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      {smSize && (
        <>
          <Box
            onChange={(e) => setMyPoint(e.target.value)}
            style={{ marginBottom: helperText.length > 0 ? "30px" : "20px" }}
          >
            <Input
              type="inputText"
              label={label}
              fullWidth
              multiline
              value={myPoint}
              helperText={helperText}
              inputProps={{ maxLength: maxNoteLength > 0 ? maxNoteLength : 99999 }}
            />

            {myPoint.trim().length > 0 && (
              <div style={{ marginTop: "30px" }}>
                <Submit onSubmit={onSubmit} />
              </div>
            )}
          </Box>

          {myPoint.trim().length < 1 && (
            <Grid container columnSpacing={{ xs: 1, sm: 2 }}>
              <Grid item xs>
                {filterOppose(extractAllReplies(interpretations)).map((e) => (
                  <div key={Math.random()}>
                    <IntellCard intell={e} />
                  </div>
                ))}
              </Grid>
              <Grid item xs>
                {filterSupport(extractAllReplies(interpretations)).map((e) => (
                  <div key={Math.random()}>
                    <IntellCard intell={e} />
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
      {!smSize && (
        <>
          {myPoint.trim().length < 1 && (
            <div style={{ marginTop: "40px", marginBottom: "75px" }}>
              {extractAllReplies(interpretations).map((e) => (
                <div key={Math.random()}>
                  <IntellCard intell={e} />
                </div>
              ))}
            </div>
          )}
          <InputBottom
            label={label}
            fullWidth
            multiline
            value={myPoint}
            helperText={helperText}
            setValue={setMyPoint}
            maxNoteLength={maxNoteLength}
            onSubmit={onSubmit}
          />
        </>
      )}
    </div>
  );
};

ChildrenView.propTypes = {
  ideaType: PropTypes.number.isRequired,
  interpretations: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setIntell: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  auth: state.auth,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { addIdea, setAlert })(ChildrenView);
