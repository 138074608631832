import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI
import { Card, CardMedia, Divider } from "@mui/material";

import Input from "../MaterialKit/MKInput";
import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import SelectImage from "../media/SelectImage";
import NewsMakers from "./NewsMakers";
import exists from "../../utils/exists";
import isBaseDomain from "../../utils/isBaseDomain";
import getBaseDomain from "../../utils/getBaseDomain";
import { searchAgents, loadingAgent } from "../../actions/agent";
import { getIdeas, loadingIdea } from "../../actions/idea";

// RotatingIdeaCard in populated:
// - scrape specified url (if does not exists, return validUrl: false)
// - if available, the scraped title is used as the default title (but this is editable)
// - if available, the first scraped image is used as the default initial image (but image is selectable - No AI-generated images allowed here)
// - if a root url address is available, assume this to be a publisher (a waveMaker) - component <NewsMakers> is in charge
//   - if the waveMaker exist in the db, display as standard Chip
//   - if the waveMaker does not exist in the db, display as an editable AgentChip (which will prompt more url scraping and an AI call)
//   - allow other authors/publishers to be added
// Once all initial url scraping and AI calls are complete, this component notifies parent
const RotatingIdeaCard = ({
  disabled = false,
  ideaObj,
  setIdeaObj,
  searchAgents,
  loadingAgent,
  getIdeas,
  loadingIdea,
  agent: { loading: agentLoading, agents },
  idea: { loading: ideaLoading, ideas },
  fellowship: { fellowship },
}) => {
  // Store a reference to the current creators array to preserve it
  const creatorsRef = useRef(ideaObj.creators || []);

  const [interceptedIdea, setInterceptedIdea] = useState(ideaObj);
  const { finalUrl } = interceptedIdea;
  const [rotate, setRotate] = useState(false);
  const [requestingAgentDB, setRequestingAgentDB] = useState(false);
  const [requestingIdeaDB, setRequestingIdeaDB] = useState(0); // 0:done, 1:initial check of url, 2:finalUrl changed
  const frontRef = useRef(null);
  const backRef = useRef(null);

  // Whenever creators change in interceptedIdea, update our reference
  useEffect(() => {
    if (interceptedIdea.creators && interceptedIdea.creators.length > 0) {
      creatorsRef.current = [...interceptedIdea.creators];
    }
  }, [interceptedIdea.creators]);

  // Safe setter for interceptedIdea that preserves creators
  const safeSetInterceptedIdea = useCallback((newState) => {
    if ((!newState.creators || newState.creators.length === 0) && creatorsRef.current.length > 0) {
      newState.creators = [...creatorsRef.current];
    }
    setInterceptedIdea(newState);
  }, []);

  // Safe updater for parent
  const safeUpdateParent = useCallback(
    (newState) => {
      if (
        (!newState.creators || newState.creators.length === 0) &&
        creatorsRef.current.length > 0
      ) {
        newState.creators = [...creatorsRef.current];
      }
      setIdeaObj(newState);
    },
    [setIdeaObj]
  );

  // Keep interceptedIdea in sync with parent ideaObj when it changes externally
  useEffect(() => {
    if (JSON.stringify(ideaObj) !== JSON.stringify(interceptedIdea)) {
      setInterceptedIdea(ideaObj);
    }
  }, [ideaObj]);

  const normalize = (url) => {
    return url
      .toLowerCase()
      .replace(/^https?:\/\//, "")
      .replace(/^www\./, "")
      .replace(/\/$/, "");
  };

  const orderQuery = (url) => {
    const [baseUrl, queryString] = url.split("?"); // Separate base URL and query string

    if (!queryString) return baseUrl; // Return if no query string

    const sortedQuery = queryString
      .split("&")
      .map((param) => param.split("="))
      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB)) // Sort by keys
      .map(([key, value]) => `${key}=${value || ""}`) // Reconstruct params
      .join("&");

    return `${baseUrl}?${sortedQuery}`;
  };

  // Used as part of the idea searching logic; this code triggered with the idea search request returns from the back-end
  useEffect(() => {
    if (!ideaLoading && requestingIdeaDB > 0) {
      let foundIndex = -1;
      for (let i = 0; i < ideas.length; i += 1) {
        if (
          (requestingIdeaDB === 1 &&
            normalize(orderQuery(interceptedIdea.url)) === normalize(ideas[i].url)) ||
          (requestingIdeaDB === 2 &&
            normalize(orderQuery(interceptedIdea.finalUrl)) === normalize(ideas[i].url))
        ) {
          foundIndex = i;
          break;
        }
      }

      if (foundIndex < 0) {
        setInterceptedIdea({ ...interceptedIdea, inDB: false });
      } else {
        const updatedIdea = {
          ...ideas[foundIndex],
          inDB: true,
          validUrl: true,
        };
        setInterceptedIdea(updatedIdea);
        setIdeaObj(updatedIdea);
      }
      setRequestingIdeaDB(0); // finished requesting
    }
  }, [ideaLoading, requestingIdeaDB]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!(exists(fellowship) && exists(fellowship._id))) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }
    loadingIdea();
    if (exists(finalUrl) && finalUrl.length > 0) {
      getIdeas(fellowship._id, 101, normalize(orderQuery(interceptedIdea.finalUrl))); // 101: search urls only - partial match ok
      setRequestingIdeaDB(2);
    } else {
      getIdeas(fellowship._id, 101, normalize(orderQuery(interceptedIdea.url))); // 101: search urls only - partial match ok
      setRequestingIdeaDB(1);
    }
  }, [finalUrl]);

  // Used as part of the agent searching logic; this code triggered with the agent search request returns from the back-end
  useEffect(() => {
    if (!agentLoading && requestingAgentDB) {
      setRequestingAgentDB(false);

      if (agents.length > 0) {
        const existingCreators = creatorsRef.current.length > 0 ? [...creatorsRef.current] : [];

        // Check if this agent is already in the creators list
        const agentExists = existingCreators.some((creator) => creator._id === agents[0]._id);

        // Only add the agent if it doesn't already exist
        if (!agentExists) {
          existingCreators.push(agents[0]);
        }

        const updatedIdea = {
          ...interceptedIdea,
          creators: existingCreators,
        };

        safeSetInterceptedIdea(updatedIdea);
        safeUpdateParent(updatedIdea);
      } else {
        setInterceptedIdea({
          ...interceptedIdea,
          defaultAgent: { url: getBaseDomain(interceptedIdea.url, true) },
        });
        // Do not yet setIdea() -- we need to scrape url/make AI request for the defaultAgent
      }
    }
  }, [agentLoading, requestingAgentDB]);

  // When rotating the card, preserve the state
  const handleRotate = (shouldRotate) => {
    if (shouldRotate) {
      safeUpdateParent(interceptedIdea);
    }
    setRotate(shouldRotate);
  };

  const handleSelectImageReturnedIdea = (e) => {
    if (exists(e.validUrl) && !e.validUrl) {
      // Invalid url -- card will be closed
      const updatedIdea = { ...interceptedIdea, validUrl: false };
      setInterceptedIdea(updatedIdea);
      setIdeaObj(updatedIdea);
    } else {
      if (exists(e.image)) {
        // Rotate back to initial view if selectedImage exists
        setRotate(false);
      }

      // Create an updated idea object that preserves ALL existing properties
      const updatedIdea = {
        ...interceptedIdea,
        creators: [...creatorsRef.current],
        selectedImage: exists(e.image) ? e.image : interceptedIdea.selectedImage || "",
        title:
          exists(interceptedIdea.title) && interceptedIdea.title.length > 0
            ? interceptedIdea.title
            : e.title && e.title.length > 0
              ? e.title.substring(0, 100)
              : "",
        validUrl: true,
        finalUrl: exists(e.finalUrl) ? e.finalUrl : interceptedIdea.finalUrl,
      };

      safeSetInterceptedIdea(updatedIdea);

      // If this is the first time (creating defaultAgent)
      if (!exists(interceptedIdea.image) && !isBaseDomain(interceptedIdea.url)) {
        if (requestingAgentDB) {
          // We're already requesting agent data, no need to request again
        } else if (typeof interceptedIdea.defaultAgent === "undefined") {
          // Will only create defaultAgent if it has not yet been defined
          setRequestingAgentDB(true);
          loadingAgent();
          searchAgents(getBaseDomain(interceptedIdea.url), 1, 100);
          // We still update the parent for other changes
          safeUpdateParent(updatedIdea);
        } else {
          safeUpdateParent(updatedIdea);
        }
      } else {
        // Not first time, update both state and parent
        safeUpdateParent(updatedIdea);
      }
    }
  };

  // Called whenever a newsMaker is added, deleted, modified, etc.
  const handleNewsMakersReturnedIdea = (newIdea) => {
    // Keep full objects for the UI display
    if (newIdea.creators && newIdea.creators.length > 0) {
      creatorsRef.current = [...newIdea.creators];
    }

    const updatedIdea = {
      ...interceptedIdea,
      defaultAgent: newIdea.defaultAgent,
      creators: newIdea.creators,
      isMine: newIdea.isMine,
    };

    safeSetInterceptedIdea(updatedIdea);
    safeUpdateParent(updatedIdea);
  };

  return (
    <div>
      {interceptedIdea &&
        interceptedIdea.url &&
        interceptedIdea.url.length > 0 &&
        exists(interceptedIdea.inDB) && (
          <Box sx={{ perspective: "1000px", position: "relative" }}>
            <Card
              sx={{
                transform: rotate ? "rotateY(180deg)" : "rotateY(0)",
                transformStyle: "preserve-3d",
                transition: "transform 0.8s cubic-bezier(0.34, 1.45, 0.7, 1)",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.23), 0px 8px 23px rgba(0, 0, 0, 0.21)",
                borderRadius: "0 0 15px 15px",
              }}
            >
              {/* Front of card */}
              {interceptedIdea.validUrl && (
                <Box
                  ref={frontRef}
                  sx={{
                    backfaceVisibility: "hidden",
                    position: rotate ? "absolute" : "relative",
                    width: "100%",
                    display: rotate ? "none" : "flex", // Only display when visible
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flexGrow: 1,
                    }}
                  >
                    {interceptedIdea.selectedImage && (
                      <CardMedia
                        component="img"
                        sx={{
                          mt: 0,
                          width: "100%",
                          borderRadius: "0px",
                        }}
                        image={interceptedIdea.selectedImage}
                        alt="Image"
                      />
                    )}
                    {!interceptedIdea.inDB && (
                      <Button
                        onClick={() => handleRotate(true)}
                        variant="text"
                        color="primary"
                        disabled={disabled}
                      >
                        {interceptedIdea.selectedImage
                          ? "Select alternative image"
                          : "Add profile image"}
                      </Button>
                    )}
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: "grey.500",
                      textAlign: "center",
                      mt: 0,
                      ml: 2,
                      mr: 2,
                      mb: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {interceptedIdea.finalUrl || interceptedIdea.url || ""}
                  </Typography>

                  <Box sx={{ mt: 1, ml: 1, mr: 1 }}>
                    <Input
                      disabled={disabled || (exists(interceptedIdea.inDB) && interceptedIdea.inDB)}
                      type="requestWhat"
                      label="inTell Title"
                      multiline
                      fullWidth
                      value={interceptedIdea.title || ""}
                      onChange={(e) =>
                        setInterceptedIdea({
                          ...interceptedIdea,
                          title: e.target.value.substring(0, 100),
                        })
                      }
                      helperText={
                        disabled || (exists(interceptedIdea.inDB) && interceptedIdea.inDB)
                          ? ""
                          : interceptedIdea.title.length === 100
                            ? "Refine (100 characters max)"
                            : "Refine as necessary"
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderWidth: "2px",
                            borderColor: "secondary.main",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "primary.main",
                          },
                          "& .MuiInputBase-input": {
                            fontSize: "12px",
                            lineHeight: "1.3",
                          },
                        },
                      }}
                    />
                  </Box>

                  {interceptedIdea.validUrl && (
                    <>
                      <Divider sx={{ ml: 2, mr: 2 }} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column", // Stack NewsMakers vertically
                          alignItems: "center", // Center children vertically (if needed)
                          width: "100%",
                          maxWidth: "600px", // Limit the width of NewsMakers
                          margin: "0 auto", // Ensure it's horizontally centered in the parent container
                        }}
                      >
                        <NewsMakers
                          disabled={disabled}
                          defaultAlreadyInDB={exists(interceptedIdea.inDB) && interceptedIdea.inDB}
                          idea={interceptedIdea}
                          setIdea={handleNewsMakersReturnedIdea}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              )}

              {/* Back of card */}
              <Box
                ref={backRef}
                sx={{
                  backfaceVisibility: "hidden",
                  transform: "rotateY(180deg)",
                  position: !rotate ? "absolute" : "relative",
                  width: "100%",
                  display: !rotate ? "none" : "flex", // Only display when visible
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 1,
                }}
              >
                <Typography sx={{ fontSize: "11px", fontWeight: 100, textAlign: "center", mb: 1 }}>
                  {interceptedIdea.title || ""}
                </Typography>

                <SelectImage
                  cardMode
                  url={interceptedIdea.url}
                  initialImage={interceptedIdea.selectedImage || ""}
                  description={interceptedIdea.title || ""}
                  updateUrlDetails={handleSelectImageReturnedIdea}
                />
              </Box>
            </Card>
          </Box>
        )}
    </div>
  );
};

RotatingIdeaCard.propTypes = {
  disabled: PropTypes.bool,
  ideaObj: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setIdeaObj: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  agent: state.agent,
  idea: state.idea,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, { searchAgents, loadingAgent, getIdeas, loadingIdea })(
  RotatingIdeaCard
);
