import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

// MUI
import { Container, Stack, Paper, Alert } from "@mui/material";
import { FormatListNumbered, Groups, Settings } from "@mui/icons-material";

import { ADMIN } from "../../config/personalize";
import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import Spinner from "../layout/Spinner";
import DialogType from "./DialogType";
import DialogFraming from "./DialogFraming";
import DialogIntell from "./DialogIntell";
import DialogWaveMakers from "./DialogWaveMakers";
import DialogDig from "./DialogDig";
import DialogPrivacy from "./DialogPrivacy";
import DialogConfirm from "../dialog/DialogConfirm";
import Members from "../member/Members";
import ActivityList from "../activity/ActivityList";
import NavbarTop from "../navbar/NavbarTop";
import exists from "../../utils/exists";
import FellowshipOptions from "./FellowshipOptions"; // Imported new component

import { getFellowship, modifyFellowship, deleteFellowship } from "../../actions/fellowship";

const EditFellowship = ({
  getFellowship,
  modifyFellowship,
  deleteFellowship,
  fellowship: { fellowship },
  auth,
}) => {
  const { id } = useParams();

  // Load fellowship on first render
  useEffect(() => {
    getFellowship(id);
  }, [getFellowship, id]);

  const [initCompleted, setInitCompleted] = useState(false);
  const [amHost, setAmHost] = useState(false);

  const [view, setView] = useState(1);
  const handleView = (e) => {
    if (e !== view && e >= 1 && e <= 3) {
      setView(e);
    }
  };

  const [fellowshipType, setFellowshipType] = useState(1);
  const [privateGroup, setPrivateGroup] = useState(true);
  const [groupNumber, setGroupNumber] = useState(1);
  const [sequential, setSequential] = useState(true);
  const [allowed, setAllowed] = useState([]);
  const [ftLink0, setFtLink0] = useState(1); // -1:none, 0:none-initially-optional-after, 1=optional, 2:mandatory
  const [ftLink11, setFtLink11] = useState(0); // -1:none, 0:none-initially-optional-after, 1=optional, 2:mandatory
  const [ftLinkTextLength, setFtLinkTextLength] = useState(-1); // -1: unlimited, 0:none, >0:maxCharacterLength
  const [intellLink0, setIntellLink0] = useState(1); // -1:none, 0:none-initially-optional-after, 1=optional, 2:mandatory
  const [intellLink1, setIntellLink1] = useState(0); // -1:none, 0:none-initially-optional-after, 1=optional, 2:mandatory
  const [intellLink2, setIntellLink2] = useState(0); // -1:none, 0:none-initially-optional-after, 1=optional, 2:mandatory
  const [intellLinkTextLength, setIntellLinkTextLength] = useState(-1); // -1: unlimited, 0:none, >0:maxCharacterLength
  const [linkWaveMaker, setLinkWaveMaker] = useState(1);
  const [defaultWaveMakers, setDefaultWaveMakers] = useState([]);
  const [expertiseLink, setExpertiseLink] = useState(1); // -1:never, 1=if-text, 2:always
  const [expertiseLinkSteelManTextLength, setExpertiseLinkSteelManTextLength] = useState(160); // -1: unlimited, 0:none, >0:maxCharacterLength
  const [expertiseLinkMyTextLength, setExpertiseLinkMyTextLength] = useState(-1); // -1: unlimited, 0:none, >0:maxCharacterLength
  const [expertiseLinkChangedTextLength, setExpertiseLinkChangedTextLength] = useState(160); // -1: unlimited, 0:none, >0:maxCharacterLength
  const [profileView, setProfileView] = useState(1); // -1:hide, 1:show
  const [profileLinkable, setProfileLinkable] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (fellowship) {
      // Set component state from fellowship data
      setFellowshipType(fellowship.fellowshipType || 1);
      setPrivateGroup(fellowship.privateGroup !== undefined ? fellowship.privateGroup : true);
      setGroupNumber(fellowship.groupNumber || 0);
      setSequential(fellowship.sequential !== undefined ? fellowship.sequential : true);
      setAllowed(fellowship.allowed || []);
      setFtLink0(fellowship.ftLink0 !== undefined ? fellowship.ftLink0 : -1);
      setFtLink11(fellowship.ftLink11 !== undefined ? fellowship.ftLink11 : -1);
      setFtLinkTextLength(fellowship.ftLinkTextLength || 200);
      setIntellLink0(fellowship.intellLink0 !== undefined ? fellowship.intellLink0 : -1);
      setIntellLink1(fellowship.intellLink1 !== undefined ? fellowship.intellLink1 : -1);
      setIntellLink2(fellowship.intellLink2 !== undefined ? fellowship.intellLink2 : -1);
      setIntellLinkTextLength(fellowship.intellLinkTextLength || 200);
      setLinkWaveMaker(fellowship.linkWaveMaker !== undefined ? fellowship.linkWaveMaker : -1);
      setDefaultWaveMakers(fellowship.defaultWaveMakers || []);
      setExpertiseLink(fellowship.expertiseLink !== undefined ? fellowship.expertiseLink : -1);
      setExpertiseLinkSteelManTextLength(fellowship.expertiseLinkSteelManTextLength || 200);
      setExpertiseLinkMyTextLength(fellowship.expertiseLinkMyTextLength || 200);
      setExpertiseLinkChangedTextLength(fellowship.expertiseLinkChangedTextLength || 200);
      setProfileView(fellowship.profileView !== undefined ? fellowship.profileView : 0);
      setProfileLinkable(
        fellowship.profileLinkable !== undefined ? fellowship.profileLinkable : false
      );

      // Mark initialization as complete after setting all values
      setInitCompleted(true);
    }
  }, [fellowship]);

  useEffect(() => {
    if (fellowship && auth && auth.user && fellowship._id === id) {
      // Restore existing fields
      if (exists(fellowship.fellowshipType)) setFellowshipType(fellowship.fellowshipType);
      if (exists(fellowship.privateGroup)) setPrivateGroup(fellowship.privateGroup);
      if (exists(fellowship.groupNumber)) setGroupNumber(fellowship.groupNumber);
      if (exists(fellowship.sequential)) setSequential(fellowship.sequential);
      if (exists(fellowship.allowed)) setAllowed(fellowship.allowed);
      if (exists(fellowship.ftLink0)) setFtLink0(fellowship.ftLink0);
      if (exists(fellowship.ftLink11)) setFtLink11(fellowship.ftLink11);
      if (exists(fellowship.ftLinkTextLength)) setFtLinkTextLength(fellowship.ftLinkTextLength);
      if (exists(fellowship.intellLink0)) setIntellLink0(fellowship.intellLink0);
      if (exists(fellowship.intellLink1)) setIntellLink1(fellowship.intellLink1);
      if (exists(fellowship.intellLink2)) setIntellLink2(fellowship.intellLink2);
      if (exists(fellowship.intellLinkTextLength))
        setIntellLinkTextLength(fellowship.intellLinkTextLength);
      if (exists(fellowship.linkWaveMaker)) setLinkWaveMaker(fellowship.linkWaveMaker);
      if (exists(fellowship.defaultWaveMakers)) setDefaultWaveMakers(fellowship.defaultWaveMakers);
      if (exists(fellowship.expertiseLink)) setExpertiseLink(fellowship.expertiseLink);
      if (exists(fellowship.expertiseLinkSteelManTextLength))
        setExpertiseLinkSteelManTextLength(fellowship.expertiseLinkSteelManTextLength);
      if (exists(fellowship.expertiseLinkMyTextLength))
        setExpertiseLinkMyTextLength(fellowship.expertiseLinkMyTextLength);
      if (exists(fellowship.expertiseLinkChangedTextLength))
        setExpertiseLinkChangedTextLength(fellowship.expertiseLinkChangedTextLength);
      if (exists(fellowship.profileView)) setProfileView(fellowship.profileView);
      if (exists(fellowship.profileLinkable)) setProfileLinkable(fellowship.profileLinkable);

      // Does current user have HOST privileges?
      if (
        ADMIN.includes(auth.user.email) ||
        fellowship.allowed.some(
          (memberObj) => memberObj.email === auth.user.email && memberObj.myGroup <= 0
        )
      ) {
        setAmHost(true);
      } else {
        setAmHost(false);
      }
      setInitCompleted(true);
    } else {
      setInitCompleted(false);
    }
  }, [fellowship, auth, id]);

  const handleNewDefaultWaveMaker = (e) => {
    if (!e || !e._id || !Array.isArray(defaultWaveMakers)) return;

    const existingIds = new Set(defaultWaveMakers.map((item) => item._id));

    if (!existingIds.has(e._id)) {
      const updatedDefaultWaveMakers = [...defaultWaveMakers, e];

      if (fellowship) {
        modifyFellowship(fellowship._id, {
          defaultWaveMakers: updatedDefaultWaveMakers,
        });
      }
    }
  };

  const handleRemoveDefaultWaveMaker = (e) => {
    if (e) {
      const index = defaultWaveMakers.findIndex((obj) => obj._id === e);
      if (index >= 0) {
        if (fellowship) {
          // console.log("handleRemoveDefaultWaveMaker: modifyFellowship()");
          modifyFellowship(fellowship._id, {
            defaultWaveMakers: [
              ...defaultWaveMakers.slice(0, index),
              ...defaultWaveMakers.slice(index + 1),
            ],
          });
        }
      }
    }
  };

  /**
   * Save changes to the fellowship
   */
  const onSubmit = () => {
    // Don't attempt to save if fellowship isn't loaded yet
    if (!fellowship) {
      console.log("Fellowship not loaded yet, skipping save");
      return;
    }

    setIsUpdating(true);
    // Create a clean copy of the fellowship with only the fields that can be updated
    const updatedFellowship = {
      name: fellowship.name,
      fellowshipType,
      privateGroup,
      groupNumber,
      sequential,
      allowed,
      ftLink0,
      ftLink11,
      ftLinkTextLength,
      intellLink0,
      intellLink1,
      intellLink2,
      intellLinkTextLength,
      linkWaveMaker,
      defaultWaveMakers,
      expertiseLink,
      expertiseLinkSteelManTextLength,
      expertiseLinkMyTextLength,
      expertiseLinkChangedTextLength,
      profileView,
      profileLinkable,
      activities: fellowship.activities, // Preserve existing activities
    };

    // Use bypassCache=true to force a fresh fetch after update
    modifyFellowship(fellowship._id, updatedFellowship)
      .then(() => {
        // Refresh fellowship data with no caching
        getFellowship(fellowship._id, true);
        setIsUpdating(false);
      })
      .catch((error) => {
        console.error("Error updating fellowship:", error);
        setIsUpdating(false);
      });
  };

  // Only call onSubmit when specific values have changed to prevent infinite loops
  useEffect(() => {
    if (fellowship && initCompleted && !isUpdating) {
      // Prevent unnecessary saving by comparing current values with original fellowship data
      const hasChanges =
        fellowship.fellowshipType !== fellowshipType ||
        fellowship.privateGroup !== privateGroup ||
        fellowship.groupNumber !== groupNumber ||
        fellowship.sequential !== sequential ||
        fellowship.ftLink0 !== ftLink0 ||
        fellowship.ftLink11 !== ftLink11 ||
        fellowship.ftLinkTextLength !== ftLinkTextLength ||
        fellowship.intellLink0 !== intellLink0 ||
        fellowship.intellLink1 !== intellLink1 ||
        fellowship.intellLink2 !== intellLink2 ||
        fellowship.intellLinkTextLength !== intellLinkTextLength ||
        fellowship.linkWaveMaker !== linkWaveMaker ||
        fellowship.expertiseLink !== expertiseLink ||
        fellowship.expertiseLinkSteelManTextLength !== expertiseLinkSteelManTextLength ||
        fellowship.expertiseLinkMyTextLength !== expertiseLinkMyTextLength ||
        fellowship.expertiseLinkChangedTextLength !== expertiseLinkChangedTextLength ||
        fellowship.profileView !== profileView ||
        fellowship.profileLinkable !== profileLinkable;

      if (hasChanges) {
        onSubmit();
      }
    }
  }, [
    fellowship,
    initCompleted,
    fellowshipType,
    privateGroup,
    groupNumber,
    sequential,
    ftLink0,
    ftLink11,
    ftLinkTextLength,
    intellLink0,
    intellLink1,
    intellLink2,
    intellLinkTextLength,
    linkWaveMaker,
    expertiseLink,
    expertiseLinkSteelManTextLength,
    expertiseLinkMyTextLength,
    expertiseLinkChangedTextLength,
    profileView,
    profileLinkable,
    isUpdating,
  ]);

  const handleAllowed = (allowed) => {
    if (fellowship) {
      // console.log("handleAllowed: modifyFellowship()");
      modifyFellowship(fellowship._id, {
        allowed,
      });
    }
  };

  const handleFellowshipType = (e) => {
    setFellowshipType(e);
    setSequential(e === 1); // Set true if LEARN
  };

  const navigate = useNavigate();
  const onCancel = () => {
    navigate("/fellowships");
  };

  // Dialog states for MyGoto section extracted to FellowshipOptions
  const [dialogType, setDialogType] = useState(false);
  const [dialogFraming, setDialogFraming] = useState(false);
  const [dialogIntell, setDialogIntell] = useState(false);
  const [dialogWaveMakers, setDialogWaveMakers] = useState(false);
  const [dialogDig, setDialogDig] = useState(false);
  const [dialogPrivacy, setDialogPrivacy] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false);

  const handleDialogConfirmClose = (e) => {
    if (e) {
      // console.log("Delete current fellowship");
      deleteFellowship(fellowship._id);
      navigate("/fellowships");
    }
    setDialogDelete(false); // close confirm dialog
  };

  return !initCompleted ? (
    <Spinner />
  ) : (
    <>
      {/* Could not find fellowship id */}
      {!fellowship && (
        <>
          <NavbarTop paper dropdown={0} />
          <Container sx={{ p: 0, mb: 1 }}>
            <Box
              sx={{
                mt: 4,
                mb: 2,
              }}
            >
              <Alert severity="error" variant="filled" sx={{ pt: 0, pb: 0, mt: 3 }}>
                Could not find specified fellowship
              </Alert>
              <Stack flexWrap="wrap" flexDirection="row" width={1.0} justifyContent="center">
                <Button
                  onClick={() => onCancel()}
                  color="primary"
                  circular
                  sx={{ ml: 1, mr: 1, mt: 4 }}
                >
                  Return
                </Button>
              </Stack>
            </Box>
          </Container>
        </>
      )}

      {/* Found fellowship id, but current user is not a HOST */}
      {fellowship && !amHost && (
        <>
          <NavbarTop paper dropdown={2} />
          <Container sx={{ p: 0, mb: 1 }}>
            <Box
              sx={{
                mt: 4,
                mb: 2,
              }}
            >
              <Alert severity="error" variant="filled" sx={{ pt: 0, pb: 0, mt: 3 }}>
                You do not have HOST privledges to edit this fellowship
              </Alert>
              <Stack flexWrap="wrap" flexDirection="row" width={1.0} justifyContent="center">
                <Button
                  onClick={() => onCancel()}
                  color="primary"
                  circular
                  sx={{ ml: 1, mr: 1, mt: 4 }}
                >
                  Return
                </Button>
              </Stack>
            </Box>
          </Container>
        </>
      )}

      {fellowship && amHost && (
        <>
          <NavbarTop paper dropdown={2} bottomSpacePixels={-20} allowEdit />

          <Container sx={{ p: 0, mb: 0, mt: -3 }}>
            <Paper sx={{ pt: 2 }}>
              <Box
                sx={{
                  mt: 0,
                  mb: 5,
                  display: "grid",
                  gap: 0,
                  gridTemplateColumns: fellowshipType <= 2 ? "repeat(3, 1fr)" : "repeat(2, 1fr)",
                }}
              >
                <Button fullWidth onClick={() => handleView(1)}>
                  <Stack justifyContent="center">
                    <Settings
                      sx={{
                        ml: 0.3,
                        height: "30px",
                        width: "30px",
                        color: view !== 1 ? "grey" : "#0074F4",
                      }}
                    />
                    <Typography sx={{ fontSize: "10px", color: view !== 1 ? "grey" : "#0074F4" }}>
                      Settings
                    </Typography>
                  </Stack>
                </Button>
                <Button fullWidth onClick={() => handleView(2)}>
                  <Stack justifyContent="center">
                    <Groups
                      sx={{
                        ml: 0.75,
                        height: "30px",
                        width: "30px",
                        color: view !== 2 ? "grey" : "#0074F4",
                      }}
                    />
                    <Typography sx={{ fontSize: "10px", color: view !== 2 ? "grey" : "#0074F4" }}>
                      Members
                    </Typography>
                  </Stack>
                </Button>

                {fellowshipType <= 2 && (
                  <Button fullWidth onClick={() => handleView(3)}>
                    <Stack justifyContent="center">
                      <FormatListNumbered
                        sx={{
                          ml: 0.65,
                          height: "30px",
                          width: "30px",
                          color: view !== 3 ? "grey" : "#0074F4",
                        }}
                      />
                      <Typography sx={{ fontSize: "10px", color: view !== 3 ? "grey" : "#0074F4" }}>
                        Activities
                      </Typography>
                    </Stack>
                  </Button>
                )}
              </Box>
            </Paper>

            {view === 1 && (
              <FellowshipOptions
                fellowshipType={fellowshipType}
                setDialogType={setDialogType}
                setDialogFraming={setDialogFraming}
                setDialogIntell={setDialogIntell}
                setDialogWaveMakers={setDialogWaveMakers}
                setDialogDig={setDialogDig}
                setDialogPrivacy={setDialogPrivacy}
                setDialogDelete={setDialogDelete}
              />
            )}

            {/* Manage fellowship members */}
            {view === 2 && (
              <Members
                groupNumber={groupNumber}
                setGroupNumber={setGroupNumber}
                fellowshipType={fellowshipType}
                privateGroup={privateGroup}
                setPrivateGroup={setPrivateGroup}
                allowed={allowed}
                setAllowed={handleAllowed}
              />
            )}

            {view === 3 && <ActivityList />}
          </Container>
        </>
      )}

      {dialogType && (
        <DialogType
          handleBack={setDialogType}
          fellowshipType={fellowshipType}
          handleFellowshipType={handleFellowshipType}
          sequential={sequential}
          setSequential={setSequential}
          privateGroup={privateGroup}
          setPrivateGroup={setPrivateGroup}
        />
      )}

      {dialogFraming && (
        <DialogFraming
          handleBack={setDialogFraming}
          ftLink0={ftLink0}
          setFtLink0={setFtLink0}
          ftLink11={ftLink11}
          setFtLink11={setFtLink11}
          ftLinkTextLength={ftLinkTextLength}
          setFtLinkTextLength={setFtLinkTextLength}
        />
      )}

      {dialogIntell && (
        <DialogIntell
          handleBack={setDialogIntell}
          intellLink0={intellLink0}
          setIntellLink0={setIntellLink0}
          intellLink1={intellLink1}
          setIntellLink1={setIntellLink1}
          intellLink2={intellLink2}
          setIntellLink2={setIntellLink2}
          intellLinkTextLength={intellLinkTextLength}
          setIntellLinkTextLength={setIntellLinkTextLength}
        />
      )}

      {dialogWaveMakers && (
        <DialogWaveMakers
          handleBack={setDialogWaveMakers}
          linkWaveMaker={linkWaveMaker}
          setLinkWaveMaker={setLinkWaveMaker}
          defaultWaveMakers={defaultWaveMakers}
          handleNewDefaultWaveMaker={handleNewDefaultWaveMaker}
          handleRemoveDefaultWaveMaker={handleRemoveDefaultWaveMaker}
        />
      )}

      {dialogDig && (
        <DialogDig
          handleBack={setDialogDig}
          expertiseLink={expertiseLink}
          setExpertiseLink={setExpertiseLink}
          expertiseLinkSteelManTextLength={expertiseLinkSteelManTextLength}
          setExpertiseLinkSteelManTextLength={setExpertiseLinkSteelManTextLength}
          expertiseLinkMyTextLength={expertiseLinkMyTextLength}
          setExpertiseLinkMyTextLength={setExpertiseLinkMyTextLength}
          expertiseLinkChangedTextLength={expertiseLinkChangedTextLength}
          setExpertiseLinkChangedTextLength={setExpertiseLinkChangedTextLength}
        />
      )}

      {dialogPrivacy && (
        <DialogPrivacy
          handleBack={setDialogPrivacy}
          profileView={profileView}
          setProfileView={setProfileView}
          profileLinkable={profileLinkable}
          setProfileLinkable={setProfileLinkable}
        />
      )}

      {dialogDelete && (
        <DialogConfirm
          title="Are you sure?"
          subTitles={["Once deleted, it cannot be recovered."]}
          handleDialogClose={handleDialogConfirmClose}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  fellowship: state.fellowship,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getFellowship,
  modifyFellowship,
  deleteFellowship,
})(EditFellowship);
