import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import {
  Container,
  Card,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Slide,
} from "@mui/material";
import { Forum, People, MoreVert, Check } from "@mui/icons-material";

import inSightLogoBlack from "assets/images/icons/logo.svg";
import inSightLogoWhite from "assets/images/icons/insight-icon-white.png";
import elephantWhite from "assets/images/elephantWhite.svg";
import elephantBlack from "assets/images/elephantBlack.svg";

import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import ChildrenView from "./ChildrenView";
import Wavemakers from "./Wavemakers";
import Elephant from "./Elephant";
import SliderDiggingDeeper from "../slider/SliderDiggingDeeper";
import InterpretIntell from "../interpret/InterpretIntell";
import IntellCard from "../intell/IntellCard";
import exists from "../../utils/exists";

const PostView = ({ initIntell, activity = null, auth }) => {
  const [menuSelected, setMenuSelected] = useState(2);

  // START OF SWIPE HANDLER

  const [defaultTransitionDuration] = useState(300); // 250
  const [transitionDuration, setTransitionDuration] = useState(0);

  const [emergenceSlideDirection] = useState("right");
  const [elephantSlideDirection, setElephantSlideDirection] = useState();
  const [replySlideDirection, setReplySlideDirection] = useState();
  const [agentsSlideDirection] = useState("left");

  const [emergenceView, setEmergenceView] = useState(menuSelected === 1);
  const [elephantView, setElephantView] = useState(menuSelected === 2);
  const [replyView, setReplyView] = useState(menuSelected === 3);
  const [agentsView, setAgentsView] = useState(menuSelected === 4);

  // Change in menuSelected
  useEffect(() => {
    // Goto emergenceView (#1)
    if (menuSelected === 1) {
      let bChange = false;
      if (elephantView) {
        // 2->1
        bChange = true;
        setElephantView(false);
        setElephantSlideDirection("left");
      }
      if (replyView) {
        // 3->1
        bChange = true;
        setReplyView(false);
        setReplySlideDirection("left");
      }
      if (agentsView) {
        // 4->1
        bChange = true;
        setAgentsView(false);
      }
      if (bChange) {
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setEmergenceView(true);
        }, transitionDuration);
      }
    }
    // Goto elephantView (#2)
    if (menuSelected === 2) {
      let bChange = false;
      if (emergenceView) {
        // 1->2
        bChange = true;
        setEmergenceView(false);
        setElephantSlideDirection("left");
      }
      if (replyView) {
        // 3->2
        bChange = true;
        setReplyView(false);
        setElephantSlideDirection("right");
        setReplySlideDirection("left");
      }
      if (agentsView) {
        // 4->2
        bChange = true;
        setElephantSlideDirection("right");
        setAgentsView(false);
      }
      if (bChange) {
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setElephantView(true);
        }, transitionDuration);
      }
    }
    // Goto replyView (#3)
    if (menuSelected === 3) {
      let bChange = false;
      if (emergenceView) {
        // 1->3
        bChange = true;
        setEmergenceView(false);
        setReplySlideDirection("left");
      }
      if (elephantView) {
        // 2->3
        bChange = true;
        setElephantView(false);
        setReplySlideDirection("left");
        setElephantSlideDirection("right");
      }
      if (agentsView) {
        // 4->3
        bChange = true;
        setAgentsView(false);
        setReplySlideDirection("right");
      }
      if (bChange) {
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setReplyView(true);
        }, transitionDuration);
      }
    }

    // Goto agentsView (#4)
    if (menuSelected === 4) {
      let bChange = false;
      if (emergenceView) {
        // 1->4
        bChange = true;
        setEmergenceView(false);
      }
      if (elephantView) {
        // 2->4
        bChange = true;
        setElephantView(false);
        setElephantSlideDirection("right");
      }
      if (replyView) {
        // 3->4
        bChange = true;
        setReplyView(false);
        setReplySlideDirection("right");
      }
      if (bChange) {
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setAgentsView(true);
        }, transitionDuration);
      }
    }
  }, [menuSelected, emergenceView, elephantView, replyView, agentsView, transitionDuration]);

  // Swipe handler
  //  const handlers = useSwipeable({
  //    onSwipedRight: () => {
  //      if (menuSelected > 1) {
  //        setMenuSelected(menuSelected - 1);
  //      }
  //    },
  //    onSwipedLeft: () => {
  //      if (menuSelected < 4) {
  //        setMenuSelected(menuSelected + 1);
  //      }
  //    },
  //    preventDefaultTouchmoveEvent: true,
  //    trackMouse: true,
  //  });

  // END OF SWIPE HANDLER

  const [viewOnlyChanged, setViewOnlyChanged] = useState(false);
  const [interpretations, setInterpretations] = useState([]);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [intell, setIntell] = useState(initIntell); // Initialized by parent (<Post> or <Activity>), but updated by child (<InterpretIntell>, <Reply>)
  const [sliderExpertiseLevel, setSliderExpertiseLevel] = useState(0);
  const [expertsExist, setExpertsExist] = useState(false);
  const [myInterpretationExists, setMyInterpretationExists] = useState(false); // Whenever intell changes, check whether current user has interpreted
  const [modifyAllowed, setModifyAllowed] = useState(false); // Only data using latest data structures can be updated

  const filterChanged = (arr) => {
    if (viewOnlyChanged) {
      return arr.filter(
        (e) =>
          (e.expertise &&
            exists(e.expertise.changedMind) &&
            e.expertise.changedMind &&
            exists(e.expertise.changedText) &&
            e.expertise.changedText.length > 0) ||
          exists(e.expertise.supportTopic1)
      );
    }
    return arr;
  };

  const filterOutNoElephantDisplayData = (arr) => {
    return arr.filter((e) => exists(e.rational) || exists(e.theGood));
  };

  //  sliderExpertiseLevel(user-controlled slider) ranges from 0->100, essentially mapping the expertise levels onto four sections of the slider
  // - e.expertise.myExpertiseLevel:1 translates to 25
  // - e.expertise.myExpertiseLevel:2 translates to 50
  // - e.expertise.myExpertiseLevel:3 translates to 75
  // - e.expertise.myExpertiseLevel:4 translates to 100
  const filterExpertise = (arr) => {
    if (sliderExpertiseLevel > 1) {
      return arr.filter((e) => 25 * e.expertise.myExpertiseLevel >= sliderExpertiseLevel);
    }
    return arr;
  };

  // First time & when <intell> changes
  useEffect(() => {
    if (
      auth &&
      auth.user &&
      auth.user._id &&
      intell &&
      intell.scales &&
      intell.scales.find((e) => e.user._id === auth.user._id)
    ) {
      setMyInterpretationExists(true);
      if (
        intell.scales.find(
          (e) => e.user._id === auth.user._id && exists(e.rational) && exists(e.theGood)
        ) ||
        (intell.parentScale &&
          intell.parentScale.user &&
          auth &&
          auth.user &&
          auth.user._id &&
          ((intell.parentScale.user.id && intell.parentScale.user.id === auth.user._id) ||
            JSON.stringify(intell.parentScale.user) === JSON.stringify(auth.user._id)))
      ) {
        // Does not support legacy data that does not have rational/theGood props
        setModifyAllowed(true);
      }
    } else {
      setMyInterpretationExists(false);
    }
    if (menuSelected === 5) {
      setMenuSelected(2);
    }
  }, [intell]);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef && divRef.current && divRef.current.scrollIntoView) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [updatedCount]);

  const handleMenuSelected = (e) => {
    setMenuSelected(e);
  };

  // Prepare intell/scale data:
  // - Massage legacy data
  // - Determine if there is any expertise present in the interpretations
  // - Fully populate children ideas (ie, user)
  // - Props include:
  //   - updatedAt
  //   - user
  //   - ideaType
  //   - associatedAgent
  //   - rational/theGood (the only exception is if the primary idea is mine, then it's "MeSpeak")
  //   - expertise
  //   - manipulative
  //   - badgeType: 1: calling out manipulation, 2 ("MeSpeak"), badgeType: 3 (rational/theGood)
  //   - myPoint
  //   - supportTopic N/A (supportTopic is only somewhat relevant if the primary idea's parent is a framing statement, but the supportTopic would be an interpretation of the idea's parent)
  useEffect(() => {
    const selectedInterpretations = [];
    let foundExperts = false;

    intell.scales.forEach((node, index) => {
      if (exists(node.rational) && exists(node.theGood)) {
        // Latest data (expecting node.expertise)
        if (
          node.expertise &&
          node.expertise.myExpertiseLevel &&
          node.expertise.myExpertiseLevel > 1
        ) {
          foundExperts = true;
        }

        // Under some circumstances (i.e., an agent is associated in the interpretation, but no reply is provided), we still display the inTell, and there we need a badge
        node.manipulative = exists(node.goodFaith) && node.goodFaith > -2 && node.goodFaith < 0;
        node.badgeType = node.manipulative ? 1 : 3;
        if (!node.ideaType) node.ideaType = 0;
        node.expertise = node.expertise ? node.expertise : { myExpertiseLevel: 1 };

        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i += 1) {
            node.children[i].user = node.user; // node.children[i].user is not fully populated; use user from fully populated scale
            if (!node.children[i].ideaType) node.children[i].ideaType = 0;
            node.children[i].associatedAgent = node.associatedAgent;
            node.children[i].rational = node.rational;
            node.children[i].rational1 = node.rational1;
            node.children[i].theGood = node.theGood;
            node.children[i].theGood1 = node.theGood1;
            node.children[i].goodFaith = node.goodFaith;
            node.children[i].goodFaith1 = node.goodFaith1;
            node.children[i].updatedAt = node.updatedAt; // node.children[i].updatedAt is already expected to be initialized (overwrite)
            node.children[i].createdAt = node.createdAt;
            node.children[i].expertise = node.expertise;
            node.children[i].manipulative = node.manipulative;
            node.children[i].badgeType = node.badgeType;
            // node.children[i].myPoint is already expected to be initialized
          }
        } else if (node.note && node.note.length > 0) {
          node.children = [
            {
              updatedAt: node.updatedAt,
              createdAt: node.createdAt,
              user: node.user,
              ideaType: 0,
              associatedAgent: node.associatedAgent,
              rational: node.rational,
              rational1: node.rational1,
              theGood: node.theGood,
              theGood1: node.theGood1,
              goodFaith: node.goodFaith,
              goodFaith1: node.goodFaith1,
              expertise: node.expertise,
              manipulative: node.manipulative,
              badgeType: node.badgeType,
              myPoint: node.note,
            },
          ];
        }
        selectedInterpretations.push(node);
      } else if (exists(node.myEmotion)) {
        // Legacy interpretation

        if (!node.expertise) {
          node.expertise = {
            myExpertiseText: node.myExpertise,
            changedText:
              node.haveChanged && node.haveChanged.length > 0
                ? node.haveChanged
                : node.wouldChange && node.wouldChange.length > 0
                  ? node.wouldChange
                  : null,
            changedMind: node.haveChanged && node.haveChanged.length > 0,
            myExpertiseLevel: !exists(node.confidence) ? 0.5 : node.confidence,
          };
        }
        if (node.expertise.myExpertiseLevel > 1) {
          foundExperts = true;
        }

        let sound = node.sound; // eslint-disable-line prefer-destructuring
        const authorIntent =
          exists(node.goodFaith) && node.goodFaith > -2
            ? node.authorIntent * node.goodFaith // penalize authorIntent
            : node.authorIntent;
        let myExpertiseLevelForSignalCalc =
          node.expertise.myExpertiseLevel > 1 ? 1 : node.expertise.myExpertiseLevel;

        const modernizedScale = {
          user: node.user,
          updatedAt: node.updatedAt,
          ideaType: 0,
          expertise: node.expertise,
          theGood: node.myEmotion,
          divisive: 0,
          manipulative: exists(node.goodFaith) && node.goodFaith > -2 && node.goodFaith < 0.5, // Note: This old data scale goes from 0->1 (not -1 to 1, modern meaning)
        };

        // Under some circumstances (i.e., an agent is associated in the interpretation, but no reply is provided), we still display the inTell, and there we need a badge
        modernizedScale.badgeType = modernizedScale.manipulative ? 1 : 3;

        // For divisive entries replace 'sound' with 0 and 'myExpertiseLevel' with 1
        if (node.divisive && node.divisive > 0) {
          myExpertiseLevelForSignalCalc = 1; // Set to max
          sound = 0;
          modernizedScale.divisive = node.divisive;
        }

        let signal = authorIntent * sound * myExpertiseLevelForSignalCalc;
        if (node.heartOfIssue && node.heartOfIssue > 0.5) {
          signal += (1.0 - signal) * 2.0 * (node.heartOfIssue - 0.5);
        }

        const noise = (1.0 - authorIntent) * (1.0 - sound) * myExpertiseLevelForSignalCalc;

        modernizedScale.rational = signal - noise;
        //      if (node.valuesAgent && node.valuesAgent.name && node.valuesAgent.name.length > 0) {
        //        modernizedScale.associatedAgent = node.valuesAgent;
        //      }

        if (node.children && node.children.length > 0) {
          modernizedScale.children = node.children;
          for (let i = 0; i < modernizedScale.children.length; i += 1) {
            // modernizedScale.children[i].updatedAt is already initialized in copy above: modernizedScale.children = node.children
            modernizedScale.children[i].user = node.user; // node.children[i].user is not fully populated; use user from fully populated scale
            if (!modernizedScale.children[i].ideaType) modernizedScale.children[i].ideaType = 0;
            modernizedScale.children[i].associatedAgent = modernizedScale.associatedAgent;
            modernizedScale.children[i].rational = modernizedScale.rational;
            modernizedScale.children[i].theGood = modernizedScale.theGood;
            modernizedScale.children[i].expertise = node.expertise;
            modernizedScale.children[i].manipulative = modernizedScale.manipulative;
            modernizedScale.children[i].badgeType = modernizedScale.manipulative ? 1 : 3;
            // modernizedScale.children[i].myPoint is already initialized in copy above: modernizedScale.children = node.children
          }
        } else {
          modernizedScale.children = [];
          if (
            (node.whyManipulative && node.whyManipulative.length > 0) ||
            (node.note && node.note.length > 0)
          ) {
            // <note> or <whyManipulative> found
            modernizedScale.children.push({
              updatedAt: node.updatedAt,
              user: node.user,
              ideaType: 0,
              associatedAgent: modernizedScale.associatedAgent,
              rational: modernizedScale.rational,
              theGood: modernizedScale.theGood,
              expertise: node.expertise,
              manipulative: modernizedScale.manipulative,
              badgeType: modernizedScale.manipulative ? 1 : 3,
              myPoint:
                node.whyManipulative && node.whyManipulative.length > 0
                  ? node.whyManipulative
                  : node.note,
            });
          } else if (
            node.ideaCreated &&
            node.ideaCreated.myPoint &&
            node.ideaCreated.myPoint.length > 0
          ) {
            // <ideaCreated> data structure used
            modernizedScale.children.push({
              updatedAt: node.ideaCreated.updatedAt,
              user: node.user,
              ideaType: 0,
              associatedAgent: modernizedScale.associatedAgent,
              rational: modernizedScale.rational,
              theGood: modernizedScale.theGood,
              expertise: node.expertise ? node.expertise : { myExpertiseLevel: 1 },
              manipulative: modernizedScale.manipulative,
              badgeType: modernizedScale.manipulative ? 1 : 3,
              myPoint: node.ideaCreated.myPoint,
            });
          }

          if (node.solution && node.solution.length > 0) {
            // <solution> found
            modernizedScale.children.push({
              updatedAt: node.updatedAt,
              user: node.user,
              ideaType: 1,
              associatedAgent: modernizedScale.associatedAgent,
              rational: modernizedScale.rational,
              theGood: modernizedScale.theGood,
              expertise: node.expertise ? node.expertise : { myExpertiseLevel: 1 },
              manipulative: modernizedScale.manipulative,
              badgeType: modernizedScale.manipulative ? 1 : 3,
              myPoint: node.solution,
            });
          }
        }
        selectedInterpretations.push(modernizedScale);
      } else if (index === 0) {
        // Valid interpretation not found; assume this is
        console.log("THIS IS MY INTERPRETATION OF MY INTELL");
        if (!node.expertise) {
          node.expertise = {
            myExpertiseText: node.myExpertise,
            changedText:
              node.haveChanged && node.haveChanged.length > 0
                ? node.haveChanged
                : node.wouldChange && node.wouldChange.length > 0
                  ? node.wouldChange
                  : null,
            changedMind: node.haveChanged && node.haveChanged.length > 0,
            myExpertiseLevel: !exists(node.confidence) ? 0.5 : node.confidence,
          };
        }
        if (node.expertise.myExpertiseLevel > 1) {
          foundExperts = true;
        }

        // Under some circumstances (i.e., an agent is associated in the interpretation, but no reply is provided), we still display the inTell, and there we need a badge
        node.badgeType = 2;
        if (!node.ideaType) node.ideaType = 0;

        for (let i = 0; i < node.children.length; i += 1) {
          // node.children[i].updatedAt is already expected to be initialized
          node.children[i].user = node.user; // node.children[i].user is not fully populated; use user from fully populated scale
          if (!node.children[i].ideaType) node.children[i].ideaType = 0;
          // N/A node.children[i].associatedAgent
          // N/A node.children[i].rational
          // N/A node.children[i].theGood
          node.children[i].expertise = node.expertise ? node.expertise : { myExpertiseLevel: 1 };
          // N/A node.children[i].manipulative
          node.children[i].badgeType = 2;
          // node.children[i].myPoint is already expected to be initialized
        }
        selectedInterpretations.push(node);
      }
    });

    if (foundExperts) {
      setExpertsExist(true);
    } else {
      setExpertsExist(false);
      setSliderExpertiseLevel(1);
    }

    setInterpretations(selectedInterpretations);
  }, [intell]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDropdownItemSelected = (e) => {
    if (e === 1 || e === 2) {
      setViewOnlyChanged(!viewOnlyChanged);
    }
    if (e === 3) {
      setMenuSelected(5);
    }
    setAnchorEl(null);
  };
  let currentlyHovering = false;
  const handleHover = () => {
    currentlyHovering = true;
  };
  const handleCloseHover = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 100);
  };

  return (
    <>
      {intell && (
        <Container sx={{ p: 0, top: 0, mt: 2 }} maxWidth="sm">
          {/* Menu options */}
          <Card style={{ padding: "0px", marginTop: "1px", borderRadius: 0 }}>
            <div style={{ marginTop: "0px" }}>
              <IntellCard
                activity={
                  !myInterpretationExists ||
                  (activity &&
                    activity.submitter &&
                    auth &&
                    auth.user &&
                    activity.submitter._id === auth.user._id)
                    ? activity
                    : null
                }
                cardOutline="none"
                intell={intell}
                //                defaultExpandParent={
                //                  intell &&
                //                  intell.parentIdea &&
                //                  (intell.parentIdea.ideaType === 10 || intell.parentIdea.ideaType === 11)
                //                }
              />

              {myInterpretationExists && (
                <>
                  <Divider sx={{ mt: -0.5 }} />
                  {menuSelected !== 5 && (
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        variant={menuSelected === 1 ? "contained" : "text"}
                        iconOnly
                        color={menuSelected === 1 ? "primary" : "dark"}
                        onClick={() => handleMenuSelected(1)}
                      >
                        <img
                          src={menuSelected === 1 ? inSightLogoWhite : inSightLogoBlack}
                          style={{ width: "28px", marginTop: "-2px" }}
                          alt="..."
                        />
                      </Button>

                      <Button
                        variant={menuSelected === 2 ? "contained" : "text"}
                        iconOnly
                        color="primary"
                        onClick={() => handleMenuSelected(2)}
                      >
                        <img
                          src={menuSelected === 2 ? elephantWhite : elephantBlack}
                          style={{ width: "24px", marginTop: "-6px" }}
                          alt="..."
                        />
                      </Button>
                      <Button
                        variant={menuSelected === 3 ? "contained" : "text"}
                        iconOnly
                        color={menuSelected === 3 ? "primary" : "dark"}
                        onClick={() => handleMenuSelected(3)}
                      >
                        <Forum />
                      </Button>

                      <Button
                        variant={menuSelected === 4 ? "contained" : "text"}
                        iconOnly
                        color={menuSelected === 4 ? "primary" : "dark"}
                        onClick={() => handleMenuSelected(4)}
                      >
                        <People />
                      </Button>

                      <Button
                        variant="text"
                        color="dark"
                        iconOnly
                        size="large"
                        sx={{ mt: -1 }}
                        onClick={handleClick}
                        onMouseOver={handleClick}
                        onMouseLeave={handleCloseHover}
                      >
                        <MoreVert />
                      </Button>
                      <Menu
                        style={{ pointerEvents: "none" }}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        MenuListProps={{
                          onMouseEnter: handleHover,
                          onMouseLeave: handleCloseHover,
                          style: { pointerEvents: "auto" },
                        }}
                      >
                        <MenuItem>
                          {!viewOnlyChanged && (
                            <>
                              <ListItemIcon onClick={() => handleDropdownItemSelected(1)}>
                                <Check />
                              </ListItemIcon>
                              <Typography
                                variant="caption"
                                style={{ fontSize: "16px", marginRight: "10px" }}
                              >
                                View All Perspectives
                              </Typography>
                            </>
                          )}
                          {viewOnlyChanged && (
                            <ListItemText inset onClick={() => handleDropdownItemSelected(1)}>
                              <Typography
                                variant="caption"
                                style={{ fontSize: "16px", marginRight: "10px" }}
                              >
                                View All Perspectives
                              </Typography>
                            </ListItemText>
                          )}
                        </MenuItem>
                        <MenuItem>
                          {viewOnlyChanged && (
                            <>
                              <ListItemIcon onClick={() => handleDropdownItemSelected(2)}>
                                <Check />
                              </ListItemIcon>
                              <Typography
                                variant="caption"
                                style={{ fontSize: "16px", marginRight: "10px" }}
                              >
                                View Shifted Perspectives (only)
                              </Typography>
                            </>
                          )}
                          {!viewOnlyChanged && (
                            <ListItemText inset onClick={() => handleDropdownItemSelected(2)}>
                              <Typography
                                variant="caption"
                                style={{ fontSize: "16px", marginRight: "10px" }}
                              >
                                View Shifted Perspectives (only)
                              </Typography>
                            </ListItemText>
                          )}
                        </MenuItem>

                        <Divider />

                        <MenuItem
                          disabled={!modifyAllowed}
                          onClick={() => handleDropdownItemSelected(3)}
                        >
                          <Typography
                            variant="caption"
                            style={{ fontSize: "16px", marginRight: "10px" }}
                          >
                            Edit my prior response
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                  {menuSelected === 5 && (
                    <div style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "5px", marginBottom: "15px" }}>
                        <Button
                          onClick={() => handleMenuSelected(2)}
                          color="primary"
                          circular
                          size="small"
                        >
                          Cancel Editing
                        </Button>
                      </div>
                    </div>
                  )}

                  {menuSelected !== 5 && expertsExist && (
                    <div
                      style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                    >
                      <Container sx={{ p: 0, top: 0 }} maxWidth="sm">
                        <SliderDiggingDeeper
                          userInitLevel={sliderExpertiseLevel}
                          updated={setSliderExpertiseLevel}
                        />
                      </Container>
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>

          {/* Options below the menu */}
          {myInterpretationExists && (
            <div style={{ marginTop: "30px" }}>
              <Slide
                in={emergenceView}
                timeout={transitionDuration}
                direction={emergenceSlideDirection}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <ChildrenView
                    ideaType={1}
                    intell={intell}
                    setIntell={setIntell}
                    interpretations={filterExpertise(filterChanged(interpretations))}
                    label="Post your inSights"
                    helperText="Zoom out. Break frame. Rise above the noise. Unmask half-truths"
                  />
                </div>
              </Slide>

              <Slide
                in={elephantView}
                timeout={transitionDuration}
                direction={elephantSlideDirection}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <Elephant
                    interpretations={filterExpertise(
                      filterChanged(filterOutNoElephantDisplayData(interpretations))
                    )}
                    showChangeLines={viewOnlyChanged}
                    updatedCount={updatedCount}
                    setUpdatedCount={setUpdatedCount}
                  />
                </div>
              </Slide>

              <Slide
                in={replyView}
                timeout={transitionDuration}
                direction={replySlideDirection}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <ChildrenView
                    ideaType={0}
                    intell={intell}
                    setIntell={setIntell}
                    interpretations={filterExpertise(filterChanged(interpretations))}
                    label="Post your reply"
                    helperText="Zoom in. Go deeper. Critique."
                  />
                </div>
              </Slide>

              <Slide
                in={agentsView}
                timeout={transitionDuration}
                direction={agentsSlideDirection}
                mountOnEnter
                unmountOnExit
              >
                <div>
                  <Wavemakers interpretations={filterExpertise(filterChanged(interpretations))} />
                </div>
              </Slide>
            </div>
          )}

          {(menuSelected === 5 || !myInterpretationExists) && (
            <InterpretIntell
              intell={intell}
              setIntell={setIntell}
              updatedCount={updatedCount}
              setUpdatedCount={setUpdatedCount}
              alwaysInterpretable={exists(activity)}
            />
          )}
        </Container>
      )}
      <div ref={divRef} />
    </>
  );
};

PostView.propTypes = {
  initIntell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activity: PropTypes.oneOfType([PropTypes.object]),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PostView);
