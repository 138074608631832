import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// MUI
import { grey } from "@mui/material/colors";
import { Badge, styled, Box } from "@mui/material";
import { alpha } from "@mui/material/styles";

import elephant from "assets/images/elephantGrey.png";
import Typography from "../MaterialKit/MKTypography";
import exists from "../../utils/exists";

const FooterBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -10,
    left: 10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    color: grey[600],
    padding: "0 4px",
    fontWeight: 500,
    fontSize: "11px",
  },
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1, 0),
  borderTop: `1px solid ${alpha(grey[300], 0.3)}`,
  marginTop: theme.spacing(1.5),
}));

const BadgeWrapper = styled(Box)(({ theme, clickable }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0.7, 1.5),
  borderRadius: "18px",
  transition: "all 0.2s ease",
  cursor: clickable ? "pointer" : "default",
  "&:hover": clickable
    ? {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        transform: "translateY(-2px)",
      }
    : {},
}));

const MyPointLink = styled(Typography)(({ theme, view }) => ({
  fontSize: view < 2 ? "12px" : "14px",
  color: view < 2 ? grey[500] : grey[700],
  fontWeight: 500,
  transition: "color 0.2s ease",
  "&:hover": {
    color: view >= 2 ? theme.palette.primary.main : undefined,
  },
}));

const Footer = ({ intell, view, setNavigationClicked, activity = null }) => {
  const navigate = useNavigate();

  const handleNavigationClicked = () => {
    if (view >= 2 && intell?._id) {
      setNavigationClicked(true);
      const route = activity ? `/activity/${activity._id}` : `/post/${intell._id}`;
      navigate(route);
      return null;
    }
    return undefined;
  };

  const badgeCount =
    intell?.scales?.filter(
      (obj) => exists(obj.rational) || exists(obj.myEmotion) || exists(obj.authorIntent)
    ).length || 0;

  return (
    <FooterContainer>
      <BadgeWrapper
        clickable={view >= 2 && intell?._id}
        onClick={view >= 2 && intell?._id ? handleNavigationClicked : undefined}
      >
        <MyPointLink view={view}>
          <FooterBadge badgeContent={badgeCount} showZero>
            <img
              src={elephant}
              style={{
                width: "24px",
                height: "24px",
                transition: "transform 0.2s ease",
                transform: view >= 2 && intell?._id ? "scale(1)" : "scale(0.9)",
              }}
              alt="elephant icon"
            />
          </FooterBadge>
        </MyPointLink>
      </BadgeWrapper>
    </FooterContainer>
  );
};

Footer.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setNavigationClicked: PropTypes.func.isRequired,
  view: PropTypes.number.isRequired,
  activity: PropTypes.oneOfType([PropTypes.object]),
};

export default Footer;
