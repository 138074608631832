import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";

import EditType from "./EditType";
import NavbarTitle from "../navbar/NavbarTitle";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DialogType = ({
  handleBack,
  fellowshipType,
  handleFellowshipType,
  sequential,
  setSequential,
}) => {
  return (
    <Dialog open fullScreen>
      <NavbarTitle paper title="Fellowship type" backClick={() => handleBack(false)} />
      <Container sx={{ mt: 4, mb: 3 }}>
        <ContentPanel elevation={0}>
          <EditType
            fellowshipType={fellowshipType}
            handleFellowshipType={handleFellowshipType}
            sequential={sequential}
            setSequential={setSequential}
          />
        </ContentPanel>
      </Container>
    </Dialog>
  );
};

DialogType.propTypes = {
  handleBack: PropTypes.func.isRequired,
  fellowshipType: PropTypes.number.isRequired,
  handleFellowshipType: PropTypes.func.isRequired,
  sequential: PropTypes.bool.isRequired,
  setSequential: PropTypes.func.isRequired,
};

export default DialogType;
