import axios from "axios";
import { debugLevel } from "config/personalize";

const handleUpload = async (file, setFilePath) => {
  if (debugLevel > 0) console.log("file uploaded", file, file.size);

  //      if (!pdf && !isImage(file.name)) {
  //        setAlert("Not an image file", "danger");
  //        return;
  //      }
  //      if (pdf && file.name.split(".").pop().toLowerCase() !== "pdf") {
  //        setAlert("Not a PDF file", "danger");
  //        return;
  //      }
  if (file.size >= 10000000) {
    //    setAlert("Unable to load image (image file exceeds 10MB)", "danger");
    console.log("Unable to load image (image file exceeds 10MB)");
    return;
  }

  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await axios.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (debugLevel > 0) console.log(response.data);
    setFilePath(response.data.path);
  } catch (err) {
    console.error(err);
  }
};

export default handleUpload;
