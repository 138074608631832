import React from "react";
import PropTypes from "prop-types";

// MUI
import { Divider, Icon } from "@mui/material";
import {
  AutoAwesomeMotion,
  PersonAddAlt1,
  Engineering,
  Approval,
  TipsAndUpdates,
} from "@mui/icons-material";

import Input from "../MaterialKit/MKInput";
import Box from "../MaterialKit/MKBox";
import MySwitch from "../layout/MySwitch";
import MySelect from "../layout/MySelect";
import MyGoto from "../layout/MyGoto";
import InfoCard from "../layout/InfoCard";

const ActivityEdit = ({
  childAllowed,
  setChildAllowed,
  childAllowedAuthors,
  setChildAllowedAuthors,
  childAllowedExpertise,
  setChildAllowedExpertise,
  childGoLiveAuto,
  setChildGoLiveAuto,
  childAllowedComments,
  setChildAllowedComments,
  childAllowedInsights,
  setChildAllowedInsights,
  childAllowedQuotations,
  setChildAllowedQuotations,
  instructionsTime,
  setInstructionsTime,
  instructionsOther,
  setInstructionsOther,
}) => {
  const handleChildAllowed = (e) => {
    if (e && childAllowedAuthors === 0) {
      // childAllowedAuthors cannot === 0
      setChildAllowedAuthors(2); // Host only
    }
    setChildAllowed(e);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <MyGoto
        icon={<TipsAndUpdates />}
        label="Additional guidance and tips for participants"
        subLabel={[
          "Estimate the approximate time a participant will need to complete this activity, including time to review material and time to interpret - (i.e, 5 minutes).",
          "Then, consider providing additional instructions, suggestions, points to consider, etc.",
        ]}
        divider="none"
      />

      <Box sx={{ mt: 3 }}>
        <Input
          onChange={(e) => setInstructionsTime(e.target.value)}
          type="inputText"
          fullWidth
          multiline
          value={instructionsTime}
          label="Estimated time to complete (e.g., '5 minutes', '10-15 minutes')"
          placeholder="Enter the approximate time needed to complete this activity"
        />
      </Box>

      <Box sx={{ mt: 3, mb: 3 }}>
        <Input
          onChange={(e) => setInstructionsOther(e.target.value)}
          type="inputText"
          fullWidth
          multiline
          rows={3}
          value={instructionsOther}
          label="Additional instructions for participants (optional)"
          placeholder="Enter any specific guidance, suggestions, or points to consider"
        />
      </Box>

      {/* LABEL: Auto generate child activities */}
      {childAllowed ? (
        <InfoCard
          title="Automatically create new activities visible to all participants in this fellowship"
          sx
        />
      ) : (
        <Divider />
      )}

      {/* Auto generate child activities? */}
      <MySwitch
        icon={<AutoAwesomeMotion />}
        select={childAllowed}
        setSelect={handleChildAllowed}
        label={
          childAllowed
            ? "Text responses to this activity will automatically become additional new activities"
            : "Text responses to this activity will NOT become additional new activities"
        }
        divider={childAllowed ? "partial" : "full"}
      />

      {childAllowed && (
        <>
          {/* Auto generate child activities: Who can author? */}
          <Box sx={{ mt: 2 }}>
            <MySelect
              icon={<PersonAddAlt1 />}
              menuList={[
                {
                  value: 1,
                  label: "Any participant",
                },
                {
                  value: 2,
                  label: "Host only",
                },
                {
                  value: 3,
                  label: "Specify users (future)",
                  disabled: true,
                },
              ]}
              select={childAllowedAuthors}
              setSelect={setChildAllowedAuthors}
              label="Whose responses will become new activities?"
              divider="none"
            />
          </Box>

          <MySwitch
            icon={<Icon />}
            select={childAllowedComments}
            setSelect={setChildAllowedComments}
            subLabel={
              childAllowedAuthors === 2
                ? ["Host comments/replies"]
                : ["Participant comments/replies"]
            }
            divider="none"
          />
          <MySwitch
            icon={<Icon />}
            select={childAllowedInsights}
            setSelect={setChildAllowedInsights}
            subLabel={childAllowedAuthors === 2 ? ["Host insights"] : ["Participant insights"]}
            divider="none"
          />
          <MySwitch
            icon={<Icon />}
            select={childAllowedQuotations}
            setSelect={setChildAllowedQuotations}
            subLabel={childAllowedAuthors === 2 ? ["Host quotations"] : ["Participant quotations"]}
            divider={childAllowedAuthors === 1 ? "partial" : "full"}
          />

          {childAllowedAuthors === 1 && (
            <>
              {/* Auto generate child activities (when any participant selected): Limit to self-declared expertise level? */}
              <MySelect
                icon={<Engineering />}
                menuList={[
                  {
                    value: 0,
                    label: "No limits",
                  },
                  {
                    value: 2,
                    label: "14% or Expert",
                  },
                  {
                    value: 3,
                    label: "Expert only",
                  },
                ]}
                select={childAllowedExpertise}
                setSelect={setChildAllowedExpertise}
                label="Minimum level of self-declared expertise?"
                subLabel={[
                  "What is the minimum level of self-declared expertise required by a participant for his/her reply to warrant becoming a new activity?",
                ]}
              />

              <MySelect
                icon={<Approval />}
                menuList={[
                  {
                    value: 0,
                    label: "Automatically",
                  },
                  {
                    value: 1,
                    label: "Stage/manual",
                  },
                  {
                    value: 3,
                    label: "AI-Assisted (future)",
                    disabled: true,
                  },
                ]}
                select={childGoLiveAuto}
                setSelect={setChildGoLiveAuto}
                label="Bring newly-created children activities live"
                subLabel={
                  childGoLiveAuto === 1
                    ? ["Stage activities, and HOST will manually approve their release"]
                    : [
                        "Bring activities live automatically as soon as they are submitted by participants",
                      ]
                }
                divider="full"
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

ActivityEdit.propTypes = {
  childAllowed: PropTypes.bool.isRequired,
  setChildAllowed: PropTypes.func.isRequired,
  childAllowedAuthors: PropTypes.number.isRequired,
  setChildAllowedAuthors: PropTypes.func.isRequired,
  childAllowedExpertise: PropTypes.number.isRequired,
  setChildAllowedExpertise: PropTypes.func.isRequired,
  childGoLiveAuto: PropTypes.number.isRequired,
  setChildGoLiveAuto: PropTypes.func.isRequired,
  childAllowedComments: PropTypes.bool.isRequired,
  setChildAllowedComments: PropTypes.func.isRequired,
  childAllowedInsights: PropTypes.bool.isRequired,
  setChildAllowedInsights: PropTypes.func.isRequired,
  childAllowedQuotations: PropTypes.bool.isRequired,
  setChildAllowedQuotations: PropTypes.func.isRequired,
  instructionsTime: PropTypes.string.isRequired,
  setInstructionsTime: PropTypes.func.isRequired,
  instructionsOther: PropTypes.string.isRequired,
  setInstructionsOther: PropTypes.func.isRequired,
};

export default ActivityEdit;
