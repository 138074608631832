import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// MUI
import { Collapse } from "@mui/material";
import MuiLink from "@mui/material/Link";

import MKBox from "../MaterialKit/MKBox";
import MKTypography from "../MaterialKit/MKTypography";
import DropdownMenu from "./DropdownMenu";
import CreateBugReport from "../dialog/CreateBugReport";

const Dropdown = ({ routes, open }) => {
  const [collapse, setCollapse] = useState("");

  const handleSetCollapse = (name) => (collapse === name ? setCollapse(false) : setCollapse(name));

  const [actionActive, setActionActive] = useState(false);
  const handleAction = (action) => {
    console.log("handleAction", action);
    setActionActive(true);
  };
  const handleDialogClose = (e) => {
    console.log("handleDialogClose", e);
    setActionActive(false);
  };

  const renderNavbarItems = routes.map(
    ({ name, icon, collapse: routeCollapses, href, route, action, collapse: navCollapse }) => (
      <DropdownMenu
        key={name}
        name={name}
        icon={icon}
        collapseStatus={name === collapse}
        onClick={action ? () => handleAction(action) : () => handleSetCollapse(name)}
        href={href}
        route={route}
        collapse={Boolean(navCollapse)}
      >
        <MKBox>
          {routeCollapses &&
            routeCollapses.map((item) => (
              <MKBox key={item.name} px={2}>
                {item.collapse ? (
                  <>
                    <MKTypography
                      display="block"
                      variant="button"
                      color="text"
                      fontWeight="bold"
                      textTransform="capitalize"
                      py={1}
                      px={0.5}
                    >
                      {item.name}
                    </MKTypography>
                    {item.collapse.map((el) => (
                      <MKTypography
                        key={el.name}
                        component={el.route ? Link : MuiLink}
                        to={el.route ? el.route : ""}
                        href={el.href ? el.href : ""}
                        target={el.href ? "_blank" : ""}
                        rel={el.href ? "noreferrer" : "noreferrer"}
                        minWidth="11.25rem"
                        display="block"
                        variant="button"
                        color="text"
                        textTransform="capitalize"
                        fontWeight="regular"
                        py={0.625}
                        px={2}
                        sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                          borderRadius: borderRadius.md,
                          cursor: "pointer",
                          transition: "all 300ms linear",
                          "&:hover": {
                            backgroundColor: grey[200],
                            color: dark.main,
                          },
                        })}
                      >
                        {el.name}
                      </MKTypography>
                    ))}
                  </>
                ) : (
                  <MKBox
                    key={item.key}
                    display="block"
                    color="text"
                    component={item.route ? Link : MuiLink}
                    to={item.route ? item.route : ""}
                    href={item.href ? item.href : ""}
                    target={item.href ? "_blank" : ""}
                    rel={item.href ? "noreferrer" : "noreferrer"}
                    sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
                      borderRadius: borderRadius.md,
                      cursor: "pointer",
                      transition: "all 300ms linear",
                      py: 1,
                      px: 1.625,

                      "&:hover": {
                        backgroundColor: grey[200],
                        color: dark.main,

                        "& *": {
                          color: dark.main,
                        },
                      },
                    })}
                  >
                    <MKTypography display="block" variant="button" fontWeight="bold" color="text">
                      {item.name}
                    </MKTypography>
                  </MKBox>
                )}
              </MKBox>
            ))}
        </MKBox>
      </DropdownMenu>
    )
  );

  return (
    <>
      <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
        <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
          {renderNavbarItems}
        </MKBox>
      </Collapse>
      {actionActive && <CreateBugReport handleDialogClose={handleDialogClose} />}
    </>
  );
};

Dropdown.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default Dropdown;
