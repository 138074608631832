import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// MUI
import { Container } from "@mui/material";

import Box from "../MaterialKit/MKBox";
import Button from "../MaterialKit/MKButton";
import Spinner from "../layout/Spinner";
import IntellCard from "../intell/IntellCard";
import NavbarTop from "../navbar/NavbarTop";
import { loadingIdea, getIdeas } from "../../actions/idea";
import exists from "../../utils/exists";

const PostAll = ({
  loadingIdea,
  getIdeas,
  idea: { loading, ideas },
  fellowship: { fellowship },
}) => {
  const [ideaType, setIdeaType] = useState(1);

  // console.log("ideas:", ideas);
  console.log("fellowship", fellowship);

  // Load on first render
  const navigate = useNavigate();
  useEffect(() => {
    if (!(exists(fellowship) && exists(fellowship._id))) {
      console.log("UNEXPECTED: Invalid fellowship!");
      navigate("/fellowships");
    }
    loadingIdea();
    getIdeas(fellowship._id, ideaType);
  }, [ideaType]);

  return loading ? (
    <Spinner />
  ) : (
    <>
      <NavbarTop paper bottomSpacePixels={10} />
      <Container sx={{ p: 0, mb: 1 }}>
        <Box
          sx={{
            mt: 1.5,
            mb: 2,
            display: "grid",
            gap: 0,
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <Button
            fullWidth
            onClick={() => setIdeaType(1)}
            sx={{
              borderBottom: ideaType === 1 ? "3px solid #0074F4" : "",
              borderRadius: 0,
              pt: 2.5,
            }}
          >
            Insights
          </Button>
          <Button
            fullWidth
            onClick={() => setIdeaType(0)}
            sx={{
              borderBottom: ideaType === 0 ? "3px solid #0074F4" : "",
              borderRadius: 0,
              pt: 2.5,
            }}
          >
            Comments
          </Button>
          <Button
            fullWidth
            onClick={() => setIdeaType(10)}
            sx={{
              borderBottom: ideaType === 10 ? "3px solid #0074F4" : "",
              borderRadius: 0,
              pt: 2.5,
            }}
          >
            Framing statements
          </Button>
        </Box>

        {ideas.map((e) => (
          <div key={e._id} style={{ marginTop: "8px" }}>
            <IntellCard intell={e} />
          </div>
        ))}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  idea: state.idea,
  fellowship: state.fellowship,
});

export default connect(mapStateToProps, {
  loadingIdea,
  getIdeas,
})(PostAll);
