import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import { Divider, Paper } from "@mui/material";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { Check } from "@mui/icons-material";

import { debugLevel } from "../../config/personalize";
import Typography from "../MaterialKit/MKTypography";
import Button from "../MaterialKit/MKButton";
import Box from "../MaterialKit/MKBox";
import Spinner from "../layout/Spinner";
import { getAI } from "../../actions/ai";
import { setAlert } from "../../actions/alert";
import SelectImageOptions from "./SelectImageOptions";
import SelectImagePrimary from "./SelectImagePrimary";
import exists from "../../utils/exists";

// Create a styled component for the glass container
const GlassContainer = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  overflow: "hidden",
  padding: theme.spacing(2),
  "&:hover": {
    boxShadow: `0 12px 32px 0 ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const SelectImage = ({
  useAI = false,
  avatarView = false, // rounded Avatar view
  onlyInitImage = false,
  cardMode = false,
  url,
  initialImage = "",
  updateUrlDetails, // Only updates fields that have changed
  description = "",
  getAI,
  setAlert,
  ai: { ai, error },
}) => {
  const theme = useTheme();

  if (useAI) {
    if (debugLevel > 0) console.log("Can generate AI-images for url:", url);
  }

  const prevUrlRef = useRef(null); // To track the previous URL
  const [scrapeData, setScrapeData] = useState({});
  const { validUrl, scrapedTitle, allImages, finalUrl } = scrapeData;
  const [selectedImage, setSelectedImage] = useState("");
  const [initialImageCopy] = useState(initialImage);

  useEffect(() => {
    setSelectedImage(initialImage);
  }, [initialImage]);

  // Memoizing the scrapeUrl fetch logic to avoid re-fetching unless URL changes
  const fetchScrapeData = useCallback(async () => {
    try {
      console.log("SCRAPE START:", url);

      // Check if URL is valid before making the API call
      let isValidUrl = false;
      let processedUrl = url;

      try {
        // First try to parse the URL as is - fix the unused variable warning
        const parsedUrl = new URL(url);
        isValidUrl = parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
      } catch (e) {
        // URL constructor throws if the URL is invalid
        // Try adding https:// prefix if no protocol was specified
        if (!url.match(/^https?:\/\//i)) {
          try {
            const urlWithProtocol = `https://${url}`;
            // Fix: Store the URL object instead of just using new for side effects
            const tempUrl = new URL(urlWithProtocol);
            isValidUrl = tempUrl.protocol === "http:" || tempUrl.protocol === "https:";
            processedUrl = urlWithProtocol;
          } catch (e2) {
            isValidUrl = false;
          }
        } else {
          isValidUrl = false;
        }
      }

      if (!isValidUrl) {
        console.log("Invalid URL, skipping API call:", url);
        setScrapeData({ validUrl: false });
        return;
      }

      const response = await fetch("/api/scrape", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ url: processedUrl, maxCount: 8 }),
      });
      const result = await response.json();
      console.log("SCRAPE RETURN:", result);
      if (!result.validUrl) {
        console.log(processedUrl, " SCRAPE INVALID:", result);
      }
      setScrapeData(result);
    } catch (error) {
      console.error("Error fetching scrape data:", error);
      setScrapeData(null);
    }
  }, [url]);

  useEffect(() => {
    if (url && url !== prevUrlRef.current) {
      prevUrlRef.current = url; // Track the previous URL
      fetchScrapeData(); // Fetch new data
    }
  }, [url, fetchScrapeData]);

  useEffect(() => {
    if (exists(validUrl)) {
      //  Done scraping
      if (debugLevel > 1) console.log("Done scraping:", validUrl, scrapedTitle, allImages);
      if (validUrl) {
        if (allImages && allImages.length > 0 && initialImage.length === 0) {
          // If nothing was pre-selected & at least one image was found by the webpage scraper, nominate the first scraped image
          setSelectedImage(allImages[0]);
          updateUrlDetails({
            title: scrapedTitle,
            validUrl,
            image: allImages[0],
            finalUrl,
          });
        } else {
          // Use default
          updateUrlDetails({
            title: scrapedTitle,
            validUrl,
            image: initialImage,
            finalUrl,
          });
        }
      } else {
        // invalid URL
        updateUrlDetails({
          validUrl,
        });
      }
    }
  }, [allImages, scrapedTitle, validUrl]);

  const [failedAttemptsAI, setFailedAttemptsAI] = useState(0);
  const [aiImage, setAiImage] = useState("");
  const [aiError, setAiError] = useState(false);
  const [aiPending, setAiPending] = useState(false);

  const generateAiImage = () => {
    if (useAI && !aiPending && description.length > 0 && !aiError) {
      setAiPending(true);
      updateUrlDetails({ busyAI: true });
      if (debugLevel > 0) console.log("AI image request:", description);

      if (failedAttemptsAI === 0) {
        // This is the first attempt
        if (debugLevel > 0)
          console.log("First attempt - waveMaker image AI image request:", description);
        getAI({
          model: "image",
          clientRequest: `Design an image that represents and is immediately identifiable of: ${description}`,
        });
      } else if (failedAttemptsAI === 1) {
        // First attempt failed; will now try again
        if (debugLevel > 0)
          console.log("Next attempt - waveMaker image AI image request:", description);
        getAI({
          model: "image",
          clientRequest: `Create a high-contrast, modern, and abstract representation of: ${description}, using a single universally recognizable symbol that best embodies its positive attributes, such as a human figure, scales of justice, heart, shield, owl, book, mountain, open palm, magnifying glass, anchor, light bulb, or circle of hands. The design should feature strong, clean lines, a minimalist aesthetic, and a striking contrast between light and dark elements to ensure visibility at small sizes. Use a limited but impactful color palette, such as black and white or complementary colors, to enhance clarity and recognizability. . It should be immediately identifiable as representing ${description}.`,
          //          clientRequest: `Create an abstract representation inspired by, and symbolizing the positive attributes of: ${description}. Use neutral but universally recognizable elements like an abstract human figure, symbols of integrity (lock, scales of justice), empathy (heart, open hands), courage (lion, shield), wisdom (owl, open book), perseverance (mountain, clenched fist), generosity (open palm, gift box), curiosity (magnifying glass, question mark), responsibility (anchor, checklist), creativity (light bulb, pencil), collaboration (circle of hands), professionalism (briefcase), or whatever universally recognizable elements/symbols/logos that are most representative for this individual/group. Use clean lines, a modern aesthetic, and soft colors. Most importantly, the image should be (1) immediately identifiable as representative of: ${description}, (2) it should not use more than 3 of the symbols listed above, and (3) The number 3 or a group of 3 things should not be prominent take-away.`,
        });
      } else {
        // Both prior attempts faileds; will not try again
        console.log("generateAiImage() failed; failedAttemptsAI=", failedAttemptsAI);
      }
    }
  };

  useEffect(() => {
    if (aiPending) {
      // url AI pending request
      if (error && error.status) {
        // AI error
        setAiPending(false);
        if (failedAttemptsAI === 0) {
          // First failed attempt; try again
          generateAiImage();
          setFailedAttemptsAI(1);
        } else {
          // Previously failed; all done
          setAiError(true);
          setAlert("Unable to generate AI image", "warning");
          setFailedAttemptsAI(failedAttemptsAI + 1);
          updateUrlDetails({ busyAI: false });
        }
      } else if (ai && ai.length > 0) {
        // Done image
        setAiImage(ai);
        setAiPending(false);
        setSelectedImage(ai);
        updateUrlDetails({ image: ai, busyAI: false });
      } else {
        // No response yet
        setAiImage("");
      }
    }
  }, [ai, error]);

  return (
    <div>
      {(exists(allImages) || (useAI && !aiError && description.length > 0)) && (
        <div>
          {aiPending ? (
            <GlassContainer elevation={0}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                <Spinner />
                <Typography
                  sx={{
                    fontSize: "14px",
                    mt: 2,
                    color: alpha(theme.palette.text.primary, 0.8),
                  }}
                >
                  Checking with our AI assistant...
                </Typography>
              </Box>
            </GlassContainer>
          ) : (
            <div>
              {onlyInitImage ? (
                <div>
                  {selectedImage && (
                    <SelectImagePrimary
                      selectedImage={selectedImage}
                      avatarView={avatarView}
                      small
                    />
                  )}
                </div>
              ) : (
                <div>
                  {cardMode ? (
                    <GlassContainer elevation={0}>
                      {selectedImage ? (
                        <Box position="relative" width="100%" height="100%">
                          <SelectImagePrimary
                            selectedImage={selectedImage}
                            avatarView={avatarView}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                              borderRadius: "28px",
                              mt: 2,
                              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${alpha(theme.palette.primary.light, 0.9)})`,
                              boxShadow: `0 8px 20px -4px ${alpha(theme.palette.primary.main, 0.4)}`,
                              "&:hover": {
                                boxShadow: `0 12px 24px -6px ${alpha(theme.palette.primary.main, 0.6)}`,
                                transform: "translateY(-2px)",
                              },
                              transition: "all 0.3s ease",
                            }}
                            startIcon={<Check />}
                            onClick={() => updateUrlDetails({ image: selectedImage })}
                          >
                            Confirm Image Selection
                          </Button>
                        </Box>
                      ) : (
                        <Box display="flex" justifyContent="center" sx={{ mb: 2 }}>
                          <Button
                            variant="outlined"
                            color="dark"
                            onClick={() => updateUrlDetails({ image: selectedImage })}
                            startIcon={<Check />}
                            sx={{
                              borderRadius: "28px",
                              py: 1,
                              px: 3,
                              borderWidth: "2px",
                              transition: "all 0.2s ease",
                              "&:hover": {
                                borderWidth: "2px",
                                transform: "translateY(-2px)",
                                boxShadow: `0 6px 15px -3px ${alpha(theme.palette.common.black, 0.15)}`,
                              },
                            }}
                          >
                            Confirm: No image
                          </Button>
                        </Box>
                      )}

                      <Divider sx={{ my: 2 }} />

                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: 400,
                          color: alpha(theme.palette.text.primary, 0.8),
                          mb: 2,
                        }}
                      >
                        {selectedImage
                          ? "Or select alternative image below"
                          : exists(allImages) && allImages.length > 0
                            ? "Or select an image below"
                            : "No images available"}
                      </Typography>

                      <SelectImageOptions
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                        url={url}
                        useAI={useAI}
                        aiError={aiError}
                        aiPending={aiPending}
                        description={description}
                        aiImage={aiImage}
                        allImages={allImages}
                        generateAiImage={generateAiImage}
                        initialImageCopy={initialImageCopy}
                      />
                    </GlassContainer>
                  ) : (
                    <GlassContainer elevation={0}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                          alignItems: "center",
                          gap: 3,
                        }}
                      >
                        <Box>
                          {selectedImage ? (
                            <SelectImagePrimary
                              selectedImage={selectedImage}
                              avatarView={avatarView}
                            />
                          ) : (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                border: `2px dashed ${alpha(theme.palette.text.secondary, 0.3)}`,
                                borderRadius: "12px",
                                width: { xs: "100px", sm: "150px" },
                                height: { xs: "100px", sm: "150px" },
                                color: alpha(theme.palette.text.secondary, 0.7),
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              NO IMAGE
                            </Box>
                          )}
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 500,
                              color: theme.palette.text.secondary,
                              mb: 1.5,
                            }}
                          >
                            Select most representative image
                          </Typography>

                          <SelectImageOptions
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                            url={url}
                            useAI={useAI}
                            aiError={aiError}
                            aiPending={aiPending}
                            description={description}
                            aiImage={aiImage}
                            allImages={allImages}
                            generateAiImage={generateAiImage}
                            initialImageCopy={initialImageCopy}
                          />
                        </Box>
                      </Box>
                    </GlassContainer>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SelectImage.propTypes = {
  url: PropTypes.string.isRequired,
  initialImage: PropTypes.string,
  updateUrlDetails: PropTypes.func.isRequired,
  description: PropTypes.string,
  useAI: PropTypes.bool,
  avatarView: PropTypes.bool,
  cardMode: PropTypes.bool,
  onlyInitImage: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  ai: state.ai,
});

export default connect(mapStateToProps, { getAI, setAlert })(SelectImage);
