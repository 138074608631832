import {
  FELLOWSHIP_ERROR,
  MODIFY_FELLOWSHIP,
  GET_FELLOWSHIPS,
  GET_FELLOWSHIP,
  ADD_FELLOWSHIP,
  LOADING_FELLOWSHIP,
  DELETE_FELLOWSHIP,
} from "../actions/types";

const initialState = {
  fellowships: [], //  only gets overwritten on GET_FELLOWSHIPS & LOADING_FELLOWSHIPS
  fellowship: null,
  loading: true,
  error: {},
};

// eslint-disable-next-line
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FELLOWSHIPS:
      return {
        ...state,
        fellowships: payload,
        loading: false,
      };

    case DELETE_FELLOWSHIP:
      return {
        ...state,
        fellowships: payload,
        fellowship: null,
        loading: false,
      };
    case MODIFY_FELLOWSHIP:
    case ADD_FELLOWSHIP:
    case GET_FELLOWSHIP:
      return {
        ...state,
        fellowship: payload,
        loading: false,
      };
    case FELLOWSHIP_ERROR:
      return {
        ...state,
        fellowships: [],
        fellowship: null,
        error: payload,
        loading: false,
      };
    case LOADING_FELLOWSHIP:
      return {
        ...state,
        fellowships: [],
        fellowship: null,
        loading: true,
      };
    default:
      return state;
  }
}
