const isBaseDomain = (url) => {
  // Remove protocol (http, https, etc.) and 'www.'
  const strippedUrl = url.replace(/^https?:\/\//, "").replace(/^www\./, "");

  // Split the remaining URL by '/' to isolate the domain part
  const domain = strippedUrl.split("/")[0];

  // Split the domain by '.' to analyze its parts
  const parts = domain.split(".");

  // Handle known subdomains that should not be stripped
  const knownSubdomains = ["developer.chrome.com", "news.google.com"];
  if (knownSubdomains.includes(domain)) {
    return false; // For known subdomains, return false
  }

  // Remove generic subdomains like 'www', 'ftp', etc.
  const genericSubdomains = ["www", "ftp", "mail", "blog", "api", "m"];
  if (genericSubdomains.includes(parts[0])) {
    parts.shift(); // Remove the first part if it's a generic subdomain
  }

  // Check if there's a path or query string after the domain
  const afterDomain = strippedUrl.slice(domain.length);
  if (afterDomain.startsWith("/") || afterDomain.startsWith("?")) {
    return false; // Not a base domain if there's a path or query
  }

  // Check if the domain is a base domain (2 parts like "example.com")
  if (parts.length === 2) {
    return true; // It's a base domain
  }

  // Check if it's a base domain with "www" as the first part (3 parts like "www.example.com")
  if (parts.length === 3 && parts[0] === "www") {
    return true; // It's a base domain
  }

  // Otherwise, it's not a base domain
  return false;
};

export default isBaseDomain;
