import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// MUI
import { Collapse } from "@mui/material";
import Icon from "@mui/material/Icon";

import MKBox from "../MaterialKit/MKBox";
import MKTypography from "../MaterialKit/MKTypography";

const DropdownMenu = ({
  name,
  icon,
  children = false,
  collapseStatus = false,
  href = "",
  route = "",
  collapse,
  ...rest
}) => {
  const linkComponent = {
    component: "a",
    href,
    target: "_blank",
    rel: "noreferrer",
  };

  const routeComponent = {
    component: Link,
    to: route,
  };

  return (
    <>
      <MKBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color="dark"
        opacity={1}
        sx={({ palette: { grey, dark } }) => ({
          cursor: "pointer",
          userSelect: "none",
          "&:hover": {
            backgroundColor: !collapse ? grey[200] : "transparent",
            color: !collapse ? dark.main : null,
          },
        })}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        <MKTypography
          variant="body2"
          lineHeight={1}
          color="inherit"
          sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
        >
          {icon}
        </MKTypography>
        <MKTypography
          variant="button"
          fontWeight="regular"
          color="inherit"
          sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
        >
          {name}
        </MKTypography>
        <MKTypography variant="body2" color="dark" ml="auto">
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
            {collapse && "keyboard_arrow_down"}
          </Icon>
        </MKTypography>
      </MKBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
};

DropdownMenu.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default DropdownMenu;
