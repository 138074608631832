import React from "react";
import PropTypes from "prop-types";

// MUI
import { Dialog, Container, Paper } from "@mui/material";
import { Whatshot, Reply, LinearScale, FormatQuote, Abc } from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";

import NavbarTitle from "../navbar/NavbarTitle";
import MyRadioMulti from "../layout/MyRadioMulti";
import MySwitch from "../layout/MySwitch";
import MyGoto from "../layout/MyGoto";
import SelectTextLength from "./SelectTextLength";

// Create a styled component for the content panel
const ContentPanel = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  border: `1px solid ${alpha(theme.palette.common.white, 0.2)}`,
  boxShadow: `0 8px 32px 0 ${alpha(theme.palette.common.black, 0.1)}`,
  transition: "all 0.3s ease",
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  "&:hover": {
    boxShadow: `0 12px 48px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    background: alpha(theme.palette.background.paper, 0.8),
  },
}));

const DialogIntell = ({
  handleBack,
  intellLink0,
  setIntellLink0,
  intellLink1,
  setIntellLink1,
  intellLink2,
  // setIntellLink2,
  intellLinkTextLength,
  setIntellLinkTextLength,
}) => {
  const options = [
    { label: "mandatory on first interpretation (optional afterwards)", value: 2 },
    { label: "optional on first interpretation (and optional afterwards)", value: 1 },
    { label: "not requested on first interpretation (but optional afterwards)", value: 0 },
    { label: "never requested", value: -1 },
  ];

  return (
    <Dialog open fullScreen>
      <NavbarTitle
        paper
        title="How will inTell be interpreted?"
        backClick={() => handleBack(false)}
      />
      <Container sx={{ mt: 4, mb: 3 }}>
        <ContentPanel elevation={0}>
          <MySwitch
            label="Initially, request levels of rationality & goodness"
            subLabel={[
              "Participants select positions on sliders.",
              "The positions on the sliders represent their view of how rational & how good is the inTell.",
            ]}
            icon={<LinearScale />}
            select
          />

          {/* (intellLink0): Reply (-1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-after) */}
          <MyRadioMulti
            label="Adding text comments are..."
            subLabel={["Perspectives, digging into the details, zooming in."]}
            icon={<Reply />}
            options={options}
            select={intellLink0}
            setSelect={setIntellLink0}
          />

          {/* (intellLink1): Reply (-1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-after) */}
          <MyRadioMulti
            label="Adding text insights are..."
            subLabel={[
              "Breaking-frame, zooming out to see the issue from a more relevant and/or nuanced frame.",
            ]}
            icon={<Whatshot />}
            options={options}
            select={intellLink1}
            setSelect={setIntellLink1}
          />

          {/* (intellLink2): Quotations  (-1:none, 0:none-initially-optional-after, 1=optional-initially-optional-after, 2:mandatory-initially-optional-after) */}
          <MyRadioMulti
            label="Adding quotations from this inTell are..."
            subLabel={["Focus in on specific words within this inTell."]}
            icon={<FormatQuote />}
            options={options}
            select={-1}
            divider={intellLink0 >= 0 || intellLink1 >= 0 || intellLink2 >= 0 ? "partial" : "none"}
          />

          {(intellLink0 >= 0 || intellLink1 >= 0 || intellLink2 >= 0) && (
            <MyGoto label="Length of text fields limited to..." icon={<Abc />} divider="none">
              <div style={{ marginTop: "5px" }}>
                <SelectTextLength
                  textLength={intellLinkTextLength}
                  setTextLength={setIntellLinkTextLength}
                />
              </div>
            </MyGoto>
          )}
        </ContentPanel>
      </Container>
    </Dialog>
  );
};

DialogIntell.propTypes = {
  handleBack: PropTypes.func.isRequired,
  intellLink0: PropTypes.number.isRequired,
  setIntellLink0: PropTypes.func.isRequired,
  intellLink1: PropTypes.number.isRequired,
  setIntellLink1: PropTypes.func.isRequired,
  intellLink2: PropTypes.number.isRequired,
  // setIntellLink2: PropTypes.func.isRequired,
  intellLinkTextLength: PropTypes.number.isRequired,
  setIntellLinkTextLength: PropTypes.func.isRequired,
};

export default DialogIntell;
