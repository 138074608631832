import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// MUI
import {
  Container,
  Card,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Slide,
} from "@mui/material";
import { Forum, MoreVert, Check } from "@mui/icons-material";

import elephantWhite from "assets/images/elephantWhite.svg";
import elephantBlack from "assets/images/elephantBlack.svg";

import Button from "../MaterialKit/MKButton";
import Typography from "../MaterialKit/MKTypography";
import ChildrenView from "./ChildrenView";
import Elephant from "./Elephant";
import SliderDiggingDeeper from "../slider/SliderDiggingDeeper";
import InterpretQuestion from "../interpret/InterpretQuestion";
import IntellCard from "../intell/IntellCard";
import exists from "../../utils/exists";

const QuestionView = ({ initIntell, activity = null, auth }) => {
  const [menuSelected, setMenuSelected] = useState(2);

  // START OF SWIPE HANDLER

  const [defaultTransitionDuration] = useState(300); // 250
  const [transitionDuration, setTransitionDuration] = useState(0);

  const [elephantSlideDirection] = useState("right");
  const [replySlideDirection] = useState("left");

  const [elephantView, setElephantView] = useState(menuSelected === 2);
  const [replyView, setReplyView] = useState(menuSelected === 3);

  // Change in menuSelected
  useEffect(() => {
    // Goto elephantView (#2)
    if (menuSelected === 2) {
      if (replyView) {
        // 3->2
        setReplyView(false);
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setElephantView(true);
        }, transitionDuration);
      }
    }
    // Goto replyView (#3)
    if (menuSelected === 3) {
      if (elephantView) {
        // 2->3
        setElephantView(false);
        setTransitionDuration(defaultTransitionDuration);
        setTimeout(() => {
          setReplyView(true);
        }, transitionDuration);
      }
    }
  }, [menuSelected, elephantView, replyView, transitionDuration]);

  // END OF SWIPE HANDLER

  const [viewOnlyChanged, setViewOnlyChanged] = useState(false);
  const [interpretations, setInterpretations] = useState([]);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [intell, setIntell] = useState(initIntell); // Initialized by parent (<Post> or <Activity>), but updated by child (<InterpretQuestion>, <Reply>)
  const [sliderExpertiseLevel, setSliderExpertiseLevel] = useState(0);
  const [expertsExist, setExpertsExist] = useState(false);
  const [myInterpretationExists, setMyInterpretationExists] = useState(false); // Whenever intell changes, check whether current user has interprete
  const [modifyAllowed, setModifyAllowed] = useState(false); // Only data using latest data structures can be updated

  const filterChanged = (arr) => {
    if (viewOnlyChanged) {
      return arr.filter(
        (e) =>
          (e.expertise &&
            exists(e.expertise.changedMind) &&
            e.expertise.changedMind &&
            exists(e.expertise.changedText) &&
            e.expertise.changedText.length > 0) ||
          exists(e.expertise.supportTopic1)
      );
    }
    return arr;
  };

  const filterOutNoElephantDisplayData = (arr) => {
    return arr.filter((e) => exists(e.supportTopic));
  };

  // sliderExpertiseLevel (user-controlled slider) ranges from 0->100, essentially mapping the expertise levels onto four sections of the slider
  // - e.expertise.myExpertiseLevel:1 translates to 25
  // - e.expertise.myExpertiseLevel:2 translates to 50
  // - e.expertise.myExpertiseLevel:3 translates to 75
  // - e.expertise.myExpertiseLevel:4 translates to 100
  const filterExpertise = (arr) => {
    if (sliderExpertiseLevel > 1) {
      return arr.filter((e) => 25 * e.expertise.myExpertiseLevel >= sliderExpertiseLevel);
    }
    return arr;
  };

  // First time & when <intell> changes
  useEffect(() => {
    if (
      auth &&
      auth.user &&
      auth.user._id &&
      intell.scales &&
      intell.scales.find((e) => e.user._id === auth.user._id)
    ) {
      setMyInterpretationExists(true);
      if (
        intell.scales.find((e) => e.user._id === auth.user._id && exists(e.supportTopic)) ||
        (intell.parentScale &&
          intell.parentScale.user &&
          auth &&
          auth.user &&
          auth.user._id &&
          ((intell.parentScale.user.id && intell.parentScale.user.id === auth.user._id) ||
            JSON.stringify(intell.parentScale.user) === JSON.stringify(auth.user._id)))
      ) {
        // Does not support legacy data that does not have supportTopic prop
        setModifyAllowed(true);
      }
    } else {
      setMyInterpretationExists(false);
    }
    if (menuSelected === 5) {
      setMenuSelected(2);
    }
  }, [intell]);

  const divRef = useRef(null);
  useEffect(() => {
    if (divRef && divRef.current && divRef.current.scrollIntoView) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [updatedCount]);

  const handleMenuSelected = (e) => {
    setMenuSelected(e);
  };

  // Prepare intell/scale data:
  // - Massage legacy data
  // - Determine if there is any expertise present in the interpretations
  // - Fully populate children ideas (ie, user)
  // - Props include:
  //   - updatedAt
  //   - user
  //   - ideaType
  //   - supportTopic
  //   - expertise
  //   - badgeType: 4
  //   - myPoint
  useEffect(() => {
    const selectedInterpretations = [];
    let foundExperts = false;

    intell.scales.forEach((node) => {
      if (exists(node.supportTopic)) {
        // Latest data
        if (
          node.expertise &&
          node.expertise.myExpertiseLevel &&
          node.expertise.myExpertiseLevel > 1
        ) {
          foundExperts = true;
        }

        // Under some circumstances (i.e., an agent is associated in the interpretation, but no reply is provided), we still display the inTell, and there we need a badge
        node.badgeType = 4;
        if (!node.ideaType) node.ideaType = 0;
        node.expertise = node.expertise ? node.expertise : { myExpertiseLevel: 1 };

        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i += 1) {
            node.children[i].user = node.user; // node.children[i].user is not fully populated; use user from fully populated scale
            if (!node.children[i].ideaType) node.children[i].ideaType = 0;
            node.children[i].supportTopic = node.supportTopic;
            node.children[i].supportTopic1 = node.supportTopic1;
            node.children[i].updatedAt = node.updatedAt; // node.children[i].updatedAt is already expected to be initialized (overwrite)
            node.children[i].createdAt = node.createdAt;
            node.children[i].expertise = node.expertise;
            node.children[i].badgeType = 4;
            // node.children[i].myPoint is already expected to be initialized
          }
        } else if (node.note && node.note.length > 0) {
          node.children = [
            {
              updatedAt: node.updatedAt,
              createdAt: node.createdAt,
              user: node.user,
              ideaType: 0,
              supportTopic: node.supportTopic,
              supportTopic1: node.supportTopic1,
              expertise: node.expertise,
              badgeType: 4,
              myPoint: node.note,
            },
          ];
        }
        selectedInterpretations.push(node);
      } else if (exists(node.authorIntent)) {
        // Legacy interpretation

        if (!node.expertise) {
          node.expertise = {
            myExpertiseText: node.myExpertise,
            changedText:
              node.haveChanged && node.haveChanged.length > 0
                ? node.haveChanged
                : node.wouldChange && node.wouldChange.length > 0
                  ? node.wouldChange
                  : null,
            changedMind: node.haveChanged && node.haveChanged.length > 0,
            myExpertiseLevel: !exists(node.confidence) ? 0.5 : node.confidence,
          };
        }
        if (node.expertise.myExpertiseLevel > 1) {
          foundExperts = true;
        }

        const modernizedScale = {
          user: node.user,
          updatedAt: node.updatedAt,
          expertise: node.expertise,
          supportTopic: node.authorIntent * node.confidence,
          badgeType: 4,
        };

        if (node.children && node.children.length > 0) {
          modernizedScale.children = node.children;
          for (let i = 0; i < modernizedScale.children.length; i += 1) {
            // modernizedScale.children[i].updatedAt is already initialized in copy above: modernizedScale.children = node.children
            modernizedScale.children[i].user = node.user; // node.children[i].user is not fully populated; use user from fully populated scale
            if (!modernizedScale.children[i].ideaType) modernizedScale.children[i].ideaType = 0;
            modernizedScale.children[i].supportTopic = modernizedScale.supportTopic;
            modernizedScale.children[i].expertise = node.expertise;
            modernizedScale.children[i].badgeType = 4;
            // modernizedScale.children[i].myPoint is already initialized in copy above: modernizedScale.children = node.children
          }
        } else {
          modernizedScale.children = [];
          if (node.note && node.note.length > 0) {
            modernizedScale.children.push({
              updatedAt: node.updatedAt,
              user: node.user,
              ideaType: 0,
              supportTopic: modernizedScale.supportTopic,
              expertise: node.expertise,
              badgeType: 4,
              myPoint: node.note,
            });
          } else if (
            node.ideaCreated &&
            node.ideaCreated.myPoint &&
            node.ideaCreated.myPoint.length > 0
          ) {
            modernizedScale.children.push({
              updatedAt: node.ideaCreated.iupdatedAt,
              user: node.user,
              ideaType: 0,
              supportTopic: modernizedScale.supportTopic,
              expertise: node.expertise,
              badgeType: 4,
              myPoint: node.ideaCreated.myPoint,
            });
          }
        }
        selectedInterpretations.push(modernizedScale);
      }
    });

    if (foundExperts) {
      setExpertsExist(true);
    } else {
      setExpertsExist(false);
    }

    setInterpretations(selectedInterpretations);
  }, [intell]);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDropdownItemSelected = (e) => {
    if (e === 1 || e === 2) {
      setViewOnlyChanged(!viewOnlyChanged);
    }
    if (e === 3) {
      setMenuSelected(5);
    }
    setAnchorEl(null);
  };
  let currentlyHovering = false;
  const handleHover = () => {
    currentlyHovering = true;
  };
  const handleCloseHover = () => {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 100);
  };

  return (
    <>
      <Container sx={{ p: 0, top: 0, mt: 2 }} maxWidth="sm">
        {/* Menu options */}
        <Card style={{ padding: "0px", marginTop: "1px", borderRadius: 0 }}>
          <div style={{ marginTop: "0px" }}>
            <IntellCard
              activity={
                !myInterpretationExists ||
                (activity &&
                  activity.submitter &&
                  auth &&
                  auth.user &&
                  activity.submitter._id === auth.user._id)
                  ? activity
                  : null
              }
              cardOutline="none"
              intell={intell}
            />

            {myInterpretationExists && (
              <>
                <Divider sx={{ mt: -0.5 }} />
                {menuSelected !== 5 && (
                  <div
                    style={{
                      marginBottom: "10px",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      variant={menuSelected === 2 ? "contained" : "text"}
                      iconOnly
                      color="primary"
                      onClick={() => handleMenuSelected(2)}
                    >
                      <img
                        src={menuSelected === 2 ? elephantWhite : elephantBlack}
                        style={{ width: "24px", marginTop: "-6px" }}
                        alt="..."
                      />
                    </Button>
                    <Button
                      variant={menuSelected === 3 ? "contained" : "text"}
                      iconOnly
                      color={menuSelected === 3 ? "primary" : "dark"}
                      onClick={() => handleMenuSelected(3)}
                    >
                      <Forum />
                    </Button>

                    <Button
                      variant="text"
                      color="dark"
                      iconOnly
                      size="large"
                      sx={{ mt: -1 }}
                      onClick={handleClick}
                      onMouseOver={handleClick}
                      onMouseLeave={handleCloseHover}
                    >
                      <MoreVert />
                    </Button>
                    <Menu
                      style={{ pointerEvents: "none" }}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      MenuListProps={{
                        onMouseEnter: handleHover,
                        onMouseLeave: handleCloseHover,
                        style: { pointerEvents: "auto" },
                      }}
                    >
                      <MenuItem>
                        {!viewOnlyChanged && (
                          <>
                            <ListItemIcon onClick={() => handleDropdownItemSelected(1)}>
                              <Check />
                            </ListItemIcon>
                            <Typography
                              variant="caption"
                              style={{ fontSize: "16px", marginRight: "10px" }}
                            >
                              View All Perspectives
                            </Typography>
                          </>
                        )}
                        {viewOnlyChanged && (
                          <ListItemText inset onClick={() => handleDropdownItemSelected(1)}>
                            <Typography
                              variant="caption"
                              style={{ fontSize: "16px", marginRight: "10px" }}
                            >
                              View All Perspectives
                            </Typography>
                          </ListItemText>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {viewOnlyChanged && (
                          <>
                            <ListItemIcon onClick={() => handleDropdownItemSelected(2)}>
                              <Check />
                            </ListItemIcon>
                            <Typography
                              variant="caption"
                              style={{ fontSize: "16px", marginRight: "10px" }}
                            >
                              View Shifted Perspectives (only)
                            </Typography>
                          </>
                        )}
                        {!viewOnlyChanged && (
                          <ListItemText inset onClick={() => handleDropdownItemSelected(2)}>
                            <Typography
                              variant="caption"
                              style={{ fontSize: "16px", marginRight: "10px" }}
                            >
                              View Shifted Perspectives (only)
                            </Typography>
                          </ListItemText>
                        )}
                      </MenuItem>

                      <Divider />

                      <MenuItem
                        disabled={!modifyAllowed}
                        onClick={() => handleDropdownItemSelected(3)}
                      >
                        <Typography
                          variant="caption"
                          style={{ fontSize: "16px", marginRight: "10px" }}
                        >
                          Edit my prior response
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </div>
                )}
                {menuSelected === 5 && (
                  <div style={{ textAlign: "center" }}>
                    <div style={{ marginTop: "5px", marginBottom: "15px" }}>
                      <Button
                        onClick={() => handleMenuSelected(2)}
                        color="primary"
                        circular
                        size="small"
                      >
                        Cancel Editing
                      </Button>
                    </div>
                  </div>
                )}

                {menuSelected !== 5 && expertsExist && (
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                  >
                    <Container sx={{ p: 0, top: 0 }} maxWidth="sm">
                      <SliderDiggingDeeper
                        userInitLevel={sliderExpertiseLevel}
                        updated={setSliderExpertiseLevel}
                      />
                    </Container>
                  </div>
                )}
              </>
            )}
          </div>
        </Card>

        {/* Options below the menu */}
        {myInterpretationExists && (
          <div style={{ marginTop: "30px" }}>
            <Slide
              in={elephantView}
              timeout={transitionDuration}
              direction={elephantSlideDirection}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <Elephant
                  interpretations={filterExpertise(
                    filterChanged(filterOutNoElephantDisplayData(interpretations))
                  )}
                  showChangeLines={viewOnlyChanged}
                  updatedCount={updatedCount}
                  setUpdatedCount={setUpdatedCount}
                  isQuestion
                />
              </div>
            </Slide>

            <Slide
              in={replyView}
              timeout={transitionDuration}
              direction={replySlideDirection}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <ChildrenView
                  ideaType={0}
                  intell={intell}
                  setIntell={setIntell}
                  interpretations={filterExpertise(filterChanged(interpretations))}
                  label="On this issue, what has shaped your view most?"
                  displaySupportOppose
                />
              </div>
            </Slide>
          </div>
        )}

        {(menuSelected === 5 || !myInterpretationExists) && (
          <InterpretQuestion
            intell={intell}
            setIntell={setIntell}
            updatedCount={updatedCount}
            setUpdatedCount={setUpdatedCount}
            activity={activity}
          />
        )}
      </Container>
      <div ref={divRef} />
    </>
  );
};

QuestionView.propTypes = {
  initIntell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activity: PropTypes.oneOfType([PropTypes.object]),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(QuestionView);
