import React from "react";
import PropTypes from "prop-types";

// MUI
import { styled } from "@mui/material/styles";
import { Bolt, RecordVoiceOver } from "@mui/icons-material";
import { Avatar } from "@mui/material";

import MyInterpretation from "../chart/MyInterpretation";
import MySupport from "../chart/MySupport";
import exists from "../../utils/exists";

const MyAvatar = styled(Avatar)(({ theme }) => ({
  border: `2px solid ${theme.palette.background.paper}`,
}));

const QuadBadge = ({ intell, size }) => {
  const getBadgeContent = () => {
    switch (intell.badgeType) {
      case 1:
        return <Bolt />; // Manipulative
      case 2:
        return <RecordVoiceOver />; // My inTell
      case 3:
        // Reasonable-good
        if (exists(intell.rational) && exists(intell.theGood)) {
          //        const bgColor =
          //          intell.rational > 0.2 ? "limegreen" : intell.rational < -0.2 ? "red" : undefined;
          return (
            <div style={{ marginLeft: "1px", marginTop: "3px" }}>
              <MyInterpretation xLevel={intell.rational} yLevel={intell.theGood} size="xs" />
            </div>
          );
        }
        break;
      case 4:
        // Support-oppose
        if (exists(intell.supportTopic)) {
          //          const bgColor =
          //            intell.supportTopic > 0.2
          //              ? "limegreen"
          //              : intell.supportTopic < -0.2
          //                ? "red"
          //                : undefined;
          return (
            <div style={{ marginLeft: "1px", marginTop: "7px" }}>
              <MySupport supportOpposeLevel={intell.supportTopic} size="xs" />
            </div>
          );
        }
        break;
      default:
        return null;
    }
    return null; // Ensure a return value in all cases.
  };

  const badgeContent = getBadgeContent();
  if (!badgeContent) return null;

  const bgColor = (() => {
    switch (intell.badgeType) {
      case 1:
      case 2:
        return "red";
      case 3:
        return intell.rational > 0.2 ? "limegreen" : intell.rational < -0.2 ? "red" : undefined;
      case 4:
        return intell.supportTopic > 0.2
          ? "limegreen"
          : intell.supportTopic < -0.2
            ? "red"
            : undefined;
      default:
        return "black";
    }
  })();

  return (
    <div style={{ marginLeft: "0px" }}>
      <MyAvatar sx={{ bgcolor: bgColor, width: size, height: size }}>{badgeContent}</MyAvatar>
    </div>
  );
};

QuadBadge.propTypes = {
  intell: PropTypes.oneOfType([PropTypes.object]).isRequired,
  size: PropTypes.number.isRequired,
};

export default QuadBadge;
